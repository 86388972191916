import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface AssignService {
  id: string;
  name: string;
  description: string;
  price: string;
  durationInMinute: string;
  service_category_id: string;
  image_path: string;
  partner_id: string;
  slug: string;
  is_active: boolean;
  created_at: Date;
  created_by: string;
  modified_at: Date;
  modified_by: string;
  serviceCategory: ServiceCategory;
  service_category_name: string;
  assignedto: string;
}
export interface ServiceCategory {
  id: string;
  name: string;
  description: string;
  partner_id: string;
  slug: string;
  is_active: boolean;
  created_at: Date;
  created_by: string;
  modifiedAt: Date;
  modified_by: string;
}
interface ServicesState {
  services: AssignService[];
}
const initialState: ServicesState = {
  services: [],
};
const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    resetService: (state) => {
      state.services = [];
    },
    addService: (state, action: PayloadAction<any>) => {
      const newItem = action.payload;
      // Add the new item to the `items` array in the state
      state.services.push(newItem);
    },
    updateService: (state, action: PayloadAction<any>) => {
      const newItem = action.payload;
      // Add the new item to the `items` array in the state
      state.services = newItem;
    },
    removeService: (state, action: PayloadAction<any>) => {
      const newItem = action.payload;

      state.services = state.services.filter((item) => item.id !== newItem);
    },
  },
});

export const { addService, removeService, updateService, resetService } =
  servicesSlice.actions;

export default servicesSlice.reducer;
