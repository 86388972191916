import { Button, IconButton, MenuItem, OutlinedInput } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import add_circle from "../../../../assets/images/add-circle.png";

import ButtonLog from "../../../../components/common/Button/Button";
import CustomLoader from "../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../components/common/SnackBar/SnackBar";
import {
  getAuthDataFromLocalStorage,
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../utils/helper.utils";
import { SnackStateProp } from "../../Mechanic/components/Mechanic";
import { EditProfileProps } from "../container/editProfileContainer";
import styles from "./EditProfile.module.css";

export default function EditProfile(props: EditProfileProps) {
  const {
    GetAllActiveService,
    activeServiceDetails,
    activeServiceError,
    activeServiceLoading,
    activeServiceSuccess,
    UpdatePartner,
    updatePartner_Details,
    updatePartner_Error,
    updatePartner_Loading,
    updatePartner_Success,
    UpdateUser,
    updateUser_Details,
    updateUser_Error,
    updateUser_Loading,
    updateUser_Success,
    Reset,
  } = props;
  const userDataParse = getUserDataFromLocalStorage();
  const authData = getAuthDataFromLocalStorage();
  const navigate = useNavigate();
  const options = ["Supervisor", "Mechanic"];
  useEffect(() => {
    if (authData.rolename === "Mechanic")
      GetAllActiveService(userDataParse.partner_id);
  }, [GetAllActiveService, authData.rolename, userDataParse.partner_id]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedOptions(event.target.value as string[]);
  };
  const [image, setImage] = React.useState<any>();

  const [formType, setFormType] = React.useState<string | undefined>(
    "edit-profile"
  );
  let param = useParams();
  const { state } = useLocation();

  // let userDataParse: { id: string; company_id: number } = {
  //   id: "",
  //   company_id: 0,
  // };

  // const userData: any = localStorage.getItem("USER_DATA");

  const [user, setUser] = React.useState({
    email: userDataParse ? userDataParse?.email_id : "",
    name: userDataParse ? userDataParse?.name : "",
    // partner_id: userDataParse.partner_id,
    logo: userDataParse ? userDataParse?.logo : "",
    specialities: userDataParse.specialities ? userDataParse.specialities : [],
    slug: "user",
  });
  // const [checked, setChecked] = React.useState(
  //   state ? state?.user.is_active : true
  // );

  const handleChange = (event: any) => {
    const updatedValue = event.target.checked;
    // setChecked(updatedValue);
  };
  useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "error",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: user.name,
      errorMsz: "Name is required and must be of maximum 30 characters",
      max: 30,
      min: 1,
    },
    {
      checkwith: "regex",
      value: user.email,
      errorMsz: "Please enter valid email address",
      regex: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
    },
  ];
  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL: any = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  let imageIndex = 0;

  const handleImageUpload = React.useCallback(
    async (e: any) => {
      setImage(e.target.files[0]);
      let selectedFile = e.target.files[0];
      if (image?.length <= 3 && e.target.files[0].size < 500000) {
        setImage([
          ...image,
          {
            index: imageIndex++,
            imgUrl: URL.createObjectURL(selectedFile),
          },
        ]);
      } else {
      }
      getBase64(selectedFile)
        .then((base64) => {
          selectedFile["base64"] = base64;
          // setFile(selectedFile);
          // setBase64URL([...base64URL, base64]);
          setUser((prevState: typeof user) => ({
            ...prevState,
            logo: base64,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
      setImage(URL.createObjectURL(e.target.files[0]));
    },
    [image, imageIndex]
  );
  const handleSave = () => {
    validate(ValidateData).status
      ? UpdatePartner({
          ...user,
          partner_id: userDataParse.partner_id,
          updated_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };

  const handleUserSave = () => {
    UpdateUser({
      ...user,
      // is_active: checked ? true : false,
      partner_id: userDataParse.partner_id,
      user_id: userDataParse.id,
      updated_by: userDataParse.id,
    });
  };
  const [userType, setUserType] = React.useState("");
  // const handleChange = (event: SelectChangeEvent) => {
  //   setUserType(event.target.value as string);
  // };
  React.useEffect(() => {
    if (updateUser_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateUser_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      Reset();
    } else if (updateUser_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateUser_Details || updateUser_Error),
        snackOpen: true,
        snackSeverity: "error",
      }));
      Reset();
    }
  }, [
    Reset,
    navigate,
    updateUser_Details,
    updateUser_Error,
    updateUser_Success,
  ]);

  React.useEffect(() => {
    if (updatePartner_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updatePartner_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      Reset();
    } else if (updatePartner_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updatePartner_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      Reset();
    }
  }, [
    Reset,
    navigate,
    updatePartner_Details,
    updatePartner_Error,
    updatePartner_Success,
  ]);
  const handleDeleteSpecialty = (index: number) => {
    // if (!selectedMechanic) {
    //   return;
    // }
    // const updatedSpecialties = [
    //   ...selectedMechanic.garage_mechanic_specialties,
    // ];
    // updatedSpecialties.splice(index, 1);
    // const updatedMechanic = {
    //   ...selectedMechanic,
    //   garage_mechanic_specialties: updatedSpecialties,
    // };
    // setSelectedMechanic(updatedMechanic);
  };
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {activeServiceLoading || updatePartner_Loading || updateUser_Loading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>{"Edit Profile"}</p>
        </div>
        <div className="container mt-5" style={{ backgroundColor: "#F5F5F5;" }}>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>Full Name</span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Full Name"
                  type="text"
                  onChange={(e) => {
                    setUser((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  value={user?.name}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>Email</span>
                <OutlinedInput
                  placeholder="Enter Email"
                  className={styles.outline_inputbox}
                  type="email"
                  onChange={(e) => {
                    setUser((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                  value={user?.email}
                  disabled={formType === "edit-profile"}
                />
              </FormControl>
            </div>
          </div>
          {authData.rolename === "Partner" ? (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <label className={styles.label} htmlFor="colorPicker">
                      Logo
                    </label>
                    <div className="imageAdder">
                      <div className={styles.vehiclesimagesAdd}>
                        <div className={styles.img_container}>
                          {!image ? (
                            <Button
                              className={styles.uploadLogo_btn}
                              component="label"
                              color="secondary"
                            >
                              <img
                                src={add_circle}
                                alt=""
                                width="24"
                                className="my-2"
                              />
                              <p className={styles.add_img_txt}>Add Image</p>
                              <input
                                hidden
                                accept="image/*"
                                name="cover"
                                type="file"
                                onChange={handleImageUpload}
                              />
                            </Button>
                          ) : (
                            <img
                              src={image}
                              alt=""
                              width="100"
                              className="img-fluid"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <label className={styles.label} htmlFor="colorPicker">
                      Specialties
                    </label>
                    <FormControl
                      className={styles.formControl}
                      size="small"
                      sx={{
                        width: "100%",
                      }}
                    >
                      {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={userType}
                  label="Role"
                  onChange={(e) => {
                    setUser((prevState) => ({
                      ...prevState,
                      user_type: e.target.value,
                    }));
                  }}
                  value={user?.user_type}
                  className={styles.outline_inputbox}
                >
                
                  <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                  <MenuItem value={"Mechanic"}>Mechanic</MenuItem>
                </Select> */}
                      <Select
                        className={styles.outline_inputbox}
                        multiple
                        value={user.specialities}
                        onChange={(e) => {
                          setUser((prevState: any) => ({
                            ...prevState,
                            specialities: e.target.value as string[],
                          }));
                        }}
                        renderValue={(selected) =>
                          (selected as string[]).join(", ")
                        }
                      >
                        {activeServiceDetails?.map((option) => (
                          <MenuItem key={option.id} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-6">
                  <Box sx={{ mt: 2 }}>
                    <label className={styles.label} htmlFor="colorPicker">
                      Selected Specialty
                    </label>
                    {[1, 2, 3].length > 0 ? (
                      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1 }}>
                        {userDataParse.specialities.map(
                          (specialty: string[], index: number) => (
                            <Chip
                              key={index}
                              label={specialty}
                              variant="outlined"
                              // onDelete={() => handleDeleteSpecialty(index)}
                              // deleteIcon={<Delete />}
                              sx={{ m: 1 }}
                            />
                          )
                        )}
                      </Box>
                    ) : (
                      <Typography>No specialties available.</Typography>
                    )}
                  </Box>
                </div>
              </div> */}
            </>
          )}

          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              variant={"contained"}
              className={styles.saveBtn}
              onClick={
                authData.rolename === "Partner" ? handleSave : handleUserSave
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
