import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import {
  CreateFeedbackMechanics,
  GetAllMechanics,
  GetAllSupervisor,
} from "./allMechanics.request";
import { MechanicFeedBackDT, MechanicsState } from "./types";

export const initialState: MechanicsState = {
  getAllMechanics_Loading: false,
  getAllMechanics_Error: false,
  getAllMechanics_Success: false,
  getAllMechanics_details: [],
  createFeedbackMechanics_Loading: false,
  createFeedbackMechanics_Error: false,
  createFeedbackMechanics_Success: false,
  createFeedbackMechanics_details: null,
  getAllSupervisor_Loading: false,
  getAllSupervisor_Error: false,
  getAllSupervisor_Success: false,
  getAllSupervisor_details: [],
};
export const getAllMechanics = createAsyncThunk(
  "user/getAllMechanics",
  (id: string) => {
    return GetAllMechanics(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data.mechanic;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const createFeedbackMechanics = createAsyncThunk(
  "user/createFeedbackMechanics",
  (body: MechanicFeedBackDT) => {
    return CreateFeedbackMechanics(body)
      .then((res) => {
        if (res.status === 201) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getAllSupervisor = createAsyncThunk(
  "user/getAllSupervisor",
  (id: string) => {
    return GetAllSupervisor(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const MechanicsList = createSlice({
  name: "MechanicsList",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMechanics.pending, (state) => {
      state.getAllMechanics_Loading = true;
      state.getAllMechanics_Error = false;
      state.getAllMechanics_Success = false;
    });
    builder.addCase(
      getAllMechanics.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllMechanics_Loading = false;
        state.getAllMechanics_Error = false;
        state.getAllMechanics_Success = true;
        state.getAllMechanics_details = action?.payload;
      }
    );
    builder.addCase(getAllMechanics.rejected, (state, action: AnyAction) => {
      state.getAllMechanics_Loading = false;
      state.getAllMechanics_Error = false;
      state.getAllMechanics_Success =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createFeedbackMechanics.pending, (state) => {
      state.createFeedbackMechanics_Loading = true;
      state.createFeedbackMechanics_Error = false;
      state.createFeedbackMechanics_Success = false;
    });
    builder.addCase(
      createFeedbackMechanics.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createFeedbackMechanics_Loading = false;
        state.createFeedbackMechanics_Error = false;
        state.createFeedbackMechanics_Success = true;
        state.createFeedbackMechanics_details = action?.payload;
      }
    );
    builder.addCase(
      createFeedbackMechanics.rejected,
      (state, action: AnyAction) => {
        state.createFeedbackMechanics_Loading = false;
        state.createFeedbackMechanics_Error = false;
        state.createFeedbackMechanics_Success =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getAllSupervisor.pending, (state) => {
      state.getAllSupervisor_Loading = true;
      state.getAllSupervisor_Error = false;
      state.getAllSupervisor_Success = false;
    });
    builder.addCase(
      getAllSupervisor.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllSupervisor_Loading = false;
        state.getAllSupervisor_Error = false;
        state.getAllSupervisor_Success = true;
        state.getAllSupervisor_details = action?.payload;
      }
    );
    builder.addCase(getAllSupervisor.rejected, (state, action: AnyAction) => {
      state.getAllSupervisor_Loading = false;
      state.getAllSupervisor_Error = false;
      state.getAllSupervisor_Success =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default MechanicsList.reducer;
export const { reset } = MechanicsList.actions;
