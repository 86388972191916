import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Spare {
  ID: number;
  Name: string;
  Price: number;
  Quantity: number;
  spareCount: number;
}

interface SpareState {
  spares: Spare[];
}

const initialState: SpareState = {
  spares: [],
};

const sparesSlice = createSlice({
  name: "spares",
  initialState,
  reducers: {
    resetSpare: (state) => {
      state.spares = [];
    },
    addSpare: (state, action: PayloadAction<any>) => {
      // const newItem = action.payload;
      // const matchedData = state.spares.find(
      //   (ele: any) => ele.spareId === newItem.spareId
      // );
      // if (matchedData) {
      //   ++matchedData.Quantity;
      //   matchedData.Price = newItem.Price * matchedData.Quantity;
      // } else {
      //   state.spares.push({ ...newItem, Quantity: 1 });
      // }
      const newItem = action.payload;
      const existingItem = state.spares.find((item) => item.ID === newItem.ID);
      if (existingItem) {
        existingItem.Quantity += 1;
        existingItem.Price = newItem.Price * existingItem.Quantity;
      } else {
        state.spares.push({ ...newItem, Quantity: 1 });
      }

      // Add the new item to the `spares` array in the state
      // state.spares.push(newItem);
    },
    removeSpare: (state, action: PayloadAction<any>) => {
      // const spareId = action.payload;
      // const matchedData = state.spares.find(
      //   (ele: any) => ele.spareId === spareId
      // );
      // if (matchedData) {
      //   if (matchedData.Quantity > 1) {
      //     matchedData.Price =
      //       matchedData.Price - matchedData.Price / matchedData.Quantity;
      //     --matchedData.Quantity;
      //   } else {
      //     state.spares.filter((spare: any) => spareId !== spare.spareId);
      //   }
      // }
      const spareId = action.payload;
      const existingItem = state.spares.find((item) => item.ID === spareId);
      if (existingItem) {
        if (existingItem.Quantity === 1) {
          state.spares = state.spares.filter((item) => item.ID !== spareId);
        } else {
          existingItem.Quantity -= 1;
          existingItem.Price =
            existingItem.Price - existingItem.Price / existingItem.Quantity;
        }
        // state.totalPrice -= existingItem.Price;
      }
    },
  },
});

export const { addSpare, removeSpare, resetSpare } = sparesSlice.actions;

export default sparesSlice.reducer;
