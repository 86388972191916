import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Button, Chip } from "@mui/material";
import { Edit } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { PartnerDT } from "../../../../pages/app/Configuration/types";
import ButtonLog from "../../../common/Button/Button";
import CustomTable from "../../../common/Table/CustomTable";
import styles from "../../User/components/User.module.css";

interface PartnerProps {
  PartnerDetails: PartnerDT[] | null;
}

export default function Partner(props: PartnerProps) {
  const { PartnerDetails } = props;
  const navigation = useNavigate();
  const handleEditClick = (partner: PartnerDT) => {
    navigation("/partner-form/edit", {
      state: { partner },
    });
  };

  const updatedRows: any = PartnerDetails?.map((row: PartnerDT) => ({
    ...row,
    is_active:
      row.is_active === false ? (
        <Chip label="In Active" variant="outlined" color="error" />
      ) : (
        <Chip label="Active" variant="outlined" color="success" />
      ),
    action: (
      <Button
        variant="outlined"
        startIcon={<Edit size="18" color="#0761e9" />}
        color="primary"
        onClick={() => handleEditClick(row)}
      >
        Edit
      </Button>
    ),
  }));

  const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "email", label: "Email", minWidth: 100 },
    { id: "phone_number", label: "Phone No.", minWidth: 100 },
    { id: "garage_name", label: "Garage Name", minWidth: 100 },
    { id: "is_active", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  return (
    <>
      <div className={styles.inventoryNavBar}>
        <ButtonLog
          text={"Back"}
          variant={"contained"}
          onClick={() => navigation(-1)}
          className={styles.backBtn}
        />
        <div className={styles.filter}>
          <ButtonLog
            text={"Add Partner"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/partner-form/add")}
          />
        </div>
      </div>

      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <div className="table-responsive">
          <CustomTable columns={columns} rows={updatedRows} />
        </div>
      </div>
    </>
  );
}
