import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  ForgotPasswordRequest,
  ResetPasswordRequest,
} from "./forgotPassword.request";
import { FORGOTPASSWORDDT, ForgotPasswordInit, ResetPasswordDT } from "./types";

export const initialState: ForgotPasswordInit = {
  forgotPasswordSuccess: false,
  forgotPasswordError: false,
  forgotPasswordLoading: false,
  forgotPasswordDetails: "",
  resetPasswordSuccess: false,
  resetPasswordError: false,
  resetPasswordLoading: false,
  resetPasswordDetails: "",
};

export const forgotpassword = createAsyncThunk(
  "user/forgotpassword",
  (data: FORGOTPASSWORDDT) => {
    return ForgotPasswordRequest(data)
      .then((res) => {
        if (res.status === 201) {
        }
        return res.data.message;
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  }
);
export const resetpassword = createAsyncThunk(
  "user/resetpassword",
  (data: ResetPasswordDT) => {
    return ResetPasswordRequest(data)
      .then((res) => {
        if (res.status === 201) {
        }
        return res.data.message;
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  }
);
export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(forgotpassword.pending, (state) => {
      state.forgotPasswordLoading = true;
      state.forgotPasswordError = false;
      state.forgotPasswordSuccess = false;
    });
    builder.addCase(
      forgotpassword.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordSuccess = true;
        state.forgotPasswordError = false;
        state.forgotPasswordDetails = action?.payload;
      }
    );
    builder.addCase(forgotpassword.rejected, (state, action: AnyAction) => {
      state.forgotPasswordLoading = false;

      state.forgotPasswordSuccess = false;
      state.forgotPasswordError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(resetpassword.pending, (state) => {
      state.resetPasswordLoading = true;
      state.resetPasswordError = false;
      state.resetPasswordSuccess = false;
    });
    builder.addCase(
      resetpassword.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.resetPasswordLoading = false;
        state.resetPasswordSuccess = true;
        state.resetPasswordError = false;
        state.resetPasswordDetails = action?.payload;
      }
    );
    builder.addCase(resetpassword.rejected, (state, action: AnyAction) => {
      state.resetPasswordLoading = false;

      state.resetPasswordSuccess = false;
      state.resetPasswordError =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default forgotPasswordSlice.reducer;
export const { reset } = forgotPasswordSlice.actions;
