import Photo from "../../../assets/images/Photo.png";
import { Technician } from "./types";

export const technicians: Technician[] = [
  {
    name: "Jel Chibuzo",
    img_url: Photo,
    category_name: "Tech Categories Name",
    pending: 9,
    complete: 3,
    cost: 201,
  },
  {
    name: "Jel Chibuzo",
    img_url: Photo,
    category_name: "Tech Categories Name",
    pending: 12,
    complete: 4,
    cost: 190,
  },
  {
    name: "Jel Chibuzo",
    img_url: Photo,
    category_name: "Tech Categories Name",
    pending: 3,
    complete: 3,
    cost: 133,
  },
];
export const freetechnicians: Technician[] = [
  {
    name: "Jel Chibuzo",
    img_url: Photo,
    category_name: "Tech Categories Name",
    pending: 0,
    complete: 3,
    cost: 201,
  },
  {
    name: "Jel Chibuzo",
    img_url: Photo,
    category_name: "Tech Categories Name",
    pending: 0,
    complete: 4,
    cost: 190,
  },
];
