import axios, { AxiosError, AxiosRequestConfig } from "axios";
import Swal from "sweetalert2";
import { tokenExpiredError } from "../utils/helper.utils";

interface UserData {
  token: string;
}

const nonTokenHeader: AxiosRequestConfig["headers"] = {
  Accept: "*",
  "Content-Type": "application/json",
};

const tokenHeader: AxiosRequestConfig["headers"] = {
  Accept: "*",
  "Content-Type": "application/json",
};
const userDataString = localStorage.getItem("TOKEN");
if (userDataString) {
  const userData: UserData = JSON.parse(userDataString);
  tokenHeader.Authorization = `Bearer ${userData.token}`;
}

const headers = tokenHeader.Authorization ? tokenHeader : nonTokenHeader;
const axiosOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers,
};

const instance = axios.create(axiosOptions);
instance.interceptors.request.use(
  (request) => {
    if (!navigator.onLine) {
      showErrorToUser(
        "Device is offline. Please check your internet connection."
      );
      return Promise.reject(new Error("Device offline"));
    }
    return request;
  },
  (error) => {
    console.log("ERROR IN [instance.interceptors.request.use]: ", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    console.error(error);

    if (!navigator.onLine) {
      showErrorToUser("Device went offline while processing the request.");
      return Promise.reject(new Error("Device offline"));
    }

    const err = {
      url: error.config?.baseURL,
      body: error.config?.data ? JSON.parse(error.config.data) : null,
      status: error.response ? error.response.status : null,
      message: error.response ? error.response.data : null,
    };

    if (error.response && error.response.status === 401) {
      handleTokenExpiredError();
    } else if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      handleClientError(error.response.status);
    } else if (
      error.response &&
      error.response.status >= 500 &&
      error.response.status < 600
    ) {
      handleServerError(error.response.status);
    } else {
      handleOtherErrors();
    }

    return Promise.reject(error);
  }
);

export { instance as axios };

function showErrorToUser(errorMessage: string) {
  Swal.fire({
    icon: "error",
    text: errorMessage,
    confirmButtonColor: "#0761E9",
  });
}

function handleTokenExpiredError() {
  // redirectToLogin();
  tokenExpiredError();
}

function handleClientError(statusCode: number) {
  if (statusCode === 400) {
    showErrorToUser("Bad request. Please check your input.");
  } else if (statusCode === 404) {
    // showErrorToUser("Resource not found.");
  } else {
    showErrorToUser("Client error occurred.");
  }
}

function handleServerError(statusCode: number) {
  if (statusCode === 500) {
    showErrorToUser("Internal server error. Please try again later.");
  } else {
    showErrorToUser("Server error occurred.");
  }
}

function handleOtherErrors() {
  showErrorToUser("Something went wrong. Please try again.");
}
