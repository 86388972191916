import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  SnackbarOrigin,
  Switch,
} from "@mui/material";

import FormControl from "@mui/material/FormControl";
import { groupBy } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../utils/helper.utils";
import ButtonLog from "../../../../common/Button/Button";
import CustomLoader from "../../../../common/Loader/Loader";
import { SnackBar } from "../../../../common/SnackBar/SnackBar";
import { RoleFormProps } from "../../container/roleContainer";
import styles from "../Role.module.css";
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
interface CheckedFunction {
  id: string;
  checked: boolean;
}
export default function RoleForm(props: RoleFormProps) {
  const {
    GetAllRole,
    RoleDetails,
    getAllRoleError,
    getAllRoleLoading,
    getAllRoleSuccess,
    FunctionDetails,
    GetAllFunction,
    getAllFunctionError,
    getAllFunctionLoading,
    getAllFunctionSuccess,
    CreateRole,
    createRoleDetails,
    createRoleError,
    createRoleLoading,
    createRoleSuccess,
    ResetAll,
  } = props;

  useEffect(() => {
    GetAllRole();
    GetAllFunction();
  }, [GetAllFunction, GetAllRole]);

  const userDataParse = getUserDataFromLocalStorage();
  const { state } = useLocation();
  let param = useParams();
  React.useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);
  const [formType, setFormType] = React.useState<string | undefined>(
    "role-form/add"
  );
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const [role, setRole] = React.useState({
    // partner_id: userDataParse.partner_id,
    roleid: state ? state?.role.roleid : "",
    // slug: "user",
  });

  const [selectedFunctionIds, setSelectedFunctionIds] = useState<string[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [functionCheckboxes, setFunctionCheckboxes] = useState<
    CheckedFunction[]
  >([]);

  useEffect(() => {
    const initialCheckboxes = FunctionDetails?.map((func) => ({
      id: func.id,
      checked: false,
    }));
    setFunctionCheckboxes(initialCheckboxes);
  }, [FunctionDetails]);
  const categorizedFunctions = groupBy(FunctionDetails, "category_name");

  const handleFunctionCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const checked = e.target.checked;
    const updatedCheckboxes = [...functionCheckboxes];
    updatedCheckboxes[index].checked = e.target?.checked;
    setFunctionCheckboxes(updatedCheckboxes);
    const allChecked = updatedCheckboxes.every((checkbox) => checkbox.checked);
    setSelectAllChecked(allChecked);
    if (checked) {
      setSelectedFunctionIds((prevIds) => [
        ...prevIds,
        FunctionDetails[index].id,
      ]);
    } else {
      setSelectedFunctionIds((prevIds) =>
        prevIds.filter((id) => id !== FunctionDetails[index].id)
      );
    }
  };
  const handleSelectAllCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedCheckboxes = functionCheckboxes.map((checkbox) => ({
      ...checkbox,
      checked: e.target.checked,
    }));
    setFunctionCheckboxes(updatedCheckboxes);
    // setSelectAllChecked(e.target.checked);
    const checked = e.target.checked;

    setSelectAllChecked(checked);

    if (checked) {
      const allFunctionIds = FunctionDetails.map((func) => func.id);
      setSelectedFunctionIds(allFunctionIds);
    } else {
      setSelectedFunctionIds([]);
    }
  };
  const navigate = useNavigate();
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: role.roleid,
      errorMsz: "Role is required",
      min: 1,
    },
  ];
  const handleSave = () => {
    validate(ValidateData).status
      ? CreateRole({
          ...role,
          functionid: selectedFunctionIds,
          created_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };
  const handleEdit = () => {
    // UpdatePartner({
    //   ...partner,
    //   IsActive: checked ? true : false,
    //   partner_id: state.partner.id,
    //   // logo: base64URL[0],
    //   updated_by: userDataParse.id,
    // });
  };

  React.useEffect(() => {
    if (createRoleSuccess) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createRoleDetails),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      ResetAll();
    } else if (createRoleError) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          createRoleDetails ? createRoleDetails : createRoleError
        ),
        snackOpen: true,
        snackSeverity: createRoleDetails ? "success" : "error",
      }));
      ResetAll();
    }
  }, [
    ResetAll,
    createRoleDetails,
    createRoleError,
    createRoleSuccess,
    navigate,
  ]);

  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {createRoleLoading || getAllRoleLoading || getAllFunctionLoading ? (
        <CustomLoader />
      ) : null}

      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>
            {" "}
            {formType === "role-form/add" ? "Add Role" : "Edit Role"}
          </p>
        </div>
        <div className="container mt-5" style={{ backgroundColor: "#F5F5F5;" }}>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Role
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>

                <Select
                  className={styles.outline_inputbox}
                  onChange={(e) => {
                    setRole((prevState) => ({
                      ...prevState,
                      roleid: e.target.value,
                    }));
                  }}
                  value={role?.roleid}
                  // renderValue={(selected) => (selected as string[]).join(", ")}
                >
                  {RoleDetails?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                  fontSize: "10px",
                }}
              >
                <span className={styles.label}>Functions Access</span>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAllChecked}
                        onChange={handleSelectAllCheckboxChange}
                      />
                    }
                    label="All Access"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "12px" },
                    }}
                  />
                </FormGroup>
                {Object.entries(categorizedFunctions).map(
                  ([category, functions]) => (
                    <div key={category}>
                      <p className={styles.label}>{category}</p>

                      <FormGroup row style={{ fontSize: "12px" }}>
                        {functions.map((func) => {
                          const checkboxIndex = functionCheckboxes.findIndex(
                            (checkbox) => checkbox.id === func.id
                          );
                          const isChecked =
                            checkboxIndex !== -1 &&
                            functionCheckboxes[checkboxIndex].checked;

                          return (
                            <FormControlLabel
                              key={func.id}
                              control={
                                <Checkbox
                                  checked={isChecked}
                                  onChange={(e) =>
                                    handleFunctionCheckboxChange(
                                      e,
                                      checkboxIndex
                                    )
                                  }
                                />
                              }
                              label={func.name}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "12px",
                                },
                              }}
                            />
                          );
                        })}
                      </FormGroup>
                    </div>
                  )
                )}
              </FormControl>
              {formType === "role-form/edit" && (
                <>
                  <FormControl
                    className={styles.formControl}
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <span className={styles.label}>
                      Status
                      {/* <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span> */}
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          // checked={checked}
                          // onChange={handleChange}
                        />
                      }
                      label="Active"
                      // onChange={}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </div>
          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              variant={"contained"}
              className={styles.saveBtn}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </>
  );
}
