import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Chip,
  IconButton,
  Modal,
  SnackbarOrigin,
  Typography,
} from "@mui/material";
import { AddSquare, MinusSquare } from "iconsax-react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonLog from "../../../../components/common/Button/Button";
import CustomLoader from "../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../components/common/SnackBar/SnackBar";
import CustomTable from "../../../../components/common/Table/CustomTable";
import { TypedDispatch } from "../../../../redux/store";
import {
  getAuthDataFromLocalStorage,
  getUserDataFromLocalStorage,
  isFunctionAllowed,
} from "../../../../utils/helper.utils";
import { createNotification } from "../../Manage/Home/Service/homeSlice";
import { SparePartRequestProps } from "../container/spareContainer";
import {
  InventoryDetail,
  RequestSpareRes,
  Stock,
  UpdateReqSparePartDT,
} from "../service/types";
import styles from "./Sparepart.module.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function SparePartRequest(props: SparePartRequestProps) {
  const {
    GetAllSpareRequest,
    getAllSpareRequest_Error,
    getAllSpareRequest_Loading,
    getAllSpareRequest_Success,
    getAllSpareRequest_details,
    UpdateSparePartReq,
    updateSparePartReq_Error,
    updateSparePartReq_Loading,
    updateSparePartReq_Success,
    updateSparePartReq_details,
    ResetAll,
  } = props;

  const userData: any = getUserDataFromLocalStorage();
  const authData = getAuthDataFromLocalStorage();
  const dispatch: TypedDispatch = useDispatch();

  useEffect(() => {
    ResetAll();
    GetAllSpareRequest(userData?.partner_id);
  }, [
    GetAllSpareRequest,
    userData?.partner_id,
    updateSparePartReq_Success,
    updateSparePartReq_Error,
    ResetAll,
  ]);

  const [product, setProduct] = useState({
    request_id: "",
    name: "",
    quantity: "",
    product_id: "",
    products: [] as InventoryDetail[],
    jobcard_id: "",
  });
  const [totalQuantity, setTotalQuantity] = useState(0);
  useEffect(() => {
    const selectedQuantitySum = product?.products?.reduce((sum, item) => {
      const selectedQuantity = item.selected_quantity;
      if (selectedQuantity !== null && selectedQuantity !== undefined) {
        return sum + selectedQuantity;
      } else {
        return sum;
      }
    }, 0);
    setTotalQuantity(selectedQuantitySum);
  }, [product?.products]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong. Please try again.",
    snackSeverity: "error",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openModal2, setopenModal2] = React.useState(false);
  const handleOpen2 = () => setopenModal2(true);
  const handleClose2 = () => setopenModal2(false);

  const columns = [
    { id: "sl", label: "Serial No.", minWidth: 100 },
    { id: "mechanic", label: "Mechanic", minWidth: 100 },
    { id: "product_names", label: "Spare Part ", minWidth: 100 },
    { id: "quantity", label: "Quantity", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];
  const stockColumns = [
    { id: "quantity", label: "Quantity", minWidth: 100 },
    { id: "unit_price", label: "Unit Price ", minWidth: 100 },
    { id: "selected_quantity", label: "Selected Quantity", minWidth: 100 },
    { id: "total_price", label: "Total Price", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];
  const handleMarkasPaidClick = useCallback((row: RequestSpareRes) => {
    setProduct((prevState) => ({
      ...prevState,
      products: row.inventoryDetails,
      name: row.product_names,
      quantity: row.quantity,
      product_id: row.spare_parts,
      request_id: row.id,
      jobcard_id: row.jobCard_id,
    }));
    handleOpen2();
  }, []);

  const handleUpdateClick = useCallback((row: RequestSpareRes) => {
    setProduct((prevState) => ({
      ...prevState,
      products: row.inventoryDetails,
      name: row.product_names,
      quantity: row.quantity,
      product_id: row.spare_parts,
      request_id: row.id,
      jobcard_id: row.jobCard_id,
    }));
    handleOpen();
  }, []);

  const updatedRows = getAllSpareRequest_details?.map(
    (row: RequestSpareRes, index: number) => ({
      ...row,
      sl: index + 1,
      Total_Price: Number(row.quantity) * Number(row.price),
      status:
        row.status === "completed" ? (
          <Chip label="Completed" variant="outlined" color="success" />
        ) : row.status === "in Progress" ? (
          <Chip label="In Progress" variant="outlined" color="info" />
        ) : row.status === "Approved" ? (
          <Chip label="Approved" variant="outlined" color="warning" />
        ) : row.status === "pending" ? (
          <Chip label="Pending" variant="outlined" color="secondary" />
        ) : (
          <Chip label="Rejected" variant="outlined" color="error" />
        ),
      action: row.status === "pending" && (
        <>
          {isFunctionAllowed(authData, "Spare Parts Acceptance", "Update") ? (
            <div className={styles.btn_group}>
              <div>
                <ButtonLog
                  text={"Approve"}
                  variant={"outlined"}
                  className={styles.approvebtn}
                  onClick={() => handleUpdateClick(row)}
                />
              </div>

              <div>
                <ButtonLog
                  text={"Reject"}
                  variant={"outlined"}
                  className={styles.rejectbtn}
                  onClick={() => handleMarkasPaidClick(row)}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    })
  );

  const handleIncrement = (row: InventoryDetail) => {
    const currentQuantity = row.selected_quantity ?? 0;
    const updatedQuantity = currentQuantity + 1;
    const updatedRow: InventoryDetail = {
      ...row,
      selected_quantity: updatedQuantity,
    };
    setProduct((prevState) => ({
      ...prevState,
      products: prevState.products.map((item) =>
        item.id === row.id ? updatedRow : item
      ),
    }));
  };

  const handleDecrement = (row: InventoryDetail) => {
    const currentQuantity = row.selected_quantity ?? 0;
    const updatedQuantity = Math.max(currentQuantity - 1, 0);
    const updatedRow: InventoryDetail = {
      ...row,
      selected_quantity: updatedQuantity,
    };
    setProduct((prevState) => ({
      ...prevState,
      products: prevState.products.map((item) =>
        item.id === row.id ? updatedRow : item
      ),
    }));
  };
  const updatedStockRows = product?.products?.map((row: InventoryDetail) => ({
    ...row,
    total_price: (row.selected_quantity ?? 0) * row.unit_price,
    selected_quantity: row.selected_quantity ?? 0,
    action: (
      <div>
        <IconButton onClick={() => handleIncrement(row)}>
          <AddSquare style={{ color: "green", cursor: "pointer" }} />
        </IconButton>
        <IconButton onClick={() => handleDecrement(row)}>
          <MinusSquare style={{ color: "red", cursor: "pointer" }} />
        </IconButton>
      </div>
    ),
  }));
  const handleReject = useCallback(
    (status: string) => {
      const data: UpdateReqSparePartDT = {
        request_id: product.request_id,
        status: status,
        stock: [],
        product_id: product.product_id,
        createdby: userData.id,
        jobcard_id: product.jobcard_id,
        partnerid: userData.partner_id,
      };
      UpdateSparePartReq(data);
      handleClose2();
    },
    [
      UpdateSparePartReq,
      product.jobcard_id,
      product.product_id,
      product.request_id,
      userData.id,
      userData.partner_id,
    ]
  );
  const handleApproved = useCallback(
    (status: string) => {
      const convertedData: Stock[] = product?.products.map((item) => ({
        inventoryid: item.id,
        quantity: item.selected_quantity ?? 0,
      }));
      const data: UpdateReqSparePartDT = {
        request_id: product.request_id,
        status: status,
        stock: convertedData,
        product_id: product.product_id,
        createdby: userData.id,
        jobcard_id: product.jobcard_id,
        partnerid: userData.partner_id,
      };

      UpdateSparePartReq(data);
      handleClose();
      dispatch(
        createNotification({
          message: "",
          partner_id: "",
          receiver_id: "",
          sender_id: "",
        })
      );
    },
    [
      UpdateSparePartReq,
      dispatch,
      product.jobcard_id,
      product.product_id,
      product?.products,
      product.request_id,
      userData.id,
      userData.partner_id,
    ]
  );
  React.useEffect(() => {
    if (
      updateSparePartReq_Error &&
      typeof updateSparePartReq_Error === "string" &&
      updateSparePartReq_Error !== "false"
    ) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: updateSparePartReq_Error
          ? updateSparePartReq_Error
          : "Something went wrong!",
        snackOpen: true,
      }));
      // ResetAll();
    }
    if (updateSparePartReq_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: updateSparePartReq_details
          ? updateSparePartReq_details
          : "Successfully Updated",
        snackOpen: true,
        snackSeverity: "success",
      }));
      // ResetAll();
    }
  }, [
    updateSparePartReq_Error,
    updateSparePartReq_Success,
    updateSparePartReq_details,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {getAllSpareRequest_Loading || updateSparePartReq_Loading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.inventoryNavBar}>
        <div className={styles.filter}>
          <p className={styles.txt_title}> Spare Parts Requests</p>
        </div>
        {/* approve / reject button for table  */}
      </div>
      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <CustomTable columns={columns} rows={updatedRows} />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.sparerequestModal}>
          <div className={styles.title_modal}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Approve Request
            </Typography>
          </div>
          <Typography variant="body2" textAlign="center">
            Confirm to approve mechanic request of spare parts{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {product.name}
            </span>
            ({product.quantity}) ?
          </Typography>
          <div className={styles.title_modal}>
            {/* <Typography
              id="modal-modal-description"
              sx={{ mt: 2, width: "70%", fontSize: "14px", padding: "15px" }}
            >
              Confirm to approve mechanic request of spare parts{" "}
              <span className={styles.sub_txt_modal}>
                {" "}
                {product.name}({product.quantity}){" "}
              </span>{" "}
              ?
            </Typography> */}
            {updatedStockRows ? (
              <>
                <CustomTable columns={stockColumns} rows={updatedStockRows} />
              </>
            ) : (
              <>
                <Typography variant="body1" color="CaptionText">
                  Out Of Stock
                </Typography>
              </>
            )}
          </div>

          <div className={styles.btn_modal_group}>
            <div>
              <ButtonLog
                text={"Cancel"}
                variant={"outlined"}
                className={styles.cancelbtn}
                onClick={handleClose}
              />
            </div>

            <div>
              <div>
                <ButtonLog
                  text={"Approve"}
                  variant="contained"
                  className={styles.approvebtn_modal}
                  onClick={() => handleApproved("Approved")}
                  disabled={Number(product.quantity) !== totalQuantity}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openModal2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.sparerequestModal}>
          <div className={styles.title_modal}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Reject Request
            </Typography>
          </div>
          <div className={styles.title_modal}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, width: "70%", fontSize: "14px", padding: "15px" }}
            >
              Confirm to reject mechanic request of spare parts
              <span className={styles.sub_txt_modal}>
                {" "}
                {product.name}({product.quantity}){" "}
              </span>{" "}
              ?
            </Typography>
          </div>

          <div className={styles.btn_modal_group}>
            <div>
              <ButtonLog
                text={"Cancel"}
                variant={"outlined"}
                className={styles.cancelbtn}
                onClick={handleClose2}
              />
            </div>

            <div>
              <div>
                <ButtonLog
                  text={"Reject"}
                  variant="contained"
                  className={styles.approvebtn_modal}
                  onClick={() => handleReject("Rejected")}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
