import { combineReducers } from "@reduxjs/toolkit";
import allMechanicSlice from "../pages/app/AllMechanics/services/allMechanicSlice";
import archiveJobCardSlice from "../pages/app/ArchiveJobCard/service/archiveJobCardSlice";
import configurationSlice from "../pages/app/Configuration/services/configurationSlice";
import TrackingSlice from "../pages/app/CustomerOrderTracking/services/customerTrackingSlice";
import dashboardSlice from "../pages/app/DashbordNew/service/dashboardSlice";
import expensesLogSlice from "../pages/app/ExpensesLog/services/expensesLogSlice";
import FeedbackSclice from "../pages/app/Feedback/services/FeedbackSlice";
import History from "../pages/app/History/services/historySlice";
import invoiceSlice from "../pages/app/Invoice/service/invoiceSlice";
import homeSlice from "../pages/app/Manage/Home/Service/homeSlice";
import complaintsSplice from "../pages/app/Manage/VeichleRegistration/components/ComplantJobCard/service/complaintsSplice";
import servicesSlice from "../pages/app/Manage/VeichleRegistration/components/ComplantJobCard/service/servicesSlice";
import estimateSlice from "../pages/app/Manage/VeichleRegistration/components/Estimate/service/estimateSlice";
import sparesSlice from "../pages/app/Manage/VeichleRegistration/components/Spares/services/sparesSlice";
import vehicleCustomerData from "../pages/app/Manage/VeichleRegistration/components/VehicleCustomerDetails/services/VehicleCustomerDetailsSlice";
import veichleRegistrationSlice from "../pages/app/Manage/VeichleRegistration/service/veichleRegistrationSlice";
import MechanicJobs from "../pages/app/Mechanic/service/MechanicSlice";
import ownerDashboardSlice from "../pages/app/OwnerDashboard/services/ownerDashboardSlice";
import partnerDashboardSlice from "../pages/app/PartnerDashboard/service/partnerDashboardSlice";
import PaymentRequest from "../pages/app/Payment/service/paymentSlice";
import EnquiryPlan from "../pages/app/PlanEnquiry/service/planEnquirySlice";
import generateReportSlice from "../pages/app/Reports/components/GenerateReport/services/generateReportSlice";
import reportsSlice from "../pages/app/Reports/services/reportsSlice";
import RequestSpare from "../pages/app/SparePartRequest/service/spareRequestSlice";
import supervisorDashboardSlice from "../pages/app/SupervisorDashboard/services/dashboardSlice";
import forgotPasswordSlice from "../pages/auth/ForgotPassword/service/forgotPasswordSlice";
import loginSlice from "../pages/auth/Login/service/loginSlice";

export const rootReducer = combineReducers({
  login: loginSlice,
  vehicleCustomerData: vehicleCustomerData,
  services: servicesSlice,
  spares: sparesSlice,
  complaints: complaintsSplice,
  estimate: estimateSlice,
  configuration: configurationSlice,
  vehicleRegistration: veichleRegistrationSlice,
  home: homeSlice,
  mechanicJobs: MechanicJobs,
  invoice: invoiceSlice,
  ownerDashboard: ownerDashboardSlice,
  partnerDashboard: partnerDashboardSlice,
  generateReport: generateReportSlice,
  reports: reportsSlice,
  requestSpare: RequestSpare,
  paymentRequest: PaymentRequest,
  history: History,
  forgotPassword: forgotPasswordSlice,
  feedback: FeedbackSclice,
  allMechanics: allMechanicSlice,
  supervisorDashboard: supervisorDashboardSlice,
  tracking: TrackingSlice,
  enquiryPlan: EnquiryPlan,
  expensesLog: expensesLogSlice,
  dashboard: dashboardSlice,
  archiveJobCard: archiveJobCardSlice,
});
