import StarIcon from "@mui/icons-material/Star";
import { Card, Rating } from "@mui/material";
import customer from "../../../assets/images/customer.svg";
import mechanic from "../../../assets/images/mechanic.png";
import review from "../../../assets/images/reviews.svg";
import Progress from "../../../pages/app/DashbordMain/Progresser/Progress";
import { useDashboardIReview } from "../../../pages/app/DashbordNew/service/dashboard.request";
import {
  CustomerFeedback,
  MechanicReviewRes,
} from "../../../pages/app/DashbordNew/service/types";
import { getUserDataFromLocalStorage } from "../../../utils/helper.utils";
import CustomTable from "../../common/Table/CustomTable";
import styles from "./Reviews.module.css";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    // title: {
    //   display: true,
    //   text: "Chart.js Bar Chart",
    // },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const datas = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => Math.random()),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => Math.random()),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

interface ReviewProps {
  getAllCustReview_details: CustomerFeedback[] | null;
  getAllMechReview_details: MechanicReviewRes[] | null;
}
export default function Reviews(props: ReviewProps) {
  const { getAllMechReview_details, getAllCustReview_details } = props;
  const userData: any = getUserDataFromLocalStorage();

  const { data } = useDashboardIReview(userData?.partner_id);
  const customerFeedbackColumns = [
    { id: "customer_name", label: "Customer", minWidth: 100 },
    { id: "remarks", label: "Comment", minWidth: 100 },
    { id: "rating", label: "Rating", minWidth: 100 },
  ];
  const updatedRowsCust = getAllCustReview_details?.map((row) => ({
    ...row,
    rating: (
      <>
        <Rating
          value={Number(row.rating)}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          readOnly
        />
      </>
    ),
  }));
  const mechanicReviewColumns = [
    { id: "mechanic_name", label: "Mechanic", minWidth: 100 },
    { id: "review", label: "Comment", minWidth: 100 },
    { id: "rating", label: "Rating", minWidth: 100 },
  ];

  const updatedRows = getAllMechReview_details?.map((row) => ({
    ...row,
    rating: (
      <>
        <Rating
          value={Number(row.rating)}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          readOnly
        />
      </>
    ),
  }));
  return (
    <>
      <div className="row mt-3">
        <div className="col-md-3">
          {/* <Box sx={{ height: "91%" }}> */}
          <div className="row ">
            <div className="col-md-12 col-lg-12 ">
              <div className={styles.jobStatusCard_progress}>
                <img
                  src={customer}
                  height={32}
                  width={32}
                  className={"img-fluid"}
                  alt=""
                />
                <h3 className={styles.txt_count_progress}>
                  {data?.total_customer}
                </h3>
                <p className={styles.jobStatus_txt}>Total Customers</p>
              </div>
              <div className={styles.jobStatusCard_progress_review}>
                <img
                  src={review}
                  height={36}
                  width={36}
                  className={"img-fluid"}
                  alt=""
                />
                <h3 className={styles.txt_count_redy}>{data?.total_reviews}</h3>
                <p className={styles.jobStatus_txt}>Total Reviews</p>
              </div>
            </div>
          </div>
          {/* </Box> */}
        </div>
        <div className="col-md-3">
          <Card variant="outlined" className={styles.root_Revenue}>
            <div className={styles.job_headbar}>
              <div>
                <p className={styles.txt_job}> Customer Satisfaction Index</p>
              </div>
            </div>
            <div>
              <div>
                {/* <p className={styles.count_revenue}> S$ 5852.00</p> */}
              </div>
              {/* <Chart /> */}
              <div className={styles.progress_root}>
                <Progress percentage={data?.customerSatisfaction_Index} />
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-3">
          <Card variant="outlined" className={styles.TopServiceTaken}>
            <div className={styles.job_headbar}>
              <div>
                <p className={styles.txt_job}> Start Performers</p>
              </div>
            </div>

            <div className={styles.TopThreeVehicle}>
              <div className={styles.root_vec}>
                {data?.starPerformerNames?.map((item: any, id: number) => (
                  <div className={styles.veiclename} key={id}>
                    <div>
                      <img src={mechanic} alt="" />
                    </div>
                    <div>
                      <p className={styles.veicl_name_txt}>{item}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-3">
          <Card variant="outlined" className={styles.root_Revenue}>
            <div className={styles.job_headbar}>
              <div>
                <p className={styles.txt_job}>Performance index</p>
              </div>
            </div>
            <div>
              <div>
                {/* <p className={styles.count_revenue}> S$ 5852.00</p> */}
              </div>
              {/* <Chart /> */}
              <div className={styles.progress_root}>
                <Progress percentage={data?.mechanicSatisfaction_Index} />
              </div>
            </div>
          </Card>
        </div>

        <div className="col-md-6 mb-3">
          <Card variant="outlined">
            <div className={styles.job_headbar}>
              <div>
                <p className={styles.txt_job}> Customer(Review/Feedback)</p>
              </div>
            </div>

            {/* <div className={styles.service_time}> */}
            <CustomTable
              columns={customerFeedbackColumns}
              rows={updatedRowsCust ? updatedRowsCust : []}
            />
            {/* </div> */}
          </Card>
        </div>
        <div className="col-md-6">
          <Card variant="outlined">
            <div className={styles.job_headbar}>
              <div>
                <p className={styles.txt_job}> Mechanic(Review/Feedback)</p>
              </div>
            </div>

            {/* <div className={styles.service_time}> */}
            <CustomTable
              columns={mechanicReviewColumns}
              rows={updatedRows ? updatedRows : []}
            />
            {/* </div> */}
          </Card>
        </div>
      </div>
    </>
  );
}
