import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";

import { getJobcardInvoice } from "../../Invoice/service/invoiceSlice";
import { JobcardInvoice } from "../../Invoice/service/types";
import { CustomerOrderTracking } from "../components/CustomerOrderTracking";
import { getFeedback } from "../services/customerTrackingSlice";
import { CustomerFeedback } from "../services/types";

interface StateProps {
  getFeedback_Loading: boolean;
  getFeedback_Error: boolean;
  getFeedback_Success: boolean;
  getFeedback_Details: CustomerFeedback | null;
  getJobCradInvoice_loading: boolean;
  getJobCradInvoice_Error: boolean;
  getJobCradInvoice_Success: boolean;
  getJobCradInvoice_Details: JobcardInvoice | null;
}

interface DispatchProps {
  GetFeedback(data: string): void;
  GetJobCradInvoice(data: any): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getFeedback_Loading: state.tracking.feedbackPost_Loading,
    getFeedback_Error: state.tracking.getFeedback_Error,
    getFeedback_Success: state.tracking.getFeedback_Success,
    getFeedback_Details: state.tracking.getFeedback_Details,
    getJobCradInvoice_loading: state.invoice.getJobCradInvoice_loading,
    getJobCradInvoice_Error: state.invoice.getJobCradInvoice_Error,
    getJobCradInvoice_Success: state.invoice.getJobCradInvoice_Success,
    getJobCradInvoice_Details: state.invoice.getJobCradInvoice_Details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetFeedback: (data) => {
      return dispatch(getFeedback(data));
    },
    GetJobCradInvoice: (data) => {
      dispatch(getJobcardInvoice(data));
    },
  };
};

export interface CustomerOrderTrackingProps extends StateProps, DispatchProps {}
export const CustomerOrderTrackingComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerOrderTracking);
