import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { LoginRequest } from "./login.request";
import { LoginDTS, LoginInit } from "./types";

export const initialState: LoginInit = {
  isLogin: false,
  error: false,
  loading: false,
  userDetails: null,
};
const storeTokenDataInLocalStorage = async (data: any) => {
  try {
    localStorage.setItem("TOKEN", JSON.stringify(data));
  } catch (e) {
    console.log(e);
  }
};
const storeUserDataInLocalStorage = async (data: any) => {
  try {
    localStorage.setItem("USER_DATA", JSON.stringify(data));
  } catch (e) {
    console.log(e);
  }
};
const storeAuthDataInLocalStorage = async (data: any) => {
  try {
    localStorage.setItem("AUTHORIZATION", JSON.stringify(data));
  } catch (e) {
    console.log(e);
  }
};
export const login = createAsyncThunk("user/login", (data: LoginDTS) => {
  return LoginRequest(data)
    .then((res) => {
      if (res.status === 201) {
        if (res.data.statusCode !== 401) {
          storeTokenDataInLocalStorage({ token: res.data.token.access_token });
          storeUserDataInLocalStorage({
            id: res.data.user.id,
            email_id: res.data.user.email,
            name: res.data.user.name,
            user_type: res.data.user.user_type,
            partner_id: res.data.user.partner_id,
            tax_percentage: res.data.tax_percentage,
            logo: res.data.image_path,
            specialities: res.data.user.specialities,
            currency_data: res.data.currency_data,
          });
          storeAuthDataInLocalStorage({
            category: res.data.transformedData[0]?.category,
            functionName: res.data.transformedData[0]?.functionName,
            rolename: res.data.transformedData[0]?.rolename,
          });
        } else {
          throw new Error(res.data.message);
        }
      }
      return res.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
});

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    reset: () => initialState,
    setUserDetails: (state, action: PayloadAction<any>) => {
      return { ...state, userDetails: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.isLogin = false;
      state.error = false;
    });
    builder.addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.isLogin = true;
      state.error = false;
      state.userDetails = action?.payload;
    });
    builder.addCase(login.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.isLogin = false;
      state.error = action?.error?.message || "Something went wrong";
    });
  },
});

export default loginSlice.reducer;
export const { reset, setUserDetails } = loginSlice.actions;
