import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../redux/store";
import { getAllBay } from "../../../Configuration/services/configurationSlice";
import { getFeedback } from "../../../CustomerOrderTracking/services/customerTrackingSlice";
import { CustomerFeedback } from "../../../CustomerOrderTracking/services/types";
import { ProductRes } from "../../../Mechanic/components/MechanicPendingtask/service/types";
import { selectBayByMech } from "../../../Mechanic/service/MechanicSlice";
import { SelectBayByMechProps } from "../../../Mechanic/service/type";
import { updatePaymentRequest } from "../../../Payment/service/paymentSlice";
import { UpdatePaymentDT } from "../../../Payment/service/types";
import {
  getAllActiveProduct,
  getAllActiveService,
  getAllActiveUser,
} from "../../VeichleRegistration/service/veichleRegistrationSlice";
import {
  createJobCardServices,
  createJobCardSpareParts,
  deleteJobCardById,
  deleteJobcardServiceByID,
  deleteSparePartsByID,
  getAllJobCard,
  getEstimate,
  getJobCardDetails,
  mechanicReAssign,
  reset,
  updateJobCardSpareParts,
  updateJobcardStatus,
} from "../Service/homeSlice";
import Home from "../components/Home";
import {
  AllJobCards,
  EstimateRes,
  JobCardCreateService,
  JobCardCreateSpares,
  JobCardDTO,
  updateJobcardStatusDTO,
} from "../type";

interface StateProps {
  getAllJobCardLoading: boolean;
  getAllJobCardError: boolean;
  getAllJobCardSuccess: boolean;
  getAllJobCardDetails: Array<AllJobCards>;
  getJobCardDetailsLoading: boolean;
  getJobCardDetailsError: boolean | string;
  getJobCardDetailsSuccess: boolean;
  getJobCardDetailsData: JobCardDTO | undefined;
  updateJobcardStatusLoading: boolean;
  updateJobcardStatusError: boolean;
  updateJobcardStatusSuccess: boolean;
  getAllActiveProductSuccess: boolean;
  getAllActiveProductError: boolean;
  getAllActiveProductLoading: boolean;
  activeProductDetails: ProductRes[];
  activeServiceSuccess: boolean;
  activeServiceError: boolean;
  activeServiceLoading: boolean;
  activeServiceDetails: [];
  deleteJobCardByIdSuccess: boolean;
  deleteJobCardByIdError: boolean;
  deleteJobCardByIdLoading: boolean;
  deleteJobCardByIdData: null;
  getAllActiveUser_Loading: boolean;
  getAllActiveUser_Error: boolean;
  getAllActiveUser_Success: boolean;
  getAllActiveUser_Details: null | [];
  loading: boolean;
  deleteSparePartById_Loading: boolean;
  deleteSparePartById_Error: boolean;
  deleteSparePartById_Success: boolean;
  deleteSparePartById_Details: null;
  deleteJobCardServiceById_Loading: boolean;
  deleteJobCardServiceById_Error: boolean;
  deleteJobCardServiceById_Success: boolean;
  deleteJobCardServiceById_Details: null;
  mechanicReAssign_Loading: boolean;
  mechanicReAssign_Error: boolean;
  mechanicReAssign_Success: boolean;
  mechanicReAssign_Details: null;
  createJobCardSpareParts_Loading: boolean;
  createJobCardSpareParts_Error: boolean;
  createJobCardSpareParts_Success: boolean;
  createJobCardSpareParts_Details: null;
  createJobCardSServices_Loading: boolean;
  createJobCardSServices_Error: boolean;
  createJobCardSServices_Success: boolean;
  createJobCardSServices_Details: null;
  getAllBaySuccess: boolean;
  getAllBayError: boolean;
  getAllBayLoading: boolean;
  BayDetails: null | [];
  selectBayByMech_Loading: boolean;
  selectBayByMech_Error: boolean;
  selectBayByMech_Success: boolean;
  updatePaymentRequest_Loading: boolean;
  updatePaymentRequest_Error: boolean;
  updatePaymentRequest_Success: boolean;
  updatePaymentRequest_details: null;
  getEstimateLoading: boolean;
  getEstimateError: boolean;
  getEstimateSuccess: boolean;
  getEstimateDetails: EstimateRes | null;
  getFeedback_Loading: boolean;
  getFeedback_Error: boolean;
  getFeedback_Success: boolean;
  getFeedback_Details: CustomerFeedback | null;
  updateJobCardSpareParts_Loading: boolean;
  updateJobCardSpareParts_Error: boolean;
  updateJobCardSpareParts_Success: boolean;
  updateJobCardSpareParts_Details: null;
}

interface DispatchProps {
  GetAllJobCard(id: number): void;
  GetJobCardDetails(id: string): void;
  UpdateJobcardStatus(data: updateJobcardStatusDTO): void;
  GetAllActiveUsers(id: number): void;
  GetAllActiveProduct(id: number): void;
  GetAllActiveService(id: number): void;
  DeleteJobCardById(data: any): void;
  DeleteSparePartsByID(data: any): void;
  DeleteJobCardServiceByID(data: any): void;
  SetMachanicReassign(data: any): void;
  CreateJobCardSpareParts(data: JobCardCreateSpares): void;
  UpdateJobCardSpareParts(data: JobCardCreateSpares): void;
  CreateJobCardServices(data: JobCardCreateService): void;
  GetAllBay(id: string): void;
  ResetAll(): void;
  SelectBayByMech(data: SelectBayByMechProps): void;
  UpdatePaymentRequest(body: UpdatePaymentDT): void;
  GetEstimate(id: string): void;
  GetFeedback(data: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    loading: state.home.loading,
    getAllJobCardSuccess: state.home.getAllJobCardSuccess,
    getAllJobCardError: state.home.getAllJobCardError,
    getAllJobCardLoading: state.home.getAllJobCardLoading,
    getAllJobCardDetails: state.home.getAllJobCardDetails,
    getJobCardDetailsLoading: state.home.getJobCardDetailsLoading,
    getJobCardDetailsError: state.home.getJobCardDetailsError,
    getJobCardDetailsSuccess: state.home.getJobCardDetailsSuccess,
    getJobCardDetailsData: state.home.getJobCardDetailsData,
    updateJobcardStatusLoading: state.home.updateJobcardStatusLoading,
    updateJobcardStatusError: state.home.updateJobcardStatusError,
    updateJobcardStatusSuccess: state.home.updateJobcardStatusSuccess,
    activeServiceSuccess: state.vehicleRegistration.activeServiceSuccess,
    activeServiceError: state.vehicleRegistration.activeServiceError,
    activeServiceLoading: state.vehicleRegistration.activeServiceLoading,
    activeServiceDetails: state.vehicleRegistration.activeServiceDetails,
    getAllActiveProductSuccess:
      state.vehicleRegistration.getAllActiveProductSuccess,
    getAllActiveProductError:
      state.vehicleRegistration.getAllActiveProductError,
    getAllActiveProductLoading:
      state.vehicleRegistration.getAllActiveProductLoading,
    activeProductDetails: state.vehicleRegistration.activeProductDetails,
    getAllActiveUser_Loading:
      state.vehicleRegistration.getAllActiveUser_Loading,
    getAllActiveUser_Error: state.vehicleRegistration.getAllActiveUser_Error,
    getAllActiveUser_Success:
      state.vehicleRegistration.getAllActiveUser_Success,
    getAllActiveUser_Details:
      state.vehicleRegistration.getAllActiveUser_Details,
    deleteJobCardByIdLoading: state.home.deleteJobCardByIdLoading,
    deleteJobCardByIdError: state.home.deleteJobCardByIdError,
    deleteJobCardByIdSuccess: state.home.deleteJobCardByIdSuccess,
    deleteJobCardByIdData: state.home.deleteJobCardByIdData,
    deleteSparePartById_Loading: state.home.deleteSparePartById_Loading,
    deleteSparePartById_Error: state.home.deleteSparePartById_Error,
    deleteSparePartById_Success: state.home.deleteSparePartById_Success,
    deleteSparePartById_Details: state.home.deleteSparePartById_Details,
    deleteJobCardServiceById_Loading:
      state.home.deleteJobCardServiceById_Loading,
    deleteJobCardServiceById_Error: state.home.deleteJobCardServiceById_Error,
    deleteJobCardServiceById_Success:
      state.home.deleteJobCardServiceById_Success,
    deleteJobCardServiceById_Details:
      state.home.deleteJobCardServiceById_Details,
    mechanicReAssign_Loading: state.home.mechanicReAssign_Loading,
    mechanicReAssign_Error: state.home.mechanicReAssign_Error,
    mechanicReAssign_Success: state.home.mechanicReAssign_Success,
    mechanicReAssign_Details: state.home.mechanicReAssign_Details,
    createJobCardSpareParts_Loading: state.home.createJobCardSpareParts_Loading,
    createJobCardSpareParts_Error: state.home.createJobCardSpareParts_Error,
    createJobCardSpareParts_Success: state.home.createJobCardSpareParts_Success,
    createJobCardSpareParts_Details: state.home.createJobCardSpareParts_Details,
    createJobCardSServices_Loading: state.home.createJobCardSServices_Loading,
    createJobCardSServices_Error: state.home.createJobCardSServices_Error,
    createJobCardSServices_Success: state.home.createJobCardSServices_Success,
    createJobCardSServices_Details: state.home.createJobCardSServices_Details,
    getAllBaySuccess: state.configuration.getAllBaySuccess,
    getAllBayError: state.configuration.getAllBayError,
    getAllBayLoading: state.configuration.getAllBayLoading,
    BayDetails: state.configuration.BayDetails,
    selectBayByMech_Error: state.mechanicJobs.selectBayByMech_Error,
    selectBayByMech_Loading: state.mechanicJobs.selectBayByMech_Loading,
    selectBayByMech_Success: state.mechanicJobs.selectBayByMech_Success,
    updatePaymentRequest_Success:
      state.paymentRequest.updatePaymentRequest_Success,
    updatePaymentRequest_Error: state.paymentRequest.updatePaymentRequest_Error,
    updatePaymentRequest_Loading:
      state.paymentRequest.updatePaymentRequest_Loading,
    updatePaymentRequest_details:
      state.paymentRequest.updatePaymentRequest_details,
    getEstimateSuccess: state.home.getEstimateSuccess,
    getEstimateError: state.home.getEstimateError,
    getEstimateLoading: state.home.getEstimateLoading,
    getEstimateDetails: state.home.getEstimateDetails,
    getFeedback_Loading: state.tracking.feedbackPost_Loading,
    getFeedback_Error: state.tracking.getFeedback_Error,
    getFeedback_Success: state.tracking.getFeedback_Success,
    getFeedback_Details: state.tracking.getFeedback_Details,
    updateJobCardSpareParts_Loading: state.home.updateJobCardSpareParts_Loading,
    updateJobCardSpareParts_Error: state.home.updateJobCardSpareParts_Error,
    updateJobCardSpareParts_Success: state.home.updateJobCardSpareParts_Success,
    updateJobCardSpareParts_Details: state.home.updateJobCardSpareParts_Details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllJobCard: (id) => {
      dispatch(getAllJobCard(id));
    },
    GetJobCardDetails: (id) => {
      dispatch(getJobCardDetails(id));
    },
    UpdateJobcardStatus: (data) => {
      dispatch(updateJobcardStatus(data));
    },
    GetAllActiveUsers: (id) => {
      dispatch(getAllActiveUser(id));
    },
    GetAllActiveService: (id) => {
      dispatch(getAllActiveService(id));
    },
    GetAllActiveProduct: (id) => {
      dispatch(getAllActiveProduct(id));
    },
    DeleteJobCardById: (data) => {
      dispatch(deleteJobCardById(data));
    },
    DeleteSparePartsByID: (data) => {
      dispatch(deleteSparePartsByID(data));
    },
    DeleteJobCardServiceByID: (data) => {
      dispatch(deleteJobcardServiceByID(data));
    },
    SetMachanicReassign: (data) => {
      dispatch(mechanicReAssign(data));
    },
    CreateJobCardSpareParts: (data) => {
      dispatch(createJobCardSpareParts(data));
    },
    CreateJobCardServices: (data) => {
      dispatch(createJobCardServices(data));
    },
    GetAllBay: (id) => {
      dispatch(getAllBay({ id }));
    },
    ResetAll: () => {
      dispatch(reset());
    },
    SelectBayByMech(data) {
      dispatch(selectBayByMech(data));
    },
    UpdatePaymentRequest(body) {
      dispatch(updatePaymentRequest(body));
    },
    GetEstimate(id) {
      dispatch(getEstimate(id));
    },
    GetFeedback: (data) => {
      return dispatch(getFeedback(data));
    },
    UpdateJobCardSpareParts: (data) => {
      dispatch(updateJobCardSpareParts(data));
    },
  };
};

export interface HomeProps extends StateProps, DispatchProps {}
export const HomeComponent = connect(mapStateToProps, mapDispatchToProps)(Home);
