import { axios } from "../../../../api/setup.intersepter";
import { ReportDT } from "./types";
export const GetGarageReport = (body: ReportDT) => {
  return axios({
    method: "GET",
    url: `/jobcard/garageReport/${body.id}?startDate=${body.startDate}&endDate=${body.endDate}`,
  });
};
export const GetTaskReport = (body: ReportDT) => {
  return axios({
    method: "GET",
    url: `/jobcard/taskReport/${body.id}?startDate=${body.startDate}&endDate=${body.endDate}`,
  });
};
export const GetRevenueReport = (body: ReportDT) => {
  return axios({
    method: "GET",
    url: `/jobcard/revenueReport/${body.id}?startDate=${body.startDate}&endDate=${body.endDate}`,
  });
};
export const GetSparePartReport = (body: ReportDT) => {
  return axios({
    method: "GET",
    url: `/jobcard/sparePartReport/${body.id}?startDate=${body.startDate}&endDate=${body.endDate}`,
  });
};

export const GetMechanicReport = (body: ReportDT) => {
  return axios({
    method: "GET",
    url: `/jobcard/mechanicReport/${body.id}?startDate=${body.startDate}&endDate=${body.endDate}`,
  });
};

export const GetJobDurationReport = (body: ReportDT) => {
  return axios({
    method: "GET",
    url: `/jobcard/jobDurationReport/${body.id}?startDate=${body.startDate}&endDate=${body.endDate}`,
  });
};
