import { useState } from "react";
import "react-image-lightbox/style.css";

import Lightbox from "react-image-lightbox";
import { JobCardDTO } from "../../../pages/app/Manage/Home/type";
import styles from "./Gallerystyle.module.css";
interface GalleryProps {
  getJobCardDetailsData: JobCardDTO;
}

const GalleryPage = (props: GalleryProps) => {
  const { getJobCardDetailsData } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Replace `images` with your own image URLs
  const images = [
    "https://source.unsplash.com/random/900×700/?carengine",
    "https://source.unsplash.com/random/900×700/?carwheel",
    "https://source.unsplash.com/random/900×700/?headlight",
    "https://source.unsplash.com/random/900×700/?exaust",
  ];

  const openLightbox = (index: number) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const closeLightbox = () => {
    setIsOpen(false);
    setPhotoIndex(0);
  };

  return (
    <div className={styles.thumbnailimagemain}>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            {getJobCardDetailsData.image_paths.length !== 0 ? (
              <>
                {getJobCardDetailsData.image_paths.map((image, index) => (
                  <div
                    className="col-lg-3 col-md-6 col-sm-12 col-xs-12"
                    key={index}
                  >
                    <img
                      className={styles.thumbnailimage}
                      src={`${
                        process.env.REACT_APP_API_ENDPOINT
                      }/${image.replace("uploads/", "")}`}
                      alt=""
                      onClick={() => openLightbox(index)}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {images.map((image, index) => (
                  <div
                    className="col-lg-3 col-md-6 col-sm-12 col-xs-12"
                    key={index}
                  >
                    <img
                      className={styles.thumbnailimage}
                      src={image}
                      alt=""
                      onClick={() => openLightbox(index)}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        {/* <div className="col-md-2">
          <div className={styles.viewallimagesmain}>
            <h3 className={styles.viewallimages}>View All</h3>
          </div>
        </div> */}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={`${
            process.env.REACT_APP_API_ENDPOINT
          }/${getJobCardDetailsData.image_paths[photoIndex].replace(
            "uploads/",
            ""
          )}`}
          nextSrc={
            getJobCardDetailsData.image_paths[
              (photoIndex + 1) % getJobCardDetailsData.image_paths.length
            ]
          }
          prevSrc={
            getJobCardDetailsData.image_paths[
              (photoIndex + getJobCardDetailsData.image_paths.length - 1) %
                getJobCardDetailsData.image_paths.length
            ]
          }
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + getJobCardDetailsData.image_paths.length - 1) %
                getJobCardDetailsData.image_paths.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex + 1) % getJobCardDetailsData.image_paths.length
            )
          }
          imagePadding={100}
        />
      )}
    </div>
  );
};

export default GalleryPage;
