import { useEffect } from "react";
import ProductInventory from "../../../../../components/appSpecific/ProductInventory/components/ProductInventory";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { getUserDataFromLocalStorage } from "../../../../../utils/helper.utils";
import { ConfigurationProps } from "../../container/configuartionContainer";
export default function ProductsPage(props: ConfigurationProps) {
  const { loading, GetAllProduct, productDetails, getAllProductLoading } =
    props;
  const userData = getUserDataFromLocalStorage();
  useEffect(() => {
    GetAllProduct(userData?.partner_id);
  }, [GetAllProduct, userData?.partner_id]);
  return (
    <>
      {loading || getAllProductLoading ? (
        <CustomLoader />
      ) : (
        <ProductInventory productDetails={productDetails} />
      )}
    </>
  );
}
