import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import {
  reset,
  updatePartner,
  updateUser,
} from "../../Configuration/services/configurationSlice";
import { UpdatePartnerApiDTo, UpdateUserDT } from "../../Configuration/types";
import { getAllActiveService } from "../../Manage/VeichleRegistration/service/veichleRegistrationSlice";
import EditProfile from "../components/EditProfile";
import { Service } from "../services/types";

interface StateProps {
  activeServiceSuccess: boolean;
  activeServiceError: boolean;
  activeServiceLoading: boolean;
  activeServiceDetails: Service[];
  updatePartner_Loading: boolean;
  updatePartner_Error: boolean;
  updatePartner_Success: boolean;
  updatePartner_Details: null | [];
  updateUser_Loading: boolean;
  updateUser_Error: boolean;
  updateUser_Success: boolean;
  updateUser_Details: null | [];
}

interface DispatchProps {
  GetAllActiveService(id: number): void;
  UpdatePartner(data: UpdatePartnerApiDTo): void;
  UpdateUser(data: UpdateUserDT): void;
  Reset: () => void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    activeServiceSuccess: state.vehicleRegistration.activeServiceSuccess,
    activeServiceError: state.vehicleRegistration.activeServiceError,
    activeServiceLoading: state.vehicleRegistration.activeServiceLoading,
    activeServiceDetails: state.vehicleRegistration.activeServiceDetails,
    updatePartner_Loading: state.configuration.updatePartner_Loading,
    updatePartner_Error: state.configuration.updatePartner_Error,
    updatePartner_Success: state.configuration.updatePartner_Success,
    updatePartner_Details: state.configuration.updatePartner_Details,
    updateUser_Loading: state.configuration.updateUser_Loading,
    updateUser_Error: state.configuration.updateUser_Error,
    updateUser_Success: state.configuration.updateUser_Success,
    updateUser_Details: state.configuration.updateUser_Details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllActiveService: (id) => {
      dispatch(getAllActiveService(id));
    },
    UpdatePartner: (data) => {
      dispatch(updatePartner(data));
    },
    UpdateUser: (data) => {
      dispatch(updateUser(data));
    },
    Reset() {
      dispatch(reset());
    },
  };
};

export interface EditProfileProps extends StateProps, DispatchProps {}
export const EditProfileComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfile);
