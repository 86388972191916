import { axios } from "../../../../../api/setup.intersepter";
import { JobCardCreateFinalDataDTO, JobCardDT, JobCardSearchDT } from "../type";

export const GetAllActiveUser = (id: number) => {
  return axios({
    method: "GET",
    url: `/user/active/${id}`,
  });
};
export const GetAllActiveService = (id: number) => {
  return axios({
    method: "GET",
    url: `/service/active/${id}`,
  });
};
export const GetAllActiveProduct = (id: number) => {
  return axios({
    method: "GET",
    url: `/product/active/${id}`,
  });
};

export const CreateJobCard = (data: JobCardDT) => {
  return axios({
    method: "POST",
    url: `/jobcard`,
    data: { jobcard: data },
  });
};
export const SearchJobCard = (data: JobCardSearchDT) => {
  return axios({
    method: "GET",
    url: `jobcard/vehicle/${data.vehicle_number}?id=${data.partner_id}`,
  });
};

// export const CreateJobCardComplaints = (data: CreateComplaintType[]) => {
//   return axios({
//     method: "POST",
//     url: `/jobcard/createJobCardComplaints`,
//     data: data,
//   });
// };

// export const CreateJobCardServices = (data: CreateServiceType[]) => {
//   return axios({
//     method: "POST",
//     url: `/jobcard/createJobCardServices`,
//     data: data,
//   });
// };
// export const CreateJobCardSpareParts = (data: OrderType[]) => {
//   return axios({
//     method: "POST",
//     url: `/jobcard/createJobCardSpareParts`,
//     data: data,
//   });
// };
export const CreateJobCardFinal = (data: JobCardCreateFinalDataDTO) => {
  return axios({
    method: "POST",
    url: `/jobcard/update`,
    data: data,
  });
};
