import { Button } from "@mui/material";
import defaultImage from "../../../assets/images/DefaultImage.png";
import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";
export function SparesList(props: any) {
  const {
    id,
    name,
    product_price,
    product_quantity,
    image_path,
    handleSpareAdd,
    // handleRemoveSpare,
  } = props;
  return (
    <div className="col">
      <div className={styles.spares_wrapper}>
        <div className="d-flex justify-content-center">
          <div className={styles.img_wrapper}>
            <img
              src={
                image_path
                  ? `${
                      process.env.REACT_APP_API_ENDPOINT
                    }/${image_path?.replace("uploads/", "")}`
                  : defaultImage
              }
              alt="pic"
              className={styles.img_wrapper_img}
            />
          </div>
        </div>
        <p className={styles.spares_name}>{name}</p>
        <div className=" ">
          <p className={styles.spares_stock}>Product Price {product_price}</p>
        </div>
        <div className=" ">
          {/* <p className={styles.spares_stock}>Stock available {max_quantity}</p> */}
        </div>
        <div className={styles.adding_item_botton_spares}>
          {product_quantity > 0 ? (
            <>
              {/* <button>
                <RemoveIcon
                  sx={{
                    color: "#0761E9",
                  }}
                  onClick={() => handleRemoveSpare(part_id)}
                />
              </button> */}
              <Button
                size="small"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  handleSpareAdd({
                    ID: id,
                    Name: name,
                    Price: product_price,
                    Quantity: product_quantity,
                    spareImage: image_path,
                    stock: product_quantity,
                  })
                }
                variant="outlined"
              >
                Add
              </Button>
              {/* <button>
                <AddIcon
                  sx={{
                    color: "#0761E9",
                  }}
                  onClick={() =>
                    handleSpareAdd({
                      spareId: part_id,
                      spareName: part_name,
                      spareCost: spareCost,
                      spareAvailable: quantity,
                      spareImage: spare_image,
                    })
                  }
                />
              </button> */}
            </>
          ) : (
            <div className={styles.increment_btn_spares}>
              <p className={styles.spares_out_stock}>Out of Stock</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
