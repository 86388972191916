import { connect } from "react-redux";
import {
  createService,
  getAllActiveServiceCate,
  reset,
  updateService,
} from "../../../../pages/app/Configuration/services/configurationSlice";
import { ServiceCategoryRes } from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import ServiceInventoryForm from "../components/ServiceInventoryForm/ServiceInventoryForm";

interface StateProps {
  getAllServiceActiveCateSuccess: boolean;
  getAllServiceActiveCateError: boolean;
  getAllServiceActiveCateLoading: boolean;
  activeServiceCategoryDetails: ServiceCategoryRes[];
  createService_Loading: boolean;
  createService_Error: boolean;
  createService_Success: boolean;
  createService_Details: null;
  updateService_Loading: boolean;
  updateService_Error: boolean;
  updateService_Success: boolean;
  updateService_Details: null;
}

interface DispatchProps {
  GetAllActiveServiceCate(id: number): void;
  resetAllServiceCategory(): void;
  createService(data: any): void;
  updateService(data: any): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    createService_Loading: state.configuration.createService_Loading,
    createService_Error: state.configuration.createService_Error,
    createService_Success: state.configuration.createService_Success,
    createService_Details: state.configuration.createService_Details,
    updateService_Loading: state.configuration.updateService_Loading,
    updateService_Error: state.configuration.updateService_Error,
    updateService_Success: state.configuration.updateService_Success,
    updateService_Details: state.configuration.updateService_Details,
    getAllServiceActiveCateSuccess:
      state.configuration.getAllServiceActiveCateSuccess,
    getAllServiceActiveCateError:
      state.configuration.getAllServiceActiveCateError,
    getAllServiceActiveCateLoading:
      state.configuration.getAllServiceActiveCateLoading,
    activeServiceCategoryDetails:
      state.configuration.activeServiceCategoryDetails,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllActiveServiceCate: (id) => {
      dispatch(getAllActiveServiceCate(id));
    },
    resetAllServiceCategory: () => {
      dispatch(reset());
    },
    createService: (data) => {
      dispatch(createService(data));
    },
    updateService: (data) => {
      dispatch(updateService(data));
    },
  };
};

export interface ServiceInventoryFormProps extends StateProps, DispatchProps {}
export const ServiceInventoryFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceInventoryForm);
