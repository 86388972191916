import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import Reports from "../components/Reports";

interface StateProps {}

interface DispatchProps {}

const mapStateToProps = (state: RootState): StateProps => {
  return {};
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {};
};

export interface ReportsProps extends StateProps, DispatchProps {}
export const ReportsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports);
