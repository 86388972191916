import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { CustomerFeedback } from "../../../pages/app/CustomerOrderTracking/services/types";
import { JobcardInvoice } from "../../../pages/app/Invoice/service/types";
interface InvoiceProps {
  getFeedback_Details: CustomerFeedback | null;
  getJobCradInvoice_Details: JobcardInvoice | null;
}
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    // fontFamily: "Calibri",
    fontSize: 10,
    paddingTop: 35,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.15,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 10,
    textAlign: "center",
    marginBottom: 10,
  },
  titles: {
    fontSize: 10,
    // marginBottom: 5,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 10,
    marginBottom: 5,
  },
  item: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  items: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  contact: {
    marginLeft: 121.65,
    textAlign: "justify",
    // lineHeight: 10.95,
    fontSize: 9,
    marginBottom: 10,
  },
  address: {
    fontSize: 9,
    textAlign: "center",
    marginBottom: 10,
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },

  signatureBlock: {
    width: "45%", // Adjust the width as needed
    borderWidth: 1,
    borderColor: "black",
    padding: 10,
  },
});
export default function Invoice(props: InvoiceProps) {
  const { getFeedback_Details, getJobCradInvoice_Details } = props;
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ border: "1 solid black", padding: 20 }}>
            <View>
              <Text style={styles.title}>
                {getFeedback_Details?.garage_name}
              </Text>
              <Text style={styles.address}>{getFeedback_Details?.address}</Text>
              <Text style={styles.contact}>
                <Text style={{ fontSize: 9 }}>Ph</Text>
                <Text style={{ fontSize: 9 }}>
                  : {getFeedback_Details?.contact_number}
                </Text>{" "}
                <Text style={{ fontSize: 9 }}> Email</Text>
                <Text style={{ fontSize: 9 }}>
                  :{getFeedback_Details?.email}
                </Text>
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.title}>Invoice Details</Text>
              <Text style={styles.subtitle}>
                Job Card ID: {getJobCradInvoice_Details?.id}
              </Text>
              <Text style={styles.subtitle}>
                Vehicle Number:{" "}
                {getJobCradInvoice_Details?.vehicle.vehicle_number}
              </Text>
              <Text style={styles.subtitle}>
                Customer Name:{" "}
                {getJobCradInvoice_Details?.customer.customer_name}
              </Text>
              {/* Add other customer details here if needed */}
            </View>

            <View style={styles.section}>
              <Text style={styles.title}>Services</Text>
              {getJobCradInvoice_Details?.jobcardService.map((service) => (
                <View key={service.service_name} style={styles.item}>
                  <Text>{service.service_name}</Text>
                  <Text>{service.service_price}</Text>
                </View>
              ))}
            </View>

            <View style={styles.section}>
              <Text style={styles.title}>Spare Parts</Text>
              {getJobCradInvoice_Details?.jobcardSpareParts.map((sparePart) => (
                <View key={sparePart.product_name} style={styles.item}>
                  <Text>{sparePart.product_name}</Text>
                  <Text>{sparePart.spare_part_price}</Text>
                </View>
              ))}
            </View>

            <View style={styles.section}>
              <Text style={styles.title}>Extra Service</Text>
              <View style={styles.item}>
                <Text>Extra Service</Text>
                <Text>{getJobCradInvoice_Details?.jobcardExtraService}</Text>
              </View>
            </View>

            {/* <View>
              <Text style={styles.titles}>Sub Price</Text>
              <View style={styles.items}>
                <Text></Text>
                <Text>{getJobCradInvoice_Details?.totalJobcardPrice}</Text>
              </View>
            </View>
            <View>
              <Text style={styles.titles}>Tax {userData.tax_percentage}%</Text>
              <View style={styles.items}>
                <Text></Text>
                <Text>
                  {" "}
                  {(Number(totalPrice) * userData.tax_percentage) / 100}
                </Text>
              </View>
            </View> */}
            <View>
              <Text style={styles.titles}>Total Price</Text>
              <View style={styles.items}>
                <Text></Text>
                <Text>
                  {/* {Math.round(
                    Math.floor(Number(totalPrice)) +
                      Math.floor(Number(totalPrice) * userData.tax_percentage) /
                        100
                  )} */}
                  {getJobCradInvoice_Details?.totalJobcardPrice}
                </Text>
              </View>
            </View>
            <View style={styles.signatureContainer}>
              <View style={styles.signatureBlock}>
                <Text style={{ marginBottom: 5 }}>Customer Signature</Text>
                <Text>Date: ________/________/________</Text>
              </View>
              <View style={styles.signatureBlock}>
                <Text style={{ marginBottom: 5 }}>Authorized Signature</Text>
                <Text>Date: ________/________/________</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
}
