import Brightness1Icon from "@mui/icons-material/Brightness1";
import { IconButton, Tooltip } from "@mui/material";
import userComplain from "../../../assets/images/complaints.svg";
import { ComplaintsDT } from "../../../pages/app/Manage/Home/type";
import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";

export function ComplaintsListHome({
  data,
  index,
}: {
  data: ComplaintsDT;
  index: number;
}) {
  const statusColors: any = {
    Pending: "warning",
    Start: "primary",
    StartAgain: "primary",
    Paused: "info",
    Completed: "success",
  };
  const status: any = {
    Pending: "Pending",
    Start: "In Progress",
    StartAgain: "In Progress",
    Paused: "Paused",
    Completed: "Completed",
  };
  const statusColor = statusColors[data.task_status] || "default";
  const taskStatus = status[data.task_status] || "Pending";

  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-4 col-xs-12 mb-3">
        <Tooltip arrow title={taskStatus}>
          <IconButton
            style={{
              float: "right",
            }}
          >
            <Brightness1Icon color={statusColor} />
          </IconButton>
        </Tooltip>
        <div className={styles.spares_wrapper}>
          <div className="d-flex flex-row justify-content-start align-content-center">
            <div className={styles.img_wrapper_comp}>
              <img
                src={userComplain}
                alt="pic"
                width={30}
                height={30}
                className={styles.img_wrapper_img_comp}
              />
            </div>
            <div className="d-flex justify-content-center flex-column align-content-center ms-3">
              <p className={styles.spares_name}>
                {`Details: ` + data.complaints}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
