import * as FileSaver from "file-saver";
import { useCallback, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import ButtonLog from "../../../../../../components/common/Button/Button";
import CustomLoader from "../../../../../../components/common/Loader/Loader";
import CustomTable from "../../../../../../components/common/Table/CustomTable";
import { getUserDataFromLocalStorage } from "../../../../../../utils/helper.utils";
import { JobDurationReportRes } from "../../../services/types";
import { JobDurationReportProps } from "../container/jobDurationReportContainer";
import styles from "./JobDurationReport.module.css";

export default function JobDurationReport(props: JobDurationReportProps) {
  const {
    GetJobDurationReport,
    getJobDurationReportDetails,
    getJobDurationReportError,
    getJobDurationReportLoading,
    getJobDurationReportSuccess,
  } = props;
  const userData: any = getUserDataFromLocalStorage();
  const { state } = useLocation();

  useEffect(() => {
    GetJobDurationReport({
      id: userData?.partner_id,
      startDate: state ? state.fromDate : "",
      endDate: state ? state.toDate : "",
    });
  }, [GetJobDurationReport, state, userData?.partner_id]);
  const navigation = useNavigate();
  const columns = [
    { id: "sl", label: "Serial No.", minWidth: 100 },

    // { id: "Job_Name", label: "Name", minWidth: 100 },
    { id: "supervisor_name", label: "Supervisor", minWidth: 100 },
    { id: "mechanic_name", label: "Mechanic", minWidth: 100 },
    { id: "created_at", label: "Start Date", minWidth: 100 },
    { id: "modified_at", label: "End Date", minWidth: 100 },
    { id: "duration_days", label: "Duration", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
  ];

  const updatedRows: any = getJobDurationReportDetails?.map(
    (row: JobDurationReportRes, index: number) => ({
      ...row,
      sl: index + 1,
    })
  );

  const exportToCSV = useCallback(
    (fileName: string) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(updatedRows);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    },
    [updatedRows]
  );

  return (
    <>
      {getJobDurationReportLoading && <CustomLoader />}
      <div className={styles.inventoryNavBar}>
        <ButtonLog
          text={"Back"}
          variant={"contained"}
          onClick={() => navigation("/reports")}
          className={styles.backBtn}
        />
        {/* <div className={styles.filter}>
          <ButtonLog
            text={"Generate"}
            variant={"contained"}
            className={styles.addProducteBtn}
          />
        </div> */}
      </div>
      <div className="row me-1  mt-2">
        <div className="offset-lg-10 offset-md-8 offset-sm-6 col-lg-1 col-md-2 col-sm-3 mt-1">
          <ButtonLog
            text={
              <CSVLink
                style={{ color: "inherit" }}
                headers={columns?.map((item) => ({
                  key: item.id,
                  label: item.label,
                }))}
                data={updatedRows ?? []}
              >
                {" "}
                CSV{" "}
              </CSVLink>
            }
            variant={"contained"}
            className={styles.addProducteBtn}
          />
        </div>
        <div className="col-lg-1 col-md-2 col-sm-3 mt-1">
          <ButtonLog
            text={"XLSX"}
            variant={"contained"}
            className={styles.addProducteBtn}
            onClick={() => exportToCSV("job_duration_report")}
          />
        </div>
      </div>
      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <CustomTable columns={columns} rows={updatedRows} />
      </div>
    </>
  );
}
