import { useEffect } from "react";
import User from "../../../../../components/appSpecific/User/components/User";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { getUserDataFromLocalStorage } from "../../../../../utils/helper.utils";
import { ConfigurationProps } from "../../container/configuartionContainer";
export default function UsersPage(props: ConfigurationProps) {
  const { loading, GetAllUser, userDetails, getAllUserLoading } = props;
  // let userDataParse: { company_id: number } = { company_id: 0 };

  const userData: any = getUserDataFromLocalStorage();

  useEffect(() => {
    GetAllUser(userData?.partner_id);
  }, [GetAllUser, userData?.partner_id]);
  return (
    <>
      {loading || getAllUserLoading ? <CustomLoader /> : <></>}
      <User userDetails={userDetails} />
    </>
  );
}
