import { connect } from "react-redux";

import {
  createBay,
  reset,
  updateBay,
} from "../../../../pages/app/Configuration/services/configurationSlice";
import {
  CreateBayDT,
  UpdateBayDT,
} from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import BayForm from "../components/BaysForm/BayForm";

interface StateProps {
  createBay_Loading: boolean;
  createBay_Error: boolean;
  createBay_Success: boolean;
  createBay_Details: null;
  updateBay_Loading: boolean;
  updateBay_Error: boolean;
  updateBay_Success: boolean;
  updateBay_Details: null;
}
interface DispatchProps {
  CreateBay(data: CreateBayDT): void;
  UpdateBay(data: UpdateBayDT): void;
  resetAll(): void;
}
const mapStateToProps = (state: RootState): StateProps => {
  return {
    createBay_Loading: state.configuration.createBay_Loading,
    createBay_Error: state.configuration.createBay_Error,
    createBay_Success: state.configuration.createBay_Success,
    createBay_Details: state.configuration.createBay_Details,
    updateBay_Loading: state.configuration.updateBay_Loading,
    updateBay_Error: state.configuration.updateBay_Error,
    updateBay_Success: state.configuration.updateBay_Success,
    updateBay_Details: state.configuration.updateBay_Details,
  };
};
const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    resetAll: () => {
      dispatch(reset());
    },
    CreateBay: (data) => {
      dispatch(createBay(data));
    },
    UpdateBay: (data) => {
      dispatch(updateBay(data));
    },
  };
};
export interface BayFormProps extends StateProps, DispatchProps {}
export const BayFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BayForm);
