import {
  FormControlLabel,
  IconButton,
  OutlinedInput,
  SnackbarOrigin,
  Switch,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../utils/helper.utils";
import ButtonLog from "../../../../common/Button/Button";
import { SnackBar } from "../../../../common/SnackBar/SnackBar";

import CustomLoader from "../../../../common/Loader/Loader";
import { SupplerFormProps } from "../../container/supplierContainer";
import styles from "../Suppliers.module.css";
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function SupplerForm(props: SupplerFormProps) {
  const {
    CreateSupplier,
    UpdateSupplier,
    createSupplier_Details,
    createSupplier_Error,
    createSupplier_Loading,
    createSupplier_Success,
    updateSupplier_Details,
    updateSupplier_Error,
    updateSupplier_Loading,
    updateSupplier_Success,
    resetAll,
  } = props;
  const navigate = useNavigate();

  const [formType, setFormType] = React.useState<string | undefined>(
    "supplier-form/add"
  );
  let param = useParams();
  const { state } = useLocation();

  // let userDataParse: { id: string; company_id: number } = {
  //   id: "",
  //   company_id: 0,
  // };

  // const userData: any = localStorage.getItem("USER_DATA");
  const userDataParse = getUserDataFromLocalStorage();

  const [user, setUser] = React.useState({
    name: state ? state?.supplier.name : "",
    address: state ? state?.supplier.address : "",
    registration_number: state ? state?.supplier.registration_number : "",
    phone_number: state ? state?.supplier.phone_number : "",
    slug: state ? state?.supplier.slug : "test",
    partner_id: userDataParse?.partner_id,
  });

  const [checked, setChecked] = React.useState(
    state ? state?.supplier.is_active : true
  );

  const handleChange = (event: any) => {
    const updatedValue = event.target.checked;
    setChecked(updatedValue);
  };
  useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "error",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: user.name,
      errorMsz: "Name is required and must be of maximum 30 characters",
      max: 30,
      min: 1,
    },

    {
      checkwith: "min",
      value: user.phone_number,
      errorMsz: "Phone Number cannot be empty",
      min: 1,
    },
  ];
  const handleSave = () => {
    validate(ValidateData).status
      ? CreateSupplier({
          ...user,
          created_by: userDataParse.id,
          // companyId: userDataParse.company_id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };

  const handleEdit = () => {
    validate(ValidateData).status
      ? UpdateSupplier({
          ...user,
          IsActive: checked ? true : false,
          supplier_id: state.supplier.id,
          updated_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };
  const [userType, setUserType] = React.useState("");
  // const handleChange = (event: SelectChangeEvent) => {
  //   setUserType(event.target.value as string);
  // };

  React.useEffect(() => {
    if (createSupplier_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createSupplier_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAll();
    } else if (createSupplier_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          createSupplier_Details ? createSupplier_Details : createSupplier_Error
        ),
        snackOpen: true,
        snackSeverity: createSupplier_Details ? "success" : "error",
      }));
      resetAll();
    }
  }, [
    createSupplier_Details,
    createSupplier_Error,
    createSupplier_Success,
    navigate,
    resetAll,
  ]);

  React.useEffect(() => {
    if (updateSupplier_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateSupplier_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAll();
    } else if (updateSupplier_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          updateSupplier_Details
            ? updateSupplier_Details
            : "Something went wrong"
        ),
        snackOpen: true,
        snackSeverity: updateSupplier_Details ? "success" : "error",
      }));
      resetAll();
    }
  }, [
    navigate,
    resetAll,
    updateSupplier_Details,
    updateSupplier_Error,
    updateSupplier_Success,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {createSupplier_Loading || updateSupplier_Loading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>
            {formType === "supplier-form/add"
              ? "Add Supplier"
              : "Edit Supplier"}
          </p>
        </div>
        <div className="container mt-5" style={{ backgroundColor: "#F5F5F5;" }}>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Name
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter  Name"
                  type="text"
                  onChange={(e) => {
                    setUser((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  value={user?.name}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Phone No
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Phone No"
                  type="text"
                  onChange={(e) => {
                    setUser((prevState) => ({
                      ...prevState,
                      phone_number: e.target.value,
                    }));
                  }}
                  value={user?.phone_number}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Address
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  placeholder="Enter Address"
                  className={styles.outline_inputbox}
                  type="email"
                  onChange={(e) => {
                    setUser((prevState) => ({
                      ...prevState,
                      address: e.target.value,
                    }));
                  }}
                  value={user?.address}
                />
              </FormControl>

              {formType === "supplier-form/edit" && (
                <>
                  <FormControl
                    className={styles.formControl}
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <span className={styles.label}>
                      Status
                      {/* <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span> */}
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="Active"
                      // onChange={}
                    />
                  </FormControl>
                </>
              )}
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Registration No
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Registration No"
                  type="text"
                  onChange={(e) => {
                    setUser((prevState) => ({
                      ...prevState,
                      registration_number: e.target.value,
                    }));
                  }}
                  value={user?.registration_number}
                />
              </FormControl>
            </div>
          </div>

          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              variant={"contained"}
              className={styles.saveBtn}
              onClick={
                formType === "supplier-form/edit" ? handleEdit : handleSave
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
