import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../../redux/store";
import { getRevenueReport } from "../../../services/reportsSlice";
import { ReportDT, RevenueReportRes } from "../../../services/types";
import RevenueReport from "../components/RevenueReport";

interface StateProps {
  getRevenueReportError: boolean;
  getRevenueReportLoading: boolean;
  getRevenueReportSuccess: boolean;
  getRevenueReportDetails: RevenueReportRes[];
}

interface DispatchProps {
  GetRevenueReport(body: ReportDT): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getRevenueReportDetails: state.reports.getRevenueReportDetails,
    getRevenueReportError: state.reports.getRevenueReportError,
    getRevenueReportLoading: state.reports.getRevenueReportLoading,
    getRevenueReportSuccess: state.reports.getRevenueReportSuccess,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetRevenueReport(body) {
      dispatch(getRevenueReport(body));
    },
  };
};

export interface RevenueReportProps extends StateProps, DispatchProps {}
export const RevenueReportComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(RevenueReport);
