import { axios } from "../../../../api/setup.intersepter";
import { ExpensesLogDT } from "./types";

export const CreateExpenseLog = (data: ExpensesLogDT) => {
  return axios({
    method: "POST",
    url: `/expense-log`,
    data: data,
  });
};

export const GetAllExpenseLog = (id: string) => {
  return axios({
    method: "GET",
    url: `/expense-log/${id}`,
  });
};
