import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Button, Chip } from "@mui/material";
import { Edit } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { ServiceData } from "../../../../pages/app/Configuration/types";
import ButtonLog from "../../../common/Button/Button";
import CustomTable from "../../../common/Table/CustomTable";
import styles from "./ServiceCategory.module.css";

import { useDispatch, useSelector } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { Search } from "@mui/icons-material";
import { OutlinedInput } from "@mui/material";
import { getAllService, getAllServiceCate } from "../../../../pages/app/Configuration/services/configurationSlice";
import { useForm } from "react-hook-form";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";


interface ServiceCategoryProps {
  serviceCategoryDetails: any;
}

export default function ServiceCategory(props: ServiceCategoryProps) {


  const serviceCategoryDetails = useSelector(
    (state: RootState) => state.configuration.serviceCategoryDetails
  ) ?? [];

  const dispatch: TypedDispatch = useDispatch()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const userData = getUserDataFromLocalStorage();

  const onSubmit = async (data: any) => {
    await dispatch(getAllServiceCate({
      id: userData.partner_id,
        queryParams: {
          name: data?.name
        }
    }))
  };



  const navigation = useNavigate();
  const columns = [
    { id: "name", label: "Category Name", minWidth: 170 },
    { id: "description", label: "Details", minWidth: 100 },
    { id: "is_active", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleEditClick = (service: ServiceData) => {
    navigation("/services-category-form/edit", {
      state: { service },
    });
  };

  const updatedRows = serviceCategoryDetails?.map((row: ServiceData) => ({
    ...row,
    is_active:
      row.is_active === false ? (
        <Chip label="In Active" variant="outlined" color="error" />
      ) : (
        <Chip label="Active" variant="outlined" color="success" />
      ),
    action: (
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Edit size="18" color="#0761e9" />}
        onClick={() => handleEditClick(row)}
      >
        Edit
      </Button>
    ),
  }));

  return (
    <>
      <div className={styles.inventoryNavBar}>
        {/* <div className={styles.filter}>
          <ButtonLog
            text={"Go Back"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => setIsToggle(!isToggle)}
          />
        </div> */}
        <div>
          <ButtonLog
            text={"Back"}
            variant={"contained"}
            onClick={() => navigation(-1)}
            className={styles.backBtn}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: "flex" }}>
            <OutlinedInput sx={{
              borderRadius: "0px !important",
              height: "40px !important"
            }} {...register('name')} />
            <ButtonLog
              text="Search"
              variant="contained"
              type="submit"
              startIcon={<Search />}
            />

          </div>
        </form>
        <div className={styles.filter}>
          <ButtonLog
            text={"Add Service Category"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/services-category-form/add")}
          />
        </div>
      </div>
      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <CustomTable columns={columns} rows={updatedRows} />
      </div>
    </>
  );
}
