import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { SpareReqDT } from "../components/MechanicPendingtask/service/types";
import {
  RequestSparePart,
  ReturnSparePart,
  SelectBayByMech,
  getAllMechJobs,
  statusOfService,
} from "./mechanic.requests";
import {
  ChangeStatusOfServiceProps,
  GetMechJobDT,
  JobCardCreateSpares,
  MechanicJobState,
  SelectBayByMechProps,
} from "./type";

export const initialState: MechanicJobState = {
  getAllMechJobs_Loading: false,
  getAllMechJobs_Error: false,
  getAllMechJobs_Success: false,
  mechanicJobs: [],
  changeStatusOfService_Loading: false,
  changeStatusOfService_Error: false,
  changeStatusOfService_Success: false,
  selectBayByMech_Loading: false,
  selectBayByMech_Error: false,
  selectBayByMech_Success: false,
  requestSparePart_Loading: false,
  requestSparePart_Error: false,
  requestSparePart_Success: false,
  requestSparePart_details: null,
  returnSparePart_Loading: false,
  returnSparePart_Error: false,
  returnSparePart_Success: false,
  returnSparePart_details: null,
};

export const getAllMechJobsAction = createAsyncThunk(
  "GetAllJobsForMechanic",
  (body: GetMechJobDT) => {
    return getAllMechJobs(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data.jobcardTask;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const changeStatusOfServiceAction = createAsyncThunk(
  "ChangeStatusOfService",
  (body: ChangeStatusOfServiceProps) => {
    return statusOfService(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const selectBayByMech = createAsyncThunk(
  "mechanic/selectBayByMech",
  (body: SelectBayByMechProps) => {
    return SelectBayByMech(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const requestSparePart = createAsyncThunk(
  "mechanic/requestSparePart",
  (body: SpareReqDT) => {
    return RequestSparePart(body)
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const returnSparePart = createAsyncThunk(
  "mechanic/returnSparePart",
  (body: JobCardCreateSpares) => {
    return ReturnSparePart(body)
      .then((res) => {
        if (res.status === 201) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const MechanicJobs = createSlice({
  name: "MechanicJobs",
  initialState,
  reducers: {
    reset: () => initialState,
    resetMechanicChangeStatus: (state) => {
      state.changeStatusOfService_Success = false;
      state.changeStatusOfService_Error = false;
      state.changeStatusOfService_Loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMechJobsAction.pending, (state) => {
      state.getAllMechJobs_Loading = true;
      state.getAllMechJobs_Error = false;
      state.getAllMechJobs_Success = false;
    });
    builder.addCase(
      getAllMechJobsAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllMechJobs_Loading = false;
        state.getAllMechJobs_Error = false;
        state.getAllMechJobs_Success = true;
        state.mechanicJobs = action?.payload;
      }
    );
    builder.addCase(
      getAllMechJobsAction.rejected,
      (state, action: AnyAction) => {
        state.getAllMechJobs_Loading = false;
        state.getAllMechJobs_Success = false;
        state.getAllMechJobs_Error =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(changeStatusOfServiceAction.pending, (state) => {
      state.changeStatusOfService_Loading = true;
      state.changeStatusOfService_Error = false;
      state.changeStatusOfService_Success = false;
    });
    builder.addCase(
      changeStatusOfServiceAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.changeStatusOfService_Loading = false;
        state.changeStatusOfService_Error = false;
        state.changeStatusOfService_Success = true;
      }
    );
    builder.addCase(
      changeStatusOfServiceAction.rejected,
      (state, action: AnyAction) => {
        state.changeStatusOfService_Loading = false;
        state.changeStatusOfService_Success = false;
        state.changeStatusOfService_Error =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(selectBayByMech.pending, (state) => {
      state.selectBayByMech_Loading = true;
      state.selectBayByMech_Error = false;
      state.selectBayByMech_Success = false;
    });
    builder.addCase(
      selectBayByMech.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.selectBayByMech_Loading = false;
        state.selectBayByMech_Error = false;
        state.selectBayByMech_Success = true;
      }
    );
    builder.addCase(selectBayByMech.rejected, (state, action: AnyAction) => {
      state.selectBayByMech_Loading = false;
      state.selectBayByMech_Success = false;
      state.selectBayByMech_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(requestSparePart.pending, (state) => {
      state.requestSparePart_Loading = true;
      state.requestSparePart_Error = false;
      state.requestSparePart_Success = false;
    });
    builder.addCase(
      requestSparePart.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.requestSparePart_Loading = false;
        state.requestSparePart_Error = false;
        state.requestSparePart_Success = true;
        state.requestSparePart_details = action?.payload;
      }
    );
    builder.addCase(requestSparePart.rejected, (state, action: AnyAction) => {
      state.requestSparePart_Loading = false;
      state.requestSparePart_Success = false;
      state.requestSparePart_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(returnSparePart.pending, (state) => {
      state.returnSparePart_Loading = true;
      state.returnSparePart_Error = false;
      state.returnSparePart_Success = false;
    });
    builder.addCase(
      returnSparePart.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.returnSparePart_Loading = false;
        state.returnSparePart_Error = false;
        state.returnSparePart_Success = true;
        state.returnSparePart_details = action?.payload;
      }
    );
    builder.addCase(returnSparePart.rejected, (state, action: AnyAction) => {
      state.returnSparePart_Loading = false;
      state.returnSparePart_Success = false;
      state.returnSparePart_Error =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default MechanicJobs.reducer;
export const { reset, resetMechanicChangeStatus } = MechanicJobs.actions;
