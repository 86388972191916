import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Button, Chip } from "@mui/material";
import { Edit } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { Supplier } from "../../../../pages/app/Configuration/types";
import ButtonLog from "../../../common/Button/Button";
import CustomTable from "../../../common/Table/CustomTable";
import styles from "./Suppliers.module.css";

import { useDispatch, useSelector } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { Search } from "@mui/icons-material";
import { OutlinedInput } from "@mui/material";
import { getAllSupplier } from "../../../../pages/app/Configuration/services/configurationSlice";
import { useForm } from "react-hook-form";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";

export default function Suppliers(props: any) {
  const SupplierDetails = useSelector(
    (state: RootState) => state.configuration.SupplierDetails
  ) ?? [];

  const dispatch: TypedDispatch = useDispatch()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const userData = getUserDataFromLocalStorage();
  const onSubmit = async (data: any) => {
    await dispatch(getAllSupplier({
      id: userData.partner_id,
        queryParams: {
          name: data?.name
        }
    }))
  };


  const navigation = useNavigate();
  const columns = [
    { id: "name", label: "Name", minWidth: 100 },
    { id: "address", label: "Address", minWidth: 100 },
    { id: "phone_number", label: "Phone No.", minWidth: 100 },
    { id: "registration_number", label: "Registration No.", minWidth: 100 },
    { id: "is_active", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleEditClick = (supplier: Supplier) => {
    navigation("/supplier-form/edit", {
      state: { supplier },
    });
  };
  const updatedRows = SupplierDetails?.map((row: Supplier) => ({
    ...row,
    is_active:
      row.is_active === false ? (
        <Chip label="In Active" variant="outlined" color="error" />
      ) : (
        <Chip label="Active" variant="outlined" color="success" />
      ),
    action: (
      <Button
        variant="outlined"
        startIcon={<Edit size="18" color="#0761e9" />}
        color="primary"
        onClick={() => handleEditClick(row)}
      >
        Edit
      </Button>
    ),
  }));
  return (
    <>
      <div className={styles.inventoryNavBar}>
        <ButtonLog
          text={"Back"}
          variant={"contained"}
          onClick={() => navigation(-1)}
          className={styles.backBtn}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: "flex" }}>
            <OutlinedInput sx={{
              borderRadius: "0px !important",
              height: "40px !important"
            }} {...register('name')} />
            <ButtonLog
              text="Search"
              variant="contained"
              type="submit"
              startIcon={<Search />}
            />

          </div>
        </form>
        <div className={styles.filter}>
          <ButtonLog
            text={"Add Supplier"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/supplier-form/add")}
          />
        </div>
      </div>

      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <CustomTable columns={columns} rows={updatedRows} />
      </div>
    </>
  );
}
