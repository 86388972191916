import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import {
  GetMechanics,
  GetPercentageStatus,
  GetVehicleTracking,
} from "./dashboard.request";
import { DashboardState } from "./types";

export const initialState: DashboardState = {
  getPercentageStatus_Loading: false,
  getPercentageStatus_Error: false,
  getPercentageStatus_Success: false,
  getPercentageStatus_Details: null,
  getVehicleTracking_Loading: false,
  getVehicleTracking_Error: false,
  getVehicleTracking_Success: false,
  getVehicleTracking_Details: [],
  getMechanics_Loading: false,
  getMechanics_Error: false,
  getMechanics_Success: false,
  getMechanics_Details: [],
};

export const getPercentageStatus = createAsyncThunk(
  "user/GetPercentageStatus",
  (id: string) => {
    return GetPercentageStatus(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data.Dashboard;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getVehicleTracking = createAsyncThunk(
  "user/getVehicleTracking",
  (id: string) => {
    return GetVehicleTracking(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getMechanics = createAsyncThunk(
  "user/getMechanics",
  (id: string) => {
    return GetMechanics(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const supervisorDashboardSlice = createSlice({
  name: "supervisorDashboard",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPercentageStatus.pending, (state) => {
      state.getPercentageStatus_Loading = true;
      state.getPercentageStatus_Error = false;
      state.getPercentageStatus_Success = false;
    });
    builder.addCase(
      getPercentageStatus.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getPercentageStatus_Loading = false;
        state.getPercentageStatus_Error = false;
        state.getPercentageStatus_Success = true;
        state.getPercentageStatus_Details = action?.payload;
      }
    );
    builder.addCase(
      getPercentageStatus.rejected,
      (state, action: AnyAction) => {
        state.getPercentageStatus_Loading = false;
        state.getPercentageStatus_Error = false;
        state.getPercentageStatus_Success = false;
        state.getPercentageStatus_Error =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getVehicleTracking.pending, (state) => {
      state.getVehicleTracking_Loading = true;
      state.getVehicleTracking_Error = false;
      state.getVehicleTracking_Success = false;
    });
    builder.addCase(
      getVehicleTracking.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getVehicleTracking_Loading = false;
        state.getVehicleTracking_Error = false;
        state.getVehicleTracking_Success = true;
        state.getVehicleTracking_Details = action?.payload;
      }
    );
    builder.addCase(getVehicleTracking.rejected, (state, action: AnyAction) => {
      state.getVehicleTracking_Loading = false;
      state.getVehicleTracking_Error = false;
      state.getVehicleTracking_Success = false;
      state.getVehicleTracking_Error =
        action?.error?.message || "Something went wrong";
    });

    builder.addCase(getMechanics.pending, (state) => {
      state.getMechanics_Loading = true;
      state.getMechanics_Error = false;
      state.getMechanics_Success = false;
    });
    builder.addCase(
      getMechanics.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getMechanics_Loading = false;
        state.getMechanics_Error = false;
        state.getMechanics_Success = true;
        state.getMechanics_Details = action?.payload;
      }
    );
    builder.addCase(getMechanics.rejected, (state, action: AnyAction) => {
      state.getMechanics_Loading = false;
      state.getMechanics_Error = false;
      state.getMechanics_Success = false;
      state.getMechanics_Error =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default supervisorDashboardSlice.reducer;
export const { reset } = supervisorDashboardSlice.actions;
