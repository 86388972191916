export const productUOMArray: string[] = [
  "piece",
  "dozen",
  "pack",
  "box",
  "case",
  "carton",
  "set",
  "pair",
  "meter",
  "centimeter",
  "inch",
  "pound",
  "kilogram",
  "gram",
  "liter",
  "milliliter",
  "gallon",
  "quart",
  "pint",
  "cup",
  "fluid ounce",
];
