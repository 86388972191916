import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import defaultImage from "../../../assets/images/DefaultImage.png";
import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";
interface SelectedSparesProps {
  selectedSpares: any;
  handleSpareAdd: (spare: any) => void;
  handleRemoveSpare: (id: any) => void;
}

export function SelectedSpares(props: SelectedSparesProps) {
  const { selectedSpares, handleSpareAdd, handleRemoveSpare } = props;
  return (
    <div className={styles.selected_spare}>
      {selectedSpares.map((spare: any) => (
        <div className={styles.selectedSpareParts} key={spare.ID}>
          <div className={styles.left_img}>
            <div className={styles.img_wrapper_spare}>
              <img
                src={
                  spare.spareImage
                    ? `${
                        process.env.REACT_APP_API_ENDPOINT
                      }/${spare.spareImage?.replace("uploads/", "")}`
                    : defaultImage
                }
                alt="pic"
                className={styles.img_wrapper_img}
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
            <div className="mx-3">
              <p className={styles.spare_name}>{spare.Name}</p>
              {/* <p className={styles.spare_cost}>Rs. {Math.floor(spare.Price)}</p> */}
            </div>
          </div>
          <div>
            <div className={styles.adding_item_botton}>
              <div className={styles.increment_btn}>
                <button onClick={() => handleRemoveSpare(spare.ID)}>
                  <RemoveIcon sx={{ color: "#0761E9" }} />
                </button>
                <span>{spare.Quantity}</span>
                <button
                  disabled={spare.stock <= spare.Quantity}
                  onClick={() => handleSpareAdd({ ...spare })}
                >
                  <AddIcon sx={{ color: "#0761E9" }} />
                </button>
              </div>
            </div>
            {spare.stock > 0 ? (
              <p className={styles.spares_stock}>
                Stock available {spare.stock}
              </p>
            ) : (
              <p className={styles.spares_out_stock}>Out of Stock</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
