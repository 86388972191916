import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import defaultImage from "../../../assets/images/DefaultImage.png";
import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";
import { getUserDataFromLocalStorage } from "../../../utils/helper.utils";

export function TechnicianList(props: any) {
  const { assignTech, item, handleChange, index, values } = props;
  const data = assignTech.map((ele: any) => {
    return { label: ele.label, value: ele.id };
  });
  const handleChangeLocal = (e: any) => {
    const selectedID = e.target.value;
    handleChange(index, selectedID);
  };
  const userData = getUserDataFromLocalStorage();

  return (
    <div className={styles.spares_wrapper}>
      <div className="d-flex justify-content-center">
        <div className={styles.img_wrapper}>
          <img
            src={
              item.image_path
                ? `${
                    process.env.REACT_APP_API_ENDPOINT
                  }/${item.image_path.replace("uploads/", "")}`
                : defaultImage
            }
            alt="pic"
            width={56}
            height={56}
            // className="img-fluid"
            className={styles.img_wrapper_img}
          />
        </div>
      </div>
      <p className={styles.spares_name}>{item.name}</p>
      <div className=" ">
        <p className={styles.spares_cost}>
          Service Cost: <br />
          {userData.currency_data.symbol}
          {item.price}/-
        </p>
      </div>
      <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
        <InputLabel
          sx={{ fontSize: "0.8em" }}
          id="demo-simple-select-standard-label"
        >
          {values[index] ? "Technician" : "Select Technician"}
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={values[index]}
          onChange={handleChangeLocal}
          // label="Age"
        >
          <MenuItem disabled value={""}>
            <em>Select Technician</em>
          </MenuItem>

          {data.map((ele: any) => (
            <MenuItem value={ele.value}>{ele.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
