import Delivered from "../../../../assets/images/icon_delivered.svg";
import InProgress from "../../../../assets/images/icon_inprogress.svg";
import Pending from "../../../../assets/images/icon_pending.svg";
import Ready from "../../../../assets/images/iocn_ready.svg";
import styles from "../dashbord.module.css";

export default function JobCard({
  inProgress,
  ready,
  delivered,
  pending,
}: {
  inProgress?: number;
  ready?: number;
  delivered?: number;
  pending?: number;
}) {
  return (
    <>
      <div className={styles.job_counter_root}>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className={styles.jobStatusCard_progress}>
              <img
                src={InProgress}
                height={24}
                width={24}
                className={styles.jobStatusCard_img}
              />
              <h3 className={styles.txt_count_progress}>{inProgress ?? 0}</h3>
              <p className={styles.jobStatus_txt}>In Progress</p>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className={styles.jobStatusCard_ready}>
              <div>
                <img src={Ready} />
              </div>
              <div>
                <h3 className={styles.txt_count_redy}>{ready ?? 0}</h3>
              </div>
              <div>
                <p className={styles.jobStatus_txt}>Ready</p>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className={styles.jobStatusCard_Delivered}>
              <div>
                <img src={Delivered} />
              </div>
              <div>
                <h3 className={styles.txt_count_delivered}>{delivered ?? 0}</h3>
              </div>
              <div>
                <p className={styles.jobStatus_txt}>Delivered</p>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className={styles.jobStatusCard_pending}>
              <div>
                <img src={Pending} />
              </div>
              <div>
                <h3 className={styles.txt_count_Pending}>{pending ?? 0}</h3>
              </div>
              <div>
                <p className={styles.jobStatus_txt}>Pending</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
