import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EstimateCard } from "../../../../../../components/appSpecific/EstimateCard/EstimateCard";
import { RootState } from "../../../../../../redux/store";
import {
  convertMinutes,
  getUserDataFromLocalStorage,
} from "../../../../../../utils/helper.utils";
import styles from "../Registration.module.css";
import { addEstimate } from "./service/estimateSlice";
export function Estimate() {
  const userData = getUserDataFromLocalStorage();

  const [estimated, setEstimated] = useState({
    description: "Others",
    price: "",
    estimated: "",
  });
  const items = useSelector((state: RootState) => state.services.services);
  const estimate = useSelector((state: RootState) => state.estimate.estimate);
  const dispatch = useDispatch();
  const products = useSelector((state: RootState) => state.spares.spares);
  const complaints = useSelector(
    (state: RootState) => state.complaints.complaints
  );
  const sum = items.reduce((acc, service) => acc + parseInt(service.price), 0);
  const estimated_service_hour = items.reduce(
    (acc, service) => acc + parseInt(service.durationInMinute),
    0
  );

  // const estimatePrice =
  //   estimate && estimate[0] && estimate[0].price ? estimate[0].price : 0;
  const estimatePrice = Number(
    estimated && estimated && estimated.price ? estimated.price : 0
  );
  const productSum = products.reduce((acc, product) => acc + product.Price, 0);
  // console.log(estimateDate);
  return (
    <>
      <div className={styles.main_section}>
        <div className={styles.left_container_estimate}>
          <p className={styles.estimate_service_txt}>Services</p>
          <div className={styles.estimate_spacing}>
            {items.map((ele, index) => (
              <EstimateCard
                item={{
                  iteNname: ele.name,
                  cost: ele.price,
                  icon: ele.name,
                  duration: ele.durationInMinute,
                }}
                index={index}
              />
            ))}
          </div>
          <div className={styles.bottom_wrapper}>
            <div className="row align-items-center">
              <div className="col-6">
                <p className={styles.total_price}>
                  Total : {userData.currency_data.symbol} {Math.floor(sum)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.middle_container_estimate}>
          <p className={styles.estimate_service_txt}>Spares</p>
          <div className={styles.estimate_spacing}>
            {products.map((ele, index) => (
              <EstimateCard
                item={{
                  iteNname: ele.Name,
                  cost: ele.Price,
                  icon: ele.Name,
                  quantity: ele.Quantity,
                }}
                index={index}
              />
            ))}
          </div>
          <div className={styles.bottom_wrapper}>
            <div className="row align-items-center">
              <div className="col-6">
                <p className={styles.total_price}>
                  Total : {userData.currency_data.symbol}{" "}
                  {Math.floor(productSum)}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right_container_estimate}>
          <p className={styles.estimate_service_txt}>
            Estimate Time- {convertMinutes(estimated_service_hour)}
          </p>
          <div className={styles.estimate_spacing}>
            <div className="d-flex justify-content-between">
              <p>Services</p>
              <p>
                {userData.currency_data.symbol} {Math.floor(sum)}{" "}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Spares</p>
              <p>
                {userData.currency_data.symbol} {Math.floor(productSum)}{" "}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Extra Service</p>
              <p>
                {userData.currency_data.symbol} {Math.floor(estimatePrice)}{" "}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Total</p>
              <p>
                {userData.currency_data.symbol}{" "}
                {Math.floor(sum + estimatePrice)}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Tax {userData.tax_percentage}%</p>
              <p>
                {userData.currency_data.symbol}{" "}
                {Math.floor(
                  ((sum + productSum + estimatePrice) *
                    userData.tax_percentage) /
                    100
                )}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className={styles.sub_total}>Sub Total</p>
              <p className={styles.sub_total}>
                {userData.currency_data.symbol}{" "}
                {Math.round(
                  Math.floor(sum + +productSum + estimatePrice) +
                    Math.floor(
                      (sum + productSum + estimatePrice) *
                        userData.tax_percentage
                    ) /
                      100
                )}
              </p>
            </div>
            <FormControl
              size="small"
              sx={{
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <span style={{ marginTop: "10px" }} className={styles.label}>
                Extra Service Charges
              </span>
              <OutlinedInput
                placeholder="Extra Service Charges"
                className={styles.InputRegistrationDesign}
                type="number"
                startAdornment={
                  <InputAdornment position="start">
                    {userData.currency_data.symbol}
                  </InputAdornment>
                }
                // onChange={(e) => {
                //   dispatch(
                //     addEstimate([
                //       {
                //         description: "Others",
                //         price: parseInt(e.target.value),
                //         estimated: estimateDate,
                //       },
                //     ])
                //   );
                // }}
                onChange={(e) => {
                  setEstimated((prevState) => ({
                    ...prevState,
                    price: e.target.value,
                  }));
                }}
                value={estimated.price}
                // value={
                //   estimate && estimate[0] && estimate[0].price
                //     ? String(estimate[0].price)
                //     : ""
                // }
              />
            </FormControl>
            <FormControl
              size="small"
              sx={{
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <span className={styles.label}>Select Estimated Date</span>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disablePast
                  className={styles.InputRegistrationDesign}
                  // onChange={(date: any) =>
                  //   setEstimateDate(moment(date).format("MMMM Do YYYY"))
                  // }
                  onChange={(date: any) => {
                    dispatch(
                      addEstimate([
                        {
                          description: "Others",
                          price: Number(estimated.price),
                          estimated: moment(date).format("MMMM Do YYYY"),
                        },
                      ])
                    );
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          <div className={styles.bottom_wrapper}>
            <div className="row align-items-center">
              <div className="col-6">
                <p className={styles.total_price}>
                  Total : {userData.currency_data.symbol}{" "}
                  {Math.floor(sum + productSum + estimatePrice) +
                    Math.floor(
                      ((sum + productSum + estimatePrice) *
                        userData.tax_percentage) /
                        100
                    )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
