import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Button, Chip } from "@mui/material";
import { Edit } from "iconsax-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ButtonLog from "../../../../components/common/Button/Button";
import CustomLoader from "../../../../components/common/Loader/Loader";
import CustomTable from "../../../../components/common/Table/CustomTable";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";
import { ExpensesLogProps } from "../container/expensesLogContainer";
import { ExpensesLogRes } from "../services/types";
import styles from "./ExpensesLog.module.css";

export const ExpensesLog = (props: ExpensesLogProps) => {
  const {
    GetAllExpensesLog,
    ResetAll,
    getAllExpensesLog_details,
    getAllExpensesLog_error,
    getAllExpensesLog_loading,
    getAllExpensesLog_success,
  } = props;

  const userData: any = getUserDataFromLocalStorage();

  useEffect(() => {
    GetAllExpensesLog(userData.partner_id);
  }, [GetAllExpensesLog, userData.partner_id]);
  const navigation = useNavigate();
  const columns = [
    { id: "title", label: "Title", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 100 },
    { id: "amount", label: "Amount", minWidth: 100 },
    { id: "category", label: "Category", minWidth: 100 },
    { id: "is_expenses", label: "Expenses/Revenue", minWidth: 100 },
  ];

  const handleEditClick = (expenses: ExpensesLogRes) => {
    navigation("/expenses-form/edit", {
      state: { expenses },
    });
  };
  const updatedRows =
    Array.isArray(getAllExpensesLog_details) &&
    getAllExpensesLog_details?.map((row) => ({
      ...row,
      is_expenses: !row.is_expenses ? (
        <Chip label="Revenue" variant="outlined" color="success" />
      ) : (
        <Chip label="Expenses" variant="outlined" color="error" />
      ),
      action: (
        <Button
          variant="outlined"
          startIcon={<Edit size="18" color="#0761e9" />}
          color="primary"
          onClick={() => handleEditClick(row)}
          size="small"
        >
          Edit
        </Button>
      ),
    }));
  return (
    <>
      <div className={styles.inventoryNavBar}>
        <ButtonLog
          text={"Back"}
          variant={"contained"}
          onClick={() => navigation(-1)}
          className={styles.backBtn}
        />

        <div className={styles.filter}>
          <ButtonLog
            text={"Add Expenses"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/expenses-form/add")}
          />
        </div>
      </div>
      {getAllExpensesLog_loading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid" style={{ paddingTop: "20px" }}>
          <CustomTable
            columns={columns}
            rows={updatedRows ? updatedRows : []}
          />
        </div>
      )}
    </>
  );
};
