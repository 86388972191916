import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../../redux/store";
import { createExpensesLog, reset } from "../../../services/expensesLogSlice";
import { ExpensesLogDT } from "../../../services/types";
import ExpensesLogForm from "../components/ExpensesLogForm";

interface StateProps {
  createExpensesLog_loading: boolean;
  createExpensesLog_error: boolean;
  createExpensesLog_success: boolean;
  createExpensesLog_details: null;
}

interface DispatchProps {
  CreateExpensesLog(data: ExpensesLogDT): void;
  ResetAll(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    createExpensesLog_success: state.expensesLog.createExpensesLog_success,
    createExpensesLog_error: state.expensesLog.createExpensesLog_error,
    createExpensesLog_loading: state.expensesLog.createExpensesLog_loading,
    createExpensesLog_details: state.expensesLog.createExpensesLog_details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateExpensesLog(data) {
      dispatch(createExpensesLog(data));
    },
    ResetAll() {
      dispatch(reset());
    },
  };
};

export interface ExpensesLogFormProps extends StateProps, DispatchProps {}
export const ExpensesLogFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpensesLogForm);
