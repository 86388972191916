import { Button } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import defaultImage from "../../../../assets/images/DefaultImage.png";
import styles from "./SparePartCard.module.css";

export default function SparePartCard(props: any) {
  const {
    id,
    name,
    price,
    product_quantity,
    image_path,
    handleSpareAdd,
    // handleRemoveSpare,
  } = props;
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div style={{ margin: "0" }}>
        <Slider {...settings}>
          <div className={styles.gap_scilk}>
            <div className={styles.spares_wrapper}>
              <div className="d-flex justify-content-center">
                <div className={styles.img_wrapper}>
                  <img
                    src={
                      image_path
                        ? `${
                            process.env.REACT_APP_API_ENDPOINT
                          }/${image_path.replace("uploads/", "")}`
                        : defaultImage
                    }
                    alt="pic"
                    width={56}
                    height={56}
                    className={styles.img_wrapper_img}
                  />
                </div>
              </div>

              <p className={styles.spares_name}> {name}</p>
              {/* <div className=" ">
                <p className={styles.spares_cost}>Cost :{price}</p>
              </div> */}
              <div className=" ">
                <p className={styles.spares_stock}>
                  Stock available {product_quantity}
                </p>
              </div>
              <div className={styles.adding_item_botton_spares}>
                <>
                  {/* <button>
                <RemoveIcon
                  sx={{
                    color: "#0761E9",
                  }}
                  onClick={() => handleRemoveSpare(part_id)}
                />
              </button> */}

                  {/* <button>
                 <AddIcon
                  sx={{
                  color: "#0761E9",
                  }}
                  onClick={() =>
                    handleSpareAdd({
                      spareId: part_id,
                      spareName: part_name,
                      spareCost: spareCost,
                      spareAvailable: quantity,
                      spareImage: spare_image,
                    })
                  }
                />
              </button> */}
                </>
                {product_quantity === "0" ? (
                  <>
                    <div className={styles.increment_btn_spares}>
                      <p className={styles.spares_out_stock}>Out of Stock</p>
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      size="small"
                      // sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleSpareAdd({
                          ID: id,
                          Name: name,
                          Price: price,
                          Quantity: product_quantity,
                          spareImage: image_path,
                          stock: product_quantity,
                        })
                      }
                      variant="outlined"
                      className={styles.btn_confrim}
                    >
                      Add
                    </Button>
                  </>
                )}
              </div>
            </div>{" "}
          </div>
        </Slider>
      </div>
    </div>
  );
}
