import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import DashboardNew from "../DashbordNew";
import { getAllCustReview, getAllMechReview } from "../service/dashboardSlice";
import { CustomerFeedback, MechanicReviewRes } from "../service/types";

interface StateProps {
  getAllCustReview_loading: boolean;
  getAllCustReview_error: boolean;
  getAllCustReview_success: boolean;
  getAllCustReview_details: CustomerFeedback[] | null;
  getAllMechReview_loading: boolean;
  getAllMechReview_error: boolean;
  getAllMechReview_success: boolean;
  getAllMechReview_details: MechanicReviewRes[] | null;
}

interface DispatchProps {
  GetAllCustReview(id: string): void;
  GetAllCustMechReview(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getAllCustReview_success: state.dashboard.getAllCustReview_success,
    getAllCustReview_error: state.dashboard.getAllCustReview_error,
    getAllCustReview_loading: state.dashboard.getAllCustReview_loading,
    getAllCustReview_details: state.dashboard.getAllCustReview_details,
    getAllMechReview_success: state.dashboard.getAllMechReview_success,
    getAllMechReview_error: state.dashboard.getAllMechReview_error,
    getAllMechReview_loading: state.dashboard.getAllMechReview_loading,
    getAllMechReview_details: state.dashboard.getAllMechReview_details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllCustMechReview(id) {
      dispatch(getAllMechReview(id));
    },
    GetAllCustReview(id) {
      dispatch(getAllCustReview(id));
    },
  };
};

export interface DashboardProps extends StateProps, DispatchProps {}
export const DashboardComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardNew);
