import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../../redux/store";
import { getJobDurationReport } from "../../../services/reportsSlice";
import { JobDurationReportRes, ReportDT } from "../../../services/types";
import JobDurationReport from "../components/JobDurationReport";

interface StateProps {
  getJobDurationReportError: boolean;
  getJobDurationReportLoading: boolean;
  getJobDurationReportSuccess: boolean;
  getJobDurationReportDetails: JobDurationReportRes[];
}

interface DispatchProps {
  GetJobDurationReport(body: ReportDT): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getJobDurationReportDetails: state.reports.getJobDurationReportDetails,
    getJobDurationReportError: state.reports.getJobDurationReportError,
    getJobDurationReportLoading: state.reports.getJobDurationReportLoading,
    getJobDurationReportSuccess: state.reports.getJobDurationReportSuccess,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetJobDurationReport(body) {
      dispatch(getJobDurationReport(body));
    },
  };
};

export interface JobDurationReportProps extends StateProps, DispatchProps {}
export const JobDurationReportComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(JobDurationReport);
