import React, { useState } from "react";
import averageemoji from "../../../assets/images/Average.png";
import exellentemoji from "../../../assets/images/Exellent.png";
import exellentemojiChecked from "../../../assets/images/ExellentChechked.png";
import goodemoji from "../../../assets/images/Good.png";
import goodemojiChecked from "../../../assets/images/GoodChecked.png";
import pooremoji from "../../../assets/images/Poor.png";
import pooremojiChecked from "../../../assets/images/PoorChecked.png";
import wrostemoji from "../../../assets/images/Worst.png";
import wrostemojiChecked from "../../../assets/images/WorstChecked.png";
import averageemojiChecked from "../../../assets/images/averageChecked.png";

import styles from "./Rating.module.css";

interface EmojiRatingProps {
  rating: string;
}

const EmojiRating: React.FC<EmojiRatingProps> = ({ rating }) => {
  const [selectedRating, setSelectedRating] = useState<string | undefined>(
    rating
  );

  const emojis = [
    {
      rating: "1",
      image: wrostemoji,
      title: "Worst",
      checkedImg: wrostemojiChecked,
    },
    {
      rating: "2",
      image: pooremoji,
      title: "Poor",
      checkedImg: pooremojiChecked,
    },
    {
      rating: "3",
      image: averageemoji,
      title: "Average",
      checkedImg: averageemojiChecked,
    },
    {
      rating: "4",
      image: goodemoji,
      title: "Good",
      checkedImg: goodemojiChecked,
    },
    {
      rating: "5",
      image: exellentemoji,
      title: "Excellent",
      checkedImg: exellentemojiChecked,
    },
  ];

  return (
    <div className={styles.emo_container}>
      {emojis.map((emoji) => (
        <div
          key={emoji.rating}
          className={styles.emoimage_root}
          // onClick={() => handleEmojiClick(emoji.rating)}
        >
          <img
            src={
              selectedRating === emoji.rating ? emoji.checkedImg : emoji.image
            }
            alt=""
          />
          <p className={styles.emo_title_txt}>{emoji.title}</p>
        </div>
      ))}
    </div>
  );
};

export default EmojiRating;
