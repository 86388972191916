import styled from "@emotion/styled";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  stepConnectorClasses,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Photo from "../../../../assets/images/Mechanics.svg";
import DashboardProgressbar from "../../../../components/appSpecific/DashboardProgressbar/DashboardProgressbar";
import CustomLoader from "../../../../components/common/Loader/Loader";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";
import { SupervisorDashboardProps } from "../container/dashboardContainer";
import { MechanicRes } from "../services/types";
import styles from "./Dashboard.module.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={styles.tabPanel}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const steps = ["Pending", "In-Progress", "Ready"];
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0d6efd",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0d6efd",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    position: "relative",
    bottom: 8,
  },
}));
export const SupervisorDashboard = (props: SupervisorDashboardProps) => {
  const {
    GetPercentageStatus,
    getPercentageStatus_Details,
    getPercentageStatus_Error,
    getPercentageStatus_Loading,
    getPercentageStatus_Success,
    GetMechanics,
    GetVehicleTracking,
    getMechanics_Details,
    getMechanics_Error,
    getMechanics_Loading,
    getMechanics_Success,
    getVehicleTracking_Details,
    getVehicleTracking_Error,
    getVehicleTracking_Loading,
    getVehicleTracking_Success,
  } = props;
  const userData = getUserDataFromLocalStorage();
  const navigate = useNavigate();

  React.useEffect(() => {
    GetPercentageStatus(userData.partner_id);
    GetMechanics(userData.partner_id);
    GetVehicleTracking(userData.partner_id);
  }, [
    GetMechanics,
    GetPercentageStatus,
    GetVehicleTracking,
    userData.partner_id,
  ]);

  const [value, setValue] = React.useState(0);
  const [alignment, setAlignment] = React.useState("web");

  const handleChangee = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };
  const [engagedTec, setEngagedTec] = React.useState<MechanicRes[]>([]);
  const [freeTec, setFreeTec] = React.useState<MechanicRes[]>([]);
  React.useEffect(() => {
    const incompleteArray: MechanicRes[] = [];
    const completeArray: MechanicRes[] = [];
    getMechanics_Details.forEach((item) => {
      if (item.pendingCount === 0 && item.inProgressCount === 0) {
        completeArray.push(item);
      } else {
        incompleteArray.push(item);
      }
    });
    setEngagedTec(incompleteArray);
    setFreeTec(completeArray);
  }, [getMechanics_Details]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [selectedStatus, setSelectedStatus] = useState("Pending"); // To store the selected status

  // Function to handle the click on DashboardProgressbar labels
  const handleProgressbarClick = (status: string) => {
    setSelectedStatus(status);
  };
  return (
    <>
      <Box
        className="container-fluid"
        sx={{
          flex: 1,
          backgroundColor: "#f4f8fb",
          minHeight: "100vh",
          padding: "0.5em",
        }}
      >
        <Box className={styles.job_headbar}></Box>

        {getPercentageStatus_Loading ||
        getVehicleTracking_Loading ||
        getMechanics_Loading ? (
          <CustomLoader />
        ) : (
          <>
            <Card
              variant="outlined"
              sx={{ marginBottom: "0.5em", boxShadow: "none" }}
            >
              <CardContent>
                <Grid container spacing={2} direction="row">
                  <DashboardProgressbar
                    label="Pending"
                    value={100}
                    caption={String(getPercentageStatus_Details?.Pending)}
                    backgroundColor="#DAD7FE"
                    strokeColor="#4339F2"
                    onClick={() => handleProgressbarClick("Pending")}
                  />
                  <DashboardProgressbar
                    backgroundColor="#FFE5D3"
                    strokeColor="#FF3A29"
                    label="In Progress"
                    value={100}
                    caption={String(getPercentageStatus_Details?.In_Progress)}
                    onClick={() => handleProgressbarClick("In_Progress")}
                  />
                  <DashboardProgressbar
                    backgroundColor="#CCF8FE"
                    strokeColor="#02A0FC"
                    label="Ready"
                    value={100}
                    caption={String(getPercentageStatus_Details?.Ready)}
                    onClick={() => handleProgressbarClick("Ready")}
                  />
                </Grid>
              </CardContent>
            </Card>

            {getVehicleTracking_Details.map(
              (data, index) =>
                // Render vehicle details only if the selectedStatus matches the data's status
                selectedStatus === data.status && (
                  <>
                    <Card
                      className="card-stepper mb-2"
                      variant="outlined"
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate("/job-card", { state: { jobCardId: data.id } })
                      }
                    >
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        sx={{
                          width: "50%",
                        }}
                      >
                        <div className="row align-items-center text-nowrap">
                          <div className="col-4">
                            <Typography
                              sx={{
                                fontSize: "0.8rem",
                                fontWeight: "800",
                              }}
                              textTransform={"capitalize"}
                            >
                              {data.vehicle.model_number}
                            </Typography>
                          </div>
                          <div className="col-4">
                            <Typography
                              sx={{
                                fontSize: "0.8rem",
                                fontWeight: "800",
                              }}
                            >
                              {data.vehicle.vehicle_number}
                            </Typography>
                          </div>
                          <div className="col-4">
                            <div className={styles.img_wrapper}>
                              {" "}
                              <img
                                src={`${
                                  process.env.REACT_APP_API_ENDPOINT
                                }/${data.vehicle.image_paths[0].replace(
                                  "uploads/",
                                  ""
                                )}`}
                                alt=""
                                className={styles.img_wrapper_img}
                                width="100px"
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        lg={8}
                        sx={{
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <CardContent sx={{}}>
                          <Stepper
                            activeStep={steps.indexOf(data.status) + 1}
                            alternativeLabel
                            connector={<ColorlibConnector />}
                          >
                            {steps.map((label) => (
                              // <Tooltip title={` ${data.status}`} placement="top">
                              <Step key={label}>
                                <StepLabel>
                                  <Typography
                                    sx={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    {label}
                                  </Typography>
                                </StepLabel>
                              </Step>
                              // </Tooltip>
                            ))}
                          </Stepper>
                        </CardContent>
                      </Grid>
                    </Card>
                  </>
                )
            )}

            <Card
              variant="outlined"
              className="container-fluid"
              sx={{ marginBottom: "0.5em", boxShadow: "none" }}
            >
              <CardContent className="row">
                <Grid className="col-12">
                  <>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label={`Engaged Technician (${engagedTec.length})`}
                        {...a11yProps(0)}
                        className={styles.tab_label}
                      />
                      <Tab
                        label={`Free Technician (${freeTec.length})`}
                        {...a11yProps(1)}
                        className={styles.tab_label}
                      />
                    </Tabs>
                  </>
                  <TabPanel value={value} index={0}>
                    <TableContainer component={Paper} elevation={0}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {" "}
                              <strong>Technician </strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Pending</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>In Progress</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Completed</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {engagedTec.length === 0 ? ( // Check if the array is empty
                            <TableRow>
                              <TableCell colSpan={4} align="center">
                                No data found
                              </TableCell>
                            </TableRow>
                          ) : (
                            engagedTec.map((ele, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <div className={styles.service_img_wrapper}>
                                    <div className={styles.technician_image}>
                                      <img
                                        src={Photo}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className={styles.technician_details}>
                                      <p className={styles.technician_name}>
                                        {ele.mechanicName}
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  {ele.pendingCount}
                                </TableCell>
                                <TableCell align="center">
                                  {ele.inProgressCount}
                                </TableCell>
                                <TableCell align="center">
                                  {ele.completeCount}
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <TableContainer component={Paper} elevation={0}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {" "}
                              <strong>Technician </strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Pending</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>In Progress</strong>
                            </TableCell>
                            <TableCell align="center">
                              <strong>Completed</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {freeTec.length === 0 ? ( // Check if the array is empty
                            <TableRow>
                              <TableCell colSpan={4} align="center">
                                No data found
                              </TableCell>
                            </TableRow>
                          ) : (
                            freeTec.map((ele, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <div className={styles.service_img_wrapper}>
                                    <div className={styles.technician_image}>
                                      <img
                                        src={Photo}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className={styles.technician_details}>
                                      <p className={styles.technician_name}>
                                        {ele.mechanicName}
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  {ele.pendingCount}
                                </TableCell>
                                <TableCell align="center">
                                  {ele.inProgressCount}
                                </TableCell>
                                <TableCell align="center">
                                  {ele.completeCount}
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                </Grid>
              </CardContent>
            </Card>
          </>
        )}
      </Box>
    </>
  );
};
