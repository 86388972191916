import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import ForgotPassword from "../components/ForgotPassword";
import { forgotpassword, resetpassword } from "../service/forgotPasswordSlice";
import { FORGOTPASSWORDDT, ResetPasswordDT } from "../service/types";

interface StateProps {
  forgotPasswordSuccess: boolean;
  forgotPasswordError: boolean;
  forgotPasswordLoading: boolean;
  forgotPasswordDetails: string;
  resetPasswordSuccess: boolean;
  resetPasswordError: boolean;
  resetPasswordLoading: boolean;
  resetPasswordDetails: string;
}

interface DispatchProps {
  ForgotPassword(data: FORGOTPASSWORDDT): void;
  ResetPassword(data: ResetPasswordDT): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    forgotPasswordSuccess: state.forgotPassword.forgotPasswordSuccess,
    forgotPasswordError: state.forgotPassword.forgotPasswordError,
    forgotPasswordLoading: state.forgotPassword.forgotPasswordLoading,
    forgotPasswordDetails: state.forgotPassword.forgotPasswordDetails,
    resetPasswordSuccess: state.forgotPassword.resetPasswordSuccess,
    resetPasswordError: state.forgotPassword.resetPasswordError,
    resetPasswordLoading: state.forgotPassword.resetPasswordLoading,
    resetPasswordDetails: state.forgotPassword.resetPasswordDetails,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    ForgotPassword(data) {
      dispatch(forgotpassword(data));
    },
    ResetPassword(data) {
      dispatch(resetpassword(data));
    },
  };
};

export interface ForgotPasswordProps extends StateProps, DispatchProps {}
export const ForgotPasswordComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
