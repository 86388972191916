import styled from "@emotion/styled";
import { Card } from "@mui/material";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as React from "react";
import complaintJob from "../../../assets/images/compliant_job_card_stepper.png";
import complaintJobMarked from "../../../assets/images/compliant_job_card_stepper_marked.png";
import estimate from "../../../assets/images/estimate_stepper.png";
import checkedEstimate from "../../../assets/images/estimate_stepper_marked.png";
import spare from "../../../assets/images/spares_stepper.png";
import checkedSpare from "../../../assets/images/spares_stepper_marked.png";
import techAssign from "../../../assets/images/tech_assign_stepper.png";
import checkedTechAsign from "../../../assets/images/tech_assign_stepper_marked.png";
import customerVehicle from "../../../assets/images/vehicle_customer_details_stepper_marked.png";
import styles from "./StepperTopHeader.module.css";

const stepperIcons = [
  {
    unchecked: customerVehicle,
    checked: customerVehicle,
  },
  {
    unchecked: complaintJob,
    checked: complaintJobMarked,
  },
  {
    unchecked: spare,
    checked: checkedSpare,
  },
  {
    unchecked: techAssign,
    checked: checkedTechAsign,
  },
  {
    unchecked: estimate,
    checked: checkedEstimate,
  },
];

const customStepperIcon = (props: StepIconProps) => {
  const { active, completed, icon } = props;

  const customIcon =
    active || completed
      ? stepperIcons[Number(icon) - 1].checked
      : stepperIcons[Number(icon) - 1].unchecked;
  return (
    <div
      style={{
        marginTop: "-6px",
      }}
    >
      <img style={{ height: "40px", width: "40px" }} src={customIcon} alt="" />
    </div>
  );
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0d6efd",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0d6efd",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    position: "relative",
    bottom: 8,
  },
}));

export default function StepperTopHeader(props: any) {
  const { activeStep, steps } = props;
  return (
    <Card
      variant="outlined"
      sx={{ width: "100%" }}
      className={styles.stepper_wrapper}
    >
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<ColorlibConnector />}
      >
        {steps.map((item: any, index: number) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={item.label} {...stepProps}>
              <StepLabel StepIconComponent={customStepperIcon} {...labelProps}>
                <span className={styles.SteperLabel}>{item.label}</span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Card>
  );
}
