// Import react-circular-progressbar module and styles
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";
import styles from "./progree.module.css";
interface ProgressProps {
  percentage: number;
}
// const percentage = 66;
export default function Progress(props: ProgressProps) {
  const { percentage } = props;
  return (
    <>
      <div className={styles.progress_bar_root}>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0.25,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",
            // Text size
            textSize: "17px",
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,
            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',
            // Colors
            // pathColor: `green, ${percentage / 100})`,
            pathColor: "#437EF7",
            textColor: "#272D37",
            trailColor: "#F5F8FE",
          })}
        />
      </div>
    </>
  );
}
