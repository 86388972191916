import { axios } from "../../../../api/setup.intersepter";
import { EnquiryDT } from "./types";

export const CreateEnquiry = (body: EnquiryDT) => {
  return axios({
    method: "POST",
    url: `plan-enquiry`,
    data: body,
  });
};
