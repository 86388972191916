import { Card, IconButton, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { SearchNormal1 } from "iconsax-react";
import _ from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Complaint } from "../../../../../../components/appSpecific/Complaint/Complaint";
import { ComplaintServiceCard } from "../../../../../../components/appSpecific/ComplaintServiceCard/ComplaintServiceCard";
import { Services } from "../../../../../../components/appSpecific/SelectedServices/Services";
import { RootState } from "../../../../../../redux/store";
import { Service } from "../../type";
import styles from "../Registration.module.css";
import { addComplaints, removeComplaints } from "./service/complaintsSplice";
import { addService, removeService } from "./service/servicesSlice";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="my-3">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface GroupedServicesType {
  [service_category: string]: Service[];
}
export function ComplaintJobCard(props: any) {
  const { serviceDetails } = props;

  const items = useSelector((state: RootState) => state.services.services);

  const complaint = useSelector(
    (state: RootState) => state.complaints.complaints
  );

  const userDetails: any = useSelector(
    (state: RootState) => state.vehicleRegistration.getAllActiveUser_Details
  );
  const firstMechanicID = userDetails?.filter(
    (ele: any) => ele.user_type === "User"
  )[0]?.id;

  const [value, setValue] = React.useState(0);
  const [selectedServices, setSelectedServices] = React.useState<any>(items);
  const [inputValue, setInputValue] = React.useState("");
  const [GroupedParts, updateGroupedParts] =
    React.useState<GroupedServicesType>({});

  const dispatch = useDispatch();
  React.useEffect(() => {
    updateGroupedParts(_.groupBy(serviceDetails, "service_category_name"));
  }, [serviceDetails]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleAddService = React.useCallback(
    (item: any) => {
      const isAlreadySelected = selectedServices.some(
        (service: any) => service.id === item?.id
      );

      if (isAlreadySelected) {
        dispatch(removeService(item.id));
        setSelectedServices((prevSelectedServices: any) =>
          prevSelectedServices.filter((service: any) => service.id !== item.id)
        );
      } else {
        dispatch(addService(item));
        setSelectedServices((prevSelectedServices: any) => [
          ...prevSelectedServices,
          item,
        ]);
      }
    },
    [dispatch, selectedServices]
  );

  const handleRemoveService = React.useCallback(
    (id: number) => {
      dispatch(removeService(id));
    },
    [dispatch]
  );
  const handleAddComplaint = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      dispatch(
        addComplaints({
          complaint: inputValue,
          duration: 120,
          id: complaint.length + 1,
          assignedto: firstMechanicID,
        })
      );
      setInputValue("");
    },
    [complaint.length, dispatch, firstMechanicID, inputValue]
  );
  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    []
  );
  const handleRemoveComplaint = React.useCallback(
    (index: any) => {
      dispatch(removeComplaints(index));
    },
    [dispatch]
  );
  const [searchText, setSearchText] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(serviceDetails);

  const handleInputChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);

    const filteredResults = serviceDetails.filter((vehicle: any) =>
      vehicle.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredResults);
  };
  React.useEffect(() => {
    //
    if (filteredData?.length !== 0) {
      updateGroupedParts(_.groupBy(filteredData, "service_category_name"));
    }

    // groupPartsPerCategory(productsL);
  }, [filteredData]);
  return (
    <>
      <div className={styles.main_container}>
        <div className={styles.left_container}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 p-0">
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <div className="d-block d-lg-none">
                    <Tabs
                      value={value}
                      orientation="vertical"
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className={styles.selectedServicesTbMain}
                      sx={{
                        margin: "20px",
                      }}
                    >
                      <Tab
                        label={`Selected Services (${selectedServices.length})`}
                        {...a11yProps(0)}
                        className={styles.selectedServicesTb}
                      />
                      <Tab
                        label="Complaints"
                        {...a11yProps(1)}
                        className={styles.selectedServicesTb}
                      />
                    </Tabs>
                  </div>

                  <div className="d-none d-lg-block">
                    <Tabs
                      value={value}
                      // orientation="vertical"
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className={styles.selectedServicesTbMain}
                    >
                      <Tab
                        label={
                          <Typography
                            variant="caption"
                            className={styles.selectedServicesTbMain}
                          >{`Selected Services (${items.length})`}</Typography>
                        }
                        {...a11yProps(0)}
                        className={styles.selectedServicesTb}
                      />
                      <Tab
                        // label="Complaints"
                        label={
                          <Typography
                            variant="caption"
                            className={styles.selectedServicesTbMain}
                          >{`Complaints (${complaint.length})`}</Typography>
                        }
                        {...a11yProps(1)}
                        className={styles.selectedServicesTb}
                      />
                    </Tabs>
                  </div>
                </Box>
                <TabPanel value={value} index={0}>
                  {items?.map((ele: any) => (
                    <Services
                      item={ele}
                      handleRemoveService={handleRemoveService}
                    />
                  ))}
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <div className="container">
                    <form onSubmit={handleAddComplaint}>
                      <TextField
                        fullWidth
                        placeholder="Add customer complaint"
                        variant="outlined"
                        className="my-3"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </form>
                    {complaint.map((ele: any, index: number) => (
                      <Complaint
                        item={ele}
                        handleRemoveComplaint={handleRemoveComplaint}
                        index={index}
                      />
                    ))}
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right_container}>
          <div className={styles.serviceHeading}>
            <div className="container">
              <Card variant="outlined" className="row  align-items-center">
                <Box
                  className="col-8"
                  sx={{
                    padding: "6px 6px",
                    borderRadius: "0",
                  }}
                >
                  {/* <Box> */}
                  <p className={styles.header}>Services</p>
                  {/* </Box> */}
                </Box>
                <Box className="col-4">
                  <TextField
                    placeholder="Search Services"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          sx={{
                            padding: "0px",
                          }}
                          className={styles.googlr_search_icon}
                        >
                          <SearchNormal1 />
                        </IconButton>
                      ),
                      style: {
                        height: "39px",
                        borderRadius: "3px",
                      },
                    }}
                    value={searchText}
                    onChange={handleInputChanges}
                  />
                </Box>
              </Card>
            </div>
            <div className={styles.servicesCards}>
              {Object.keys(GroupedParts).map((category, index) => (
                <>
                  <div className="container">
                    <p className={styles.services_name}>{category}</p>
                    <div className="row mb-3 row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-xs-1">
                      {GroupedParts[category].map((item) => (
                        <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                          <ComplaintServiceCard
                            item={item}
                            firstMechanicID={firstMechanicID}
                            handleAddService={handleAddService}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
