import CloseIcon from "@mui/icons-material/Close";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import {
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SnackbarOrigin,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { PDFViewer } from "@react-pdf/renderer";
import { ArrowRight, CloseCircle, Printer } from "iconsax-react";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import complaintsIcon from "../../../../../assets/images/complaints.svg";
import estimateicon from "../../../../../assets/images/estimate.svg";
import no_data from "../../../../../assets/images/no-data-found.svg";
import searchIcon from "../../../../../assets/images/search-normal.svg";
import serviceIcon from "../../../../../assets/images/services.svg";
import sparepartIcon from "../../../../../assets/images/spare-parts.svg";
import { ComplaintServiceCard } from "../../../../../components/appSpecific/ComplaintServiceCard/ComplaintServiceCard";
import { EstimatePDF } from "../../../../../components/appSpecific/EstimatePDF/EstimatePDF";
import GalleryPage from "../../../../../components/appSpecific/Gallery/GalleryPage";
import HomeTopHeader from "../../../../../components/appSpecific/HomeTopHeader/HomeTopHeader";
import { SelectedSpares } from "../../../../../components/appSpecific/SelectedSpares/SelectedSpares";
import { ServiceCard } from "../../../../../components/appSpecific/ServiceCard/ServiceCard";
import { SparesList } from "../../../../../components/appSpecific/SparesList/SparesList";
import { ComplaintsListHome } from "../../../../../components/appSpecific/TechnicianList/ComplaintsListHome";
import { SpareListHome } from "../../../../../components/appSpecific/TechnicianList/SpareList_Home";
import { TechnicianList } from "../../../../../components/appSpecific/TechnicianList/TechnicianList";
import { TechnicianListHome } from "../../../../../components/appSpecific/TechnicianList/TechnicianList_Home";
import { UserBox } from "../../../../../components/appSpecific/UserBox/UserBox";
import { VehicleList } from "../../../../../components/appSpecific/VehicleList/VehicleList";
import ButtonLog from "../../../../../components/common/Button/Button";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../../components/common/SnackBar/SnackBar";
import { RootState } from "../../../../../redux/store";
import {
  getAuthDataFromLocalStorage,
  getUserDataFromLocalStorage,
} from "../../../../../utils/helper.utils";
import {
  AssignService,
  addService,
  removeService,
  resetService,
  updateService,
} from "../../VeichleRegistration/components/ComplantJobCard/service/servicesSlice";
import {
  Spare,
  addSpare,
  removeSpare,
  resetSpare,
} from "../../VeichleRegistration/components/Spares/services/sparesSlice";
import { HomeProps } from "../container/homeContainer";
import { JobCardCreateService, JobCardCreateSpares } from "../type";
import styles from "./Home.module.css";
import HomeWelcome from "./HomeWelcome";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={styles.TabpannelScroll}
    >
      {value === index && (
        <Box className={styles.tabPanel}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function Home(props: HomeProps) {
  const {
    GetAllJobCard,
    getAllJobCardDetails,
    GetJobCardDetails,
    getJobCardDetailsData,
    UpdateJobcardStatus,
    getAllJobCardLoading,
    getJobCardDetailsLoading,
    updateJobcardStatusLoading,
    updateJobcardStatusError,
    updateJobcardStatusSuccess,
    GetAllActiveProduct,
    GetAllActiveService,
    activeProductDetails,
    activeServiceDetails,
    GetAllActiveUsers,
    getAllActiveUser_Details,
    activeServiceLoading,
    getAllActiveProductLoading,
    getAllActiveUser_Loading,
    loading,
    deleteJobCardByIdData,
    DeleteJobCardById,
    deleteJobCardByIdLoading,
    DeleteJobCardServiceByID,
    DeleteSparePartsByID,
    deleteJobCardServiceById_Details,
    deleteJobCardServiceById_Error,
    deleteJobCardServiceById_Loading,
    deleteJobCardServiceById_Success,
    deleteSparePartById_Details,
    deleteSparePartById_Error,
    deleteSparePartById_Loading,
    deleteSparePartById_Success,
    getAllJobCardError,
    getAllJobCardSuccess,
    getJobCardDetailsError,
    getJobCardDetailsSuccess,
    CreateJobCardServices,
    CreateJobCardSpareParts,
    SetMachanicReassign,
    createJobCardSServices_Details,
    createJobCardSServices_Error,
    createJobCardSServices_Loading,
    createJobCardSServices_Success,
    createJobCardSpareParts_Details,
    createJobCardSpareParts_Error,
    createJobCardSpareParts_Loading,
    createJobCardSpareParts_Success,
    mechanicReAssign_Details,
    mechanicReAssign_Error,
    mechanicReAssign_Loading,
    mechanicReAssign_Success,
    BayDetails,
    GetAllBay,
    activeServiceError,
    activeServiceSuccess,
    getAllBayLoading,
    getAllBayError,
    getAllBaySuccess,
    ResetAll,
    SelectBayByMech,
    selectBayByMech_Loading,
    selectBayByMech_Success,
    selectBayByMech_Error,
    UpdatePaymentRequest,
    updatePaymentRequest_Error,
    updatePaymentRequest_Loading,
    updatePaymentRequest_Success,
    updatePaymentRequest_details,
    GetEstimate,
    getEstimateDetails,
    getEstimateError,
    getEstimateLoading,
    getEstimateSuccess,
    GetFeedback,
    getFeedback_Details,
    getFeedback_Error,
    getFeedback_Loading,
    getFeedback_Success,
    UpdateJobCardSpareParts,
    updateJobCardSpareParts_Details,
    updateJobCardSpareParts_Error,
    updateJobCardSpareParts_Loading,
    updateJobCardSpareParts_Success,
  } = props;
  const authData = getAuthDataFromLocalStorage();
  const location = useLocation();
  const jobCardId = location.state?.jobCardId;
  const { category, rolename } = authData ?? {};
  const jobCardCategory = category.find(
    (category: any) => category.name === "Job-Card"
  );
  const functions = jobCardCategory.functions;
  const filterEntriesByStatus = useCallback(
    (status: string) => {
      return getAllJobCardDetails?.filter((entry) => entry.status === status);
    },
    [getAllJobCardDetails]
  );
  const products = useSelector((state: RootState) => state.spares.spares);
  const items = useSelector((state: RootState) => state.services.services);
  const userData = getUserDataFromLocalStorage();

  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [GroupedParts, updateGroupedParts] = React.useState<any>({});
  React.useEffect(() => {
    updateGroupedParts(
      _.groupBy(activeProductDetails, "product_category_name")
    );

    // groupPartsPerCategory(productsL);
  }, [activeProductDetails]);
  const [GroupedService, updateGroupedService] = React.useState<any>({});
  const [filteredService, setFilteredService] = React.useState<any>();

  useEffect(() => {
    const Filter = activeServiceDetails?.filter((service: any) => {
      return !getJobCardDetailsData?.service.some(
        (dataService) => dataService.ServiceID === service.id
      );
    });
    setFilteredService(Filter);
  }, [activeServiceDetails, getJobCardDetailsData]);
  React.useEffect(() => {
    updateGroupedService(_.groupBy(filteredService, "service_category_name"));
  }, [filteredService]);
  React.useEffect(() => {
    ResetAll();
    GetAllJobCard(userData?.partner_id);
    GetAllActiveProduct(userData?.partner_id);
    GetAllActiveService(userData?.partner_id);
    GetAllActiveUsers(userData?.partner_id);
    GetAllBay(userData?.partner_id);
  }, [
    mechanicReAssign_Success,
    createJobCardSServices_Success,
    createJobCardSpareParts_Success,
    deleteJobCardServiceById_Success,
    deleteSparePartById_Success,
    GetAllActiveProduct,
    GetAllActiveService,
    GetAllActiveUsers,
    GetAllJobCard,
    deleteJobCardByIdData,
    deleteSparePartById_Details,
    deleteJobCardServiceById_Details,
    createJobCardSpareParts_Details,
    updateJobCardSpareParts_Details,
    createJobCardSServices_Details,
    userData?.partner_id,
    GetAllBay,
    selectBayByMech_Success,
    ResetAll,
    updatePaymentRequest_Success,
  ]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const [value, setValue] = React.useState(0);
  const [buttonText, setButtonText] = useState<any>("Estimate");
  const [isActive, setIsActive] = useState<any>();
  const [screen, setScreen] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [selectServiceID, setSelectServiceID] = useState();
  const [selectServiceName, setSelectServiceName] = useState();
  const [openReassign, setOpenReassign] = React.useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const [values, setValues] = useState<number[]>([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleOffCanvasClose = () => setShowOffcanvas(false);
  const handleOffCanvasShow = () => setShowOffcanvas(true);

  const [ServiceWithTechnicians, setServiceWithTechnicians] =
    useState<AssignService[]>(items);
  React.useEffect(() => {
    setServiceWithTechnicians(items);
  }, [items]);
  const handleReassignOpen = useCallback((id: any, name: any) => {
    setSelectServiceID(id);
    setSelectServiceName(name);
    setOpenReassign(true);
  }, []);
  const handleReassignClose = useCallback(() => setOpenReassign(false), []);
  const [selectedServices, setSelectedServices] = React.useState<any>(items);
  const getSpecifcJobData = React.useCallback(
    (id: string) => {
      GetJobCardDetails(id);
      GetEstimate(String(id));
      GetFeedback(id);
      setScreen(1);
    },
    [GetEstimate, GetFeedback, GetJobCardDetails]
  );
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(getAllJobCardDetails);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);

    const filteredResults = getAllJobCardDetails.filter((vehicle) =>
      vehicle.vehicle.vehicle_number.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  useEffect(() => {
    updateJobcardStatusSuccess && GetAllJobCard(userData?.partner_id);
  }, [
    updateJobcardStatusSuccess,
    GetAllJobCard,
    userData?.partner_id,
    mechanicReAssign_Success,
    deleteJobCardServiceById_Success,
    deleteSparePartById_Success,
    createJobCardSServices_Success,
    createJobCardSpareParts_Success,
    selectBayByMech_Success,
  ]);
  useEffect(() => {
    setButtonText(
      getJobCardDetailsData?.Status === "Ready" ||
        getJobCardDetailsData?.Status === "Delivered"
        ? "Invoice"
        : "Estimate"
    );
  }, [getJobCardDetailsData]);

  const firstMechanicID = getAllActiveUser_Details?.filter(
    (ele: any) => ele.user_type === "User"
  );
  useEffect(() => {
    const statuses = ["Pending", "In-Progress", "Ready", "Delivered"];
    for (let i = 0; i < statuses.length; i++) {
      const entries = filterEntriesByStatus(statuses[i]);
      if (entries?.length !== 0) {
        setValue(i);
        getSpecifcJobData(jobCardId ? jobCardId : entries[0]?.id);
        setIsActive(jobCardId ? jobCardId : entries[0]?.id);
        break;
      }
    }
  }, [
    filterEntriesByStatus,
    getSpecifcJobData,
    setValue,
    setIsActive,
    jobCardId,
  ]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleOpenComplaint = useCallback(() => {
    setScreen(3);
  }, []);
  const handleOpenSpares = useCallback(() => {
    setScreen(4);
  }, []);
  const backToNewScreen = useCallback(() => {
    setScreen(1);
  }, []);
  const handleOpenServices = useCallback(() => {
    setScreen(2);
  }, []);
  const servicesCard = [
    {
      name: "Services",
      icon: (
        <img className={styles.service_img} src={serviceIcon} alt="service" />
      ),
      onClick: () => {
        handleOpenServices();
      },
    },
    {
      name: "Customer Complaints",
      icon: (
        <img
          className={styles.service_img}
          src={complaintsIcon}
          alt="complaintsIcon"
        />
      ),
      onClick: () => {
        handleOpenComplaint();
      },
    },
    {
      name: "Spare Parts",
      icon: (
        <img
          className={styles.service_img}
          src={sparepartIcon}
          alt="sparepartIcon"
        />
      ),
      onClick: () => {
        handleOpenSpares();
      },
    },
    {
      name: buttonText,
      icon: (
        <img
          className={styles.service_img}
          src={estimateicon}
          alt="estimateicon"
        />
      ),
      onClick: () => {
        ["Invoice"].some((str) => functions.includes(str)) && setScreen(5);
        buttonText === "Invoice" &&
          ["Invoice"].some((str) => functions.includes(str)) &&
          navigation("/invoice", { state: { id: getJobCardDetailsData?.id } });
      },
    },
  ];
  React.useEffect(() => {
    if (
      updateJobcardStatusSuccess ||
      createJobCardSServices_Success ||
      createJobCardSpareParts_Success ||
      mechanicReAssign_Success ||
      mechanicReAssign_Success ||
      deleteJobCardServiceById_Success ||
      deleteSparePartById_Success ||
      createJobCardSServices_Success ||
      createJobCardSpareParts_Success
    ) {
      GetJobCardDetails(
        getJobCardDetailsData ? getJobCardDetailsData?.id : "0"
      );
    }
  }, [
    updateJobcardStatusSuccess,
    createJobCardSpareParts_Details,
    updateJobCardSpareParts_Details,
    createJobCardSServices_Success,
    createJobCardSpareParts_Success,
    mechanicReAssign_Success,
    deleteJobCardServiceById_Success,
    deleteSparePartById_Success,
    GetJobCardDetails,
    getJobCardDetailsData,
  ]);
  const handleUpdateJobcardStatus = useCallback(() => {
    getJobCardDetailsData?.id &&
      UpdateJobcardStatus({
        id: getJobCardDetailsData?.id,
        status: "Delivered",
        updatedBy: JSON.parse(localStorage.getItem("USER_DATA") ?? '{"id":0}')
          .id,
      });
  }, [UpdateJobcardStatus, getJobCardDetailsData?.id]);
  const handleJobCardDelete = useCallback(
    (id: string) => {
      DeleteJobCardById({
        id: id,
      });
    },
    [DeleteJobCardById]
  );
  const handleSpareDelete = useCallback(
    (id: number) => {
      DeleteSparePartsByID({
        id: id,
      });
    },
    [DeleteSparePartsByID]
  );
  const handleServiceDelete = useCallback(
    (id: string) => {
      DeleteJobCardServiceByID({
        id: id,
      });
    },
    [DeleteJobCardServiceByID]
  );
  const handleSpareAdd = React.useCallback(
    (spare: any) => {
      dispatch(addSpare(spare));
    },
    [dispatch]
  );
  const handleRemoveSpare = React.useCallback(
    (spareId: number) => {
      dispatch(removeSpare(spareId));
    },
    [dispatch]
  );
  const JobCardSpare = products.map((ele: Spare) => {
    return {
      partner_id: getUserDataFromLocalStorage().partner_id,
      jobCard_id: getJobCardDetailsData?.id,
      product_id: ele.ID,
      quantity: ele.Quantity,
      is_estimated: "0",
      created_by: getUserDataFromLocalStorage().id,
    };
  });
  const JobCardService = items.map((ele: AssignService) => {
    return {
      partner_id: getUserDataFromLocalStorage().partner_id,
      jobCard_id: getJobCardDetailsData?.id,
      service_id: ele.id,
      is_estimated: "0",
      assigned_to: ServiceWithTechnicians[0]?.assignedto,
      created_by: getUserDataFromLocalStorage().id,
    };
  });
  const handleCreateSpare = useCallback(() => {
    const data: JobCardCreateSpares = {
      jobcardSpareParts: [...JobCardSpare],
    };
    UpdateJobCardSpareParts(data);

    handleClose();
    dispatch(resetSpare());
  }, [JobCardSpare, UpdateJobCardSpareParts, dispatch, handleClose]);
  const handleCreateService = useCallback(() => {
    const data: JobCardCreateService = {
      jobcardService: [...JobCardService],
    };
    CreateJobCardServices(data);
    handleClose();
    dispatch(resetService());
  }, [CreateJobCardServices, JobCardService, dispatch, handleClose]);
  const handleAddService = useCallback(
    (item: any) => {
      // let matchedService = selectedServices?.find(
      //   (service: any) => item.ID === service.ID
      // );
      // if (!matchedService) {
      //   dispatch(addService(item));
      //   setSelectedServices([...selectedServices, item]);
      // }
      const isAlreadySelected = selectedServices.some(
        (service: any) => service.id === item?.id
      );

      if (isAlreadySelected) {
        dispatch(removeService(item.id));
        setSelectedServices((prevSelectedServices: any) =>
          prevSelectedServices.filter((service: any) => service.id !== item.id)
        );
      } else {
        dispatch(addService(item));
        setSelectedServices((prevSelectedServices: any) => [
          ...prevSelectedServices,
          item,
        ]);
      }
    },
    [dispatch, selectedServices]
  );

  const [selectedOption, setSelectedOption] = React.useState("");

  const handleSelectChange = useCallback((event: any) => {
    setSelectedOption(event.target.value);
  }, []);
  const handleReaasignTech = useCallback(() => {
    SetMachanicReassign({
      id: selectServiceID,
      assigned_to: selectedOption,
      updated_by: getUserDataFromLocalStorage().id,
    });
    handleReassignClose();
  }, [
    SetMachanicReassign,
    handleReassignClose,
    selectServiceID,
    selectedOption,
  ]);
  const updatedUserProfiles = getAllActiveUser_Details?.map((profile: any) => ({
    ...profile,
    label: `${profile.name}`,
  }));

  const handleChangeTech = useCallback(
    (index: number, id: number) => {
      let tempItem = ServiceWithTechnicians.map((ele: any, ele_index: any) =>
        ele_index === index ? { ...ele, assignedto: id } : { ...ele }
      );
      dispatch(updateService(tempItem));
      setServiceWithTechnicians(tempItem);
      setValues((prevValues) => {
        const updatedValues: any = [...prevValues];
        updatedValues[index] = id;
        return updatedValues;
      });
    },
    [ServiceWithTechnicians, dispatch]
  );
  React.useEffect(() => {
    if (mechanicReAssign_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(mechanicReAssign_Error),
        snackOpen: true,
        snackSeverity: "error",
      }));
      ResetAll();
    } else if (mechanicReAssign_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(mechanicReAssign_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));

      ResetAll();
    }
    if (selectBayByMech_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(selectBayByMech_Error),
        snackOpen: true,
        snackSeverity: "error",
      }));
      ResetAll();
    } else if (selectBayByMech_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String("Updated Successfully"),
        snackOpen: true,
        snackSeverity: "success",
      }));

      ResetAll();
    }
    if (updatePaymentRequest_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updatePaymentRequest_Error),
        snackOpen: true,
        snackSeverity: "error",
      }));
      ResetAll();
    } else if (updatePaymentRequest_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String("Updated Successfully"),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setValue(3);
      ResetAll();
    }
    if (
      deleteSparePartById_Error ||
      createJobCardSpareParts_Error ||
      updateJobCardSpareParts_Error ||
      createJobCardSServices_Error ||
      deleteJobCardServiceById_Error
    ) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          deleteSparePartById_Error ||
            createJobCardSpareParts_Error ||
            updateJobCardSpareParts_Error ||
            createJobCardSServices_Error ||
            deleteJobCardServiceById_Error
        ),
        snackOpen: true,
        snackSeverity: "error",
      }));
      ResetAll();
    } else if (
      deleteSparePartById_Success ||
      deleteJobCardServiceById_Success ||
      createJobCardSServices_Success ||
      createJobCardSpareParts_Success ||
      updateJobCardSpareParts_Success
    ) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          deleteSparePartById_Details ||
            deleteJobCardServiceById_Details ||
            createJobCardSServices_Details ||
            createJobCardSpareParts_Details ||
            updateJobCardSpareParts_Details
        ),
        snackOpen: true,
        snackSeverity: "success",
      }));

      ResetAll();
    }
  }, [
    ResetAll,
    createJobCardSServices_Details,
    createJobCardSServices_Error,
    createJobCardSServices_Success,
    createJobCardSpareParts_Details,
    createJobCardSpareParts_Error,
    createJobCardSpareParts_Success,
    deleteJobCardServiceById_Details,
    deleteJobCardServiceById_Error,
    deleteJobCardServiceById_Success,
    deleteSparePartById_Details,
    deleteSparePartById_Error,
    deleteSparePartById_Success,
    mechanicReAssign_Details,
    mechanicReAssign_Error,
    mechanicReAssign_Success,
    selectBayByMech_Error,
    selectBayByMech_Success,
    updateJobCardSpareParts_Details,
    updateJobCardSpareParts_Error,
    updateJobCardSpareParts_Success,
    updatePaymentRequest_Error,
    updatePaymentRequest_Success,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {loading ||
      getJobCardDetailsLoading ||
      selectBayByMech_Loading ||
      updatePaymentRequest_Loading ||
      getAllBayLoading ||
      getEstimateLoading ||
      updateJobCardSpareParts_Loading ||
      getFeedback_Loading ||
      deleteJobCardServiceById_Loading ||
      deleteSparePartById_Loading ||
      deleteJobCardByIdLoading ||
      activeServiceLoading ||
      getAllActiveProductLoading ||
      getAllActiveUser_Loading ||
      createJobCardSServices_Loading ||
      createJobCardSpareParts_Loading ||
      createJobCardSpareParts_Loading ||
      mechanicReAssign_Loading ||
      updateJobcardStatusLoading ||
      getAllJobCardLoading ? (
        <CustomLoader />
      ) : null}

      <Offcanvas show={showOffcanvas} onHide={handleOffCanvasClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Jobs</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={styles.left_panel}>
            <div className={styles.leftpannel_sticky}>
              <div className="container">
                <TextField
                  inputProps={{
                    style: {
                      color: "#9A9AB0",
                      paddingLeft: "10px",
                      fontWeight: "500",
                    },
                  }}
                  fullWidth
                  placeholder="Search Vehicle No"
                  InputProps={{
                    startAdornment: <img src={searchIcon} alt="search-icon" />,
                    style: {
                      height: "45px",
                      borderRadius: "3px",
                    },
                  }}
                  className={styles.navBarSerchInput}
                  variant="outlined"
                />

                <div className="row mt-3 align-items-center">
                  <div className="col-sm-12 text-end">
                    <ButtonLog
                      text={"Add vehicle"}
                      variant={"contained"}
                      className={styles.addVehicleBtn}
                      startIcon={
                        <ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />
                      }
                      onClick={() => navigation("/vehicle-registration")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ marginBottom: "0px" }} className={styles.hr} />
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 ">
                  <div className={styles.vehiclesDetailsTaab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <div className="d-xs-block d-lg-none">
                        <Tabs
                          value={value}
                          orientation="vertical"
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          className={styles.tabHeadingHome}
                        >
                          <Tab
                            label="Pending"
                            {...a11yProps(0)}
                            className={styles.tab_label}
                          />
                          <Tab
                            label="In progress"
                            {...a11yProps(1)}
                            className={styles.tab_label}
                          />
                          <Tab
                            label="Ready"
                            {...a11yProps(2)}
                            className={styles.tab_label}
                          />
                          <Tab
                            label="Delivered"
                            {...a11yProps(3)}
                            className={styles.tab_label}
                          />
                        </Tabs>
                      </div>
                      <div className="d-none d-lg-block">
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          className={styles.tabHeadingHome}
                        >
                          <Tab
                            label="Pending"
                            {...a11yProps(0)}
                            className={styles.tab_label}
                          />
                          <Tab
                            label="In progress"
                            {...a11yProps(1)}
                            className={styles.tab_label}
                          />
                          <Tab
                            label="Ready"
                            {...a11yProps(2)}
                            className={styles.tab_label}
                          />
                          <Tab
                            label="Delivered"
                            {...a11yProps(3)}
                            className={styles.tab_label}
                          />
                        </Tabs>
                      </div>
                    </Box>

                    <TabPanel value={value} index={0}>
                      {filterEntriesByStatus("Pending")?.length > 0 ? (
                        filterEntriesByStatus("Pending").map((ele, index) => (
                          <VehicleList
                            key={index}
                            setIsActive={setIsActive}
                            isActive={isActive}
                            index={index}
                            props={ele}
                            handleJobCardDelete={handleJobCardDelete}
                            setJob={(id: any) => {
                              getSpecifcJobData(id);
                            }}
                            functions={functions}
                          />
                        ))
                      ) : (
                        <img src={no_data} alt="" className="img-fluid" />
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      {filterEntriesByStatus("In-Progress")?.length > 0 ? (
                        filterEntriesByStatus("In-Progress").map(
                          (ele, index) => (
                            <VehicleList
                              key={index}
                              setIsActive={setIsActive}
                              isActive={isActive}
                              index={index}
                              props={ele}
                              setJob={(id: any) => {
                                getSpecifcJobData(id);
                              }}
                              functions={functions}
                            />
                          )
                        )
                      ) : (
                        <img src={no_data} alt="" className="img-fluid" />
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      {filterEntriesByStatus("Ready")?.length > 0 ? (
                        filterEntriesByStatus("Ready").map((ele, index) => (
                          <VehicleList
                            key={index}
                            setIsActive={setIsActive}
                            isActive={isActive}
                            index={index}
                            props={ele}
                            setJob={(id: any) => {
                              getSpecifcJobData(id);
                            }}
                            functions={functions}
                          />
                        ))
                      ) : (
                        <img src={no_data} alt="" className="img-fluid" />
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      {filterEntriesByStatus("Delivered")?.length > 0 ? (
                        filterEntriesByStatus("Delivered").map((ele, index) => (
                          <VehicleList
                            key={index}
                            setIsActive={setIsActive}
                            isActive={isActive}
                            index={index}
                            props={ele}
                            setJob={(id: any) => {
                              getSpecifcJobData(id);
                            }}
                            functions={functions}
                          />
                        ))
                      ) : (
                        <img src={no_data} alt="" className="img-fluid" />
                      )}
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      {getAllJobCardDetails?.length !== 0 ? (
        <>
          <div className={styles.main_panel}>
            <div className={styles.left_panel_root}>
              <div className={styles.left_panel}>
                <div className={styles.leftpannel_sticky}>
                  <div className="container">
                    <TextField
                      inputProps={{
                        style: {
                          color: "#9A9AB0",
                          paddingLeft: "10px",
                          fontWeight: "500",
                        },
                      }}
                      fullWidth
                      placeholder="Search Vehicle No"
                      InputProps={{
                        startAdornment: (
                          <img src={searchIcon} alt="search-icon" />
                        ),
                        style: {
                          height: "45px",
                          borderRadius: "3px",
                        },
                      }}
                      className={styles.navBarSerchInput}
                      variant="outlined"
                      value={searchText}
                      onChange={handleInputChange}
                    />

                    <div className="row mt-3 align-items-center">
                      <div className="col-sm-12 text-end">
                        {["Create"].some((str) => functions.includes(str)) ? (
                          <>
                            <ButtonLog
                              text={"Add vehicle"}
                              variant={"contained"}
                              className={styles.addVehicleBtn}
                              startIcon={
                                <ControlPointOutlinedIcon
                                  sx={{ fontSize: "15px" }}
                                />
                              }
                              onClick={() =>
                                navigation("/vehicle-registration")
                              }
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{ marginBottom: "0px" }} className={styles.hr} />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 ">
                      <div className={styles.vehiclesDetailsTaab}>
                        <Box
                          sx={{
                            width: "350px",
                          }}
                        >
                          {searchText &&
                            filteredData.map((ele, index) => (
                              <VehicleList
                                key={index}
                                setIsActive={setIsActive}
                                isActive={isActive}
                                index={index}
                                props={ele}
                                setJob={(id: any) => {
                                  getSpecifcJobData(id);
                                }}
                                functions={functions}
                              />
                            ))}
                        </Box>
                        {!searchText ? (
                          <>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <div className="d-xs-block d-lg-none">
                                <Tabs
                                  value={value}
                                  orientation="vertical"
                                  onChange={handleChange}
                                  aria-label="basic tabs example"
                                  className={styles.tabHeadingHome}
                                >
                                  <Tab
                                    label="Pending"
                                    {...a11yProps(0)}
                                    className={styles.tab_label}
                                  />
                                  <Tab
                                    label="In progress"
                                    {...a11yProps(1)}
                                    className={styles.tab_label}
                                  />
                                  <Tab
                                    label="Ready"
                                    {...a11yProps(2)}
                                    className={styles.tab_label}
                                  />
                                  <Tab
                                    label="Delivered"
                                    {...a11yProps(3)}
                                    className={styles.tab_label}
                                  />
                                </Tabs>
                              </div>
                              <div className="d-none d-lg-block">
                                <Tabs
                                  value={value}
                                  onChange={handleChange}
                                  aria-label="basic tabs example"
                                  className={styles.tabHeadingHome}
                                >
                                  <Tab
                                    label={`Pending(${
                                      filterEntriesByStatus("Pending")?.length
                                    })`}
                                    {...a11yProps(0)}
                                    className={styles.tab_label}
                                  />
                                  <Tab
                                    label={`In progress(${
                                      filterEntriesByStatus("In-Progress")
                                        ?.length
                                    })`}
                                    {...a11yProps(1)}
                                    className={styles.tab_label}
                                  />
                                  <Tab
                                    label={`Ready(${
                                      filterEntriesByStatus("Ready")?.length
                                    })`}
                                    {...a11yProps(2)}
                                    className={styles.tab_label}
                                  />
                                  <Tab
                                    label={`Delivered(${
                                      filterEntriesByStatus("Delivered")?.length
                                    })`}
                                    {...a11yProps(3)}
                                    className={styles.tab_label}
                                  />
                                </Tabs>
                              </div>
                            </Box>

                            <TabPanel value={value} index={0}>
                              {filterEntriesByStatus("Pending")?.length > 0 ? (
                                filterEntriesByStatus("Pending").map(
                                  (ele, index) => (
                                    <VehicleList
                                      key={index}
                                      setIsActive={setIsActive}
                                      isActive={isActive}
                                      index={index}
                                      props={ele}
                                      handleJobCardDelete={handleJobCardDelete}
                                      setJob={(id: any) => {
                                        getSpecifcJobData(id);
                                      }}
                                      functions={functions}
                                    />
                                  )
                                )
                              ) : (
                                <img
                                  src={no_data}
                                  alt=""
                                  className="img-fluid"
                                />
                              )}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                              {filterEntriesByStatus("In-Progress")?.length >
                              0 ? (
                                filterEntriesByStatus("In-Progress").map(
                                  (ele, index) => (
                                    <VehicleList
                                      key={index}
                                      setIsActive={setIsActive}
                                      isActive={isActive}
                                      index={index}
                                      props={ele}
                                      setJob={(id: any) => {
                                        getSpecifcJobData(id);
                                      }}
                                      functions={functions}
                                      handleJobCardDelete={handleJobCardDelete}
                                    />
                                  )
                                )
                              ) : (
                                <img
                                  src={no_data}
                                  alt=""
                                  className="img-fluid"
                                />
                              )}
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                              {filterEntriesByStatus("Ready")?.length > 0 ? (
                                filterEntriesByStatus("Ready").map(
                                  (ele, index) => (
                                    <VehicleList
                                      key={index}
                                      setIsActive={setIsActive}
                                      isActive={isActive}
                                      index={index}
                                      props={ele}
                                      setJob={(id: any) => {
                                        getSpecifcJobData(id);
                                      }}
                                      functions={functions}
                                      handleJobCardDelete={handleJobCardDelete}
                                    />
                                  )
                                )
                              ) : (
                                <img
                                  src={no_data}
                                  alt=""
                                  className="img-fluid"
                                />
                              )}
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                              {filterEntriesByStatus("Delivered")?.length >
                              0 ? (
                                filterEntriesByStatus("Delivered").map(
                                  (ele, index) => (
                                    <VehicleList
                                      key={index}
                                      setIsActive={setIsActive}
                                      isActive={isActive}
                                      index={index}
                                      props={ele}
                                      setJob={(id: any) => {
                                        getSpecifcJobData(id);
                                      }}
                                      functions={functions}
                                      handleJobCardDelete={handleJobCardDelete}
                                    />
                                  )
                                  // )
                                )
                              ) : (
                                <img
                                  src={no_data}
                                  alt=""
                                  className="img-fluid"
                                />
                              )}
                            </TabPanel>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right_panel}>
              <div className={styles.lunchOffcanvas}>
                <div className={styles.viewJobsRoot}>
                  <div
                    className={styles.viewJobsBtn}
                    onClick={handleOffCanvasShow}
                  >
                    View Jobs <ArrowRight size="22" color="#717171" />
                  </div>
                </div>
              </div>
              {getJobCardDetailsData ? (
                <>
                  <HomeTopHeader
                    handleUpdateJobcardStatus={handleUpdateJobcardStatus}
                    props={getJobCardDetailsData}
                    BayDetails={BayDetails}
                    SelectBayByMech={SelectBayByMech}
                    functions={functions}
                    UpdatePaymentRequest={UpdatePaymentRequest}
                    getFeedback_Details={getFeedback_Details}
                  />
                  <UserBox props={getJobCardDetailsData} />
                  {screen === 1 && (
                    <>
                      <hr className={styles.hr} />
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                              {servicesCard?.map((ele, index) =>
                                index === 4 &&
                                (getJobCardDetailsData?.Status ===
                                  "In-Progress" ||
                                  getJobCardDetailsData?.Status ===
                                    "Pending") ? null : (
                                  <ServiceCard
                                    data={ele}
                                    key={index}
                                    getJobCardDetailsData={
                                      getJobCardDetailsData
                                    }
                                  />
                                )
                              )}
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <p className={styles.addVehicleBtn_img_header}>
                              Vehicle Images
                            </p>
                            <div className={styles.GalaryDesign}>
                              <GalleryPage
                                getJobCardDetailsData={getJobCardDetailsData}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {screen === 2 && (
                    <>
                      <div className={styles.backtocardScreeen}>
                        <h3 className={styles.addVehicleBtn_h3}>Services</h3>
                        <div className={styles.services_addback}>
                          <span
                            onClick={backToNewScreen}
                            className={styles.addVehicleBtn_back}
                          >
                            Back
                          </span>
                          {getJobCardDetailsData?.Status === "In-Progress" ||
                          getJobCardDetailsData?.Status === "Pending" ? (
                            <>
                              <div className={styles.addVehicleBtn_add}>
                                <ButtonLog
                                  onClick={handleOpen}
                                  text={"Add Services"}
                                  variant={"contained"}
                                  className={styles.addVehicleBtn}
                                  startIcon={
                                    <ControlPointOutlinedIcon
                                      sx={{ fontSize: "15px" }}
                                    />
                                  }
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className={styles.servicesTableScreen}>
                        <div className="row px-4">
                          {getJobCardDetailsData?.service !== null &&
                            getJobCardDetailsData?.service.map((ele) => (
                              <TechnicianListHome
                                {...ele}
                                handleServiceDelete={handleServiceDelete}
                                handleReassignModal={handleReassignOpen}
                              />
                            ))}
                        </div>
                      </div>
                      <Modal
                        open={openReassign}
                        onClose={handleReassignClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style} className={styles.reasignModal}>
                          <Box className={styles.btnClose}>
                            <CloseCircle
                              size="32"
                              color=" #273A44"
                              variant="Outline"
                              onClick={handleReassignClose}
                            />
                          </Box>
                          <Box className={styles.modal_title}>
                            <p>Reassign Mechanic for {selectServiceName}</p>
                          </Box>
                          <FormControl
                            className={styles.formControl}
                            size="small"
                            sx={{
                              width: "100%",
                            }}
                          >
                            <InputLabel id="demo-select-label">
                              Select mechanic
                            </InputLabel>
                            <Select
                              labelId="demo-select-label"
                              id="demo-select"
                              value={selectedOption}
                              onChange={handleSelectChange}
                            >
                              <MenuItem disabled>Select Mechanic</MenuItem>
                              {getAllActiveUser_Details?.map((user: any) => (
                                <MenuItem key={user?.id} value={user?.id}>
                                  {user?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <Box className={styles.btn_group}>
                            <Box className={styles.btn_standard}>
                              <ButtonLog
                                onClick={handleClose}
                                text={"Cancel"}
                                variant={"contained"}
                                className={styles.cancle_btn}
                              />
                            </Box>
                            <Box>
                              {selectedOption ? (
                                <>
                                  <ButtonLog
                                    text={"Submit"}
                                    variant={"contained"}
                                    className={styles.addVehicleBtn}
                                    onClick={handleReaasignTech}
                                  />
                                </>
                              ) : (
                                <>
                                  <ButtonLog
                                    text={"Submit"}
                                    variant={"contained"}
                                    disabled
                                    className={styles.addVehicleBtn}
                                    onClick={handleReaasignTech}
                                  />
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style} className={styles.modal_services}>
                          <Box className={styles.btnClose}>
                            <IconButton onClick={handleClose}>
                              <CloseCircle
                                size="32"
                                color=" #273A44"
                                variant="Outline"
                              />
                            </IconButton>
                          </Box>
                          <Box className={styles.modal_title}>
                            <p>Add Service </p>
                          </Box>
                          <Box className={styles.root_container}>
                            <div className="row">
                              <div className="col-md-4">
                                <p>Selected Services</p>
                                <div className={styles.root_left}>
                                  {items?.map((ele: any, index: number) => (
                                    <TechnicianList
                                      assignTech={updatedUserProfiles}
                                      item={ele}
                                      handleChange={handleChangeTech}
                                      index={index}
                                      values={values}
                                    />
                                  ))}
                                </div>
                              </div>
                              <div className="col-md-8 ">
                                <div className={styles.root_right}>
                                  <div className="row">
                                    {Object.keys(GroupedService).map(
                                      (category, index) => (
                                        <>
                                          <div className="container">
                                            <p className={styles.services_name}>
                                              {category}
                                            </p>
                                            <div className="row mb-3 row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-xs-1">
                                              {GroupedService[category].map(
                                                (item: any) => (
                                                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <ComplaintServiceCard
                                                      item={item}
                                                      firstMechanicID={
                                                        firstMechanicID
                                                      }
                                                      handleAddService={
                                                        handleAddService
                                                      }
                                                    />
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Box>
                          <Box className={styles.btn_group}>
                            <Box className={styles.btn_standard}>
                              <ButtonLog
                                onClick={handleClose}
                                text={"Cancel"}
                                variant={"contained"}
                                className={styles.cancle_btn}
                              />
                            </Box>
                            <Box>
                              <ButtonLog
                                text={"Submit"}
                                variant={"contained"}
                                className={styles.addVehicleBtn}
                                onClick={handleCreateService}
                                disabled={items.length === 0}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                    </>
                  )}
                  {screen === 3 && (
                    <>
                      <div className={styles.backtocardScreeen}>
                        <h3 className={styles.addVehicleBtn_h3}>
                          Customer Complaints
                        </h3>
                        <div className={styles.services_addback}>
                          <span
                            onClick={backToNewScreen}
                            className={styles.addVehicleBtn_back}
                          >
                            Back
                          </span>
                        </div>
                      </div>

                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Box className={styles.btnClose}>
                            <CloseCircle
                              size="32"
                              color=" #273A44"
                              variant="Outline"
                              onClick={handleClose}
                            />
                          </Box>
                          <Box className={styles.modal_title}>
                            <p>Add Customer Complaint</p>
                          </Box>
                          <FormControl
                            className={styles.formControl}
                            size="small"
                            sx={{
                              width: "100%",
                            }}
                          >
                            <span className={styles.label}>
                              Customer Complaint
                            </span>

                            <div className="container">
                              <form>
                                <TextField
                                  fullWidth
                                  placeholder="Add Customer complaint"
                                  variant="outlined"
                                  className="my-3"
                                />
                              </form>
                            </div>
                          </FormControl>

                          <Box className={styles.btn_group}>
                            <Box className={styles.btn_standard}>
                              <ButtonLog
                                onClick={handleClose}
                                text={"Cancel"}
                                variant={"contained"}
                                className={styles.cancle_btn}
                              />
                            </Box>
                            <Box>
                              <ButtonLog
                                text={"Submit"}
                                variant={"contained"}
                                className={styles.addVehicleBtn}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                      <div className={styles.servicesTableScreen}>
                        <div className="row px-4">
                          {getJobCardDetailsData?.complaints[0] !== null &&
                            getJobCardDetailsData?.complaints.map(
                              (ele: any, index) => (
                                <ComplaintsListHome data={ele} index={index} />
                              )
                            )}
                        </div>
                      </div>
                    </>
                  )}
                  {screen === 4 && (
                    <>
                      <div className={styles.backtocardScreeen}>
                        <h3 className={styles.addVehicleBtn_h3}>Spare Parts</h3>
                        <div className={styles.services_addback}>
                          <span
                            onClick={backToNewScreen}
                            className={styles.addVehicleBtn_back}
                          >
                            Back
                          </span>
                          {getJobCardDetailsData?.Status === "In-Progress" ||
                          getJobCardDetailsData?.Status === "Pending" ? (
                            <>
                              <div className={styles.addVehicleBtn_add}>
                                <ButtonLog
                                  text={"Add Spares"}
                                  variant={"contained"}
                                  onClick={handleOpen}
                                  className={styles.addVehicleBtn}
                                  startIcon={
                                    <ControlPointOutlinedIcon
                                      sx={{ fontSize: "15px" }}
                                    />
                                  }
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>

                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style} className={styles.modal_services}>
                          <Box className={styles.btnClose}>
                            <CloseCircle
                              size="32"
                              color=" #273A44"
                              variant="Outline"
                              onClick={handleClose}
                            />
                          </Box>
                          <Box className={styles.modal_title}>Add Spare</Box>
                          <div className={styles.root_spare}>
                            <div className="container">
                              <div className="row">
                                <div className="col-md-4">
                                  <p>Selected Spare</p>
                                  <div className={styles.root_left}>
                                    <SelectedSpares
                                      selectedSpares={products}
                                      handleSpareAdd={handleSpareAdd}
                                      handleRemoveSpare={handleRemoveSpare}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-8 ">
                                  {/* <p></p> */}
                                  <div className={styles.root_right}>
                                    <div className="row">
                                      {Object.keys(GroupedParts).map(
                                        (spare) => {
                                          return (
                                            <>
                                              <div className="container">
                                                <p
                                                  className={
                                                    styles.services_name
                                                  }
                                                >
                                                  {spare}
                                                </p>
                                                <div className="row mb-3 row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-xs-1">
                                                  {GroupedParts[spare].map(
                                                    (parts: any) => {
                                                      return (
                                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                          <SparesList
                                                            {...parts}
                                                            handleSpareAdd={
                                                              handleSpareAdd
                                                            }
                                                            handleRemoveSpare={
                                                              handleRemoveSpare
                                                            }
                                                          />
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Box className={styles.btn_group}>
                            <Box className={styles.btn_standard}>
                              <ButtonLog
                                onClick={handleClose}
                                text={"Cancel"}
                                variant={"contained"}
                                className={styles.cancle_btn}
                              />
                            </Box>
                            <Box>
                              <ButtonLog
                                text={"Submit"}
                                variant={"contained"}
                                className={styles.addVehicleBtn}
                                onClick={handleCreateSpare}
                                disabled={products.length === 0}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                      <div className={styles.servicesTableScreen}>
                        <div className="row px-4">
                          {getJobCardDetailsData?.spare !== null &&
                            getJobCardDetailsData?.spare.map((ele) => (
                              <SpareListHome
                                {...ele}
                                handleSpareDelete={handleSpareDelete}
                                Status={getJobCardDetailsData?.Status}
                              />
                            ))}
                        </div>
                      </div>
                    </>
                  )}
                  {screen === 5 && (
                    <>
                      <div className={styles.backtocardScreeen}>
                        <h3 className={styles.addVehicleBtn_h3}>Estimate</h3>
                        <div className={styles.services_addback}>
                          <span
                            onClick={backToNewScreen}
                            className={styles.addVehicleBtn_back}
                          >
                            Back
                          </span>
                          <div className={styles.addVehicleBtn_add}>
                            <ButtonLog
                              text={"Print Estimate"}
                              variant={"contained"}
                              onClick={handleOpen}
                              className={styles.addVehicleBtn}
                              startIcon={<Printer size="24" color="#d9e3f0" />}
                            />
                          </div>
                        </div>
                      </div>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Box className={styles.btnClose}>
                            <CloseCircle
                              size="32"
                              color=" #273A44"
                              variant="Outline"
                              onClick={handleClose}
                            />
                          </Box>

                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              backgroundColor: "#FFF",
                              outline: 0,
                              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                              padding: 16,
                            }}
                          >
                            <PDFViewer width={800} height={600}>
                              <EstimatePDF
                                data={getEstimateDetails}
                                getFeedback_Details={getFeedback_Details}
                              />
                            </PDFViewer>
                          </div>
                        </Box>
                      </Modal>
                      <div className={styles.estimateCard_root}>
                        <Card
                          variant="outlined"
                          className={styles.estimateCard}
                          sx={{
                            width: "100%",
                            maxHeight: "62vh",
                            overflowY: "scroll",
                          }}
                        >
                          <CardContent>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Description
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Quantity
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Unit Price
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Total Price
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {getEstimateDetails?.services.map(
                                  (item, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{item.service_name}</TableCell>
                                      <TableCell>-</TableCell>
                                      <TableCell>
                                        {item.price?.toFixed(2)}
                                      </TableCell>
                                      <TableCell>
                                        {item.price?.toFixed(2)}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}

                                {getEstimateDetails?.spareParts.map(
                                  (item, index) => (
                                    <TableRow key={index}>
                                      <TableCell>
                                        {item.spare_part_name}
                                      </TableCell>
                                      <TableCell>{item.quantity}</TableCell>
                                      <TableCell>
                                        {item.price?.toFixed(2)}
                                      </TableCell>
                                      <TableCell>
                                        {item.total_price?.toFixed(2)}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}

                                <TableRow>
                                  <TableCell>
                                    {
                                      getEstimateDetails?.extraService
                                        .service_name
                                    }
                                  </TableCell>
                                  <TableCell>1</TableCell>
                                  <TableCell>
                                    {getEstimateDetails?.extraService.price?.toFixed(
                                      2
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {getEstimateDetails?.extraService.price?.toFixed(
                                      2
                                    )}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell
                                    colSpan={3}
                                    className={styles.txt_table}
                                  >
                                    Sub Total
                                  </TableCell>
                                  <TableCell>
                                    {getEstimateDetails?.totalPrice?.toFixed(2)}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell
                                    colSpan={3}
                                    className={styles.txt_table}
                                  >
                                    Tax {userData.tax_percentage}%
                                  </TableCell>
                                  <TableCell>
                                    {(
                                      (Number(getEstimateDetails?.totalPrice) *
                                        userData.tax_percentage) /
                                      100
                                    )?.toFixed(2)}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell
                                    colSpan={3}
                                    className={styles.txt_table_bold}
                                  >
                                    Total
                                  </TableCell>
                                  <TableCell>
                                    {Math.round(
                                      Math.floor(
                                        Number(getEstimateDetails?.totalPrice)
                                      ) +
                                        Math.floor(
                                          (Number(
                                            getEstimateDetails?.totalPrice
                                          ) *
                                            userData.tax_percentage) /
                                            100
                                        )
                                    )?.toFixed(2)}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Card>
                      </div>
                    </>
                  )}
                  <hr className={styles.hr} />
                </>
              ) : (
                <HomeWelcome btn={true} />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <HomeWelcome loading={loading} />
        </>
      )}
    </>
  );
}
