import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import {
  GetGarageReport,
  GetJobDurationReport,
  GetMechanicReport,
  GetRevenueReport,
  GetSparePartReport,
  GetTaskReport,
} from "./reports.request";
import { REPORTSINIT, ReportDT } from "./types";

export const initialState: REPORTSINIT = {
  getGarageReportError: false,
  getGarageReportLoading: false,
  getGarageReportSuccess: false,
  getGarageReportDetails: [],
  getTaskReportError: false,
  getTaskReportLoading: false,
  getTaskReportSuccess: false,
  getTaskReportDetails: [],
  getRevenueReportError: false,
  getRevenueReportLoading: false,
  getRevenueReportSuccess: false,
  getRevenueReportDetails: [],
  getSparePartReportError: false,
  getSparePartReportLoading: false,
  getSparePartReportSuccess: false,
  getSparePartReportDetails: [],
  getMechanicReportError: false,
  getMechanicReportLoading: false,
  getMechanicReportSuccess: false,
  getMechanicReportDetails: [],
  getJobDurationReportError: false,
  getJobDurationReportLoading: false,
  getJobDurationReportSuccess: false,
  getJobDurationReportDetails: [],
};
export const getGarageReport = createAsyncThunk(
  "report/getGarageReport",
  (body: ReportDT) => {
    return GetGarageReport(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getTaskReport = createAsyncThunk(
  "report/getTaskReport",
  (body: ReportDT) => {
    return GetTaskReport(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getRevenueReport = createAsyncThunk(
  "report/getRevenueReport",
  (body: ReportDT) => {
    return GetRevenueReport(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getSparePartReport = createAsyncThunk(
  "report/getSparePartReport",
  (body: ReportDT) => {
    return GetSparePartReport(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getMechanicReport = createAsyncThunk(
  "report/getMechanicReport",
  (body: ReportDT) => {
    return GetMechanicReport(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data.mechanic;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getJobDurationReport = createAsyncThunk(
  "report/ getJobDurationReport",
  (body: ReportDT) => {
    return GetJobDurationReport(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getGarageReport.pending, (state) => {
      state.getGarageReportLoading = true;
      state.getGarageReportError = false;
      state.getGarageReportSuccess = false;
    });
    builder.addCase(
      getGarageReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getGarageReportLoading = false;
        state.getGarageReportError = false;
        state.getGarageReportSuccess = true;
        state.getGarageReportDetails = action?.payload;
      }
    );
    builder.addCase(getGarageReport.rejected, (state, action: AnyAction) => {
      state.getGarageReportLoading = false;
      state.getGarageReportError = false;
      state.getGarageReportSuccess =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getTaskReport.pending, (state) => {
      state.getTaskReportLoading = true;
      state.getTaskReportError = false;
      state.getTaskReportSuccess = false;
    });
    builder.addCase(
      getTaskReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getTaskReportLoading = false;
        state.getTaskReportError = false;
        state.getTaskReportSuccess = true;
        state.getTaskReportDetails = action?.payload;
      }
    );
    builder.addCase(getTaskReport.rejected, (state, action: AnyAction) => {
      state.getTaskReportLoading = false;
      state.getTaskReportError = false;
      state.getTaskReportSuccess =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getRevenueReport.pending, (state) => {
      state.getRevenueReportLoading = true;
      state.getRevenueReportError = false;
      state.getRevenueReportSuccess = false;
    });
    builder.addCase(
      getRevenueReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getRevenueReportLoading = false;
        state.getRevenueReportError = false;
        state.getRevenueReportSuccess = true;
        state.getRevenueReportDetails = action?.payload;
      }
    );
    builder.addCase(getRevenueReport.rejected, (state, action: AnyAction) => {
      state.getRevenueReportLoading = false;
      state.getRevenueReportError = false;
      state.getRevenueReportSuccess =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getSparePartReport.pending, (state) => {
      state.getSparePartReportLoading = true;
      state.getSparePartReportError = false;
      state.getSparePartReportSuccess = false;
    });
    builder.addCase(
      getSparePartReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getSparePartReportLoading = false;
        state.getSparePartReportError = false;
        state.getSparePartReportSuccess = true;
        state.getSparePartReportDetails = action?.payload;
      }
    );
    builder.addCase(getSparePartReport.rejected, (state, action: AnyAction) => {
      state.getSparePartReportLoading = false;
      state.getSparePartReportError = false;
      state.getSparePartReportSuccess =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getMechanicReport.pending, (state) => {
      state.getMechanicReportLoading = true;
      state.getMechanicReportError = false;
      state.getMechanicReportSuccess = false;
    });
    builder.addCase(
      getMechanicReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getMechanicReportLoading = false;
        state.getMechanicReportError = false;
        state.getMechanicReportSuccess = true;
        state.getMechanicReportDetails = action?.payload;
      }
    );
    builder.addCase(getMechanicReport.rejected, (state, action: AnyAction) => {
      state.getMechanicReportLoading = false;
      state.getMechanicReportError = false;
      state.getMechanicReportSuccess =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getJobDurationReport.pending, (state) => {
      state.getJobDurationReportLoading = true;
      state.getJobDurationReportError = false;
      state.getJobDurationReportSuccess = false;
    });
    builder.addCase(
      getJobDurationReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getJobDurationReportLoading = false;
        state.getJobDurationReportError = false;
        state.getJobDurationReportSuccess = true;
        state.getJobDurationReportDetails = action?.payload;
      }
    );
    builder.addCase(
      getJobDurationReport.rejected,
      (state, action: AnyAction) => {
        state.getJobDurationReportLoading = false;
        state.getJobDurationReportError = false;
        state.getJobDurationReportSuccess =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default reportsSlice.reducer;
export const { reset } = reportsSlice.actions;
