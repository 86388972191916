import styled from "@emotion/styled";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Modal,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
} from "@mui/material";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import LogovetricyStudio from "../../../../assets/images/VerticiStudio.svg";
import GatePass from "../../../../components/appSpecific/GatePass/GatePass";
import Invoice from "../../../../components/appSpecific/Invoice/Invoice";
import CustomLoader from "../../../../components/common/Loader/Loader";
import { CustomerOrderTrackingProps } from "../container/CustomerContainer";
import styles from "./CostomerOrderTraking.module.css";

const steps = ["Pending", "In Progress", "Ready", "Delivered"];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0d6efd",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0d6efd",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    position: "relative",
    bottom: 8,
  },
}));
export const CustomerOrderTracking = (props: CustomerOrderTrackingProps) => {
  const {
    GetFeedback,
    getFeedback_Details,
    getFeedback_Error,
    getFeedback_Loading,
    getFeedback_Success,
    GetJobCradInvoice,
    getJobCradInvoice_Details,
    getJobCradInvoice_loading,
  } = props;
  const { id } = useParams<{ id: string }>();
  const [openPdf, setOpenPdf] = React.useState(false);

  const handleOpenPdf = () => {
    setOpenPdf(true);
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
  };
  React.useEffect(() => {
    GetFeedback(String(id));
    GetJobCradInvoice(String(id));
  }, [GetFeedback, GetJobCradInvoice, id]);
  const getStatusStep = (status: any) => {
    switch (status) {
      case "Delivered":
        return 4;
      case "Ready":
        return 3;
      case "In-Progress":
        return 2;
      case "Pending":
        return 1;
      // Add more cases for other statuses
      default:
        return 0; // Default to the first step
    }
  };
  return (
    <>
      {getFeedback_Loading || getJobCradInvoice_loading ? (
        <CustomLoader />
      ) : null}
      <div className="container">
        <div className={styles.FeedbackMain}>
          <div className={styles.logo}>
            <img
              src={`${
                process.env.REACT_APP_API_ENDPOINT
              }/${getFeedback_Details?.image.replace("uploads/", "")}`}
              alt=""
              width="50"
            />
            <Typography>{getFeedback_Details?.garage_name}</Typography>
            <Typography>{getFeedback_Details?.address}</Typography>

            <Typography>{getFeedback_Details?.contact_number}</Typography>
          </div>
          <div className={styles.nameHeading}>
            <h4>{getFeedback_Details?.customer_name} </h4>
            <h5>{getFeedback_Details?.vehicle_name}</h5>
          </div>
          <div className={styles.top_vehicle_numMain}>
            <div className={styles.top_vehicle_num}>
              {getFeedback_Details?.vehicle_number}
            </div>
          </div>
          <div className={styles.nameHeading}>
            <p>
              Service Date :{" "}
              {moment(getFeedback_Details?.vehicle_date).format("DD/MM/YYYY")}
            </p>
            <p>Service ID :{getFeedback_Details?.jobcard_id}</p>
          </div>
          <Typography variant="h6" gutterBottom>
            Service Tracking
          </Typography>
          <Divider />
          {/* Add order tracking content here */}
          <Grid item xs={12} md={6} lg={12} sx={{ mb: 2 }}>
            <Card
              className="card-stepper"
              variant="outlined"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <CardContent
                sx={{
                  width: "100%",
                }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography
                    variant="subtitle1"
                    fontWeight="normal"
                    sx={{
                      fontSize: "0.8rem",
                    }}
                  >
                    {getFeedback_Details?.vehicle_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontSize: "0.8rem",
                    }}
                  >
                    {getFeedback_Details?.vehicle_number}
                  </Typography>
                </Box>
                <Stepper
                  activeStep={getStatusStep(getFeedback_Details?.status)}
                  alternativeLabel
                  connector={<ColorlibConnector />}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>
                        <Typography
                          sx={{
                            fontSize: "0.6rem",
                            fontWeight: "800",
                          }}
                        >
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </CardContent>
            </Card>
          </Grid>
          {getFeedback_Details?.status === "Delivered" && (
            // <Button onClick={handleOpenPdf} className={styles.downloadButton}>
            //   Download Invoice
            // </Button>
            <>
              <PDFDownloadLink
                document={
                  <Invoice
                    getFeedback_Details={getFeedback_Details}
                    getJobCradInvoice_Details={getJobCradInvoice_Details}
                  />
                }
                fileName={`${getFeedback_Details?.vehicle_number}_${getFeedback_Details?.vehicle_name}Invoice.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Download Invoice..." : "Download Invoice"
                }
              </PDFDownloadLink>
              <br />
              <PDFDownloadLink
                document={
                  <GatePass getFeedback_Details={getFeedback_Details} />
                }
                fileName={`${getFeedback_Details?.vehicle_number}_${getFeedback_Details?.vehicle_name}_GatePass.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading
                    ? "Click here to download GatePass..."
                    : "Click here to download GatePass"
                }
              </PDFDownloadLink>
            </>
          )}
        </div>
      </div>
      <div className={styles.fooerLogo}>
        <img src={LogovetricyStudio} alt="" />
      </div>
      <Modal
        open={openPdf}
        onClose={handleClosePdf}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#FFF",
            outline: 0,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            padding: 16,
          }}
        >
          <PDFViewer width={800} height={600}>
            <Invoice
              getFeedback_Details={getFeedback_Details}
              getJobCradInvoice_Details={getJobCradInvoice_Details}
            />
          </PDFViewer>
        </div>
      </Modal>
    </>
  );
};
