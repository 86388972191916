import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { GetAllSpareRequest, UpdateSparePartReq } from "./spare.request";
import { RequestSpareState, UpdateReqSparePartDT } from "./types";

export const initialState: RequestSpareState = {
  getAllSpareRequest_Loading: false,
  getAllSpareRequest_Error: false,
  getAllSpareRequest_Success: false,
  getAllSpareRequest_details: [],
  updateSparePartReq_Loading: false,
  updateSparePartReq_Error: false,
  updateSparePartReq_Success: false,
  updateSparePartReq_details: null,
};
export const getAllSpareRequest = createAsyncThunk(
  "user/getAllSpareRequest",
  (id: string) => {
    return GetAllSpareRequest(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data.details;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const updateSparePartReq = createAsyncThunk(
  "user/updateSparePartReq",
  (body: UpdateReqSparePartDT) => {
    return UpdateSparePartReq(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const RequestSpare = createSlice({
  name: "RequestSpare",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSpareRequest.pending, (state) => {
      state.getAllSpareRequest_Loading = true;
      state.getAllSpareRequest_Error = false;
      state.getAllSpareRequest_Success = false;
    });
    builder.addCase(
      getAllSpareRequest.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllSpareRequest_Loading = false;
        state.getAllSpareRequest_Error = false;
        state.getAllSpareRequest_Success = true;
        state.getAllSpareRequest_details = action?.payload;
      }
    );
    builder.addCase(getAllSpareRequest.rejected, (state, action: AnyAction) => {
      state.getAllSpareRequest_Loading = false;
      state.getAllSpareRequest_Error = false;
      state.getAllSpareRequest_Success =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateSparePartReq.pending, (state) => {
      state.updateSparePartReq_Loading = true;
      state.updateSparePartReq_Error = false;
      state.updateSparePartReq_Success = false;
    });
    builder.addCase(
      updateSparePartReq.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateSparePartReq_Loading = false;
        state.updateSparePartReq_Error = false;
        state.updateSparePartReq_Success = true;
        state.updateSparePartReq_details = action?.payload;
      }
    );
    builder.addCase(updateSparePartReq.rejected, (state, action: AnyAction) => {
      state.updateSparePartReq_Loading = false;
      state.updateSparePartReq_Error = false;
      state.updateSparePartReq_Success =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default RequestSpare.reducer;
export const { reset } = RequestSpare.actions;
