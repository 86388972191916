import { axios } from "../../../../api/setup.intersepter";
export const GetPercentageStatus = (id: string) => {
  return axios({
    method: "GET",
    url: `jobcard-tasks/dashboard/${id}`,
  });
};
export const GetVehicleTracking = (id: string) => {
  return axios({
    method: "GET",
    url: `jobcard/vehicletracking/${id}`,
  });
};
export const GetMechanics = (id: string) => {
  return axios({
    method: "GET",
    url: `jobcard/mechanicstatus/${id}`,
  });
};
