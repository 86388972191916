import CloseIcon from "@mui/icons-material/Close";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Modal,
  SnackbarOrigin,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import CustomLoader from "../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../components/common/SnackBar/SnackBar";
import { validate, validationData } from "../../../../utils/helper.utils";
import { PlanEnquiryProps } from "../container/planEnquiryConatiner";

const useStyles: any = makeStyles((theme: any) => ({
  section: {
    backgroundImage: 'url("nereus-assets/img/bg/pattern1.png")',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  cardHeader: {
    paddingTop: theme.spacing(3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    // borderRadius: theme.spacing(1),
    outline: "none",
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
const PlanEnquiry = (props: PlanEnquiryProps) => {
  const {
    CreateEnquiryPlan,
    ResetAll,
    createEnquiryRequest_Error,
    createEnquiryRequest_Loading,
    createEnquiryRequest_Success,
    createEnquiryRequest_details,
  } = props;
  const classes = useStyles();
  const content = {
    badge: "LOREM IPSUM",
    "header-p1": "Donec lacinia",
    "header-p2": "turpis non sapien lobortis pretium",
    description:
      "Integer feugiat massa sapien, vitae tristique metus suscipit nec.",
    option1: "Monthly",
    option2: "Annual",
    "01_title": "Lorem ipsum",
    "01_price": "$9",
    "01_suffix": " / mo",
    "01_benefit1": "3 Emails",
    "01_benefit2": "1 Database",
    "01_benefit3": "Unlimited Domains",
    "01_benefit4": "10 GB Storage",
    "01_primary-action": "Select plan",
    "01_secondary-action": "Learn more",
    "02_title": "Dolor sit amet",
    "02_price": "$49",
    "02_suffix": " / mo",
    "02_benefit1": "6 Emails",
    "02_benefit2": "2 Database",
    "02_benefit3": "Unlimited Domains",
    "02_benefit4": "25 GB Storage",
    "02_primary-action": "Select plan",
    "02_secondary-action": "Learn more",
    "03_title": "Consectuter",
    "03_price": "$499",
    "03_suffix": " / mo",
    "03_benefit1": "9 Emails",
    "03_benefit2": "3 Database",
    "03_benefit3": "Unlimited Domains",
    "03_benefit4": "50 GB Storage",
    "03_primary-action": "Select plan",
    "03_secondary-action": "Learn more",
  };
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });
  const [state, setState] = React.useState({
    checkbox: true,
  });
  const [enquiry, setEnquiry] = React.useState({
    name: "",
    email: "",
    message: "",
    plan: "",
  });
  const handleChange = React.useCallback(
    (event: any) => {
      setState({ ...state, checkbox: event.target.checked });
    },
    [state]
  );

  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: enquiry.name,
      errorMsz: "Name is required",
      min: 1,
    },

    // {
    //   checkwith: "regex",
    //   value: partner.website,
    //   errorMsz: "Enter valid website ",
    //   regex:
    //     " /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}.[a-z]{2,4}\b(/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi",
    // },
    {
      checkwith: "regex",
      value: enquiry.email,
      errorMsz: "Please enter valid email address",
      regex: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
    },
    {
      checkwith: "minmax",
      value: enquiry.message,
      errorMsz: "Message is length should be between 8 to 16 characters",
      min: 8,
      max: 16,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false); // State to control the modal visibility

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // ... Other state and functions

  const handleSelectPlan = (price: string) => {
    // setSelectedPlanPrice(price);
    setEnquiry((prevState: typeof enquiry) => ({
      ...prevState,
      plan: price,
    }));
    handleOpenModal(); // Open the modal when a plan is selected
  };
  const handleSubmit = React.useCallback(() => {
    validate(ValidateData).status
      ? CreateEnquiryPlan({
          ...enquiry,

          plan_type: state.checkbox ? "annually" : "monthly",
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  }, [CreateEnquiryPlan, ValidateData, enquiry, snackState, state.checkbox]);
  React.useEffect(() => {
    if (createEnquiryRequest_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createEnquiryRequest_details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      handleCloseModal();
      ResetAll();
    } else if (createEnquiryRequest_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          createEnquiryRequest_details
            ? createEnquiryRequest_details
            : createEnquiryRequest_Error
        ),
        snackOpen: true,
        snackSeverity: createEnquiryRequest_details ? "success" : "error",
      }));
      ResetAll();
    }
  }, [
    ResetAll,
    createEnquiryRequest_Error,
    createEnquiryRequest_Success,
    createEnquiryRequest_details,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      {createEnquiryRequest_Loading ? <CustomLoader /> : null}
      <section className={classes.section}>
        <Container maxWidth="lg">
          <Box py={8} textAlign="center">
            <Box mb={3}>
              <Container maxWidth="sm">
                <Typography variant="overline" color="textSecondary">
                  {content["badge"]}
                </Typography>
                <Typography variant="h3" component="h2" gutterBottom={true}>
                  <Typography variant="h3" component="span" color="primary">
                    {content["header-p1"]}{" "}
                  </Typography>
                  <Typography variant="h3" component="span">
                    {content["header-p2"]}
                  </Typography>
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  paragraph={true}
                >
                  {content["description"]}
                </Typography>

                <div>
                  <Typography variant="subtitle1" component="span">
                    {content["option1"]}
                  </Typography>
                  &nbsp;{" "}
                  <Switch
                    name="checkbox"
                    color="primary"
                    checked={state.checkbox}
                    onChange={handleChange}
                  />{" "}
                  &nbsp;
                  <Typography variant="subtitle1" component="span">
                    {content["option2"]}
                  </Typography>
                </div>
              </Container>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card variant="elevation">
                  <CardHeader
                    title={"Standard"}
                    className={classes.cardHeader}
                  ></CardHeader>
                  <CardContent>
                    <Box px={1}>
                      <Typography
                        variant="h3"
                        component="h2"
                        gutterBottom={true}
                      >
                        {"$5"}
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          component="span"
                        >
                          /month
                        </Typography>
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        5 collections
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Worldwide accessbility
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        25 automation actions
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Access all features
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                        style={{
                          textDecoration: "line-through",
                        }}
                      >
                        24 hours support
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                        style={{
                          textDecoration: "line-through",
                        }}
                      >
                        Sync across devices
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                        paragraph={true}
                        style={{
                          textDecoration: "line-through",
                        }}
                      >
                        Share with more 5 users
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.primaryAction}
                      onClick={() => handleSelectPlan("5")}
                    >
                      {content["01_primary-action"]}
                    </Button>
                    {/* <Box mt={2}>
                      <Link href="#" color="primary">
                        {content["03_secondary-action"]}
                      </Link>
                    </Box> */}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card variant="elevation">
                  <CardHeader
                    title="Extended"
                    className={classes.cardHeader}
                  ></CardHeader>
                  <CardContent>
                    <Box px={1}>
                      <Typography
                        variant="h3"
                        component="h2"
                        gutterBottom={true}
                      >
                        $12
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          component="span"
                        >
                          /month
                        </Typography>
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Unlimited collections
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Worldwide accessibly
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Unlimited automation actions
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Access all features
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        24 hours support
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Sync across devices
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                        paragraph
                        style={{
                          textDecoration: "line-through",
                        }}
                      >
                        Share with more 5 users
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSelectPlan("12")}
                    >
                      {content["02_primary-action"]}
                    </Button>
                    {/* <Box mt={2}>
                      <Link href="#" color="primary">
                        {content["03_secondary-action"]}
                      </Link>
                    </Box> */}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card variant="elevation">
                  <CardHeader
                    title="Premium+"
                    className={classes.cardHeader}
                  ></CardHeader>
                  <CardContent>
                    <Box px={1}>
                      <Typography
                        variant="h3"
                        component="h2"
                        gutterBottom={true}
                      >
                        $16
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          component="span"
                        >
                          /month
                        </Typography>
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Unlimited collections
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Worldwide accessibly
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Unlimited automation actions
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Access all features
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        24 hours support
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                      >
                        Sync across devices
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        component="p"
                        paragraph={true}
                      >
                        Share with more 5 users
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSelectPlan("17")}
                    >
                      {content["03_primary-action"]}
                    </Button>
                    {/* <Box mt={2}>
                      <Link href="#" color="primary">
                        {content["03_secondary-action"]}
                      </Link>
                    </Box> */}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </section>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={style}>
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "#34495E",
            }}
          >
            <CloseIcon />
          </IconButton>
          <h2>Contact Us</h2>
          <form>
            <div className="mb-3">
              <TextField
                label="Name"
                name="name"
                fullWidth
                onChange={(e) => {
                  setEnquiry((prevState: typeof enquiry) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
                value={enquiry?.name}
              />
            </div>
            <div className="mb-3">
              <TextField
                label="Email"
                name="email"
                fullWidth
                onChange={(e) => {
                  setEnquiry((prevState: typeof enquiry) => ({
                    ...prevState,
                    email: e.target.value,
                  }));
                }}
                value={enquiry?.email}
              />
            </div>
            <div className="mb-3">
              <TextField
                label="Message"
                name="message"
                multiline
                rows={4}
                fullWidth
                onChange={(e) => {
                  setEnquiry((prevState: typeof enquiry) => ({
                    ...prevState,
                    message: e.target.value,
                  }));
                }}
                value={enquiry?.message}
              />
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default PlanEnquiry;
