import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { BottomSection } from "../../../../../components/appSpecific/BottomSection/BottomSection";
import StepperTopHeader from "../../../../../components/appSpecific/Stepper/StepperTopHeader";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../../components/common/SnackBar/SnackBar";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../utils/helper.utils";
import { RegistrationProps } from "../container/registrationContainer";
import {
  JobCardCreateFinalDataDTO,
  JobCardCreateFinalData_Complaints,
  JobCardCreateFinalData_Service,
  JobCardCreateFinalData_Spares,
  JobCardCreateFinalData_extraService,
  JobCardDT,
  Step,
} from "../type";
import { ComplaintJobCard } from "./ComplantJobCard/ComplantJobCard";
import { Complaint } from "./ComplantJobCard/service/complaintsSplice";
import { AssignService } from "./ComplantJobCard/service/servicesSlice";
import { Estimate } from "./Estimate/Estimate";
import styles from "./Registration.module.css";
import { Spares } from "./Spares/Spares";
import { Spare } from "./Spares/services/sparesSlice";
import { TechAssign } from "./TechAssign/TechAssign";
import { SnackStateProp } from "./VehicleCustomerDetails/VehicleCustomerDetails";
import { VehicleCustomerComponent } from "./VehicleCustomerDetails/container/VehicleCustomerDetailsContainer";

const steps: Step[] = [
  {
    label: "Vehicle & Customer Details",
    img: <></>,
  },
  {
    label: "Complaint and Job Card",
    img: <></>,
  },
  {
    label: "Spares",
    img: <></>,
  },
  {
    label: "Tech. Assign",
    img: <></>,
  },
  {
    label: "Estimate",
    img: <></>,
  },
];

export default function Registration(props: RegistrationProps) {
  const {
    GetAllActiveProduct,
    GetAllActiveService,
    activeProductDetails,
    activeServiceDetails,
    GetAllActiveUsers,
    getAllActiveUser_Details,
    activeServiceLoading,
    getAllActiveProductLoading,
    getAllActiveUser_Loading,
    CreateJobCard,
    services,
    complaints,
    spares,
    estimate,
    userID,
    jobCardDetailsID,
    CreateJobCardFinal,
    createJobCardFinalSuccess,
    createJobCardFinalError,
    createJobCardFinalLoading,
    SearchJobCard,
    searchJobCardLoading,
    ResetJobcardFinal,
    ResetJobcardEstimate,
    ResetJobcardComplaints,
    ResetJobcardServices,
    ResetJobcardSpares,
    createJobCardLoading,
    createJobCardSuccess,
  } = props;

  const navigation = useNavigate();
  const userData = getUserDataFromLocalStorage();

  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const [activeStep, setActiveStep] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [jobCardValue, setJobCardValue] = React.useState<JobCardDT>();
  React.useEffect(() => {
    GetAllActiveProduct(userData?.partner_id);
    GetAllActiveService(userData?.partner_id);
    GetAllActiveUsers(userData?.partner_id);
  }, [
    GetAllActiveProduct,
    GetAllActiveService,
    GetAllActiveUsers,
    userData?.partner_id,
  ]);
  const handleBack = React.useCallback(() => {
    setCount(count - 1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [count]);
  const handleCustomerData = React.useCallback((value: JobCardDT) => {
    setJobCardValue(value);
  }, []);

  const components = [
    <VehicleCustomerComponent
      onCustomerData={handleCustomerData}
      // searchJobCardDetails={searchJobCardDetails}
      SearchJobCard={SearchJobCard}
      key={"key-1"}
    />,
    <ComplaintJobCard serviceDetails={activeServiceDetails} key={"key-2"} />,
    <Spares productDetails={activeProductDetails} key={"key-3"} />,
    <TechAssign userDetails={getAllActiveUser_Details} key={"key-4"} />,
    <Estimate key={"key-5"} />,
  ];
  const JobCardFinal_service: JobCardCreateFinalData_Service[] = services.map(
    (ele: AssignService) => {
      return {
        jobCard_id: jobCardDetailsID,
        service_id: ele.id,
        is_estimated: 1,
        assigned_to: ele.assignedto,
        created_by: userID,
        partner_id: userData?.partner_id,
        price: ele.price,
      };
    }
  );
  const JobCardFinal_spare: JobCardCreateFinalData_Spares[] = spares.map(
    (ele: Spare) => {
      return {
        jobCard_id: jobCardDetailsID,
        product_id: ele.ID,
        quantity: ele.Quantity,
        is_estimated: 1,
        created_by: userID,
        partner_id: userData?.partner_id,
        price: ele.Price,
      };
    }
  );
  const JobCardFinal_complaints: JobCardCreateFinalData_Complaints[] =
    complaints.map((ele: Complaint) => {
      return {
        jobCard_id: jobCardDetailsID,
        complaints: ele.complaint,
        duration: ele.duration,
        assigned_to: ele.assignedto,
        created_by: userID,
        slug: "test",
        partner_id: userData?.partner_id,
      };
    });
  const JobCardFinal_extraservice: JobCardCreateFinalData_extraService[] =
    estimate.map((ele: JobCardCreateFinalData_extraService) => {
      return {
        jobCard_id: jobCardDetailsID,
        description: ele.description,
        price: ele.price,
        created_by: userID,
        estimated: ele.estimated,
        partner_id: userData?.partner_id,
      };
    });
  const CreateJobCardFinalHandler = React.useCallback(() => {
    const data: JobCardCreateFinalDataDTO = {
      jobcardComplain: [...JobCardFinal_complaints],
      jobcardService: [...JobCardFinal_service],
      jobcardSpareParts: [...JobCardFinal_spare],
      jobcardExtraService: [...JobCardFinal_extraservice],
      // id: jobCardDetailsID,
      // updatedBy: userID,
    };
    CreateJobCardFinal(data);
  }, [
    CreateJobCardFinal,
    JobCardFinal_complaints,
    JobCardFinal_extraservice,
    JobCardFinal_service,
    JobCardFinal_spare,
  ]);

  const validateHandleNext = React.useCallback(() => {
    activeStep !== steps?.length - 1 && setCount(count + 1);
    activeStep !== steps?.length - 1 &&
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    activeStep === 0 && jobCardValue && CreateJobCard(jobCardValue);
    if (activeStep === steps?.length - 1) {
      CreateJobCardFinalHandler();
    }
  }, [
    CreateJobCard,
    CreateJobCardFinalHandler,
    activeStep,
    count,
    jobCardValue,
  ]);

  const handleNext = React.useCallback(() => {
    const ValidateData: validationData[] = [
      {
        checkwith: "min",
        value: jobCardValue?.vehicle_number,
        errorMsz: "Vehicle registration number is required ",
        // max: 10,
        min: 1,
      },
      {
        checkwith: "min",
        value: jobCardValue?.model_number,
        errorMsz: "Vehicle model name is required",
        min: 1,
      },
      {
        checkwith: "min",
        value: jobCardValue?.kilometer_driven,
        errorMsz: "Kilometer driven field is required",
        min: 1,
      },
      {
        checkwith: "minmax",
        value: jobCardValue?.phone_number,
        errorMsz: "Enter a valid phone number between 10 and 12 characters.",
        min: 10,
        max: 12,
      },
      {
        checkwith: "minmax",
        value: jobCardValue?.name,
        errorMsz: "Customer name is required",
        min: 1,
      },
      {
        checkwith: "regex",
        value: jobCardValue?.chasis_number,
        errorMsz: "Enter valid chassis number",
        regex: /^[A-Za-z0-9]+$/,
      },
      {
        checkwith: "regex",
        value: jobCardValue?.engine_number,
        errorMsz: "Enter valid engine number",
        regex: /^[A-Za-z0-9]+$/,
      },
      {
        checkwith: "emptyCheck",
        value: jobCardValue?.image_paths,
        errorMsz: "Vehicle images is required",
        min: 1,
      },
      {
        checkwith: "regex",
        value: jobCardValue?.email,
        errorMsz: "Enter valid email",
        regex: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
      },
    ];
    const validation = validate(ValidateData);
    if (services.length === 0 && activeStep === 1) {
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: "Please select atleast one service",
        snackSeverity: "error",
      });
    } else {
      jobCardValue && validation.status
        ? validateHandleNext()
        : setSnackState({
            ...snackState,
            snackOpen: true,
            message: validation.message,
            snackSeverity: "error",
          });
    }
  }, [
    activeStep,
    jobCardValue,
    services.length,
    snackState,
    validateHandleNext,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  React.useEffect(() => {
    if (createJobCardFinalSuccess) {
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: "Job card created",
        snackSeverity: "success",
      });
      ResetJobcardFinal();
      ResetJobcardEstimate();
      ResetJobcardComplaints();
      ResetJobcardServices();
      ResetJobcardSpares();
      setTimeout(() => {
        setSnackState({ ...snackState, snackOpen: false });
        navigation("/job-card");
      }, 1000);
    }
    if (createJobCardFinalError) {
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: "Failed to create Job card",
        snackSeverity: "error",
      });
    }
  }, [
    createJobCardFinalSuccess,
    createJobCardFinalError,
    snackState,
    ResetJobcardFinal,
    ResetJobcardEstimate,
    ResetJobcardComplaints,
    ResetJobcardServices,
    ResetJobcardSpares,
    navigation,
  ]);
  return (
    <>
      {getAllActiveUser_Loading ||
      getAllActiveProductLoading ||
      searchJobCardLoading ||
      createJobCardFinalLoading ||
      createJobCardLoading ||
      activeServiceLoading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.registratationbg}>
        <StepperTopHeader activeStep={activeStep} steps={steps} />
        {components[count]}
        {/* <hr className={styles.hr} /> */}
        <BottomSection
          disable={createJobCardFinalLoading}
          loading={createJobCardFinalLoading}
          count={count}
          handleBack={handleBack}
          // className={styles.next_btn}
          activeStep={activeStep}
          handleNext={handleNext}
          steps={steps}
        />
      </div>
    </>
  );
}
