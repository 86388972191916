import { Box, IconButton, Modal, Tooltip } from "@mui/material";
import { Trash } from "iconsax-react";
import React from "react";
import defaultImage from "../../../assets/images/DefaultImage.png";
import info from "../../../assets/images/info_vartici.gif";
import { Spare_JobCard } from "../../../pages/app/Manage/Home/type";
import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";
import ButtonLog from "../../common/Button/Button";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};
export function SpareListHome(props: Spare_JobCard) {
  const {
    ProductSparepartID,
    Price,
    ProductID,
    ProductName,
    Quantity,
    handleSpareDelete,
    product_image,
    Status,
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <div
        className="col-lg-3 col-md-4 col-sm-4 col-xs-12 mb-3"
        style={{ position: "relative" }}
        key={ProductID}
      >
        <div className={styles.spares_wrapper}>
          <div className="d-flex justify-content-center">
            <div className={styles.img_wrapper}>
              <img
                src={
                  product_image
                    ? `${
                        process.env.REACT_APP_API_ENDPOINT
                      }/${product_image.replace("uploads/", "")}`
                    : defaultImage
                }
                alt="pic"
                className={styles.img_wrapper_img}
              />
            </div>
          </div>
          <p className={styles.spares_name}>{ProductName}</p>
          <p className={styles.spares_cost}>{Quantity} Unit</p>
          {Status !== "Delivered" && Status !== "Ready" ? (
            <div className="d-flex flex-row justify-content-center align-content-center align-items-center mt-2">
              <Tooltip arrow title={"Delete"}>
                <IconButton onClick={handleOpen}>
                  <Trash size="22" color="#c51818" variant="Outline" />
                </IconButton>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className={styles.iconInfo}>
            {/* <InfoCircle size="52" color="#F1948A" /> */}
            <img src={info} height={69} alt="" />
          </Box>
          <Box className={styles.modal_title}>
            <p> Are You Sure ? </p>
            <p> You want to delete this spare part </p>
          </Box>
          <Box className={styles.btn_group}>
            <Box className={styles.btn_standard}>
              <ButtonLog
                onClick={handleClose}
                text={"Cancel"}
                variant={"contained"}
                className={styles.cancle_btn}
              />
            </Box>
            <Box>
              <ButtonLog
                text={"Delete"}
                variant={"contained"}
                className={styles.addVehicleBtn}
                onClick={() => {
                  handleSpareDelete(ProductID);
                  handleClose();
                }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
