import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";

import { CloseCircle } from "iconsax-react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultImg from "../../../../assets/images/DefaultImage.png";
import ButtonLog from "../../../../components/common/Button/Button";
import {
  convertMinutes,
  getAuthDataFromLocalStorage,
  millisToMinutesAndSeconds,
} from "../../../../utils/helper.utils";
import {
  ChangeStatusOfServiceProps,
  SelectBayByMechProps,
  ServiceCard,
  SparePartRet,
} from "../service/type";

import { Circle } from "@mui/icons-material";
import CustomTable from "../../../../components/common/Table/CustomTable";
import styles from "./Mechanic.module.css";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
};
const styless = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "75%",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

export default function MechServiceCard(props: ServiceCard) {
  const {
    data: ele,
    type,
    changeStatus,
    selectBay,
    BayDetails,
    functions,
    returnPart,
  } = props;

  const [toggle, setToggle] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [jobCardData, setJobCardData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [returnSpareOpen, setReturnSpareOpen] = useState(false);
  const handleReturnSpareOpen = () => {
    setReturnSpareOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleReturnSpareClose = () => setReturnSpareOpen(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const currentUserId = JSON.parse(
    localStorage.getItem("USER_DATA") ?? '{"id":1}'
  )?.id;
  const authData = getAuthDataFromLocalStorage();
  const handlePause = () => {
    setToggle(false);
    setIsRunning(false);
    const data: ChangeStatusOfServiceProps = {
      id: ele.id,
      endtime: null,
      starttime: moment().format(),
      status: "Paused",
      updatedBy: currentUserId,
      Jobcard_id: ele.jobCard_id,
    };
    changeStatus(data);
  };

  const handleStart = useCallback(() => {
    setToggle(true);
    setIsRunning(true);
    const data: ChangeStatusOfServiceProps = {
      id: ele.id,
      endtime: null,
      starttime: moment().format(),
      status: `${ele.start_time === null ? "Start" : "StartAgain"}`,
      updatedBy: currentUserId,
      Jobcard_id: ele.jobCard_id,
    };
    changeStatus(data);
  }, [changeStatus, currentUserId, ele.id, ele.jobCard_id, ele.start_time]);
  const handleComplete = useCallback(() => {
    setIsRunning(false);
    setSeconds(0);
    const data: ChangeStatusOfServiceProps = {
      id: ele.id,
      endtime: moment().format(),
      starttime: null,
      status: "Completed",
      updatedBy: currentUserId,
      Jobcard_id: ele.jobCard_id,
    };
    changeStatus(data);
  }, [changeStatus, currentUserId, ele.id, ele.jobCard_id]);
  const handleSelectBay = useCallback(() => {
    handleOpen();
  }, []);
  const handleSelectReturnSpare = useCallback((ele: any) => {
    const data = ele.details.map((ele: any) => {
      setJobCardData(ele);
    });
    handleReturnSpareOpen();
  }, []);
  const handleJobCardView = useCallback((ele: any) => {
    // setJobCardData(ele);

    const data = ele.details.map((ele: any) => {
      setJobCardData(ele);
    });
    handleOpen2();
  }, []);
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isRunning) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isRunning]);
  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [bayId, setBayId] = useState<string>();
  const handleItemClick = (index: number, id: string) => {
    setActiveIndex(index);
    setBayId(id);
  };
  const handleBay = useCallback(() => {
    const data: SelectBayByMechProps = {
      jobcardTask: {
        jobcard_id: ele.jobCard_id,
        bay: bayId,
      },
    };
    selectBay(data);
  }, [bayId, ele.jobCard_id, selectBay]);
  // console.log(seconds, formatTime);
  const serviceColumns = [
    { id: "service_name", label: "Name", minWidth: 170 },
    { id: "service_price", label: "Price", minWidth: 100 },
    { id: "service_image", label: "Image", minWidth: 100 },
  ];

  const updatedServiceRows = jobCardData.services?.map(
    (row: any, index: number) => ({
      ...row,
      sl: index + 1,
      service_image: (
        <>
          <img
            src={`${
              process.env.REACT_APP_API_ENDPOINT
            }/${row.service_image.replace("uploads/", "")}`}
            alt=""
            width="50px"
          />
        </>
      ),
    })
  );
  const handleReturn = useCallback(
    (row: SparePartRet) => {
      returnPart(row);
      handleReturnSpareClose();
    },
    [returnPart]
  );
  const spareColumns = [
    { id: "product_name", label: "Name", minWidth: 170 },
    { id: "quantity", label: "Quantity", minWidth: 100 },
    { id: "product_image", label: "Image", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];
  const updatedSpareRows = jobCardData.spare?.map(
    (row: any, index: number) => ({
      ...row,
      sl: index + 1,
      product_image: (
        <>
          <img
            src={`${
              process.env.REACT_APP_API_ENDPOINT
            }/${row.product_image.replace("uploads/", "")}`}
            alt=""
            width="50px"
          />
        </>
      ),
      action: (
        <>
          <Button size="small" onClick={() => handleReturn(row)}>
            Return
          </Button>
        </>
      ),
    })
  );
  const statusColors: any = {
    Pending: "warning",
    Start: "primary",
    StartAgain: "primary",
    Pause: "info",
    Complete: "success",
  };
  const status: any = {
    Pending: "Pending",
    Start: "In Progress",
    StartAgain: "In Progress",
    Pause: "Paused",
    Complete: "Completed",
  };
  // const statusColor = statusColors[task_status] || "default";
  // const taskStatus = status[task_status] || "Pending";
  return (
    <>
      <div
        className={styles.task_wrapper}
        onClick={() => handleJobCardView(ele)}
      >
        <div className={styles.service_img_wrapper}>
          <img
            src={
              ele.module !== "Complaint"
                ? `${process.env.REACT_APP_API_ENDPOINT}/${ele.module.replace(
                    "uploads/",
                    ""
                  )}`
                : defaultImg
            }
            // sample
            alt="pic"
            width={45}
            height={45}
            className={styles.img_wrapper_img}
            loading="lazy"
          />
          <p className={styles.service_name}>{ele.task}</p>
        </div>

        <div className={styles.vehicle_details}>
          <span className={styles.vehicle_no}>
            {ele.details ? ele.details[0].vehicle_number : null}
          </span>
          <p> {ele.details ? ele.details[0].model_number : null}</p>
          <div className={styles.vehicle_color}>
            {/* <CirclePicker colors={[ele.details.vehicle.color]} /> */}
            <span
              style={{
                background: `${ele.details ? ele.details[0].color : null}`,
              }}
            ></span>
            {/* <p>blazzing Red</p> */}
          </div>
        </div>
        <div className={styles.esd}>
          <p className={styles.esdTxt}>{`ESD : ${convertMinutes(
            ele.duration
          )}`}</p>
        </div>
        <div className={styles.startTime}>
          {type === "assigned" ? (
            // <p className={styles.timer}>{formatTime(seconds)}</p>
            <></>
          ) : (
            <div>
              <p className={styles.timer_complete}>{`Processing time : ${
                // ele?.StartTime - ele.EndTime
                // (moment(ele?.StartTime, "h:mm A"), moment(ele.EndTime, "h:mm A"))
                // moment(ele.end_time, "h:mm A").diff(
                //   moment(ele?.start_time, "h:mm A"),
                //   "minutes"
                // )
                millisToMinutesAndSeconds(ele.hour_log_time) + " mins"
              }`}</p>
            </div>
          )}

          <div className={styles.start_timing}>
            {<h4>Assigned by : {ele.assigned_by}</h4>}
            <p className={styles.time}>{`Start At : ${
              ele.start_time ? moment(ele.start_time).format("LT") : "N/A"
            }`}</p>
          </div>
          <div>
            {type !== "assigned" && (
              <p className={styles.time}>{`End At: ${
                ele.end_time ? moment(ele.end_time).format("LT") : "N/A"
              }`}</p>
            )}
          </div>
        </div>
        <div className={styles.request_btn}>
          <div className={styles.bay_requestSpare}>
            <div className={styles.bay_btn}>
              {toggle && type === "assigned" ? (
                <div>
                  {["MechanicBay"].some((str) => functions?.includes(str)) ||
                  authData.rolename === "Supervisor" ? (
                    <>
                      <ButtonLog
                        text={ele.bay_name ? ele.bay_name : "Select Bay"}
                        variant="contained"
                        className={styles.bayBtn}
                        onClick={handleSelectBay}
                      />
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div>
              {["Spare Parts Request"].some((str) =>
                functions?.includes(str)
              ) || authData.rolename === "Supervisor" ? (
                <>
                  {type === "assigned" && (
                    <ButtonLog
                      text={"Request Spare"}
                      variant="contained"
                      className={styles.Request_Spare}
                      onClick={() => {
                        navigate("/request-spare", {
                          state: { jobcard_id: ele.jobCard_id },
                        });
                      }}
                    />
                    // navigation("/partner-form/edit", {
                    //   state: { partner },
                    // });
                  )}
                </>
              ) : null}
            </div>
          </div>
          <div className={styles.pause_complete}>
            {["Spare Parts Request"].some((str) => functions?.includes(str)) ||
            authData.rolename === "Supervisor" ? (
              <>
                {type === "assigned" && (
                  <ButtonLog
                    text={"Return Spare"}
                    variant="contained"
                    className={styles.return_Spare}
                    onClick={() => handleSelectReturnSpare(ele)}
                  />
                  // navigation("/partner-form/edit", {
                  //   state: { partner },
                  // });
                )}
              </>
            ) : null}

            {["Update Status"].some((str) => functions?.includes(str)) ||
            authData.rolename === "Supervisor" ? (
              <>
                {ele.status.includes("Start") && (
                  <ButtonLog
                    text={"Pause"}
                    variant="contained"
                    className={styles.pauseBtn}
                    onClick={handlePause}
                  />
                )}
              </>
            ) : null}

            {type === "assigned" ? (
              <div className={styles.statusBtn}>
                {["Update Status"].some((str) => functions?.includes(str)) ||
                authData.rolename === "Supervisor" ? (
                  <>
                    {ele.status === "Pause" || ele.status === "Pending" ? (
                      <ButtonLog
                        text={"Start"}
                        variant="contained"
                        className={styles.startBtn}
                        onClick={handleStart}
                      />
                    ) : (
                      <ButtonLog
                        text="Complete"
                        variant="contained"
                        className={styles.completeBtn}
                        onClick={handleComplete}
                      />
                    )}
                  </>
                ) : null}
              </div>
            ) : (
              <div className={styles.statusBtn_complete}>
                <p>Completed</p>
              </div>
            )}
          </div>
          {/* <Tooltip arrow title="View Details">
            <IconButton onClick={() => handleJobCardView(ele)}>
              <MoreCircle size="32" color="#0761E9" variant="Broken" />{" "}
            </IconButton>
          </Tooltip> */}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.modal_services}>
          <Box className={styles.btnClose}>
            <IconButton onClick={handleClose}>
              <CloseCircle size="32" color=" #273A44" variant="Outline" />
            </IconButton>
          </Box>
          <Box className={styles.modal_title}>
            <p>Select Bay</p>
          </Box>
          <Box className={styles.root_container}>
            <div className="container">
              <div className="row ">
                {BayDetails?.map((ele: any, index: any) => (
                  <div className={`col-2 mb-3`} key={index}>
                    <div
                      key={index}
                      style={{}}
                      className={
                        index === activeIndex
                          ? `${styles.active}`
                          : `${styles.inActive}`
                      }
                      onClick={() => handleItemClick(index, ele.id)}
                    >
                      <div className={styles.bay_box}>
                        <p
                          style={{
                            fontWeight: 600,
                            fontSize: "12px",
                            lineHeight: "15px",
                            textTransform: "capitalize",
                            color: "#2F333B",
                            display: "flex",
                            margin: "0px",
                          }}
                        >
                          {ele.name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Box className={styles.btn_group}>
              <Box className={styles.btn_standard}>
                <ButtonLog
                  onClick={handleClose}
                  text={"Cancel"}
                  variant={"contained"}
                  className={styles.cancle_btn}
                />
              </Box>
              <Box>
                <ButtonLog
                  text={"Confirm"}
                  variant={"contained"}
                  className={styles.addVehicleBtn}
                  onClick={handleBay}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styless}>
          <Box className={styles.btnClose}>
            <IconButton onClick={handleClose2}>
              <CloseCircle size="32" color=" #273A44" variant="Outline" />
            </IconButton>
          </Box>
          <Box className={styles.modal_title}>
            <p>
              Job card details of {jobCardData.model_number}(
              {jobCardData.vehicle_number})
            </p>
          </Box>
          <Box className={styles.root_container}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => setTabValue(newValue)}
            >
              <Tab label="Services" />
              <Tab label="Spares" />
              <Tab label="Complaints" />
            </Tabs>
            {tabValue === 0 && (
              <div className="container mt-3">
                <div className="row ">
                  {" "}
                  {jobCardData?.services?.length > 0 ? (
                    jobCardData?.services?.map((item: any) => (
                      <>
                        <Card
                          variant="outlined"
                          className="col-lg-3 col-md-4 col-sm-4 col-xs-12 m-1"
                        >
                          <Tooltip arrow title={item.task_status}>
                            <IconButton
                              style={{
                                float: "right",
                                position: "absolute",
                                // right: "10px",
                              }}
                            >
                              <Circle />
                            </IconButton>
                          </Tooltip>
                          <div className={styles.spares_wrapper}>
                            <div className="d-flex justify-content-center mt-3">
                              <div className={styles.img_wrapper}>
                                <img
                                  src={
                                    item
                                      ? `${
                                          process.env.REACT_APP_API_ENDPOINT
                                        }/${item?.service_image?.replace(
                                          "uploads/",
                                          ""
                                        )}`
                                      : defaultImg
                                  }
                                  alt="pic"
                                  className={styles.img_wrapper_img}
                                />
                              </div>
                            </div>
                            <p className={styles.spares_name}>
                              {jobCardData?.services && item?.service_name}
                            </p>
                            {/* <div className="mt-1">
                            <p className={styles.spares_cost}>
                              Rs.
                              {jobCardData?.services && item?.service_price}
                              /-
                            </p>
                          </div> */}
                          </div>
                        </Card>
                      </>
                    ))
                  ) : (
                    <p>No service data available.</p>
                  )}
                </div>
              </div>
            )}
            {tabValue === 1 && (
              <div className="container mt-3">
                <div className="row ">
                  {" "}
                  {jobCardData?.spare?.length > 0 ? (
                    jobCardData.spare?.map((item: any) => (
                      <>
                        <Card
                          variant="outlined"
                          className="col-lg-3 col-md-4 col-sm-4 col-xs-12 m-1"
                        >
                          <div className={styles.spares_wrapper}>
                            <div className="d-flex justify-content-center mt-3">
                              <div className={styles.img_wrapper}>
                                <img
                                  src={
                                    item
                                      ? `${
                                          process.env.REACT_APP_API_ENDPOINT
                                        }/${item?.product_image?.replace(
                                          "uploads/",
                                          ""
                                        )}`
                                      : defaultImg
                                  }
                                  alt="pic"
                                  className={styles.img_wrapper_img}
                                />
                              </div>
                            </div>
                            <p className={styles.spares_name}>
                              {jobCardData?.services && item?.product_name}
                            </p>
                            <div className="mt-1">
                              <p className={styles.spares_cost}>
                                {jobCardData?.services && item?.quantity} Unit
                              </p>
                            </div>
                          </div>
                        </Card>
                      </>
                    ))
                  ) : (
                    <p>No spare data available.</p>
                  )}
                </div>
              </div>
            )}
            {tabValue === 2 && (
              <div className="container mt-3">
                <div className="row ">
                  {" "}
                  {jobCardData?.complaints?.length > 0 ? (
                    jobCardData.complaints?.map((item: any) => (
                      <>
                        <Card
                          variant="outlined"
                          className="col-lg-3 col-md-4 col-sm-4 col-xs-12 m-1"
                        >
                          <Tooltip arrow title={item.task_status}>
                            <IconButton
                              style={{
                                float: "right",
                                position: "absolute",
                                // right: "10px",
                              }}
                            >
                              <Circle />
                            </IconButton>
                          </Tooltip>
                          <div className={styles.spares_wrapper}>
                            <div className="d-flex justify-content-center mt-3">
                              <div className={styles.img_wrapper}>
                                <img
                                  src={defaultImg}
                                  alt="pic"
                                  className={styles.img_wrapper_img}
                                />
                              </div>
                            </div>
                            <p className={styles.spares_name}>
                              {jobCardData?.services && item?.complaints}
                            </p>
                            <div className="mt-1">
                              <p className={styles.spares_cost}>
                                {jobCardData?.services && item?.duration}{" "}
                                Duration
                              </p>
                            </div>
                          </div>
                        </Card>
                      </>
                    ))
                  ) : (
                    <p>No complaints data available.</p>
                  )}
                </div>
              </div>
            )}
          </Box>
        </Box>
      </Modal>
      <Modal
        open={returnSpareOpen}
        onClose={handleReturnSpareClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styless}>
          <Box className={styles.btnClose}>
            <IconButton onClick={handleReturnSpareClose}>
              <CloseCircle size="32" color=" #273A44" variant="Outline" />
            </IconButton>
          </Box>
          <Box className={styles.modal_title}>
            <p>
              Return Spare Part {jobCardData.model_number}(
              {jobCardData.vehicle_number})
            </p>
          </Box>
          <Box className={styles.root_container}>
            <div className="container mt-3">
              <div className="row ">
                {" "}
                {/* {jobCardData?.spare?.length > 0 ? (
                  jobCardData.spare?.map((item: any) => (
                    <>
                      <Card
                        variant="outlined"
                        className="col-lg-3 col-md-4 col-sm-4 col-xs-12 m-1"
                      >
                        <div className={styles.spares_wrapper}>
                          <div className="d-flex justify-content-center mt-3">
                            <div className={styles.img_wrapper}>
                              <img
                                src={
                                  item
                                    ? `${
                                        process.env.REACT_APP_API_ENDPOINT
                                      }/${item?.product_image?.replace(
                                        "uploads/",
                                        ""
                                      )}`
                                    : defaultImg
                                }
                                alt="pic"
                                className={styles.img_wrapper_img}
                              />
                            </div>
                          </div>
                          <p className={styles.spares_name}>
                            {jobCardData?.services && item?.product_name}
                          </p>
                          <div className="mt-1">
                            <p className={styles.spares_cost}>
                              {jobCardData?.services && item?.quantity} Unit
                            </p>
                          </div>
                        </div>
                      </Card>
                    </>
                  ))
                ) : (
                  <p>No spare data available.</p>
                )} */}
                <CustomTable columns={spareColumns} rows={updatedSpareRows} />
              </div>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
