import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Avatar, Divider, Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { HambergerMenu } from "iconsax-react";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import defaultImage from "../../../assets/images/DefaultImage.png";
import logo from "../../../assets/images/VerticiStudio.svg";
import info from "../../../assets/images/info_vartici.gif";
import logoutIcon from "../../../assets/images/logout.svg";
import {
  userCheckIn,
  userCheckOut,
} from "../../../pages/app/Manage/Home/Service/homeSlice";
import { RootState, TypedDispatch } from "../../../redux/store";
import {
  getAuthDataFromLocalStorage,
  getUserDataFromLocalStorage,
  logout,
} from "../../../utils/helper.utils";
import ButtonLog from "../Button/Button";
import styles from "./Hader.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 435,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
};
const style1 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
interface Props {
  handleDrawerToggle?: () => void;
}
const notifications = [
  { id: 1, message: "You have a new message." },
  { id: 2, message: "Your order has been shipped." },
  // ... other notifications
];

const AppHeader = (props: Props) => {
  const userData = getUserDataFromLocalStorage();
  const authData = getAuthDataFromLocalStorage();
  const dispatch: TypedDispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [notificationCount, setNotificationCount] = useState(2); // Initial notification count

  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);

  const userCheckInOutByID_Details: any =
    useSelector((state: RootState) => state.home.userCheckInOutByID_Details) ??
    [];
  const getNotification_Details =
    useSelector((state: RootState) => state.home.getNotification_Details) ?? [];

  const handleNotificationModalOpen = () => {
    setNotificationModalOpen(true);
  };

  const handleNotificationModalClose = () => {
    setNotificationModalOpen(false);
  };
  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const [open, setOpen] = useState(false);
  const [openCheckin, setOpenCheckin] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);
  const handleProfileMenuOpen = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const navigate = useNavigate();
  const handleMobileMenuClose = useCallback(() => {
    setMobileMoreAnchorEl(null);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    handleMobileMenuClose();
  }, [handleMobileMenuClose]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCheckinOpen = () => setOpenCheckin(true);
  const handleCheckinClose = () => setOpenCheckin(false);
  const handleCheckoutOpen = () => setOpenCheckout(true);
  const handleCheckoutClose = () => setOpenCheckout(false);
  const handleLogout = useCallback(() => {
    handleMenuClose();
    logout();
  }, [handleMenuClose]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: "#0761e9",
      },
      children: `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`,
    };
  }
  const renderMenu = useMemo(
    () => (
      <Menu
        anchorEl={anchorEl}
        sx={{ top: "45px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="primary-search-account-menu"
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {authData?.rolename === "Mechanic" && (
          <MenuItem
            onClick={() => {
              navigate("/edit-profile");
              handleMenuClose();
            }}
          >
            Edit Profile
          </MenuItem>
        )}
        {authData?.rolename === "Partner" && (
          <MenuItem
            onClick={() => {
              navigate("/edit-profile");
              handleMenuClose();
            }}
          >
            Edit Profile
          </MenuItem>
        )}
        <MenuItem onClick={handleOpen}>Logout</MenuItem>
      </Menu>
    ),
    [anchorEl, authData?.rolename, handleMenuClose, isMenuOpen, navigate]
  );

  const renderMobileMenu = useMemo(
    () => (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="primary-search-account-menu-mobile"
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    ),
    [
      handleMobileMenuClose,
      handleProfileMenuOpen,
      isMobileMenuOpen,
      mobileMoreAnchorEl,
    ]
  );
  const handleNotificationClick = () => {
    // Handle the notification click logic here
    // For example, you could open a notifications dropdown or navigate to a notifications page
  };
  const handleCheckIn = useCallback(() => {
    dispatch(
      userCheckIn({
        checkinTime: moment().format("LT"),
        checkinDate: moment().format(),
        userId: userData.id,
        partner_id: userData.partner_id,
      })
    );
    handleCheckinClose();
  }, [dispatch, userData.id, userData.partner_id]);
  const handleCheckOut = useCallback(() => {
    dispatch(
      userCheckOut({
        checkoutTime: moment().format("LT"),
        checkoutDate: moment().format(),
        id: userCheckInOutByID_Details ? userCheckInOutByID_Details.id : "",
        partner_id: userData.partner_id,
      })
    );
    handleCheckoutClose();
  }, [dispatch, userCheckInOutByID_Details, userData.partner_id]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "#FFFFFF", borderBottom: "1px solid #E1E1E1" }}
        elevation={0}
        style={{ height: "64px", display: "flex", justifyContent: "center" }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none", md: "none" } }}
          >
            <HambergerMenu color="#1A3235" />
          </IconButton>
          <Link to="/">
            <img src={logo} width="90" alt="" className="img-fluid" />
          </Link>
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ flexGrow: 0.1 }} />
          {authData?.rolename !== "Partner" &&
            userData?.user_type !== "Owner" && (
              <Box className="me-2">
                {userCheckInOutByID_Details.checkinTime ? (
                  <>
                    <Typography variant="caption" color="GrayText">
                      {userCheckInOutByID_Details?.checkinTime}
                    </Typography>
                  </>
                ) : (
                  <>
                    <ButtonLog
                      text={"Check In"}
                      variant={"contained"}
                      className={styles.checkinBtn}
                      onClick={handleCheckinOpen}
                    />
                  </>
                )}
              </Box>
            )}
          {authData?.rolename !== "Partner" &&
            userData?.user_type !== "Owner" && (
              <Box className="me-2">
                {userCheckInOutByID_Details.checkoutTime ? (
                  <>
                    <Typography variant="caption" color="GrayText">
                      {userCheckInOutByID_Details?.checkoutTime}
                    </Typography>
                  </>
                ) : (
                  <>
                    {userCheckInOutByID_Details.checkinTime && (
                      <ButtonLog
                        text={"Check Out"}
                        variant={"contained"}
                        className={styles.checkoutBtn}
                        onClick={handleCheckoutOpen}
                      />
                    )}
                  </>
                )}
              </Box>
            )}

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {userData?.user_type !== "Owner" && (
              <img
                src={
                  userData?.logo
                    ? `${
                        process.env.REACT_APP_API_ENDPOINT
                      }/${userData.logo?.replace("uploads/", "")}`
                    : defaultImage
                }
                alt=""
                width="30px"
                height="30px"
              />
            )}
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <>
              <IconButton
                size="large"
                aria-label="show notifications"
                color="inherit"
                onClick={handleNotificationModalOpen}
              >
                <Badge
                  badgeContent={getNotification_Details.length}
                  color="error"
                >
                  <NotificationsIcon color="action" />
                </Badge>
              </IconButton>
              <Modal
                open={isNotificationModalOpen}
                onClose={handleNotificationModalClose}
                aria-labelledby="notification-modal-title"
                aria-describedby="notification-modal-description"
              >
                <Box sx={style1}>
                  <Typography variant="h6" id="notification-modal-title">
                    Notifications
                  </Typography>
                  <Divider />
                  {getNotification_Details.length > 0 ? (
                    getNotification_Details.map((notification, index) => (
                      <div key={index}>
                        <Typography>{notification.message}</Typography>
                        <Divider />
                      </div>
                    ))
                  ) : (
                    <Typography>No notifications available.</Typography>
                  )}
                </Box>
              </Modal>
            </>
            <div className={styles.icon_user}>
              {/* <UserOctagon size="35" color="#0761e9" /> */}
              {/* <img src={Avater} alt="" className="img-fluid" /> */}
              <Tooltip arrow title="Open settings">
                <IconButton onClick={handleProfileMenuOpen} sx={{ p: 0 }}>
                  <Avatar>{userData?.name[0].toUpperCase()}</Avatar>
                  {/* <Avatar {...stringAvatar(userData?.name)} /> */}
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <div>
                <Typography
                  sx={{
                    color: "#34495E",
                    position: "relative",
                    right: 20,
                    top: 5,
                    fontSize: 16,
                    fontWeight: 900,
                    textTransform: "capitalize",
                  }}
                >
                  {userData?.name}
                </Typography>
              </div>

              <div className={styles.role_action}>
                <Typography
                  sx={{
                    color: "#9A9AB0",
                    position: "relative",
                    fontSize: 12,
                    fontWeight: 400,
                    textTransform: "capitalize",
                    top: 4,
                  }}
                >
                  {authData?.rolename
                    ? authData?.rolename
                    : userData?.user_type}
                </Typography>
              </div>
            </div>
            <Tooltip arrow title="Logout">
              <IconButton className={styles.louout_btn}>
                <img src={logoutIcon} onClick={handleOpen} alt="" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: { md: "none" } }}>
            <IconButton className={styles.louout_btn}>
              <img src={logoutIcon} onClick={handleOpen} alt="" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.LogoutModal}>
          <Box className={styles.iconInfo}>
            {/* <InfoCircle size="52" color="#F1948A" /> */}
            <img src={info} height={69} alt="" />
          </Box>
          <Box className={styles.modal_title}>
            <p> Are You Sure , </p>
            <p> You want to logout </p>
          </Box>
          <Box className={styles.btn_group}>
            <Box className={styles.btn_standard}>
              <ButtonLog
                onClick={handleClose}
                text={"Cancel"}
                variant={"contained"}
                className={styles.cancle_btn}
              />
            </Box>
            <Box>
              <ButtonLog
                text={"Logout"}
                variant={"contained"}
                className={styles.addVehicleBtn}
                onClick={handleLogout}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openCheckin}
        onClose={handleCheckinClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.LogoutModal}>
          <Box className={styles.iconInfo}>
            {/* <InfoCircle size="52" color="#F1948A" /> */}
            <img src={info} height={69} alt="" />
          </Box>
          <Box className={styles.modal_title}>
            <p> Are You Sure , </p>
            <p> You want to Checkin </p>
          </Box>
          <Box className={styles.btn_group}>
            <Box className={styles.btn_standard}>
              <ButtonLog
                onClick={handleCheckinClose}
                text={"Cancel"}
                variant={"contained"}
                className={styles.cancle_btn}
              />
            </Box>
            <Box>
              <ButtonLog
                text={"Check In"}
                variant={"contained"}
                className={styles.addVehicleBtn}
                onClick={handleCheckIn}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openCheckout}
        onClose={handleCheckoutClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.LogoutModal}>
          <Box className={styles.iconInfo}>
            {/* <InfoCircle size="52" color="#F1948A" /> */}
            <img src={info} height={69} alt="" />
          </Box>
          <Box className={styles.modal_title}>
            <p> Are You Sure , </p>
            <p> You want to Checkout </p>
          </Box>
          <Box className={styles.btn_group}>
            <Box className={styles.btn_standard}>
              <ButtonLog
                onClick={handleCheckoutClose}
                text={"Cancel"}
                variant={"contained"}
                className={styles.cancle_btn}
              />
            </Box>
            <Box>
              <ButtonLog
                text={"Check Out"}
                variant={"contained"}
                className={styles.addVehicleBtn}
                onClick={handleCheckOut}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AppHeader;
