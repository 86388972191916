import { useEffect } from "react";
import Role from "../../../../../components/appSpecific/Role/components/Role";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { ConfigurationProps } from "../../container/configuartionContainer";

export default function RolePage(props: ConfigurationProps) {
  const {
    GetAllRoleFunction,
    getAllRoleFunctionDetails,
    getAllRoleFunctionLoading,
  } = props;
  useEffect(() => {
    GetAllRoleFunction();
  }, [GetAllRoleFunction]);
  return (
    <>
      {getAllRoleFunctionLoading ? <CustomLoader /> : null}

      <Role getAllRoleFunctionDetails={getAllRoleFunctionDetails} />
    </>
  );
}
