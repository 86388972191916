import { connect } from "react-redux";
import {
  createRole,
  getAllFunction,
  getAllRole,
  reset,
} from "../../../../pages/app/Configuration/services/configurationSlice";
import {
  FunctionRes,
  RoleFunction,
  RoleRes,
} from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import RoleForm from "../components/RoleForm/RoleForm";

interface StateProps {
  getAllRoleSuccess: boolean;
  getAllRoleError: boolean;
  getAllRoleLoading: boolean;
  RoleDetails: RoleRes[];
  getAllFunctionSuccess: boolean;
  getAllFunctionError: boolean;
  getAllFunctionLoading: boolean;
  FunctionDetails: FunctionRes[];
  createRoleSuccess: boolean;
  createRoleError: boolean;
  createRoleLoading: boolean;
  createRoleDetails: null;
}

interface DispatchProps {
  GetAllRole(): void;
  GetAllFunction(): void;
  CreateRole(data: RoleFunction): void;
  ResetAll(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getAllRoleSuccess: state.configuration.getAllRoleSuccess,
    getAllRoleError: state.configuration.getAllRoleError,
    getAllRoleLoading: state.configuration.getAllRoleLoading,
    RoleDetails: state.configuration.RoleDetails,
    getAllFunctionSuccess: state.configuration.getAllFunctionSuccess,
    getAllFunctionError: state.configuration.getAllFunctionError,
    getAllFunctionLoading: state.configuration.getAllFunctionLoading,
    FunctionDetails: state.configuration.FunctionDetails,
    createRoleSuccess: state.configuration.createRoleSuccess,
    createRoleError: state.configuration.createRoleError,
    createRoleLoading: state.configuration.createRoleLoading,
    createRoleDetails: state.configuration.createRoleDetails,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllRole() {
      dispatch(getAllRole());
    },
    GetAllFunction() {
      dispatch(getAllFunction());
    },
    CreateRole(data) {
      dispatch(createRole(data));
    },
    ResetAll() {
      dispatch(reset());
    },
  };
};

export interface RoleFormProps extends StateProps, DispatchProps {}
export const RoleFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleForm);
