import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../assets/images/DefaultImage.png";
import styles from "./SelectedSpare.module.css";
interface SelectedSparesProps {
  selectedSpares: any;
  handleSpareAdd: (spare: any) => void;
  handleRemoveSpare: (id: any) => void;
  handleRequestPart: () => void;
}

export const SelectedSpare = (props: SelectedSparesProps) => {
  const {
    selectedSpares,
    handleSpareAdd,
    handleRemoveSpare,
    handleRequestPart,
  } = props;
  const navigate = useNavigate();
  return (
    <div className={styles.selectedspares}>
      <div className={styles.title}>
        <Box>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <p className={styles.txt_spare_title}> Selected Spares</p>
      </div>
      {selectedSpares.length !== 0 ? (
        <>
          {selectedSpares.map((spare: any) => (
            <>
              <div className={styles.add_part} key={spare.ID}>
                <div className={styles.add_part_card}>
                  <div className={styles.img_txt_track}>
                    <div>
                      <img
                        src={
                          spare.spareImage
                            ? `${
                                process.env.REACT_APP_API_ENDPOINT
                              }/${spare.spareImage?.replace("uploads/", "")}`
                            : defaultImage
                        }
                        alt="part"
                        className="img-fluid"
                        width="60px"
                      />
                    </div>
                    <div className={styles.card_title_root}>
                      <p className={styles.spares_name}> {spare.Name}</p>
                      {/* <p className={styles.spares_cost}>Cost: Rs.4000</p> */}
                    </div>
                  </div>
                  <div className={styles.adding_item_botton}>
                    <div>
                      <button
                        className={styles.increment_btn}
                        onClick={() => handleRemoveSpare(spare.ID)}
                      >
                        <RemoveIcon
                          sx={{
                            color: "#0761E9",
                          }}
                        />
                      </button>
                      <Typography variant="button">{spare.Quantity}</Typography>
                      <button
                        className={styles.increment_btn}
                        disabled={spare.stock <= spare.Quantity}
                        onClick={() => handleSpareAdd({ ...spare })}
                      >
                        <AddIcon
                          sx={{
                            color: "#0761E9",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                  {/* {spare.stock > 0 ? (
                <p className={styles.spares_stock}>
                  Stock available {spare.stock}
                </p>
              ) : (
                <p className={styles.spares_out_stock}>Out of Stock</p>
              )} */}
                </div>
              </div>
            </>
          ))}
        </>
      ) : (
        <Box>
          <Typography align="center">No Products Selected</Typography>
        </Box>
      )}

      <div className={styles.child_button}>
        <Button
          variant="contained"
          color="primary"
          className={styles.btn_confrim}
          onClick={handleRequestPart}
          disabled={selectedSpares.length === 0}
        >
          {" "}
          Confirm
        </Button>
      </div>
    </div>
  );
};
