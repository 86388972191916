import CloseIcon from "@mui/icons-material/Close";
import { IconButton, SnackbarOrigin } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import StockInventory from "../../../../../components/appSpecific/StockInventory/components/StockInventory";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../../components/common/SnackBar/SnackBar";
import { getUserDataFromLocalStorage } from "../../../../../utils/helper.utils";
import { ConfigurationProps } from "../../container/configuartionContainer";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function StockPage(props: ConfigurationProps) {
  const {
    StockDetails,
    GetAllStock,
    getAllStockError,
    getAllStockLoading,
    getAllStockSuccess,
    OutOfStock,
    outOfStockDetails,
    outOfStockSuccess,
    outOfStockLoading,
  } = props;
  const { state } = useLocation();
  // console.log(state.product.id);
  const userData: any = getUserDataFromLocalStorage();
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong. Please try again.",
    snackSeverity: "error",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  useEffect(() => {
    GetAllStock(state?.product.id);
  }, [GetAllStock, state?.product.id, outOfStockSuccess]);
  useEffect(() => {
    if (outOfStockSuccess) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: outOfStockDetails
          ? outOfStockDetails
          : "Successfully StockOut",
        snackOpen: true,
        snackSeverity: "success",
      }));
      // ResetAll();
    }
  }, [outOfStockDetails, outOfStockSuccess]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      {getAllStockLoading || outOfStockLoading ? (
        <CustomLoader />
      ) : (
        <>
          <StockInventory
            StockDetails={StockDetails}
            state={state}
            OutOfStock={OutOfStock}
          />
        </>
      )}
    </>
  );
}
