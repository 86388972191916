import { connect } from "react-redux";

import {
  createUser,
  getAllRoleType,
  reset,
  updateUser,
} from "../../../../pages/app/Configuration/services/configurationSlice";
import {
  CreateUserDT,
  RoleResp,
  UpdateUserDT,
} from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import UserForm from "../components/UserForm/UserForm";

interface StateProps {
  createUser_Loading: boolean;
  createUser_Error: boolean;
  createUser_Success: boolean;
  createUser_Details: null | {};
  updateUser_Loading: boolean;
  updateUser_Error: boolean;
  updateUser_Success: boolean;
  updateUser_Details: null | [];
  getAllRoleTypeSuccess: boolean;
  getAllRoleTypeError: boolean;
  getAllRoleTypeLoading: boolean;
  RoleTypeDetails: RoleResp[];
}
interface DispatchProps {
  resetAllCategory(): void;
  CreateUser(data: CreateUserDT): void;
  UpdateUser(data: UpdateUserDT): void;
  GetAllRoleType(): void;
}
const mapStateToProps = (state: RootState): StateProps => {
  return {
    createUser_Loading: state.configuration.createUser_Loading,
    createUser_Error: state.configuration.createUser_Error,
    createUser_Success: state.configuration.createUser_Success,
    createUser_Details: state.configuration.createUser_Details,
    updateUser_Loading: state.configuration.updateUser_Loading,
    updateUser_Error: state.configuration.updateUser_Error,
    updateUser_Success: state.configuration.updateUser_Success,
    updateUser_Details: state.configuration.updateUser_Details,
    getAllRoleTypeLoading: state.configuration.getAllRoleTypeLoading,
    getAllRoleTypeError: state.configuration.getAllRoleTypeError,
    getAllRoleTypeSuccess: state.configuration.getAllRoleTypeSuccess,
    RoleTypeDetails: state.configuration.RoleTypeDetails,
  };
};
const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    resetAllCategory: () => {
      dispatch(reset());
    },
    CreateUser: (data) => {
      dispatch(createUser(data));
    },
    UpdateUser: (data) => {
      dispatch(updateUser(data));
    },
    GetAllRoleType: () => {
      dispatch(getAllRoleType());
    },
  };
};
export interface UserFormProps extends StateProps, DispatchProps {}
export const UserFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm);
