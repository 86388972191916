import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { PDFViewer } from "@react-pdf/renderer";
import { ColorsSquare, Printer, Speedometer } from "iconsax-react";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerFeedback } from "../../../pages/app/CustomerOrderTracking/services/types";
import { JobCardDTO } from "../../../pages/app/Manage/Home/type";
import { SelectBayByMechProps } from "../../../pages/app/Mechanic/service/type";
import { UpdatePaymentDT } from "../../../pages/app/Payment/service/types";
import ButtonLog from "../../common/Button/Button";
import GatePass from "../GatePass/GatePass";
import styles from "./HomeTopHeader.module.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};
export interface Job {
  job_id: number;
  customer_name: string;
  customer_phone: string;
  brand: string;
  model: string;
  status: number;
  make: string;
  color: string;
  registration_no: string;
  kms_driven: string;
  etd: string;
  created_at: string;
  email: string;
  services: Service[];
}
export interface Service {
  service_category: string;
  service_name: string;
  service_cost: number;
  service_icon: string;
  assigned_technician: string;
  etd: number;
}

export default function HomeTopHeader({
  props,
  handleUpdateJobcardStatus,
  BayDetails,
  SelectBayByMech,
  functions,
  UpdatePaymentRequest,
  getFeedback_Details,
}: {
  props: JobCardDTO | undefined;
  handleUpdateJobcardStatus: () => void;
  BayDetails: string[] | null;
  SelectBayByMech: (data: SelectBayByMechProps) => void;
  functions: string[];
  UpdatePaymentRequest: (data: UpdatePaymentDT) => void;
  getFeedback_Details: CustomerFeedback | null;
}) {
  const navigate = useNavigate();
  const [openPdf, setOpenPdf] = useState(false);

  const handleOpenPdf = () => {
    setOpenPdf(true);
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
  };
  // const handleClick = () => {
  //   // handleUpdateJobcardStatus();

  // };
  const [selectBay, setSelectBay] = useState("");
  const handleSelectBay = useCallback(
    (e: any) => {
      setSelectBay(e.target.value);
      const data: SelectBayByMechProps = {
        jobcardTask: {
          jobcard_id: String(props?.id),
          bay: e.target.value,
        },
      };
      SelectBayByMech(data);
    },
    [SelectBayByMech, props?.id]
  );
  const [open, setOpen] = React.useState(false);
  const [payment, setPayment] = useState({
    payment_id: "",
    payment_method: "",
    remarks: "",
  });
  const [age, setAge] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handlePaymentMethod = useCallback(() => {
    const data: UpdatePaymentDT = {
      status: "Paid",
      payment_method: payment.payment_method,
      remarks: payment.remarks,
      payment_id: String(props?.id),
    };
    handleClose();
    UpdatePaymentRequest(data);
    setPayment({
      payment_id: "",
      payment_method: "",
      remarks: "",
    });
  }, [
    UpdatePaymentRequest,
    payment.payment_method,
    payment.remarks,
    props?.id,
  ]);
  return (
    <div className={styles.flag}>
      <div className={styles.top_wrapper}>
        <div className={styles.vehiclesDetails}>
          <Tooltip arrow title="View Details" placement="top">
            <div
              className={styles.top_vehicle_num}
              onClick={() =>
                ["History"].some((str) => functions.includes(str))
                  ? navigate("/history", {
                      state: {
                        vehicle_number: props?.VehicleNumber,
                        jobcard_id: props?.id,
                      },
                    })
                  : null
              }
            >
              {props?.VehicleNumber ?? "XXYY0099"}
            </div>
          </Tooltip>
          <p className={styles.top_vehicle_name}>
            {props?.ModelNumber ?? "Volkswagon Jetta"}
          </p>
          <div className={styles.top_vehicle_color}>
            <ColorsSquare
              size="20"
              color={"#273A44"}
              variant="Bold"
              className={styles.iconSpecing}
            />
            <div>{props?.Color ?? "#273A44"} </div>
            <div
              style={{
                width: "12px",
                height: "12px",
                marginLeft: "12px",
                // display: "inline-block",
                background: `${props?.Color ?? "#273A44"}`,
              }}
            ></div>
          </div>
          <p className={styles.top_vehicle_km}>
            {/* <img src={km} alt="" className="img-fluid" />{" "} */}
            <Speedometer
              size="20"
              color="#273A44"
              variant="Bold"
              className={styles.iconSpecing}
            />
            {(props?.KilometerDriven ?? 1900) + " KM"}
          </p>
        </div>

        <div className={styles.setStatus}>
          {/* Status Chip */}
          <div className={styles.statuschip}>
            {props?.Status === "Pending"
              ? "Pending"
              : props?.Status === "In-Progress"
              ? "In-Progress"
              : props?.Status === "Ready"
              ? "In-Ready"
              : "Delivered"}
          </div>
          {/* Jobcard Action buttons */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9 col-md-8  col-sm-8 col-xs-12">
          <div className={styles.list_item_bar}>
            {/* <p className={styles.list_item_bar_txt}>
            <span className={styles.servicesLabel}>Service Station : </span> N/A
          </p> */}
            <p className={styles.list_item_bar_txt}>
              <span className={styles.servicesLabel}>Started Date : </span>
              {moment(props?.created_at).format("MMMM Do YYYY")}
            </p>
            <p className={styles.list_item_bar_txt}>
              <span className={styles.servicesLabel}>Estimated Date : </span>
              {props?.extraService[0] ? props?.extraService[0] : "N/A"}
            </p>
            {/* <p className={styles.list_item_bar_txt}>
              <span className={styles.servicesLabel}>
                Assigned Technician :
              </span>{" "}
              N/A
            </p> */}
          </div>
        </div>

        <div className="col-lg-3 col-md-4  col-sm-4 col-xs-12 text-sm-end">
          <div>
            {["JobCardBay"].some((str) => functions.includes(str)) ? (
              <>
                {props?.Status === "Pending" ||
                props?.Status === "In-Progress" ? (
                  <FormControl
                    className={styles.formControl}
                    size="small"
                    sx={
                      {
                        // width: "100%",
                      }
                    }
                  >
                    {/* <InputLabel
              id="demo-select-label"
              sx={{
                color: "#fff",
              }}
            >
              Select
            </InputLabel> */}
                    <Select
                      labelId="demo-select-label"
                      displayEmpty
                      id="demo-select"
                      value={props?.bay ? props?.bay : selectBay}
                      onChange={handleSelectBay}
                      IconComponent={ExpandMoreOutlined}
                      // Provide your custom dropdown icon here
                      sx={{
                        background: "#273A44",
                        width: "100%",
                        height: "100%",
                        color: "#fff",
                        borderRadius: 0,
                        fontSize: "12px",
                      }}
                      classes={{
                        icon: "custom-icon-color",
                      }}
                    >
                      <MenuItem value="" disabled>
                        <Typography variant="button">Select Bay</Typography>
                      </MenuItem>
                      {BayDetails?.map((ele: any, index: number) => (
                        <MenuItem key={ele.id} value={ele.id}>
                          {ele.name.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* </div> */}
                  </FormControl>
                ) : (
                  <></>
                )}
              </>
            ) : null}
            <div className={styles.button_set_delivery}>
              {props?.Status === "Delivered" ? (
                <Button
                  onClick={handleOpenPdf}
                  color={"secondary"}
                  className={styles.del_btn}
                  variant="contained"
                >
                  <Printer size="28" color="#273A44" variant="TwoTone" /> Gate
                  Pass
                </Button>
              ) : null}
            </div>
          </div>
          <div className={styles.rootMarkbtn}>
            {["Payment"].some((str) => functions.includes(str)) ? (
              <>
                {props?.Status === "Ready" ? (
                  <ButtonLog
                    text={"Mark As Paid"}
                    variant={"contained"}
                    onClick={handleOpen}
                    // onClick={() => }
                    className={styles.btnMark}
                  />
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.title_modal}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Mark as Paid
            </Typography>
          </div>
          <div className={styles.form_root}>
            <FormControl sx={{ m: 1, width: "95%" }}>
              <span className={styles.label}>Select Payment Method</span>
              <Select
                value={payment.payment_method}
                onChange={(e) => {
                  setPayment((prevState) => ({
                    ...prevState,
                    payment_method: e.target.value,
                  }));
                }}
                inputProps={{
                  "aria-label": "Without label",
                  style: {
                    borderRadius: "0px",
                  },
                }}
                className={styles.select_filed}
              >
                <MenuItem value="" disabled>
                  <em className={styles.placeholder_payment}>
                    Select Payment Method
                  </em>
                </MenuItem>
                <MenuItem value={"cash"}>Cash</MenuItem>
                <MenuItem value={"credit_card"}>Credit Card</MenuItem>
                <MenuItem value={"debit_card"}>Debit Card</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              className={styles.formControl}
              size="small"
              sx={{
                width: "100%",
              }}
            >
              <span className={styles.label_}>Enter Remarks</span>
              <div className="container">
                <form>
                  <TextField
                    value={payment.remarks}
                    onChange={(e) => {
                      setPayment((prevState) => ({
                        ...prevState,
                        remarks: e.target.value,
                      }));
                    }}
                    fullWidth
                    placeholder="Add Remarks"
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                      },
                    }}
                  />
                </form>
              </div>
            </FormControl>
          </div>
          <div className={styles.btn_modal_group}>
            <div>
              <ButtonLog
                text={"Cancel"}
                variant={"outlined"}
                className={styles.cancelbtn}
                onClick={handleClose}
              />
            </div>

            <div>
              <div>
                <ButtonLog
                  text={"Mark as Paid"}
                  variant="contained"
                  className={styles.btn_modal}
                  onClick={handlePaymentMethod}
                  disabled={payment.payment_method === ""}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      {/* pdf */}
      <Modal
        open={openPdf}
        onClose={handleClosePdf}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#FFF",
            outline: 0,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            padding: 16,
          }}
        >
          <PDFViewer width={800} height={600}>
            <GatePass data={props} getFeedback_Details={getFeedback_Details} />
          </PDFViewer>
        </div>
      </Modal>
    </div>
  );
}
