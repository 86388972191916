import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import {
  DeleteJobCardByIdDTO,
  DeleteProductById,
  DeleteServiceById,
  GetCheckinoutDT,
  HomeInitialState,
  JobCardCreateService,
  JobCardCreateSpares,
  NotificationDT,
  UserCheckInDT,
  UserCheckOutDT,
  updateJobcardStatusDTO,
} from "../type";
import {
  CreateJobCardServices,
  CreateJobCardSpareParts,
  CreateNotification,
  DeleteJobCardById,
  DeleteJobcardServiceByID,
  DeleteSparePartByID,
  GetAllJobCard,
  GetEstimate,
  GetJobCardDetails,
  GetNotification,
  MechanicReAssignTask,
  UpdateJobCardSpareParts,
  UpdateJobcardStatus,
  UserCheckIn,
  UserCheckInOutByID,
  UserCheckOut,
} from "./home.request";

import { getDistinctObjects } from "../../../../../utils/helper.utils";
export const initialState: HomeInitialState = {
  loading: false,
  getAllJobCardLoading: false,
  getAllJobCardError: false,
  getAllJobCardSuccess: false,
  getAllJobCardDetails: [],
  updateJobcardStatusLoading: false,
  updateJobcardStatusError: false,
  updateJobcardStatusSuccess: false,
  getJobCardDetailsLoading: false,
  getJobCardDetailsError: false,
  getJobCardDetailsSuccess: false,
  getJobCardDetailsData: undefined,
  deleteJobCardByIdLoading: false,
  deleteJobCardByIdError: false,
  deleteJobCardByIdSuccess: false,
  deleteJobCardByIdData: null,
  deleteSparePartById_Loading: false,
  deleteSparePartById_Error: false,
  deleteSparePartById_Success: false,
  deleteSparePartById_Details: null,
  deleteJobCardServiceById_Loading: false,
  deleteJobCardServiceById_Error: false,
  deleteJobCardServiceById_Success: false,
  deleteJobCardServiceById_Details: null,
  mechanicReAssign_Loading: false,
  mechanicReAssign_Error: false,
  mechanicReAssign_Success: false,
  mechanicReAssign_Details: null,
  createJobCardSpareParts_Loading: false,
  createJobCardSpareParts_Error: false,
  createJobCardSpareParts_Success: false,
  createJobCardSpareParts_Details: null,
  updateJobCardSpareParts_Loading: false,
  updateJobCardSpareParts_Error: false,
  updateJobCardSpareParts_Success: false,
  updateJobCardSpareParts_Details: null,
  createJobCardSServices_Loading: false,
  createJobCardSServices_Error: false,
  createJobCardSServices_Success: false,
  createJobCardSServices_Details: null,
  getEstimateLoading: false,
  getEstimateError: false,
  getEstimateSuccess: false,
  getEstimateDetails: null,
  userCheckIn_Loading: false,
  userCheckIn_Error: false,
  userCheckIn_Success: false,
  userCheckIn_Details: null,
  userCheckOut_Loading: false,
  userCheckOut_Error: false,
  userCheckOut_Success: false,
  userCheckOut_Details: null,
  userCheckInOutByID_Loading: false,
  userCheckInOutByID_Error: false,
  userCheckInOutByID_Success: false,
  userCheckInOutByID_Details: null,
  getNotification_Loading: false,
  getNotification_Error: false,
  getNotification_Success: false,
  getNotification_Details: null,
  createNotification_Loading: false,
  createNotification_Error: false,
  createNotification_Success: false,
  createNotification_Details: null,
};

export const getAllJobCard = createAsyncThunk(
  "jobcard/getAllJobCard",
  (id: number) => {
    return GetAllJobCard(id)
      .then((res) => {
        if (res.status === 200) {
          return res?.data.jobcard;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const updateJobcardStatus = createAsyncThunk(
  "jobcard/UpdateJobcardStatus",
  (data: updateJobcardStatusDTO) => {
    return UpdateJobcardStatus(data)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const deleteJobCardById = createAsyncThunk(
  "jobcard/deleteJobcardById",
  (data: DeleteJobCardByIdDTO) => {
    return DeleteJobCardById(data)
      .then((res) => {
        if (res.status === 200) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const deleteSparePartsByID = createAsyncThunk(
  "spareparts/deleteSparePartsByID",
  (data: DeleteProductById) => {
    return DeleteSparePartByID(data)
      .then((res) => {
        if (res.status === 200) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);

export const deleteJobcardServiceByID = createAsyncThunk(
  "jobcardService/deleteJobcardServiceByID",
  (data: DeleteServiceById) => {
    return DeleteJobcardServiceByID(data)
      .then((res) => {
        if (res.status === 200) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);

export const mechanicReAssign = createAsyncThunk(
  "mechanicReAssign",
  (data: any) => {
    return MechanicReAssignTask(data)
      .then((res) => {
        if (res.status === 201) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);

export const createJobCardSpareParts = createAsyncThunk(
  "createJobCardSpareParts",
  (data: JobCardCreateSpares) => {
    return CreateJobCardSpareParts(data)
      .then((res) => {
        if (res.status === 201) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const updateJobCardSpareParts = createAsyncThunk(
  "updateJobCardSpareParts",
  (data: JobCardCreateSpares) => {
    return UpdateJobCardSpareParts(data)
      .then((res) => {
        if (res.status === 201) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const createJobCardServices = createAsyncThunk(
  "createJobCardServices",
  (data: JobCardCreateService) => {
    return CreateJobCardServices(data)
      .then((res) => {
        if (res.status === 201) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getJobCardDetails = createAsyncThunk(
  "jobcard/getJobCardDetails",
  (id: string) => {
    return GetJobCardDetails(id)
      .then((res) => {
        if (res.status === 200) {
          // if (res.data.length !== 0) {
          const complaints = res.data.jobcard.complaints.map((ele: any) => {
            return {
              complaints: ele.complaints,
              task_status: ele.task_status,
            };
          });
          const extraService = res.data.jobcard?.extraService?.map(
            (ele: any) => ele.estimated
          );
          const service = res.data.jobcard?.services?.map((ele: any) => {
            return {
              ServiceID: ele.service_id,
              ServicePrice: ele.service_price,
              ServiceName: ele.service_name,
              jobcard_serviceId: ele.jobcard_serviceId,
              isActive: true,
              Status: res.data.jobcard.status,
              service_image: ele.service_image,
              task_status: ele.task_status,
            };
          });
          const spare = res.data.jobcard?.spare.map((ele: any) => {
            return {
              ProductID: ele.id,
              ProductName: ele.product_name,
              Price: ele.price,
              Quantity: ele.quantity,
              ProductSparepartID: ele.product_id,
              product_image: ele.product_image,
            };
          });
          const data = {
            id: res.data.jobcard.id,
            VehicleID: res.data.jobcard.vehicle_id,
            CustomerID: res.data.jobcard.customer_id,
            KilometerDriven: res.data.jobcard.kilometer_driven,
            Status: res.data.jobcard.status,
            VehicleNumber: res.data.jobcard.vehicle_number,
            Color: res.data.jobcard.color,
            ModelNumber: res.data.jobcard.model_number,
            Name: res.data.jobcard.customer_name,
            EmailAddress: res.data.jobcard.email,
            PhoneNo: res.data.jobcard.phone_number,
            Address: res.data.jobcard.address,
            taskId: res.data.jobcard.id,
            bay: res.data.jobcard.bay,
            created_at: res.data.jobcard.created_at,
            image_paths: res.data.jobcard.image_paths,
            complaints: getDistinctObjects(complaints),
            service: getDistinctObjects(service),
            spare: getDistinctObjects(spare),
            extraService: getDistinctObjects(extraService),
          };
          return data;
          // } else {
          //   return undefined;
          // }
          // return res.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getEstimate = createAsyncThunk(
  "jobcard/getEstimate",
  (id: string) => {
    return GetEstimate(id)
      .then((res) => {
        if (res.status === 200) {
          return res?.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const userCheckIn = createAsyncThunk(
  "user/userCheckIn",
  (data: UserCheckInDT) => {
    return UserCheckIn(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const userCheckOut = createAsyncThunk(
  "user/userCheckOut",
  (data: UserCheckOutDT) => {
    return UserCheckOut(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const userCheckInOutByID = createAsyncThunk(
  "user/userCheckInOutByID",
  (data: GetCheckinoutDT) => {
    return UserCheckInOutByID(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const createNotification = createAsyncThunk(
  "user/createNotification",
  (data: NotificationDT) => {
    return CreateNotification(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getNotification = createAsyncThunk(
  "user/getNotification",
  (id: string) => {
    return GetNotification(id)
      .then((res) => {
        if (res.status === 200) {
          return res?.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    reset: () => initialState,
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setAllJobCard: (state, action: PayloadAction<any>) => {
      return { ...state, getAllJobCardDetails: action.payload };
    },
    deleteJobCardByID: (state, action: PayloadAction<any>) => {
      return { ...state, deleteSparePartById_Details: action.payload };
    },
    deleteJobCardServiceByID: (state, action: PayloadAction<any>) => {
      return { ...state, deleteJobCardServiceById_Details: action.payload };
    },
    setMachanicReAssign: (state, action: PayloadAction<any>) => {
      return { ...state, mechanicReAssign_Details: action.payload };
    },
    setCreateJobCardSpareParts: (state, action: PayloadAction<any>) => {
      return { ...state, createJobCardSpareParts_Details: action.payload };
    },
    setUpdateJobCardSpareParts: (state, action: PayloadAction<any>) => {
      return { ...state, updateJobCardSpareParts_Details: action.payload };
    },
    setCreateJobCardServices: (state, action: PayloadAction<any>) => {
      return { ...state, createJobCardSServices_Details: action.payload };
    },
    setEstimate: (state, action: PayloadAction<any>) => {
      return { ...state, getEstimateDetails: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllJobCard.pending, (state) => {
      state.getAllJobCardLoading = true;
      state.loading = true;
      state.getAllJobCardError = false;
      state.getAllJobCardSuccess = false;
    });
    builder.addCase(
      getAllJobCard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllJobCardLoading = false;
        state.loading = false;
        state.getAllJobCardError = false;
        state.getAllJobCardSuccess = true;
        state.getAllJobCardDetails = action?.payload;
      }
    );
    builder.addCase(getAllJobCard.rejected, (state, action: AnyAction) => {
      state.getAllJobCardLoading = false;
      state.loading = false;
      state.getAllJobCardError = true;
      state.getAllJobCardSuccess = false;
      state.getAllJobCardError =
        action?.error?.message || "Something went wrong";
    });

    builder.addCase(getJobCardDetails.pending, (state) => {
      state.getJobCardDetailsLoading = true;
      state.loading = true;
      state.getJobCardDetailsError = false;
      state.getJobCardDetailsSuccess = false;
    });
    builder.addCase(
      getJobCardDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getJobCardDetailsLoading = false;
        state.loading = false;
        state.getJobCardDetailsError = false;
        state.getJobCardDetailsSuccess = true;
        state.getJobCardDetailsData = action?.payload;
      }
    );
    builder.addCase(getJobCardDetails.rejected, (state, action: AnyAction) => {
      state.getJobCardDetailsLoading = false;
      state.loading = false;
      state.getJobCardDetailsError = true;
      state.getJobCardDetailsSuccess = false;
      state.getJobCardDetailsError =
        action?.error?.message || "Something went wrong";
    });

    builder.addCase(updateJobcardStatus.pending, (state) => {
      state.updateJobcardStatusLoading = true;
      state.loading = true;
      state.updateJobcardStatusError = false;
      state.updateJobcardStatusSuccess = false;
    });
    builder.addCase(
      updateJobcardStatus.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateJobcardStatusLoading = false;
        state.loading = false;
        state.updateJobcardStatusError = false;
        state.updateJobcardStatusSuccess = true;
      }
    );
    builder.addCase(
      updateJobcardStatus.rejected,
      (state, action: AnyAction) => {
        state.updateJobcardStatusLoading = false;
        state.loading = false;
        state.updateJobcardStatusError = false;
        state.updateJobcardStatusSuccess = false;
      }
    );
    builder.addCase(deleteJobCardById.pending, (state) => {
      state.deleteJobCardByIdLoading = true;
      state.loading = true;
      state.deleteJobCardByIdError = false;
      state.deleteJobCardByIdSuccess = false;
    });
    builder.addCase(
      deleteJobCardById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteJobCardByIdLoading = false;
        state.loading = false;
        state.deleteJobCardByIdError = false;
        state.deleteJobCardByIdSuccess = true;
        state.deleteJobCardByIdData = action?.payload;
      }
    );
    builder.addCase(deleteJobCardById.rejected, (state, action: AnyAction) => {
      state.deleteJobCardByIdLoading = false;
      state.loading = false;
      state.deleteJobCardByIdError = false;
      state.deleteJobCardByIdSuccess = false;
      state.deleteJobCardByIdError =
        action?.error?.message || "Something went wrong";
      state.updateJobcardStatusError = true;
      state.updateJobcardStatusSuccess = false;
    });

    builder.addCase(deleteSparePartsByID.pending, (state) => {
      state.deleteSparePartById_Loading = true;
      state.deleteSparePartById_Error = false;
      state.deleteSparePartById_Success = false;
    });
    builder.addCase(
      deleteSparePartsByID.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteSparePartById_Loading = false;
        state.deleteSparePartById_Error = false;
        state.deleteSparePartById_Success = true;
        state.deleteSparePartById_Details = action?.payload;
      }
    );
    builder.addCase(
      deleteSparePartsByID.rejected,
      (state, action: AnyAction) => {
        state.deleteSparePartById_Loading = false;
        state.deleteSparePartById_Error = true;
        state.deleteSparePartById_Success = false;
        state.deleteSparePartById_Error =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(deleteJobcardServiceByID.pending, (state) => {
      state.deleteJobCardServiceById_Loading = true;
      state.deleteJobCardServiceById_Error = false;
      state.deleteJobCardServiceById_Success = false;
    });
    builder.addCase(
      deleteJobcardServiceByID.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteJobCardServiceById_Loading = false;
        state.deleteJobCardServiceById_Error = false;
        state.deleteJobCardServiceById_Success = true;
        state.deleteJobCardServiceById_Details = action?.payload;
      }
    );
    builder.addCase(
      deleteJobcardServiceByID.rejected,
      (state, action: AnyAction) => {
        state.deleteJobCardServiceById_Loading = false;
        state.deleteJobCardServiceById_Error = true;
        state.deleteJobCardServiceById_Success = false;
        state.deleteJobCardServiceById_Error =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(mechanicReAssign.pending, (state) => {
      state.mechanicReAssign_Loading = true;
      state.loading = true;
      state.mechanicReAssign_Error = false;
      state.mechanicReAssign_Success = false;
    });
    builder.addCase(
      mechanicReAssign.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.mechanicReAssign_Loading = false;
        state.loading = false;
        state.mechanicReAssign_Error = false;
        state.mechanicReAssign_Success = true;
        state.mechanicReAssign_Details = action?.payload;
      }
    );
    builder.addCase(mechanicReAssign.rejected, (state, action: AnyAction) => {
      state.mechanicReAssign_Loading = false;
      state.loading = false;
      state.mechanicReAssign_Error = true;
      state.mechanicReAssign_Success = false;
      state.mechanicReAssign_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createJobCardSpareParts.pending, (state) => {
      state.createJobCardSpareParts_Loading = true;
      state.loading = true;
      state.createJobCardSpareParts_Error = false;
      state.createJobCardSpareParts_Success = false;
    });
    builder.addCase(
      createJobCardSpareParts.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createJobCardSpareParts_Loading = false;
        state.loading = false;
        state.createJobCardSpareParts_Error = false;
        state.createJobCardSpareParts_Success = true;
        state.createJobCardSpareParts_Details = action?.payload;
      }
    );
    builder.addCase(
      createJobCardSpareParts.rejected,
      (state, action: AnyAction) => {
        state.createJobCardSpareParts_Loading = false;
        state.loading = false;
        state.createJobCardSpareParts_Error = true;
        state.createJobCardSpareParts_Success = false;
        state.createJobCardSpareParts_Error =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(createJobCardServices.pending, (state) => {
      state.createJobCardSServices_Loading = true;
      state.loading = true;
      state.createJobCardSServices_Error = false;
      state.createJobCardSServices_Success = false;
    });
    builder.addCase(
      createJobCardServices.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createJobCardSServices_Loading = false;
        state.loading = false;
        state.createJobCardSServices_Error = false;
        state.createJobCardSServices_Success = true;
        state.createJobCardSServices_Details = action?.payload;
      }
    );
    builder.addCase(
      createJobCardServices.rejected,
      (state, action: AnyAction) => {
        state.createJobCardSServices_Loading = false;
        state.loading = false;
        state.createJobCardSServices_Error = true;
        state.createJobCardSServices_Success = false;
        state.createJobCardSServices_Error =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getEstimate.pending, (state) => {
      state.getEstimateLoading = true;
      state.loading = true;
      state.getEstimateError = false;
      state.getEstimateSuccess = false;
    });
    builder.addCase(
      getEstimate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getEstimateLoading = false;
        state.loading = false;
        state.getEstimateError = false;
        state.getEstimateSuccess = true;
        state.getEstimateDetails = action?.payload;
      }
    );
    builder.addCase(getEstimate.rejected, (state, action: AnyAction) => {
      state.getEstimateLoading = false;
      state.loading = false;
      state.getEstimateError = true;
      state.getEstimateSuccess = false;
      state.getEstimateError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateJobCardSpareParts.pending, (state) => {
      state.updateJobCardSpareParts_Loading = true;
      state.loading = true;
      state.updateJobCardSpareParts_Error = false;
      state.updateJobCardSpareParts_Success = false;
    });
    builder.addCase(
      updateJobCardSpareParts.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateJobCardSpareParts_Loading = false;
        state.loading = false;
        state.updateJobCardSpareParts_Error = false;
        state.updateJobCardSpareParts_Success = true;
        state.updateJobCardSpareParts_Details = action?.payload;
      }
    );
    builder.addCase(
      updateJobCardSpareParts.rejected,
      (state, action: AnyAction) => {
        state.updateJobCardSpareParts_Loading = false;
        state.loading = false;
        state.updateJobCardSpareParts_Error = true;
        state.updateJobCardSpareParts_Success = false;
        state.updateJobCardSpareParts_Error =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(userCheckIn.pending, (state) => {
      state.userCheckIn_Loading = true;
      state.userCheckIn_Error = false;
      state.userCheckIn_Success = false;
    });
    builder.addCase(
      userCheckIn.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.userCheckIn_Loading = false;
        state.userCheckIn_Error = false;
        state.userCheckIn_Success = true;
        state.userCheckIn_Details = action?.payload;
      }
    );
    builder.addCase(userCheckIn.rejected, (state, action: AnyAction) => {
      state.userCheckIn_Loading = false;
      state.userCheckIn_Error = true;
      state.userCheckIn_Success = false;
      state.userCheckIn_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(userCheckOut.pending, (state) => {
      state.userCheckOut_Loading = true;
      state.userCheckOut_Error = false;
      state.userCheckOut_Success = false;
    });
    builder.addCase(
      userCheckOut.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.userCheckOut_Loading = false;
        state.userCheckOut_Error = false;
        state.userCheckOut_Success = true;
        state.userCheckOut_Details = action?.payload;
      }
    );
    builder.addCase(userCheckOut.rejected, (state, action: AnyAction) => {
      state.userCheckOut_Loading = false;
      state.userCheckOut_Error = true;
      state.userCheckOut_Success = false;
      state.userCheckOut_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(userCheckInOutByID.pending, (state) => {
      state.userCheckInOutByID_Loading = true;
      state.userCheckInOutByID_Error = false;
      state.userCheckInOutByID_Success = false;
    });
    builder.addCase(
      userCheckInOutByID.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.userCheckInOutByID_Loading = false;
        state.userCheckInOutByID_Error = false;
        state.userCheckInOutByID_Success = true;
        state.userCheckInOutByID_Details = action?.payload;
      }
    );
    builder.addCase(userCheckInOutByID.rejected, (state, action: AnyAction) => {
      state.userCheckInOutByID_Loading = false;
      state.userCheckInOutByID_Error = true;
      state.userCheckInOutByID_Success = false;
      state.userCheckInOutByID_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getNotification.pending, (state) => {
      state.getNotification_Loading = true;
      state.getNotification_Error = false;
      state.getNotification_Success = false;
    });
    builder.addCase(
      getNotification.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getNotification_Loading = false;
        state.getNotification_Error = false;
        state.getNotification_Success = true;
        state.getNotification_Details = action?.payload;
      }
    );
    builder.addCase(getNotification.rejected, (state, action: AnyAction) => {
      state.getNotification_Loading = false;
      state.getNotification_Error = true;
      state.getNotification_Success = false;
      state.getNotification_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createNotification.pending, (state) => {
      state.createNotification_Loading = true;
      state.createNotification_Error = false;
      state.createNotification_Success = false;
    });
    builder.addCase(
      createNotification.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createNotification_Loading = false;
        state.createNotification_Error = false;
        state.createNotification_Success = true;
        state.createNotification_Details = action?.payload;
      }
    );
    builder.addCase(createNotification.rejected, (state, action: AnyAction) => {
      state.createNotification_Loading = false;
      state.createNotification_Error = true;
      state.createNotification_Success = false;
      state.createNotification_Error =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default homeSlice.reducer;

export const {
  reset,
  setAllJobCard,
  startLoading,
  stopLoading,
  deleteJobCardByID,
  deleteJobCardServiceByID,
  setMachanicReAssign,
  setCreateJobCardServices,
  setCreateJobCardSpareParts,
  setUpdateJobCardSpareParts,
} = homeSlice.actions;
