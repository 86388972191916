import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Button } from "@mui/material";
import { Edit } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { CurrencyRes } from "../../../../pages/app/Configuration/types";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";
import ButtonLog from "../../../common/Button/Button";
import CustomTable from "../../../common/Table/CustomTable";
import styles from "./Curreny.module.css";
interface CurrencyIprops {
  CurrencyDetails: CurrencyRes[] | null;
}
function CurrencyScreen(props: CurrencyIprops) {
  const { CurrencyDetails } = props;
  const userData = getUserDataFromLocalStorage();

  const navigation = useNavigate();
  const columns = [
    { id: "name", label: "Name", minWidth: 100 },
    { id: "code", label: "Code", minWidth: 100 },
    { id: "exchange_rate", label: "Exchange Rate", minWidth: 100 },
    { id: "symbol", label: "Currency Symbol", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleEditClick = (currency: CurrencyRes) => {
    navigation("/currency-form/edit", {
      state: { currency },
    });
  };
  const updatedRows = CurrencyDetails?.map((row) => ({
    ...row,

    action: (
      <Button
        variant="outlined"
        startIcon={<Edit size="18" color="#0761e9" />}
        color="primary"
        onClick={() => handleEditClick(row)}
        size="small"
      >
        Edit
      </Button>
    ),
  }));
  return (
    <>
      <div className={styles.inventoryNavBar}>
        <ButtonLog
          text={"Back"}
          variant={"contained"}
          onClick={() => navigation(-1)}
          className={styles.backBtn}
        />

        <div className={styles.filter}>
          <ButtonLog
            text={"Add Currency"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/currency-form/add")}
          />
        </div>
      </div>

      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <CustomTable columns={columns} rows={updatedRows ? updatedRows : []} />
      </div>
    </>
  );
}

export default CurrencyScreen;
