import { Card, IconButton, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { SearchNormal1 } from "iconsax-react";
import _ from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectedSpares } from "../../../../../../components/appSpecific/SelectedSpares/SelectedSpares";
import { SparesList } from "../../../../../../components/appSpecific/SparesList/SparesList";
import { RootState } from "../../../../../../redux/store";
import { Part } from "../../../../Configuration/types";
import styles from "../Registration.module.css";
import { addSpare, removeSpare } from "./services/sparesSlice";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="my-3">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface GroupedProductsType {
  [category_name: string]: Part[];
}
export function Spares(props: any) {
  const { productDetails } = props;
  const [open, setOpen] = React.useState(false);
  // const [selectedPrice, setSelectedPrice] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const products = useSelector((state: RootState) => state.spares.spares);
  const productSum = products.reduce((acc, product) => acc + product.Price, 0);
  const dispatch = useDispatch();
  const [GroupedParts, updateGroupedParts] =
    React.useState<GroupedProductsType>({});

  React.useEffect(() => {
    updateGroupedParts(_.groupBy(productDetails, "product_category_name"));

    // groupPartsPerCategory(productsL);
  }, [productDetails]);
  const [selectedSpares, setselectedSpares] = React.useState<any>([]);

  const handleSpareAdd = React.useCallback(
    (spare: any) => {
      dispatch(addSpare(spare));
      // setSelectedPrice(spare);
      // handleOpen();
    },
    [dispatch]
  );
  // const handleSparePrice = () => {
  //   dispatch(addSpare(selectedPrice));
  //   handleClose();
  // };
  const handleRemoveSpare = React.useCallback(
    (spareId: number) => {
      dispatch(removeSpare(spareId));
    },
    [dispatch]
  );

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [searchText, setSearchText] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(productDetails);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);

    const filteredResults = productDetails.filter((vehicle: any) =>
      vehicle.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredResults);
  };
  React.useEffect(() => {
    //
    if (filteredData?.length !== 0) {
      updateGroupedParts(_.groupBy(filteredData, "product_category_name"));
    }

    // groupPartsPerCategory(productsL);
  }, [filteredData]);
  return (
    <>
      <div className={styles.main_container}>
        <div className={styles.left_container}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 p-0">
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <div className="d-block d-lg-none">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      orientation="vertical"
                      aria-label="basic tabs example"
                      className={styles.selectedServicesTbMain}
                    >
                      <Tab
                        label="Selected Spares"
                        {...a11yProps(0)}
                        className={styles.selectedServicesTbs}
                      />
                      {/* <Tab
                        label="Services"
                        {...a11yProps(1)}
                        className={styles.selectedServicesTb}
                      /> */}
                    </Tabs>
                  </div>
                  <div className="d-none d-lg-block">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className={styles.selectedServicesTbMain}
                    >
                      <Tab
                        // label="Selected Spares"
                        label={
                          <Typography
                            variant="caption"
                            className={styles.selectedServicesTbMain}
                          >{`Selected Spares (${products.length})`}</Typography>
                        }
                        {...a11yProps(0)}
                        className={styles.selectedServicesTb}
                      />
                      {/* <Tab
                        label="Services"
                        {...a11yProps(1)}
                        className={styles.selectedServicesTb}
                      /> */}
                    </Tabs>
                  </div>
                </Box>
                <div className={styles.servicesCards}>
                  <TabPanel value={value} index={0}>
                    <SelectedSpares
                      selectedSpares={products}
                      handleSpareAdd={handleSpareAdd}
                      handleRemoveSpare={handleRemoveSpare}
                    />

                    {/* <div className="col-12">
                    <div className={styles.bottom_wrapper}>
                      <div className="row align-items-center">
                        <div className="col-6">
                          <p className={styles.total_price}>
                            Total : {productSum}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </TabPanel>
                </div>

                <div className={styles.servicesCards}>
                  <TabPanel value={value} index={1}>
                    {/* {items.map((ele) => (
                    <Services item={ele} />
                  ))} */}
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right_container}>
          <div className={styles.serviceHeading}>
            <div className="container">
              <Card
                variant="outlined"
                className="row justify-content-between align-items-center"
              >
                <Box
                  className="col-8"
                  sx={{
                    padding: "6px 6px",
                    borderRadius: "0",
                  }}
                >
                  <p className={styles.header}>Spare Parts</p>
                </Box>
                <Box className="col-4">
                  <TextField
                    placeholder="Search Spare Parts"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          sx={{
                            padding: "0px",
                          }}
                          className={styles.googlr_search_icon}
                        >
                          <SearchNormal1 />
                        </IconButton>
                      ),
                      style: {
                        height: "39px",
                        borderRadius: "3px",
                      },
                    }}
                    value={searchText}
                    onChange={handleInputChange}
                  />
                </Box>
                {/* <div className="col-4">
                  <TextField
                    fullWidth
                    placeholder="Search Spares"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          sx={{
                            padding: "0px",
                          }}
                          className={styles.googlr_search_icon}
                        >
                          <SearchOutlinedIcon />
                        </IconButton>
                      ),
                      style: {
                        height: "39px",
                        borderRadius: "3px",
                      },
                    }}
                  />
                </div> */}
              </Card>
            </div>
          </div>
          {/* <hr className={styles.hr} /> */}
          <div className={styles.servicesCards}>
            {Object.keys(GroupedParts).map((spare) => {
              return (
                <>
                  <div className="container">
                    <p className={styles.services_name}>{spare}</p>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                      {GroupedParts[spare].map((parts) => {
                        return (
                          <SparesList
                            {...parts}
                            handleSpareAdd={handleSpareAdd}
                            handleRemoveSpare={handleRemoveSpare}
                          />
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className={styles.btnClose}>
            <CloseCircle
              size="32"
              color=" #273A44"
              variant="Outline"
              onClick={handleClose}
            />
          </Box>
          <Box className={styles.modal_title}>
            <p>Select Price</p>
          </Box>

          <Box className={styles.btn_group}>
            <Box className={styles.btn_standard}>
              <ButtonLog
                onClick={handleClose}
                text={"Cancel"}
                variant={"contained"}
                className={styles.cancle_btn}
              />
            </Box>
            <Box>
              <ButtonLog
                text={"Submit"}
                variant={"contained"}
                className={styles.addVehicleBtn}
                onClick={handleSparePrice}
              />
            </Box>
          </Box>
        </Box>
      </Modal> */}
    </>
  );
}
