import {
  FormControlLabel,
  IconButton,
  OutlinedInput,
  SnackbarOrigin,
  Switch,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../utils/helper.utils";
import ButtonLog from "../../../../common/Button/Button";
import { SnackBar } from "../../../../common/SnackBar/SnackBar";

import CustomLoader from "../../../../common/Loader/Loader";
import { BayFormProps } from "../../container/bayContainer";
import styles from "../Bays.module.css";
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function BayForm(props: BayFormProps) {
  const {
    CreateBay,
    UpdateBay,
    createBay_Details,
    createBay_Error,
    createBay_Loading,
    createBay_Success,
    resetAll,
    updateBay_Details,
    updateBay_Error,
    updateBay_Loading,
    updateBay_Success,
  } = props;
  const navigate = useNavigate();

  const [formType, setFormType] = React.useState<string | undefined>(
    "bay-form/add"
  );
  let param = useParams();
  const { state } = useLocation();

  // let userDataParse: { id: string; company_id: number } = {
  //   id: "",
  //   company_id: 0,
  // };

  // const userData: any = localStorage.getItem("USER_DATA");
  const userDataParse = getUserDataFromLocalStorage();

  const [bay, setBay] = React.useState({
    name: state ? state?.bay.name : "",
    description: state ? state?.bay.description : "",
    slug: state ? state?.bay.slug : "test",
    partner_id: userDataParse?.partner_id,
  });

  const [checked, setChecked] = React.useState(
    state ? state?.bay.is_active : true
  );

  const handleChange = (event: any) => {
    const updatedValue = event.target.checked;
    setChecked(updatedValue);
  };
  useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "error",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: bay.name,
      errorMsz: "Name is required and must be of maximum 30 characters",
      max: 30,
      min: 1,
    },

    {
      checkwith: "min",
      value: bay.description,
      errorMsz: "Phone Number cannot be empty",
      min: 1,
    },
  ];
  const handleSave = () => {
    validate(ValidateData).status
      ? CreateBay({
          ...bay,
          created_by: userDataParse.id,
          // companyId: userDataParse.company_id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };

  const handleEdit = () => {
    validate(ValidateData).status
      ? UpdateBay({
          ...bay,
          is_active: checked ? true : false,
          bay_id: state.bay.id,
          updated_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };
  const [userType, setUserType] = React.useState("");
  // const handleChange = (event: SelectChangeEvent) => {
  //   setUserType(event.target.value as string);
  // };

  React.useEffect(() => {
    if (createBay_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createBay_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAll();
    } else if (createBay_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          createBay_Details ? createBay_Details : createBay_Error
        ),
        snackOpen: true,
        snackSeverity: createBay_Details ? "success" : "error",
      }));
      resetAll();
    }
  }, [
    createBay_Details,
    createBay_Error,
    createBay_Success,
    navigate,
    resetAll,
  ]);

  React.useEffect(() => {
    if (updateBay_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateBay_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAll();
    } else if (updateBay_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          updateBay_Details ? updateBay_Details : "Something went wrong"
        ),
        snackOpen: true,
        snackSeverity: updateBay_Details ? "success" : "error",
      }));
      resetAll();
    }
  }, [
    navigate,
    resetAll,
    updateBay_Details,
    updateBay_Error,
    updateBay_Success,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {createBay_Loading || updateBay_Loading ? <CustomLoader /> : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>
            {formType === "bay-form/add" ? "Add Bay" : "Edit Bay"}
          </p>
        </div>
        <div className="container mt-5" style={{ backgroundColor: "#F5F5F5;" }}>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Name
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter  Name"
                  type="text"
                  onChange={(e) => {
                    setBay((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  value={bay?.name}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Description
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Description"
                  type="text"
                  onChange={(e) => {
                    setBay((prevState) => ({
                      ...prevState,
                      description: e.target.value,
                    }));
                  }}
                  value={bay?.description}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {formType === "bay-form/edit" && (
                <>
                  <FormControl
                    className={styles.formControl}
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <span className={styles.label}>
                      Status
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="Active"
                      // onChange={}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </div>

          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              variant={"contained"}
              className={styles.saveBtn}
              onClick={formType === "bay-form/edit" ? handleEdit : handleSave}
            />
          </div>
        </div>
      </div>
    </>
  );
}
