import { Box, Card, CardContent } from "@mui/material";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";
import { useDashboardIRevenue } from "../../DashbordNew/service/dashboard.request";
import styles from "./Revenue.module.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
const chartData = {
  labels: ["Services", "SpareParts"],
  datasets: [
    {
      data: [75000, 30000],
      backgroundColor: ["#66BB6A", "#EF5350"],
    },
  ],
};

const chartOptions = {
  responsive: true,
  // Disable aspect ratio to prevent cropping
  //   legend: {
  //     position: "bottom",
  //   },
  //   plugins: {
  //     legend: {
  //       display: true,
  //     },
  //   },
  width: 300, // Set the width of the chart
  height: 300, // Set the height of the chart
};

// export const revenueData = {
//   labels,
//   datasets: [
//     {
//       label: "Dataset 2",
//       data: labels.map(() => Math.random()),
//       backgroundColor: "rgba(53, 162, 235, 0.5)",
//     },
//   ],
// };
export const Revenue = () => {
  const userData: any = getUserDataFromLocalStorage();

  const { data } = useDashboardIRevenue(
    userData?.partner_id
    // getDatetimeRangeByFilterMode()
  );
  const stockOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const transformedData = data?.stock
    ? data.stock
    : [].map((item: any) => ({
        label: item.label,
        data: item.data,
        backgroundColor: item.backgroundColor,
      }));

  const stockData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: transformedData,
  };
  const expensesOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      // title: {
      //   display: true,
      //   text: "Chart.js Bar Chart",
      // },
    },
  };

  // const labels = ["January", "February", "March", "April", "May", "June", "July"];

  const monthlyExpenseTotals: { [key: string]: number } =
    data?.monthly_expense_totals;

  const labelss = Object.keys(monthlyExpenseTotals ? monthlyExpenseTotals : {});
  const expensesData = {
    labels,
    datasets: [
      {
        label: "Expense",
        data: labelss.map((month) => monthlyExpenseTotals[month]),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const revenueOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      // title: {
      //   display: true,
      //   text: "Chart.js Bar Chart",
      // },
    },
  };

  //   const labels = ["January", "February", "March", "April", "May", "June", "July"];
  const monthlyRevenueTotals: { [key: string]: number } =
    data?.monthly_revenue_totals;

  const labelsss = Object.keys(
    monthlyRevenueTotals ? monthlyRevenueTotals : {}
  );
  const revenueData = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: labelsss.map((month) => monthlyRevenueTotals[month]),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return (
    <>
      <Box
        className="container-fluid"
        sx={{
          flex: 1,
          backgroundColor: "#f4f8fb",
          minHeight: "100vh",
          padding: "0.5em",
        }}
      >
        <div className="container mt-1">
          <div className="row">
            <div className="col-md-4">
              <Card variant="outlined">
                <CardContent>
                  <div className={styles.job_headbar}>
                    <div>
                      <p className={styles.txt_job}> Total Revenue</p>
                    </div>
                  </div>
                  <p className={styles.count_revenue}>
                    {" "}
                    {userData.currency_data.symbol}{" "}
                    {data?.total_revenue?.toFixed(2)}
                  </p>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-4">
              <Card variant="outlined">
                <CardContent>
                  <div className={styles.job_headbar}>
                    <div>
                      <p className={styles.txt_job}> Total Expense</p>
                    </div>
                  </div>
                  <p className={styles.count_revenue}>
                    {" "}
                    {userData.currency_data.symbol}{" "}
                    {data?.total_expense?.toFixed(2)}
                  </p>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-4">
              <Card variant="outlined">
                <CardContent>
                  <div className={styles.job_headbar}>
                    <div>
                      <p className={styles.txt_job}> Total Profit</p>
                    </div>
                  </div>
                  <p className={styles.count_revenue}>
                    {" "}
                    {userData.currency_data.symbol}{" "}
                    {data?.total_profit?.toFixed(2)}
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <Card
                variant="outlined"
                style={{
                  //   width: "690px",
                  height: "100%",
                }}
              >
                <CardContent>
                  <div className={styles.job_headbar}>
                    <div>
                      <p className={styles.txt_job}> Gross Profit</p>
                    </div>
                  </div>
                  <Pie
                    data={{
                      labels: ["Expense", "Profit"],
                      datasets: [
                        {
                          data: [
                            data?.percentageExpense,
                            data?.percentageProfit,
                          ],
                          backgroundColor: ["#EF5350", "#66BB6A"],
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </CardContent>
              </Card>
            </div>
            <div className="col-md-8">
              <Card variant="outlined">
                <CardContent>
                  <div className={styles.job_headbar}>
                    <div>
                      <p className={styles.txt_job}> Spare parts stock</p>
                    </div>
                  </div>

                  <Bar options={stockOptions} data={stockData} />
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <Card variant="outlined">
                <CardContent>
                  <div className={styles.job_headbar}>
                    <div>
                      <p className={styles.txt_job}> Operating expenses</p>
                    </div>
                  </div>
                  {/* <Bar options={expensesOptions} data={expensesData} /> */}
                  <Line options={expensesOptions} data={expensesData} />
                </CardContent>
              </Card>
            </div>
            <div className="col-md-6">
              <Card variant="outlined">
                <CardContent>
                  <div className={styles.job_headbar}>
                    <div>
                      <p className={styles.txt_job}> Net Revenue</p>
                    </div>
                  </div>
                  {/* <Bar options={revenueOptions} data={revenueData} /> */}
                  <Line options={revenueOptions} data={revenueData} />
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
