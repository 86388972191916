import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { FeedbackPost, GetFeedback } from "./Feedback.request";
import { FeedbackIntialStateDTO } from "./type";

export const initialState: FeedbackIntialStateDTO = {
  getFeedback_Loading: false,
  getFeedback_Error: false,
  getFeedback_Success: false,
  getFeedback_Details: null,

  feedbackPost_Loading: false,
  feedbackPost_Error: false,
  feedbackPost_Success: false,
  feedbackPost_Details: null,
};

export const getFeedback = createAsyncThunk(
  "/user/getFeedback",
  (data: string) => {
    return GetFeedback(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data.customerFeedback;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);

export const feedbackPost = createAsyncThunk(
  "user/feedbackPost",
  (data: any) => {
    return FeedbackPost(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);

export const FeedbackSclice = createSlice({
  name: "Feedback",
  initialState,
  reducers: {
    reset: () => initialState,
    setGetFeedback: (state, action: PayloadAction<any>) => {
      return { ...state, getFeedback_Details: action.payload };
    },
    setfeedbackPost: (state, action: PayloadAction<any>) => {
      return { ...state, feedbackPost_Details: action.payload };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getFeedback.pending, (state) => {
      state.getFeedback_Loading = true;
      state.getFeedback_Success = false;
      state.getFeedback_Error = false;
    });

    builder.addCase(
      getFeedback.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getFeedback_Loading = false;
        state.getFeedback_Success = true;
        state.getFeedback_Error = false;
        state.getFeedback_Details = action?.payload;
      }
    );

    builder.addCase(getFeedback.rejected, (state, action: AnyAction) => {
      state.getFeedback_Loading = false;
      state.getFeedback_Error = false;
      state.getFeedback_Success = true;
      state.getFeedback_Error =
        action?.error?.message || "Something went wrong";
    });

    // postFeedback

    builder.addCase(feedbackPost.pending, (state) => {
      state.feedbackPost_Loading = true;
      state.feedbackPost_Success = false;
      state.feedbackPost_Error = false;
    });

    builder.addCase(
      feedbackPost.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.feedbackPost_Loading = false;
        state.feedbackPost_Success = true;
        state.feedbackPost_Error = false;
        state.feedbackPost_Details = action?.payload;
      }
    );

    builder.addCase(feedbackPost.rejected, (state, action: AnyAction) => {
      state.feedbackPost_Loading = false;
      state.feedbackPost_Error = false;
      state.feedbackPost_Success = true;
      state.feedbackPost_Error =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default FeedbackSclice.reducer;
export const { reset, setGetFeedback, setfeedbackPost } =
  FeedbackSclice.actions;
