import CloseIcon from "@mui/icons-material/Close";
import { IconButton, OutlinedInput, SnackbarOrigin } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../utils/helper.utils";
import ButtonLog from "../../../../common/Button/Button";
import CustomLoader from "../../../../common/Loader/Loader";
import { SnackBar } from "../../../../common/SnackBar/SnackBar";
import { CurrencyFormProps } from "../../container/currencyContainer";
import styles from "../Curreny.module.css";
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function CurrencyForm(props: CurrencyFormProps) {
  const {
    CreateCurrency,
    UpdateCurrency,
    createCurrency_Details,
    createCurrency_Error,
    createCurrency_Loading,
    createCurrency_Success,
    resetAll,
    updateCurrency_Details,
    updateCurrency_Error,
    updateCurrency_Loading,
    updateCurrency_Success,
  } = props;
  const navigate = useNavigate();

  const [formType, setFormType] = React.useState<string | undefined>(
    "currency-form/add"
  );
  let param = useParams();
  const { state } = useLocation();

  // let userDataParse: { id: string; company_id: number } = {
  //   id: "",
  //   company_id: 0,
  // };

  // const userData: any = localStorage.getItem("USER_DATA");
  const userDataParse = getUserDataFromLocalStorage();

  const [currency, setCurrency] = React.useState({
    name: state ? state?.currency.name : "",
    code: state ? state?.currency.code : "",
    exchange_rate: state ? state?.currency.exchange_rate : "",
    symbol: state ? state?.currency.symbol : "",
  });

  const [checked, setChecked] = React.useState(
    state ? state?.currency.is_active : true
  );

  const handleChange = (event: any) => {
    const updatedValue = event.target.checked;
    setChecked(updatedValue);
  };
  useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "error",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: currency.name,
      errorMsz:
        "Currency name is required and must be of maximum 30 characters",
      max: 30,
      min: 1,
    },

    {
      checkwith: "min",
      value: currency.code,
      errorMsz: "Code cannot be empty",
      min: 1,
    },
    {
      checkwith: "min",
      value: currency.exchange_rate,
      errorMsz: "Exchange rate cannot be empty",
      min: 1,
    },
    {
      checkwith: "min",
      value: currency.symbol,
      errorMsz: "Symbol cannot be empty",
      min: 1,
    },
  ];
  const handleSave = () => {
    validate(ValidateData).status
      ? CreateCurrency({
          ...currency,
          created_by: userDataParse.id,
          // companyId: userDataParse.company_id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };

  const handleEdit = () => {
    validate(ValidateData).status
      ? UpdateCurrency({
          ...currency,
          // is_active: checked ? true : false,
          currency_id: state.currency.id,
          updated_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };
  const [userType, setUserType] = React.useState("");
  // const handleChange = (event: SelectChangeEvent) => {
  //   setUserType(event.target.value as string);
  // };

  React.useEffect(() => {
    if (createCurrency_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createCurrency_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAll();
    } else if (createCurrency_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          createCurrency_Details ? createCurrency_Details : createCurrency_Error
        ),
        snackOpen: true,
        snackSeverity: createCurrency_Details ? "success" : "error",
      }));
      resetAll();
    }
  }, [
    createCurrency_Details,
    createCurrency_Error,
    createCurrency_Success,
    navigate,
    resetAll,
  ]);

  React.useEffect(() => {
    if (updateCurrency_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateCurrency_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAll();
    } else if (updateCurrency_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          updateCurrency_Details
            ? updateCurrency_Details
            : "Something went wrong"
        ),
        snackOpen: true,
        snackSeverity: updateCurrency_Details ? "success" : "error",
      }));
      resetAll();
    }
  }, [
    navigate,
    resetAll,
    updateCurrency_Details,
    updateCurrency_Error,
    updateCurrency_Success,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {createCurrency_Loading || updateCurrency_Loading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>
            {formType === "currency-form/add"
              ? "Add Currency"
              : "Edit Currency"}
          </p>
        </div>
        <div className="container mt-5" style={{ backgroundColor: "#F5F5F5;" }}>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Name
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Name"
                  type="text"
                  onChange={(e) => {
                    setCurrency((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  value={currency?.name}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Code
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Code"
                  type="text"
                  onChange={(e) => {
                    setCurrency((prevState) => ({
                      ...prevState,
                      code: e.target.value,
                    }));
                  }}
                  value={currency?.code}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Exchange Rate
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Exchange Rate"
                  type="number"
                  onChange={(e) => {
                    setCurrency((prevState) => ({
                      ...prevState,
                      exchange_rate: e.target.value,
                    }));
                  }}
                  value={currency?.exchange_rate}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Symbol
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Symbol"
                  type="text"
                  onChange={(e) => {
                    setCurrency((prevState) => ({
                      ...prevState,
                      symbol: e.target.value,
                    }));
                  }}
                  value={currency?.symbol}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              {/* {formType === "currency-form/edit" && (
                <>
                  <FormControl
                    className={styles.formControl}
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <span className={styles.label}>
                      Status
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="Active"
                      // onChange={}
                    />
                  </FormControl>
                </>
              )} */}
            </div>
          </div>

          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              variant={"contained"}
              className={styles.saveBtn}
              onClick={
                formType === "currency-form/edit" ? handleEdit : handleSave
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
