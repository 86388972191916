import { axios } from "../../../../api/setup.intersepter";
import { UpdatePaymentDT } from "./types";

export const GetAllPaymentRequest = (id: string) => {
  return axios({
    method: "GET",
    url: `/payment/${id}`,
  });
};

export const UpdatePaymentRequest = (body: UpdatePaymentDT) => {
  return axios({
    method: "PUT",
    url: `/payment/${body.payment_id}`,
    data: {
      status: body.status,
      payment_method: body.payment_method,
      remarks: body.remarks,
    },
  });
};
