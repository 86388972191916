import { Button } from "@mui/material";

type ButtonType = "submit";

interface buttonProps {
  disabled?: boolean;
  text: any;
  variant: any;
  endIcon?: any;
  startIcon?: any;
  type?: ButtonType;
  className?: string;
  // color:any,
  onClick?: () => void;
  // value: string,
  // error?: boolean,
  // id: string,
  // icon?: any;
}

export default function ButtonLog(props: buttonProps) {
  const {
    text,
    variant,
    endIcon,
    type,
    className,
    onClick,
    startIcon,
    disabled,
  } = props;
  return (
    <div>
      <Button
        sx={{
          borderRadius: "0px !important",
          boxShadow: "none !important",
          padding: "8px",
        }}
        fullWidth
        endIcon={endIcon}
        onClick={onClick}
        type={type}
        variant={variant}
        className={className}
        startIcon={startIcon}
        disabled={disabled}
      >
        {text}
      </Button>
    </div>
  );
}
