import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { ExpensesLog } from "../components/ExpensesLog";
import { getAllExpensesLog, reset } from "../services/expensesLogSlice";
import { ExpensesLogRes } from "../services/types";

interface StateProps {
  getAllExpensesLog_loading: boolean;
  getAllExpensesLog_error: boolean;
  getAllExpensesLog_success: boolean;
  getAllExpensesLog_details: ExpensesLogRes[] | null;
}

interface DispatchProps {
  GetAllExpensesLog(id: string): void;
  ResetAll(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getAllExpensesLog_success: state.expensesLog.getAllExpensesLog_success,
    getAllExpensesLog_error: state.expensesLog.getAllExpensesLog_error,
    getAllExpensesLog_loading: state.expensesLog.getAllExpensesLog_loading,
    getAllExpensesLog_details: state.expensesLog.getAllExpensesLog_details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllExpensesLog(id) {
      dispatch(getAllExpensesLog(id));
    },
    ResetAll() {
      dispatch(reset());
    },
  };
};

export interface ExpensesLogProps extends StateProps, DispatchProps {}
export const ExpensesLogComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpensesLog);
