import { Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import bayIcon from "../../../assets/images/Bay.png";
import iconProduct from "../../../assets/images/Layer_1.png";
import iconproductcate from "../../../assets/images/Layer_2.png";
import iconservice from "../../../assets/images/Layer_3.png";
import iconservicecate from "../../../assets/images/Layer_4.png";
import iconuser from "../../../assets/images/Layer_5.png";
import currency from "../../../assets/images/currency.png";
import supplier from "../../../assets/images/supplier.png";
import userIcon from "../../../assets/images/user.png";
import { getAuthDataFromLocalStorage } from "../../../utils/helper.utils";
import styles from "./masterList.module.css";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  background: "white",
  width: 159,
  height: 159,
  borderRadius: 0,
  boxShadow: "none",
  "&:hover": {
    bgcolor: "green",
  },
}));

interface Route {
  route: string;
  name: string;
  icon: string;
}
interface Category {
  name: string;
  functions: string[];
}
const MasterList: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = React.useState<string | undefined>();
  const [route, setRoute] = React.useState<Route[]>([]);
  const authData = getAuthDataFromLocalStorage();
  React.useEffect(() => {
    const userData: string | null = localStorage.getItem("USER_DATA");
    if (userData) {
      const userDataParse: { user_type: string } = JSON.parse(userData);
      setUser(userDataParse.user_type);
    }
  }, []);
  type RoleRoutes = {
    [key: string]: Route[];
  };

  const roleRoutes: RoleRoutes = {
    Owner: [
      // {
      //   route: "/subscription-plan",
      //   name: "Subscription Plan",
      //   icon: sub_plan,
      // },
      {
        route: "/partner",
        name: "Partner",
        icon: iconuser,
      },
      {
        route: "/role",
        name: "Role",
        icon: userIcon,
      },
      {
        route: "/role-creation",
        name: "Role Create",
        icon: userIcon,
      },
      {
        route: "/currency",
        name: "Currency",
        icon: currency,
      },
    ],
    Partner: [
      {
        route: "/users",
        name: "Users",
        icon: iconuser,
      },
    ],
    Supervisor: [
      {
        route: "/products",
        name: "Products",
        icon: iconProduct,
      },
      {
        route: "/product-category",
        name: "Product Category",
        icon: iconproductcate,
      },
      {
        route: "/services",
        name: "Service",
        icon: iconservice,
      },
      {
        route: "/service-category",
        name: "Service Category",
        icon: iconservicecate,
      },
      {
        route: "/suppliers",
        name: "Suppliers",
        icon: supplier,
      },
      {
        route: "/bays",
        name: "Bays",
        icon: bayIcon,
      },
    ],
  };

  useEffect(() => {
    if (user && roleRoutes.hasOwnProperty(user)) {
      const routes: Route[] = roleRoutes[user];
      setRoute(routes);
    }
  }, [user]);

  const routes: Route[] = [
    {
      route: "/products",
      name: "Product",
      icon: iconProduct,
    },
    {
      route: "/product-category",
      name: "Product Category",
      icon: iconproductcate,
    },
    {
      route: "/services",
      name: "Service",
      icon: iconservice,
    },
    {
      route: "/service-category",
      name: "Service Category",
      icon: iconservicecate,
    },
    {
      route: "/users",
      name: "User",
      icon: iconuser,
    },
    {
      route: "/role",
      name: "Role",
      icon: userIcon,
    },
    {
      route: "/partner",
      name: "Partner",
      icon: iconuser,
    },
    {
      route: "/suppliers",
      name: "Suppliers",
      icon: supplier,
    },
    {
      route: "/bays",
      name: "MasterBay",
      icon: bayIcon,
    },
  ];
  const functions: string[] = authData?.category
    ?.filter((cat: Category) => cat.name === "Masters")
    .flatMap((cat: Category) => cat.functions);

  const filteredRoutes: Route[] = routes?.filter((route) =>
    functions?.includes(route.name)
  );
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Box className={styles.root_container}>
          <div className="container">
            <div className="row mb-5 mt-5">
              {user === "Owner" ? (
                <>
                  {route.map((ele: Route, index: number) => (
                    <div
                      key={index}
                      className="col-lg-2 col-md-3 col-sm-4 col-xs-6 "
                    >
                      <Item
                        onClick={() => {
                          navigate(ele.route);
                        }}
                        style={{
                          cursor: "pointer",
                          marginBottom: "20px",
                          width: "100%",
                        }}
                      >
                        <div className={styles.image_root}>
                          <img src={ele.icon} height="60" width="60" alt="" />
                        </div>
                        <div className={styles.root_card}>
                          <p className={styles.card_title}>{ele.name}</p>
                        </div>
                      </Item>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {filteredRoutes.map((ele: Route, index: number) => (
                    <div
                      key={index}
                      className="col-lg-2 col-md-3 col-sm-4 col-xs-6 "
                    >
                      <Item
                        onClick={() => {
                          navigate(ele.route);
                        }}
                        style={{
                          cursor: "pointer",
                          marginBottom: "20px",
                          width: "100%",
                        }}
                      >
                        <div className={styles.image_root}>
                          <img src={ele.icon} height="60" width="60" alt="" />
                        </div>
                        <div className={styles.root_card}>
                          <p className={styles.card_title}>{ele.name}</p>
                        </div>
                      </Item>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </Box>
        <div className={styles.footer_root}></div>
      </Box>
      {/* <div className={styles.footerMainPosition}>
        <Footer />
      </div> */}
    </>
  );
};

export default MasterList;
