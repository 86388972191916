import { useEffect } from "react";
import ServiceInventory from "../../../../../components/appSpecific/ServiceInventory/components/ServiceInventory";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { getUserDataFromLocalStorage } from "../../../../../utils/helper.utils";
import { ConfigurationProps } from "../../container/configuartionContainer";
export default function ServicesPage(props: ConfigurationProps) {
  const { loading, GetAllService, serviceDetails, getAllServiceLoading } =
    props;
  const userData = getUserDataFromLocalStorage();
  useEffect(() => {
    GetAllService(userData?.partner_id);
  }, [GetAllService, userData?.partner_id]);

  return (
    <>
      {loading || getAllServiceLoading ? (
        <CustomLoader />
      ) : (
        <ServiceInventory serviceDetails={serviceDetails} />
      )}
    </>
  );
}
