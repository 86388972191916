import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import Login from "../components/Login";
import { login, reset } from "../service/loginSlice";
import { LoginDTS } from "../service/types";
// import {login, reset} from '../services/loginSlice';
// import {LoginDTS} from './../services/types.d';

interface StateProps {
  isLogin: boolean;
  error: string | boolean | undefined;
  loading: boolean;
}

interface DispatchProps {
  userLogin(data: LoginDTS): void;
  resetLoginStates(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    isLogin: state.login.isLogin,
    error: state.login.error,
    loading: state.login.loading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    userLogin: (data: any) => {
      dispatch(login(data));
    },
    resetLoginStates: () => {
      dispatch(reset());
    },
  };
};

export interface LoginProps extends StateProps, DispatchProps {}
export const LoginComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
