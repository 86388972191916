import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import userComplain from "../../../assets/images/complaints.svg";
import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";

interface ComplaintsListProps {
  assignTech: { id: string; label: string }[];
  item: { complaint: string };
  handleChange: (index: number, selectedID: string) => void;
  index: number;
  values: any;
}

export function ComplaintsList(props: ComplaintsListProps) {
  const { assignTech, item, handleChange, index, values } = props;

  const data = assignTech.map((ele) => {
    return { label: ele.label, value: ele.id };
  });

  const handleChangeLocal = (event: SelectChangeEvent<string>) => {
    const selectedID = event.target.value;
    handleChange(index, selectedID);
  };

  return (
    <div className="col-lg-2 col-md-3 col-sm-4 col-xs-12">
      <div className={styles.spares_wrapper}>
        <div className="d-flex justify-content-center">
          <div className={styles.img_wrapper}>
            <img
              src={userComplain}
              alt="pic"
              width={56}
              height={56}
              className="img-fluid"
            />
          </div>
        </div>
        <p className={styles.spares_name}>{item.complaint}</p>
        <div className=" ">
          <p className={styles.spares_cost}>
            Customer Complaint
            {/* Rs.{item.Price}/- */}
            <br />
            <br />
          </p>
        </div>
        <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
          <InputLabel
            sx={{ fontSize: "0.8em" }}
            id="demo-simple-select-standard-label"
          >
            {values[index] ? "Technician" : "Select Technician"}
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={values[index]}
            onChange={handleChangeLocal}
          >
            <MenuItem disabled value="">
              <em>Select Technician</em>
            </MenuItem>

            {data.map((ele) => (
              <MenuItem key={ele.value} value={ele.value}>
                {ele.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
