import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { GetNoOfUser } from "./ownerDashboard.request";
import { GetNoOfUserState } from "./types";

export const initialState: GetNoOfUserState = {
  getNoOfUser_Loading: false,
  getNoOfUser_Error: false,
  getNoOfUser_Success: false,
  getNoOfUser_Details: null,
};

export const getNoOfUser = createAsyncThunk("user/getNoOfUser", () => {
  return GetNoOfUser()
    .then((res) => {
      if (res.status === 200) {
        return res.data[0];
      } else {
        throw new Error("Something went wrong");
      }
    })
    .catch((error) => {
      console.log(error);
      throw new Error("Something went wrong");
    });
});

export const adminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getNoOfUser.pending, (state) => {
      state.getNoOfUser_Loading = true;
      state.getNoOfUser_Error = false;
      state.getNoOfUser_Success = false;
    });
    builder.addCase(
      getNoOfUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getNoOfUser_Loading = false;
        state.getNoOfUser_Error = false;
        state.getNoOfUser_Success = true;
        state.getNoOfUser_Details = action?.payload;
      }
    );
    builder.addCase(getNoOfUser.rejected, (state, action: AnyAction) => {
      state.getNoOfUser_Loading = false;
      state.getNoOfUser_Error = false;
      state.getNoOfUser_Success = false;
      state.getNoOfUser_Error =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default adminDashboardSlice.reducer;
export const { reset } = adminDashboardSlice.actions;
