import { Card } from "@mui/material";
import SUV from "../../../assets/images/SUV_black.svg";
import Truck from "../../../assets/images/Truck.svg";
import Bike from "../../../assets/images/bike_black.svg";
import Hatchback from "../../../assets/images/hatchBack_black.svg";
import MiniTruck from "../../../assets/images/miniTruck_black.svg";
import SedanBlack from "../../../assets/images/sadan_black.svg";
import Scooter from "../../../assets/images/scooter-black.svg";
import Progress from "../../../pages/app/DashbordNew/Progresser/Progress";
import JobCard from "../../../pages/app/DashbordNew/components/JobCard";
import ServicesCard from "../../../pages/app/DashbordNew/components/ServicesCard";
import { useGetJobcardDashboardInfor } from "../../../pages/app/DashbordNew/service/dashboard.request";
import { SparePartRes } from "../../../pages/app/DashbordNew/service/types";
import { getUserDataFromLocalStorage } from "../../../utils/helper.utils";
import styles from "./Opertions.module.css";

const vehicleTypeMeta = {
  sedan: {
    img: SedanBlack,
    name: "Sedan",
  },
  hatchback: {
    img: Hatchback,
    name: "Hatch Back",
  },
  truck: {
    img: Truck,
    name: "Truck",
  },
  suv: {
    img: SUV,
    name: "SUV",
  },
  miniTruck: {
    img: MiniTruck,
    name: "Mini truck",
  },
  bike: {
    img: Bike,
    name: "Bike",
  },
  scooter: {
    img: Scooter,
    name: "Scooter",
  },
};
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    // title: {
    //   display: true,
    //   text: "Chart.js Bar Chart",
    // },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const datas = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => Math.random()),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => Math.random()),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
const mechanicReviewColumns = [
  { id: "mechanicName", label: "Mechanic", minWidth: 100 },
  { id: "rating", label: "Rating", minWidth: 100 },
  { id: "comment", label: "Comment", minWidth: 100 },
];

// Columns for Supervisor Reviews
const supervisorReviewColumns = [
  { id: "supervisorName", label: "Supervisor", minWidth: 100 },
  { id: "rating", label: "Rating", minWidth: 100 },
  { id: "comment", label: "Comment", minWidth: 100 },
];

// Columns for Customer Feedback
const customerFeedbackColumns = [
  { id: "customerName", label: "Customer", minWidth: 100 },
  { id: "rating", label: "Rating", minWidth: 100 },
  { id: "comment", label: "Comment", minWidth: 100 },
];
const mechanicReviews = [
  {
    id: 1,
    mechanicName: "John Doe",
    rating: 4.5,
    comment: "Great service, very knowledgeable mechanic.",
  },
  {
    id: 2,
    mechanicName: "Jane Smith",
    rating: 3.8,
    comment: "Fixed the issue quickly, but could communicate better.",
  },
  // ... more mechanic reviews
];

const supervisorReviews = [
  {
    id: 1,
    supervisorName: "Mike Johnson",
    rating: 4.7,
    comment: "Supervisor was very helpful and responsive.",
  },
  {
    id: 2,
    supervisorName: "Emily Brown",
    rating: 4.2,
    comment: "Guided the team well, but had some delays.",
  },
  // ... more supervisor reviews
];

const customerFeedback = [
  {
    id: 1,
    customerName: "Alice Williams",
    rating: 5.0,
    comment: "Extremely satisfied with the service!",
  },
  {
    id: 2,
    customerName: "Bob Anderson",
    rating: 3.5,
    comment: "Average experience, could improve in certain areas.",
  },
  // ... more customer feedback
];

export default function Operations() {
  const userData: any = getUserDataFromLocalStorage();

  const { data } = useGetJobcardDashboardInfor(
    userData?.partner_id
    // getDatetimeRangeByFilterMode()
  );
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <Card variant="outlined" className={styles.root_jobs}>
            <div className={styles.job_headbar}>
              <div>
                <p className={styles.txt_job}> Jobs</p>
              </div>
            </div>
            <JobCard
              inProgress={data?.inProgressCount}
              ready={data?.readyCount}
              pending={data?.pendingCount}
              delivered={data?.completeCount}
            />
          </Card>
        </div>

        <div className="col-md-6">
          <Card variant="outlined" className={styles.DashboardThirdDivRoot}>
            <div className={styles.SuccessRate}>
              <div className={styles.job_headbar}>
                <div>
                  <p className={styles.txt_job}> Success Rate</p>
                </div>
              </div>
              <div className={styles.progress_root}>
                <Progress
                  percentage={
                    !isNaN(data?.successRate) ? parseInt(data?.successRate) : 0
                  }
                />
              </div>
              <div className={styles.progress_title_txt}>
                <p className={styles.progress_title_txt_root}>Success Rate</p>
              </div>
            </div>
            <div className={styles.TopThreeVehicle}>
              <p className={styles.txt_served_vec}>Top Served Vehicle Type</p>
              <div className={styles.root_vec}>
                {data?.topThreeVehicleTypes?.map((item: any, id: number) => (
                  <>
                    <div className={styles.veiclename} key={id}>
                      <div>
                        <img
                          src={
                            vehicleTypeMeta[
                              item as keyof typeof vehicleTypeMeta
                            ]?.img ?? null
                          }
                          alt=""
                          width="20"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <p className={styles.veicl_name_txt}>{item}</p>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </Card>
        </div>
        {/* <div className="col-md-4">
          <div className="row">
            <div className="col-md-12">
              <Card variant="outlined" className={styles.TotalVehicleHead}>
                <div className={styles.job_headbar}>
                  <div>
                    <p className={styles.txt_job}>Stock In-Out reports</p>
                  </div>
                </div>
                <div>
                
                </div>
                <Bar options={options} data={datas} />
              
              </Card>
            </div>
          </div>
        </div> */}
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 mb-3">
              <Card variant="outlined">
                <div className={styles.job_headbar}>
                  <div>
                    <p className={styles.txt_job}> Top 3 Service Taken</p>
                  </div>
                </div>

                {data?.topThreeServiceNames?.map((item: any, index: number) => (
                  <ServicesCard
                    key={index}
                    data={item}
                    time_count={Math.floor(Math.random() * 10 + 1)}
                  />
                ))}
              </Card>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 mb-3">
              <Card variant="outlined">
                <div className={styles.job_headbar}>
                  <div>
                    <p className={styles.txt_job}> Top 3 Spare Parts Taken</p>
                  </div>
                </div>

                {data?.topThreeProductNames?.map(
                  (item: SparePartRes, index: number) => (
                    <ServicesCard
                      key={index}
                      data={item}
                      time_count={Math.floor(Math.random() * 10 + 1)}
                    />
                  )
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
