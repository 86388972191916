import { axios } from "../../../../api/setup.intersepter";
import { SpareReqDT } from "../components/MechanicPendingtask/service/types";
import {
  ChangeStatusOfServiceProps,
  GetMechJobDT,
  JobCardCreateSpares,
  SelectBayByMechProps,
} from "./type";

// const getAllMechJobs = (id: string | undefined) => {
//   return axios.get("/view-mechanic-jobs/" + id);
// };

// export { getAllMechJobs };
export const getAllMechJobs = (body: GetMechJobDT) => {
  return axios({
    method: "GET",
    url: `/jobcard-tasks/mechanic/${body.id}?partner_id=${body.partner_id}`,
  });
};

export const statusOfService = (body: ChangeStatusOfServiceProps) => {
  return axios({
    method: "PUT",
    url: `/jobcard-tasks/${body.id}`,
    data: {
      jobcardTask: {
        status: body.status,
        start_time: body.starttime,
        end_time: body.endtime,
        updated_by: body.updatedBy,
        Jobcard_id: body.Jobcard_id,
      },
    },
  });
};
export const SelectBayByMech = (body: SelectBayByMechProps) => {
  return axios({
    method: "PUT",
    url: `/jobcard-tasks/updateBay/${body.jobcardTask.jobcard_id}`,
    data: {
      jobcardTask: {
        bay: body.jobcardTask.bay,
      },
    },
  });
};
export const RequestSparePart = (body: SpareReqDT) => {
  return axios({
    method: "POST",
    url: `/sparepart-request`,
    data: body,
  });
};
export const ReturnSparePart = (data: JobCardCreateSpares) => {
  return axios({
    method: "POST",
    url: `jobcard-spare-parts/add-inventory`,
    data: data,
  });
};
