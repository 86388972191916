import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CloseCircle } from "iconsax-react";
import moment from "moment";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Garage from "../../../../assets/images/GarageReport.svg";
import jobDuration from "../../../../assets/images/JobDurationReport.svg";
import Mechanic from "../../../../assets/images/Mechanics.svg";
import Revenue from "../../../../assets/images/RevenueReport.svg";
import SparePartReport from "../../../../assets/images/SparePartsReport.svg";
import TaskReport from "../../../../assets/images/TaskReport.svg";
import ButtonLog from "../../../../components/common/Button/Button";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";
import { ReportsProps } from "../containers/reportsContainer";
import styles from "./Reports.module.css";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  background: "white",
  width: 159,
  height: 159,
  borderRadius: 0,
  boxShadow: "none",
}));

const reports = [
  {
    icon: Garage,
    title: "Garage Report",
    route: "garage-report",
  },
  {
    icon: TaskReport,
    title: "Task Report",
    route: "task-report",
  },
  {
    icon: Revenue,
    title: "Revenue Report",
    route: "revenue-report",
  },
  {
    icon: SparePartReport,
    title: "Spare Parts Report",
    route: "spare-part-report",
  },
  {
    icon: Mechanic,
    title: "Mechanics Performance Report",
    route: "mechanics-performance-report",
  },
  {
    icon: jobDuration,
    title: "Job Duration Report",
    route: "job-duration-report",
  },
];

export default function Reports(props: ReportsProps) {
  const {} = props;
  const userData: any = getUserDataFromLocalStorage();

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Route, setRoute] = useState("");
  const [name, setName] = useState("");
  const [fromDate, setFromDate] = useState<moment.Moment>(moment());
  const [toDate, setToDate] = useState<moment.Moment>(moment());
  const [error, setError] = useState<boolean>(false);
  const handleFromDateChange = (newFromDate: moment.Moment | null) => {
    if (newFromDate) {
      setFromDate(newFromDate);
      if (newFromDate.isAfter(toDate)) {
        setToDate(newFromDate);
      }
      setError(newFromDate.isAfter(toDate));
    }
  };

  const handleToDateChange = (newToDate: moment.Moment | null) => {
    if (newToDate) {
      setToDate(newToDate);
      if (newToDate.isBefore(fromDate)) {
        setFromDate(newToDate);
      }
      setError(newToDate.isBefore(fromDate));
    }
  };
  const handleReport = useCallback((route: string, name: string) => {
    setRoute(route);
    setName(name);
    setIsModalOpen(true);
  }, []);
  //   const openModal = () => {};

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleGenerateReport = useCallback(() => {
    navigate(`/${Route}`, {
      state: {
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      },
    });
  }, [Route, fromDate, navigate, toDate]);
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Box className={styles.root_container}>
          <Grid container spacing={4}>
            {reports.map((ele: any, index: number) => {
              return (
                <Grid item key={index}>
                  <Item
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleReport(ele.route, ele.title)}
                  >
                    <div className={styles.image_root}>
                      <img src={ele.icon} height="60" width="60" alt="" />
                    </div>
                    <div className={styles.root_card}>
                      <p className={styles.card_title}>{ele.title}</p>
                    </div>
                  </Item>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",

            boxShadow: 24,
            p: 4,
          }}
        >
          <Box className={styles.btnClose}>
            <IconButton onClick={closeModal}>
              <CloseCircle size="32" color=" #273A44" variant="Outline" />
            </IconButton>
          </Box>
          <Box className={styles.modal_title}>
            <p>{name}</p>
          </Box>
          <FormControl
            size="small"
            sx={{
              width: "100%",
            }}
          >
            <span>From Date</span>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={fromDate}
                onChange={handleFromDateChange}
                format="DD/MM/YYYY"
              />
              {error && (
                <FormHelperText error>
                  From Date should not be later than To Date
                </FormHelperText>
              )}
            </LocalizationProvider>
          </FormControl>
          <FormControl
            size="small"
            sx={{
              width: "100%",
            }}
          >
            <span>To Date</span>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={toDate}
                onChange={handleToDateChange}
                format="DD/MM/YYYY"
              />
              {error && (
                <FormHelperText error>
                  To Date should not be earlier than From Date
                </FormHelperText>
              )}
            </LocalizationProvider>
          </FormControl>
          <Box className={styles.btn_group}>
            <Box className={styles.btn_standard}>
              <ButtonLog
                onClick={closeModal}
                text={"Cancel"}
                variant={"contained"}
                className={styles.cancle_btn}
              />
            </Box>
            <Box>
              <ButtonLog
                text={"Generate"}
                variant={"contained"}
                className={styles.addVehicleBtn}
                onClick={handleGenerateReport}
                disabled={error}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
