import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../../../components/common/Loader/Loader";
import CustomTable from "../../../../components/common/Table/CustomTable";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";
import { ArchiveJobCardProps as IProps } from "../container/archiveJobCardContainer";
import styles from "./ArchiveJobCard.module.css";

interface ArchiveJobCardProps extends IProps {}

export default function ArchiveJobCard(props: IProps) {
  const {
    GetAllArchiveJobCard,
    ResetAll,
    getAllArchiveJobCard_details,
    getAllArchiveJobCard_error,
    getAllArchiveJobCard_loading,
    getAllArchiveJobCard_success,
  } = props;
  const navigation = useNavigate();
  const userData: any = getUserDataFromLocalStorage();
  useEffect(() => {
    ResetAll();
    GetAllArchiveJobCard(userData.partner_id);
  }, [GetAllArchiveJobCard, ResetAll, userData.partner_id]);
  const columns = [
    { id: "sl", label: "Sl No.", minWidth: 100 },
    { id: "vehicle_number", label: "Vehicle Number", minWidth: 100 },
    { id: "vehicle_name", label: "Vehicle Name", minWidth: 100 },
    // { id: "model_number", label: "Model", minWidth: 100 },
    { id: "customer_name", label: "Customer Name", minWidth: 100 },
    { id: "email", label: "Email", minWidth: 100 },
    { id: "phone_number", label: "Phone Number", minWidth: 100 },
    // { id: "action", label: "Action", minWidth: 100 },
  ];

  const updatedRows =
    Array.isArray(getAllArchiveJobCard_details) &&
    getAllArchiveJobCard_details?.map((row, index: number) => ({
      sl: index + 1,
      vehicle_number: row.vehicle.vehicle_number,
      vehicle_name: row.vehicle.vehicle_name,
      model_number: row.vehicle.model_number,
      customer_name: row.customer.customer_name,
      email: row.customer.email,
      phone_number: row.customer.phone_number,
    }));
  return (
    <>
      {getAllArchiveJobCard_loading ? <CustomLoader /> : null}

      <div className={styles.inventoryNavBar}>
        <div className={styles.filter}>
          <p className={styles.txt_title}>Archive Job Card</p>
        </div>
      </div>

      <div
        className="container-fluid"
        style={{ paddingTop: "20px", paddingBottom: "100px" }}
      >
        <CustomTable columns={columns} rows={updatedRows ? updatedRows : []} />
      </div>
    </>
  );
}
