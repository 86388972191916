import CloseIcon from "@mui/icons-material/Close";
import {
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  SnackbarOrigin,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useCallback, useEffect, useState } from "react";
import ButtonLog from "../../../../components/common/Button/Button";
import CustomLoader from "../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../components/common/SnackBar/SnackBar";
import CustomTable from "../../../../components/common/Table/CustomTable";
import {
  getAuthDataFromLocalStorage,
  getUserDataFromLocalStorage,
  isFunctionAllowed,
} from "../../../../utils/helper.utils";
import { PaymentPageProps } from "../container/paymentContainer";
import { PaymentRes, UpdatePaymentDT } from "../service/types";
import styles from "./Payment.module.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function PaymentPage(props: PaymentPageProps) {
  const {
    GetAllPaymentRequest,
    getAllPaymentRequest_Error,
    getAllPaymentRequest_Loading,
    getAllPaymentRequest_Success,
    getAllPaymentRequest_details,
    UpdatePaymentRequest,
    updatePaymentRequest_Error,
    updatePaymentRequest_Loading,
    updatePaymentRequest_Success,
    updatePaymentRequest_details,
    ResetAll,
  } = props;
  const userData: any = getUserDataFromLocalStorage();
  const authData = getAuthDataFromLocalStorage();

  useEffect(() => {
    ResetAll();
    GetAllPaymentRequest(userData?.partner_id);
  }, [
    GetAllPaymentRequest,
    userData?.partner_id,
    updatePaymentRequest_Success,
    updatePaymentRequest_Error,
    ResetAll,
  ]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong. Please try again.",
    snackSeverity: "error",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState("");
  const [payment, setPayment] = useState({
    payment_id: "",
    payment_method: "",
    remarks: "",
  });
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpen1 = useCallback(() => setOpenUpdate(true), []);
  const handleClose1 = useCallback(() => setOpenUpdate(false), []);

  const handleChange = useCallback((event: SelectChangeEvent) => {
    setAge(event.target.value);
  }, []);
  const columns = [
    { id: "sl", label: "Serial No.", minWidth: 100 },
    { id: "customer_name", label: "Customer Name", minWidth: 100 },
    { id: "vehicle_number", label: "Vehicle Number", minWidth: 100 },
    { id: "contact", label: "Contact", minWidth: 100 },
    { id: "amount", label: "Amount", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "payment_method", label: "Payment Method", minWidth: 100 },
    { id: "remarks", label: "Remarks", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleMarkasPaidClick = (row: PaymentRes) => {
    setPayment((prevState) => ({
      ...prevState,
      payment_id: row.jobCard_id,
      // payment_method: row.payment_method,
    }));
    handleOpen();
  };
  const handleUpdateClick = (row: PaymentRes) => {
    handleOpen1();
    setPayment((prevState) => ({
      ...prevState,
      payment_id: row.id,
      payment_method: row.payment_method,
    }));
  };
  const updatedRows = getAllPaymentRequest_details?.map(
    (row: PaymentRes, index: number) => ({
      ...row,
      sl: index + 1,
      status:
        row.status === "Paid" ? (
          <Chip label="Paid" variant="outlined" color="info" />
        ) : (
          <Chip label="Unpaid" variant="outlined" color="error" />
        ),
      action:
        row.status === "Unpaid" ? (
          <>
            {isFunctionAllowed(authData, "Payment", "Update") ? (
              <>
                <ButtonLog
                  text={"Mark as Paid"}
                  variant={"outlined"}
                  className={styles.paidbtn}
                  onClick={() => handleMarkasPaidClick(row)}
                />
              </>
            ) : null}
          </>
        ) : (
          <>N/A</>
        ),
    })
  );
  const handlePaymentMethod = useCallback(() => {
    const data: UpdatePaymentDT = {
      status: "Paid",
      payment_method: payment.payment_method,
      remarks: payment.remarks,
      payment_id: payment.payment_id,
    };

    handleClose();
    setPayment({
      payment_id: "",
      payment_method: "",
      remarks: "",
    });
    UpdatePaymentRequest(data);
  }, [
    UpdatePaymentRequest,
    handleClose,
    payment.payment_id,
    payment.payment_method,
    payment.remarks,
  ]);
  const handleUpdatePayment = useCallback(() => {
    const data: UpdatePaymentDT = {
      status: "Complete",
      payment_method: payment.payment_method,
      remarks: payment.remarks,
      payment_id: payment.payment_id,
    };

    handleClose1();
    UpdatePaymentRequest(data);
  }, [
    UpdatePaymentRequest,
    handleClose1,
    payment.payment_id,
    payment.payment_method,
    payment.remarks,
  ]);
  React.useEffect(() => {
    if (
      updatePaymentRequest_Error &&
      typeof updatePaymentRequest_Error === "string" &&
      updatePaymentRequest_Error !== "false"
    ) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: updatePaymentRequest_Error
          ? updatePaymentRequest_Error
          : "Something went wrong!",
        snackOpen: true,
      }));
    }
    // ResetAll();
  }, [updatePaymentRequest_Error]);
  React.useEffect(() => {
    if (updatePaymentRequest_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: updatePaymentRequest_details
          ? updatePaymentRequest_details
          : "Successfully Updated",
        snackOpen: true,
        snackSeverity: "success",
      }));
      // ResetAll();
    }
  }, [updatePaymentRequest_Success, updatePaymentRequest_details]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {getAllPaymentRequest_Loading || updatePaymentRequest_Loading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.inventoryNavBar}>
        <div className={styles.filter}>
          <p className={styles.txt_title}>Payments</p>
        </div>
      </div>

      <div
        className="container-fluid"
        style={{ paddingTop: "20px", paddingBottom: "100px" }}
      >
        <CustomTable columns={columns} rows={updatedRows} />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={styles.paymentModal}
      >
        <Box sx={style} className={styles.sparerequestModal}>
          <div className={styles.title_modal}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Mark as Paid
            </Typography>
          </div>
          <div className={styles.form_root}>
            <FormControl sx={{ m: 1, width: "95%" }}>
              <span className={styles.label}>Select Payment Method</span>
              <Select
                value={payment.payment_method}
                onChange={(e) => {
                  setPayment((prevState) => ({
                    ...prevState,
                    payment_method: e.target.value,
                  }));
                }}
                inputProps={{
                  "aria-label": "Without label",
                  style: {
                    borderRadius: "0px",
                  },
                }}
                className={styles.select_filed}
              >
                <MenuItem value="" disabled>
                  <em className={styles.placeholder_payment}>
                    Select Payment Method
                  </em>
                </MenuItem>
                <MenuItem value={"cash"}>Cash</MenuItem>
                <MenuItem value={"credit_card"}>Credit Card</MenuItem>
                <MenuItem value={"debit_card"}>Debit Card</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              className={styles.formControl}
              size="small"
              sx={{
                width: "100%",
              }}
            >
              <span className={styles.label_}>Enter Remarks</span>
              <div className="container">
                <form>
                  <TextField
                    value={payment.remarks}
                    onChange={(e) => {
                      setPayment((prevState) => ({
                        ...prevState,
                        remarks: e.target.value,
                      }));
                    }}
                    fullWidth
                    placeholder="Enter Remarks"
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                      },
                    }}
                  />
                </form>
              </div>
            </FormControl>
          </div>
          <div className={styles.btn_modal_group}>
            <div>
              <ButtonLog
                text={"Cancel"}
                variant={"outlined"}
                className={styles.cancelbtn}
                onClick={handleClose}
              />
            </div>

            <div>
              <div>
                <ButtonLog
                  text={"Mark as Paid"}
                  variant="contained"
                  className={styles.btn_modal}
                  disabled={payment.payment_method === ""}
                  onClick={handlePaymentMethod}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openUpdate}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={styles.paymentModal}
      >
        <Box sx={style} className={styles.sparerequestModal}>
          <div className={styles.title_modal}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update
            </Typography>
          </div>
          <div className={styles.form_root}>
            <FormControl sx={{ m: 1, width: "95%" }}>
              <span className={styles.label}>Select Payment Method</span>
              <Select
                value={payment.payment_method}
                onChange={(e) => {
                  setPayment((prevState) => ({
                    ...prevState,
                    payment_method: e.target.value,
                  }));
                }}
                inputProps={{
                  "aria-label": "Without label",
                  style: {
                    borderRadius: "0px",
                  },
                }}
                className={styles.select_filed}
              >
                <MenuItem value={payment.payment_method} disabled>
                  <em className={styles.placeholder_payment}>
                    {payment.payment_method}
                  </em>
                </MenuItem>
                {/* <MenuItem value={10}>Method 1</MenuItem>
                <MenuItem value={20}>Method 2</MenuItem>
                <MenuItem value={30}>Method 3</MenuItem> */}
              </Select>
            </FormControl>

            <FormControl
              className={styles.formControl}
              size="small"
              sx={{
                width: "100%",
              }}
            >
              <span className={styles.label_}>Enter Remarks</span>
              <div className="container">
                <form>
                  <TextField
                    fullWidth
                    value={payment.remarks}
                    onChange={(e) => {
                      setPayment((prevState) => ({
                        ...prevState,
                        remarks: e.target.value,
                      }));
                    }}
                    placeholder="Enter Remarks"
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                      },
                    }}
                  />
                </form>
              </div>
            </FormControl>
          </div>
          <div className={styles.btn_modal_group}>
            <div>
              <ButtonLog
                text={"Cancel"}
                variant={"outlined"}
                className={styles.cancelbtn}
                onClick={handleClose1}
              />
            </div>

            <div>
              <div>
                <ButtonLog
                  text={"Submit"}
                  variant="contained"
                  className={styles.btn_modal}
                  onClick={handleUpdatePayment}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      {/* <div className={styles.footerMainPosition}>
        <Footer />
      </div> */}
    </>
  );
}
