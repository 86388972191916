import CloseIcon from "@mui/icons-material/Close";
import {
  FormControlLabel,
  IconButton,
  OutlinedInput,
  SnackbarOrigin,
  Switch,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../utils/helper.utils";
import ButtonLog from "../../../../common/Button/Button";
import CustomLoader from "../../../../common/Loader/Loader";
import { SnackBar } from "../../../../common/SnackBar/SnackBar";
import { ServiceCategoryProps } from "../../container/serviceCategoryContainer";
import styles from "../ServiceCategory.module.css";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
interface ServiceCategory {
  name: string;
  description: string;
  is_active?: boolean;
  createBy: string;
}
export default function ServiceCategoryForm(props: ServiceCategoryProps) {
  const {
    CreateServiceCate,
    createServiceCateError,
    createServiceCateLoading,
    createServiceCateDetails,
    createServiceCateSuccess,
    UpdateServiceCate,
    updateServiceCate_Details,
    updateServiceCate_Error,
    updateServiceCate_Loading,
    updateServiceCate_Success,
    resetAllcatagory,
  } = props;
  const navigate = useNavigate();
  const { state } = useLocation();

  // let userDataParse: { id: string } = { id: "" };

  // const userData: any = localStorage.getItem("USER_DATA");
  const userDataParse = getUserDataFromLocalStorage();
  const [checked, setChecked] = React.useState(
    state ? state?.service.is_active : true
  );

  const handleChange = (event: any) => {
    const updatedValue = event.target.checked;
    setChecked(updatedValue);
  };
  const [serviceCategory, setServiceCategory] = React.useState({
    name: state ? state?.service.name : "",
    description: state ? state?.service.description : "",
    slug: "partner_1",
    partner_id: userDataParse.partner_id,
    services: [],
  });
  const [formType, setFormType] = React.useState<string | undefined>(
    "services-category-form/add"
  );
  let param = useParams();
  React.useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: serviceCategory.name,
      errorMsz: "Category name must be of 1 and maximum 30 characters",
      max: 30,
      min: 1,
    },
    {
      checkwith: "minmax",
      value: serviceCategory.description,
      errorMsz:
        "Category description must be of minimum 1 and maximum 50 characters allowed",
      max: 50,
      min: 1,
    },
  ];
  const handleSave = () => {
    validate(ValidateData).status
      ? CreateServiceCate({
          ...serviceCategory,
          created_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };
  const handleEdit = () => {
    validate(ValidateData).status
      ? UpdateServiceCate({
          ...serviceCategory,
          IsActive: checked ? true : false,
          service_category_id: state.service.id,
          updated_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };

  React.useEffect(() => {
    if (createServiceCateError) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createServiceCateError),
        snackOpen: true,
      }));

      setTimeout(() => {
        navigate(-1);
      }, 300);
      resetAllcatagory();
    }
    if (
      createServiceCateSuccess &&
      createServiceCateDetails === "Name already exists"
    ) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createServiceCateDetails),
        snackOpen: true,
      }));
      setTimeout(() => {
        navigate(-1);
      }, 300);
      resetAllcatagory();
    } else if (createServiceCateSuccess) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createServiceCateDetails),
        snackOpen: true,
      }));
      setTimeout(() => {
        navigate(-1);
      }, 300);
      resetAllcatagory();
    }
  }, [
    resetAllcatagory,
    navigate,
    createServiceCateError,
    createServiceCateSuccess,
    createServiceCateDetails,
  ]);
  React.useEffect(() => {
    if (updateServiceCate_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateServiceCate_Error),
        snackOpen: true,
      }));
      setTimeout(() => {
        navigate(-1);
      });
      resetAllcatagory();
    }
    if (
      updateServiceCate_Success &&
      updateServiceCate_Details === "Name already exists"
    ) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateServiceCate_Details),
        snackOpen: true,
      }));
      setTimeout(() => {
        navigate(-1);
      }, 300);
      resetAllcatagory();
    } else if (updateServiceCate_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateServiceCate_Details),
        snackOpen: true,
      }));
      setTimeout(() => {
        navigate(-1);
      }, 300);
      resetAllcatagory();
    }
  }, [
    resetAllcatagory,
    navigate,
    updateServiceCate_Error,
    updateServiceCate_Success,
    updateServiceCate_Details,
  ]);

  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {createServiceCateLoading || updateServiceCate_Loading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>
            {formType === "services-category-form/add"
              ? "Add Service Category"
              : "Edit Service Category"}
          </p>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Service Category Name
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>

                <OutlinedInput
                  placeholder="Enter Category Name"
                  className={styles.outline_inputbox}
                  rows={2}
                  maxRows={4}
                  onChange={(e) => {
                    setServiceCategory((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  value={serviceCategory?.name}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Service Category Details
                  {/* <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Details"
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={(e) => {
                    setServiceCategory((prevState) => ({
                      ...prevState,
                      description: e.target.value,
                    }));
                  }}
                  value={serviceCategory?.description}
                />
              </FormControl>
              {formType === "services-category-form/edit" && (
                <>
                  <FormControl
                    className={styles.formControl}
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <span className={styles.label}>
                      Status
                      {/* <span
                          style={{
                            color: "red",
                          }}
                        >
                          *
                        </span> */}
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="Active"
                      // onChange={}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </div>
          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              onClick={
                formType === "services-category-form/edit"
                  ? handleEdit
                  : handleSave
              }
              variant="contained"
              type="submit"
              className={styles.saveBtn}
            />
          </div>
        </div>
      </div>
    </>
  );
}
