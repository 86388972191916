import { axios } from "../../../../api/setup.intersepter";
import { UpdateReqSparePartDT } from "./types";

export const GetAllSpareRequest = (id: string) => {
  return axios({
    method: "GET",
    url: `/sparepart-details/${id}`,
  });
};

export const UpdateSparePartReq = (body: UpdateReqSparePartDT) => {
  return axios({
    method: "PUT",
    url: `/sparepart-details/${body.product_id}`,
    data: {
      request_id: body.request_id,
      status: body.status,
      stock: body.stock,
      jobcard_id: body.jobcard_id,
      partner_id: body.partnerid,
      created_by: body.createdby,
    },
  });
};
