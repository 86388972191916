import { ThemeProvider, createTheme } from "@mui/material";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import colorJSON from "./assets/colors/colorset-alpha.json";
import { store } from "./redux/store";
import Router from "./router/Router";
const theme = createTheme({
  palette: {
    primary: {
      light: colorJSON["primary"],
      main: colorJSON.primary,
      contrastText: colorJSON.white,
    },
    secondary: {
      light: colorJSON["secondary"],
      main: colorJSON.secondary,
      contrastText: colorJSON.white,
    },
    error: {
      light: colorJSON["error-light"],
      main: colorJSON.error,
      dark: colorJSON["error-dark"],
      contrastText: colorJSON.black,
    },
    warning: {
      light: colorJSON["warning-light"],
      main: colorJSON.warning,
      dark: colorJSON["warning-dark"],
      contrastText: colorJSON.black,
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
