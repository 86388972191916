import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import PlanEnquiry from "../components/PlanEnquiry";
import { createEnquiryRequest, reset } from "../service/planEnquirySlice";
import { EnquiryDT, EnquiryPlanState } from "../service/types";

interface StateProps extends EnquiryPlanState {
  createEnquiryRequest_Loading: boolean;
  createEnquiryRequest_Error: boolean;
  createEnquiryRequest_Success: boolean;
  createEnquiryRequest_details: null;
}

interface DispatchProps {
  CreateEnquiryPlan(body: EnquiryDT): void;
  ResetAll(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    createEnquiryRequest_Success:
      state.enquiryPlan.createEnquiryRequest_Success,
    createEnquiryRequest_Error: state.enquiryPlan.createEnquiryRequest_Error,
    createEnquiryRequest_Loading:
      state.enquiryPlan.createEnquiryRequest_Loading,
    createEnquiryRequest_details:
      state.enquiryPlan.createEnquiryRequest_details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateEnquiryPlan(body) {
      dispatch(createEnquiryRequest(body));
    },
    ResetAll() {
      dispatch(reset());
    },
  };
};

export interface PlanEnquiryProps extends StateProps, DispatchProps {}
export const PlanEnquiryComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanEnquiry);
