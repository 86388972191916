import { connect } from "react-redux";
import {
  createInventryProduct,
  getAllActiveProductCate,
  reset,
  updateInventryProduct,
} from "../../../../../../pages/app/Configuration/services/configurationSlice";
import {
  ProductCategoryRes,
  UpdateProductInventryApiDTO,
} from "../../../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../../../redux/store";
import ProductInventoryForm from "../ProductInventoryForm";

interface StateProps {
  createInventryProduct_Loading: boolean;
  createInventryProduct_Error: boolean;
  createInventryProduct_Success: boolean;
  inventryProductDetails: null;
  updateInventryProduct_Loading: boolean;
  updateInventryProduct_Error: boolean;
  updateInventryProduct_Success: boolean;
  updateinventryProductDetails: null;
  getAllProductActiveCateSuccess: boolean;
  getAllProductActiveCateError: boolean;
  getAllProductActiveCateLoading: boolean;
  activeProductCategoryDetails: ProductCategoryRes[];
}
interface DispatchProps {
  resetAllServiceCategory(): void;
  createInventryProduct(data: any): void;
  updateInventryProduct(data: UpdateProductInventryApiDTO): void;
  GetAllActiveProductCate(id: number): void;
}
const mapStateToProps = (state: RootState): StateProps => {
  return {
    createInventryProduct_Loading:
      state.configuration.createInventryProduct_Loading,
    createInventryProduct_Error:
      state.configuration.createInventryProduct_Error,
    createInventryProduct_Success:
      state.configuration.createInventryProduct_Success,
    inventryProductDetails: state.configuration.inventryProductDetails,
    getAllProductActiveCateSuccess:
      state.configuration.getAllProductActiveCateSuccess,
    getAllProductActiveCateError:
      state.configuration.getAllProductActiveCateError,
    getAllProductActiveCateLoading:
      state.configuration.getAllProductActiveCateLoading,
    activeProductCategoryDetails:
      state.configuration.activeProductCategoryDetails,
    updateInventryProduct_Loading:
      state.configuration.updateInventryProduct_Loading,
    updateInventryProduct_Error:
      state.configuration.updateInventryProduct_Error,
    updateInventryProduct_Success:
      state.configuration.updateInventryProduct_Success,
    updateinventryProductDetails:
      state.configuration.updateinventryProductDetails,
  };
};
const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    resetAllServiceCategory: () => {
      dispatch(reset());
    },
    createInventryProduct: (data) => {
      dispatch(createInventryProduct(data));
    },
    updateInventryProduct: (data) => {
      dispatch(updateInventryProduct(data));
    },
    GetAllActiveProductCate: (id) => {
      dispatch(getAllActiveProductCate(id));
    },
  };
};
export interface ProductInventoryFormProps extends StateProps, DispatchProps {}
export const ProductInventoryFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductInventoryForm);
