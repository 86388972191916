import { Card } from "@mui/material";
import React from "react";
import defaultImage from "../../../assets/images/DefaultImage.png";
import styles from "./SelectedSpare.module.css";

interface SparePartCardProps {
  sparePartName: string | null | undefined;
  quantity: string | null;
  image: string | null;
  price: string | null;
}

const SparePartCard: React.FC<SparePartCardProps> = ({
  sparePartName,
  quantity,
  image,
  price,
}) => {
  return (
    <div className="col-lg-2">
      <Card variant="outlined" className={styles.spares_wrapper}>
        <div className="d-flex justify-content-center">
          <div className={styles.img_wrapper}>
            <img
              src={
                image
                  ? `${process.env.REACT_APP_API_ENDPOINT}/${image.replace(
                      "uploads/",
                      ""
                    )}`
                  : defaultImage
              }
              alt="pic"
              width={56}
              height={56}
              className={styles.image_spare_part}
            />
          </div>
        </div>
        <p className={styles.spares_name}>{sparePartName}</p>
        <div>
          <p className={styles.spares_cost}>Price : {price}</p>
        </div>
        <div>
          <p className={styles.spares_cost}>Quantity : {quantity}</p>
        </div>
      </Card>
    </div>
  );
};

export default SparePartCard;
