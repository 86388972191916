import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { getFeedback as getFeedbackG } from "../../CustomerOrderTracking/services/customerTrackingSlice";
import { CustomerFeedback } from "../../CustomerOrderTracking/services/types";
import Feedback from "../Feedback";
import { feedbackPost, getFeedback } from "../services/FeedbackSlice";
import { CreateFeedback, FeedbackResponce } from "../services/type";

interface StateProps {
  getFeedback_Loading: boolean;
  getFeedback_Error: boolean;
  getFeedback_Success: boolean;
  getFeedback_Details: FeedbackResponce | null;

  feedbackPost_Loading: boolean;
  feedbackPost_Error: boolean;
  feedbackPost_Success: boolean;
  feedbackPost_Details: null;
  getFeedbackG_Loading: boolean;
  getFeedbackG_Error: boolean;
  getFeedbackG_Success: boolean;
  getFeedbackG_Details: CustomerFeedback | null;
}

interface DispatchProps {
  GetFeedback(data: string): void;
  FeedbackPost(data: CreateFeedback): void;
  GetFeedbackWithGarageName(data: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getFeedback_Loading: state.feedback.getFeedback_Loading,
    getFeedback_Error: state.feedback.getFeedback_Error,
    getFeedback_Success: state.feedback.getFeedback_Success,
    getFeedback_Details: state.feedback.getFeedback_Details,

    feedbackPost_Loading: state.feedback.feedbackPost_Loading,
    feedbackPost_Error: state.feedback.feedbackPost_Error,
    feedbackPost_Success: state.feedback.feedbackPost_Success,
    feedbackPost_Details: state.feedback.feedbackPost_Details,
    getFeedbackG_Loading: state.tracking.getFeedback_Loading,
    getFeedbackG_Error: state.tracking.getFeedback_Error,
    getFeedbackG_Success: state.tracking.getFeedback_Success,
    getFeedbackG_Details: state.tracking.getFeedback_Details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetFeedback: (data) => {
      return dispatch(getFeedback(data));
    },

    FeedbackPost: (data) => {
      return dispatch(feedbackPost(data));
    },
    GetFeedbackWithGarageName(data) {
      dispatch(getFeedbackG(data));
    },
  };
};

export interface FeedbackProps extends StateProps, DispatchProps {}
export const FeedbackComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Feedback);
