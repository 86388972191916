import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { GetAllVehicleHistory } from "./history.request";
import { HistoryDT, VehicleHistoryState } from "./types";

export const initialState: VehicleHistoryState = {
  getAllVehicleHistory_Loading: false,
  getAllVehicleHistory_Error: false,
  getAllVehicleHistory_Success: false,
  getAllVehicleHistory_details: [],
};
export const getAllVehicleHistory = createAsyncThunk(
  "user/getAllVehicleHistory",
  (body: HistoryDT) => {
    return GetAllVehicleHistory(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);

export const History = createSlice({
  name: "History",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVehicleHistory.pending, (state) => {
      state.getAllVehicleHistory_Loading = true;
      state.getAllVehicleHistory_Error = false;
      state.getAllVehicleHistory_Success = false;
    });
    builder.addCase(
      getAllVehicleHistory.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllVehicleHistory_Loading = false;
        state.getAllVehicleHistory_Error = false;
        state.getAllVehicleHistory_Success = true;
        state.getAllVehicleHistory_details = action?.payload;
      }
    );
    builder.addCase(
      getAllVehicleHistory.rejected,
      (state, action: AnyAction) => {
        state.getAllVehicleHistory_Loading = false;
        state.getAllVehicleHistory_Error = false;
        state.getAllVehicleHistory_Success =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default History.reducer;
export const { reset } = History.actions;
