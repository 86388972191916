import { useEffect } from "react";
import Partner from "../../../../../components/appSpecific/Partner/component/Partner";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { ConfigurationProps } from "../../container/configuartionContainer";

export default function PartnerPage(props: ConfigurationProps) {
  const { GetAllPartner, PartnerDetails, getAllPartnerLoading } = props;

  useEffect(() => {
    GetAllPartner();
  }, [GetAllPartner]);

  return (
    <>
      {getAllPartnerLoading ? <CustomLoader /> : null}

      <Partner PartnerDetails={PartnerDetails} />
    </>
  );
}
