import { Calendar2 } from "iconsax-react";
import moment from "moment";
import React from "react";
import styles from "./ListItems.module.css";
interface ListItemProps {
  date: Date;
  jobId: string;
  handleSelected(id: string): void;
  isActive: string;
}

const ListItem: React.FC<ListItemProps> = ({
  date,
  jobId,
  handleSelected,
  isActive,
}) => {
  return (
    <div
      className={`${
        isActive === jobId
          ? styles.title_list_items_active
          : styles.title_list_items
      }`}
      onClick={() => handleSelected(jobId)}
    >
      <div className={styles.title_list_items_date}>
        {/* Assuming you have imported and used the Calendar2 component */}
        <div>
          <Calendar2 size="22" color="#292D32" variant="Outline" />
        </div>
        <div>
          <p className={styles.list_items_date_date}>
            {" "}
            {moment(date).format("MMMM Do YYYY")}
          </p>
        </div>
      </div>
      <div>
        <p className={styles.list_items_jobId}>
          Job ID :{" "}
          <span className={styles.list_items_jobId_num}>
            {jobId.slice(0, 8)}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ListItem;
