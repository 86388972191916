import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";

import History from "../components/History";
import { getAllVehicleHistory, reset } from "../services/historySlice";
import { HistoryDT, HistoryRes, VehicleHistoryState } from "../services/types";

interface StateProps extends VehicleHistoryState {
  getAllVehicleHistory_Loading: boolean;
  getAllVehicleHistory_Error: boolean;
  getAllVehicleHistory_Success: boolean;
  getAllVehicleHistory_details: HistoryRes[];
}

interface DispatchProps {
  GetAllVehicleHistory(body: HistoryDT): void;
  ResetAll(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getAllVehicleHistory_Success: state.history.getAllVehicleHistory_Success,
    getAllVehicleHistory_Error: state.history.getAllVehicleHistory_Error,
    getAllVehicleHistory_Loading: state.history.getAllVehicleHistory_Loading,
    getAllVehicleHistory_details: state.history.getAllVehicleHistory_details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllVehicleHistory(id) {
      dispatch(getAllVehicleHistory(id));
    },
    ResetAll() {
      dispatch(reset());
    },
  };
};

export interface HistoryProps extends StateProps, DispatchProps {}
export const HistoryComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(History);
