import { Grid, Typography } from "@mui/material";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
interface Props {
  label?: string;
  value: number;
  strokeColor?: string;
  backgroundColor?: string;
  caption?: string;
  captionTextColor?: string;
  onClick?(): void;
}
export default function DashboardProgressbar(props: Props) {
  const {
    label,
    value,
    strokeColor = "#34B53A",
    backgroundColor = "#E2FBD7",
    caption,
    captionTextColor = "black",
    onClick,
  } = props;
  return (
    <Grid
      // item
      // flex={1}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
      }}
      item
      xs={6}
      md={6}
      lg={4}
      onClick={onClick}
    >
      {label ? (
        <Typography
          sx={{ fontWeight: "600", fontSize: "0.8rem", marginRight: "0.5em" }}
          align="center"
          color="black"
        >
          {label}
        </Typography>
      ) : null}
      <div style={{ width: "4rem" }}>
        <CircularProgressbarWithChildren
          value={value}
          maxValue={100}
          minValue={1}
          background={true}
          strokeWidth={5}
          styles={{
            path: {
              stroke: strokeColor,
              transformOrigin: "center center",
            },
            trail: {
              stroke: backgroundColor,
              transformOrigin: "center center",
            },
            background: {
              fill: backgroundColor,
            },
            root: {},
          }}
        >
          {caption ? (
            <Typography
              sx={{ fontSize: "0.8rem", fontWeight: "600" }}
              align="center"
              color={captionTextColor}
            >
              {caption}
            </Typography>
          ) : null}
        </CircularProgressbarWithChildren>
      </div>
    </Grid>
  );
}
