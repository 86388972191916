import React from "react";
import logoInvincix from "../../../assets/images/Invincix_TM 1.svg";
import styles from "./Footer.module.css";

const Footer: React.FC = () => {
  const currentDate = new Date();

  return (
    <div className={styles.footer_root_main1}>
      <div className={styles.footer_root}>
        <div>
          <p className={styles.txt_company}>
            © {currentDate.getFullYear()} INVINCIX Solutions Pvt. Ltd.
          </p>
        </div>
        <div className={styles.powerBy_root}>
          <div>
            <p className={styles.txt_company_Powered}> Powered by</p>
          </div>
          <div>
            <img
              src={logoInvincix}
              alt="pic"
              width={80}
              height={80}
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
