import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { CustomerFeedback } from "../../../pages/app/CustomerOrderTracking/services/types";
import { EstimateRes } from "../../../pages/app/Manage/Home/type";
import { getUserDataFromLocalStorage } from "../../../utils/helper.utils";

interface EstimatePDFProps {
  data: EstimateRes | null;
  getFeedback_Details: CustomerFeedback | null;
}

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    // fontFamily: "Calibri",
    fontSize: 10,
    paddingTop: 25,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.15,
  },
  section: {
    marginBottom: 10,
    border: "1pt solid black",
    padding: 10,
  },
  title: {
    fontSize: 19,
    textAlign: "center",
    marginBottom: 10,
  },
  titles: {
    fontSize: 10,
    // marginBottom: 5,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 10,
    marginBottom: 5,
  },
  item: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  items: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  contact: {
    marginLeft: 121.65,
    textAlign: "justify",
    // lineHeight: 10.95,
    fontSize: 9,
    marginBottom: 10,
  },
  address: {
    fontSize: 9,
    textAlign: "center",
    marginBottom: 10,
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },

  signatureBlock: {
    // border: "1pt solid black",
    padding: 10,
    marginBottom: 10,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  fieldLabel: {
    fontWeight: "bold",
    marginBottom: 5,
    fontSize: 12,
  },
});

export const EstimatePDF = (props: EstimatePDFProps) => {
  const { data, getFeedback_Details } = props;
  const userData = getUserDataFromLocalStorage();

  if (!data) {
    return null;
  }

  const {
    jobcardDetails,
    vehicle,
    customer,
    services,
    spareParts,
    extraService,
    totalPrice,
  } = data;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ border: "1pt solid black", padding: 20 }}>
          <View>
            <Text style={styles.title}>{getFeedback_Details?.garage_name}</Text>
            <Text style={styles.address}>
              {getFeedback_Details?.address}, Ph:{" "}
              {getFeedback_Details?.contact_number}, Email:{" "}
              {getFeedback_Details?.email}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.fieldLabel}>Estimate Details</Text>
            {/* <Text style={styles.subtitle}>
              Job Card ID: {jobcardDetails?.id}
            </Text> */}
            <Text style={styles.subtitle}>
              Vehicle Number: {vehicle?.vehicle_number}
            </Text>
            <Text style={styles.subtitle}>Customer Name: {customer?.name}</Text>
            {/* Add other customer details here if needed */}
          </View>

          <View style={styles.section}>
            <Text style={styles.fieldLabel}>Services</Text>
            {services.map((service) => (
              <View key={service.service_name} style={styles.item}>
                <Text>{service.service_name}</Text>
                <Text>{service.price?.toFixed(2)}</Text>
              </View>
            ))}
          </View>

          <View style={styles.section}>
            <Text style={styles.fieldLabel}>Spare Parts</Text>
            {spareParts.map((sparePart) => (
              <View key={sparePart.spare_part_name} style={styles.item}>
                <Text>{sparePart.spare_part_name}</Text>
                <Text>{sparePart.total_price?.toFixed(2)}</Text>
              </View>
            ))}
          </View>

          <View style={styles.section}>
            <Text style={styles.fieldLabel}>Extra Service</Text>
            <View style={styles.item}>
              <Text>{extraService.service_name}</Text>
              <Text>{extraService.price?.toFixed(2)}</Text>
            </View>
          </View>

          <View>
            <Text style={styles.titles}>Sub Price</Text>
            <View style={styles.items}>
              <Text></Text>
              <Text>{totalPrice?.toFixed(2)}</Text>
            </View>
          </View>
          <View>
            <Text style={styles.titles}>Tax {userData.tax_percentage}%</Text>
            <View style={styles.items}>
              <Text></Text>
              <Text>
                {" "}
                {(
                  (Number(totalPrice) * userData.tax_percentage) /
                  100
                )?.toFixed(2)}
              </Text>
            </View>
          </View>
          <View>
            <Text style={styles.titles}>Total Price</Text>
            <View style={styles.items}>
              <Text></Text>
              <Text>
                {Math.round(
                  Math.floor(Number(totalPrice)) +
                    Math.floor(Number(totalPrice) * userData.tax_percentage) /
                      100
                )?.toFixed(2)}
              </Text>
            </View>
          </View>
          <View style={styles.signatureContainer}>
            <View style={styles.signatureBlock}>
              <Text>Customer Signature: ________________________</Text>
              <Text
                style={{
                  marginTop: "10",
                }}
              >
                Date: ________________________
              </Text>
            </View>
            <View style={styles.signatureBlock}>
              <Text>Authorized Signature: ________________________</Text>
              <Text
                style={{
                  marginTop: "10",
                }}
              >
                Date: ________________________
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
