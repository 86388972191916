import CloseIcon from "@mui/icons-material/Close";
import { IconButton, InputBase, SnackbarOrigin } from "@mui/material";
import { SearchNormal1 } from "iconsax-react";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SelectedSpare } from "../../../../../../components/appSpecific/MechanicSelectedSpare/SelectedSpare";
import SparePartCard from "../../../../../../components/appSpecific/MechanicSparePartCard/SparePartCard/SparePartCard";
import AppHeader from "../../../../../../components/common/AppHeader/AppHeader";
import CustomLoader from "../../../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../../../components/common/SnackBar/SnackBar";
import { RootState } from "../../../../../../redux/store";
import { getUserDataFromLocalStorage } from "../../../../../../utils/helper.utils";
import {
  Spare,
  addSpare,
  removeSpare,
} from "../../../../Manage/VeichleRegistration/components/Spares/services/sparesSlice";
import { MechanicPendingProps } from "../container/mechanicPendingTaskContainer";
import { SpareReqDT } from "../service/types";
import styles from "./MechanicPending.module.css";
interface GroupedProductsType {
  [category_name: string]: any;
}
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function MechanicPending(props: MechanicPendingProps) {
  const {
    GetAllActiveProduct,
    activeProductDetails,
    getAllActiveProductError,
    getAllActiveProductLoading,
    getAllActiveProductSuccess,
    spares,
    RequestSparePart,
    requestSparePart_Error,
    requestSparePart_Loading,
    requestSparePart_Success,
    requestSparePart_details,
  } = props;
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const userDataParse = getUserDataFromLocalStorage();
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const products = useSelector((state: RootState) => state.spares.spares);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(activeProductDetails);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);

    const filteredResults = activeProductDetails.filter((vehicle) =>
      vehicle.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  const userData = getUserDataFromLocalStorage();
  const [GroupedParts, updateGroupedParts] =
    React.useState<GroupedProductsType>({});
  useEffect(() => {
    GetAllActiveProduct(userData?.partner_id);
  }, [GetAllActiveProduct, userData?.partner_id]);

  useEffect(() => {
    updateGroupedParts(
      _.groupBy(activeProductDetails, "product_category_name")
    );
  }, [activeProductDetails]);

  React.useEffect(() => {
    //
    if (filteredData?.length !== 0) {
      updateGroupedParts(_.groupBy(filteredData, "product_category_name"));
    }

    // groupPartsPerCategory(productsL);
  }, [filteredData]);
  const handleSpareAdd = React.useCallback(
    (spare: any) => {
      dispatch(addSpare(spare));
      // setSelectedPrice(spare);
      // handleOpen();
    },
    [dispatch]
  );
  // const handleSparePrice = () => {
  //   dispatch(addSpare(selectedPrice));
  //   handleClose();
  // };
  const handleRemoveSpare = React.useCallback(
    (spareId: number) => {
      dispatch(removeSpare(spareId));
    },
    [dispatch]
  );
  const requestedPart: SpareReqDT = spares.map((ele: Spare) => {
    return {
      jobCard_id: state?.jobcard_id,
      product_id: ele.ID,
      quantity: ele.Quantity,
      is_estimated: 1,
      created_by: userDataParse.id,
      mechanic: userDataParse.name,
      partner_id: userDataParse.partner_id,
    };
  });
  const handleRequestPart = useCallback(() => {
    RequestSparePart(requestedPart);
  }, [RequestSparePart, requestedPart]);
  React.useEffect(() => {
    if (requestSparePart_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(requestSparePart_details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      // resetAllCategory();
    } else if (requestSparePart_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          requestSparePart_details
            ? requestSparePart_details
            : "Something went wrong"
        ),
        snackOpen: true,
        snackSeverity: requestSparePart_details ? "success" : "error",
      }));
      // resetAllCategory();
    }
  }, [
    navigate,
    requestSparePart_Error,
    requestSparePart_Success,
    requestSparePart_details,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <AppHeader />
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      {getAllActiveProductLoading && <CustomLoader />}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3" style={{ padding: "0px" }}>
            <SelectedSpare
              selectedSpares={products}
              handleSpareAdd={handleSpareAdd}
              handleRemoveSpare={handleRemoveSpare}
              handleRequestPart={handleRequestPart}
            />
          </div>
          <div className="col-md-9 ps-0">
            <div className={styles.spareparts}>
              <div className={styles.title_spareaprt}>
                <p className={styles.txt_spare_title}> Spare Parts </p>
                <div className={styles.search_main}>
                  <div className={styles.search}>
                    <InputBase
                      startAdornment={
                        <SearchNormal1 color="#C0C0C0" width="20" />
                      }
                      placeholder="Search..."
                      inputProps={{
                        style: {
                          color: "#9A9AB0",
                          paddingLeft: "10px",
                          fontWeight: "500",
                        },
                      }}
                      className={
                        styles.inputRoot
                        // input: styles.inputInput,
                      }
                      value={searchText}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              {Object.keys(GroupedParts).map((spare) => {
                return (
                  <>
                    <div className="container ps-4">
                      <div className={styles.root_cata_stage}>
                        <div className={styles.title_categories}>
                          <p className={styles.categories_txt}> {spare}</p>
                        </div>
                        <div className={styles.slider_root}>
                          {GroupedParts[spare].map((parts: any) => {
                            return (
                              <SparePartCard
                                {...parts}
                                handleSpareAdd={handleSpareAdd}
                                handleRemoveSpare={handleRemoveSpare}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
