import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import Invoice from "../Invoice";
import {
  downloadInvoice,
  // getCustomerDetails,
  getJobcardInvoice,
  reset,
} from "../service/invoiceSlice";
import { JobcardInvoice } from "../service/types";

interface StateProps {
  downloadInvoice_Loading: boolean;
  downloadInvoice_Error: boolean;
  downloadInvoice_Success: boolean;
  downloadInvoice_Details: [];

  getCustomerDetails_Loading: boolean;
  getCustomerDetails_Error: boolean;
  getCustomerDetails_Success: boolean;
  getCustomerDetails: [];

  getJobCradInvoice_loading: boolean;
  getJobCradInvoice_Error: boolean;
  getJobCradInvoice_Success: boolean;
  getJobCradInvoice_Details: JobcardInvoice | null;
}

interface DispatchProps {
  DownloadInvoice(data: any): void;
  resetDownloadInvoice(): void;
  // GetCustomerDetails(data: any): void;
  GetJobCradInvoice(data: any): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    downloadInvoice_Loading: state.invoice.downloadInvoice_Loading,
    downloadInvoice_Error: state.invoice.downloadInvoice_Error,
    downloadInvoice_Success: state.invoice.downloadInvoice_Success,
    downloadInvoice_Details: state.invoice.downloadInvoice_Details,

    getCustomerDetails_Loading: state.invoice.getCustomerDetails_Loading,
    getCustomerDetails_Error: state.invoice.getCustomerDetails_Error,
    getCustomerDetails_Success: state.invoice.getCustomerDetails_Success,
    getCustomerDetails: state.invoice.getCustomerDetails,

    getJobCradInvoice_loading: state.invoice.getJobCradInvoice_loading,
    getJobCradInvoice_Error: state.invoice.getJobCradInvoice_Error,
    getJobCradInvoice_Success: state.invoice.getJobCradInvoice_Success,
    getJobCradInvoice_Details: state.invoice.getJobCradInvoice_Details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    DownloadInvoice: (data) => {
      dispatch(downloadInvoice(data));
    },

    resetDownloadInvoice: () => {
      dispatch(reset());
    },
    // GetCustomerDetails: (data) => {
    //   dispatch(getCustomerDetails(data));
    // },
    GetJobCradInvoice: (data) => {
      dispatch(getJobcardInvoice(data));
    },
  };
};

export interface InvoiceProps extends StateProps, DispatchProps {}
export const InvoiceComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoice);
