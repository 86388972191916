// import axios from "axios";
import { LoginDTS } from "./types";
// const axios = require("axios").default;
import { axios } from "../../../../api/setup.intersepter";

export const LoginRequest = (data: LoginDTS) => {
  return axios({
    method: "POST",
    url: `/auth/login`,
    data: { email: data.email, password: data.password },
  });
};

// export const GetUserDetails = (data: string, token: string) => {
//   return axios({
//     method: "POST",
//     url: `/user/getUserByEmail`,
//     headers: {
//       token,
//     },
//     data: { email: data },
//   });
// };
