import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RoleRes } from "../../../../pages/app/Configuration/types";
import ButtonLog from "../../../common/Button/Button";
import CustomTable from "../../../common/Table/CustomTable";
import styles from "./RoleCreation.module.css";

interface RoleCreationProps {
  RoleDetails: RoleRes[];
}
export default function RoleCreation(props: RoleCreationProps) {
  const { RoleDetails } = props;
  const navigation = useNavigate();
  const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "description", label: "Description", minWidth: 100 },
    { id: "is_active", label: "Status", minWidth: 100 },
    // { id: "action", label: "Action", minWidth: 100 },
  ];

  // const handleEditClick = (role: any) => {
  //   navigation("/role-creation-form/edit", {
  //     state: { role },
  //   });
  // };

  const updatedRows = RoleDetails?.map((row: any) => ({
    ...row,
    is_active:
      row.is_active === false ? (
        <Chip label="In Active" variant="outlined" color="error" />
      ) : (
        <Chip label="Active" variant="outlined" color="success" />
      ),
    // action: (
    //   <Button
    //     variant="outlined"
    //     color="primary"
    //     startIcon={<Edit size="18" color="#0761e9" />}
    //     onClick={() => handleEditClick(row)}
    //   >
    //     Edit
    //   </Button>
    // ),
  }));
  return (
    <>
      <div className={styles.inventoryNavBar}>
        <div>
          <ButtonLog
            text={"Back"}
            variant={"contained"}
            onClick={() => navigation(-1)}
            className={styles.backBtn}
          />
        </div>

        <div className={styles.filter}>
          <ButtonLog
            text={"Add Role Creation"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/role-creation-form/add")}
          />
        </div>
      </div>
      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <CustomTable columns={columns} rows={updatedRows} />
      </div>
    </>
  );
}
