import React, { useEffect, useState } from "react";
// Import react-circular-progressbar module and styles
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";
import styles from "./progree.module.css";

interface ProgressProps {
  percentage?: number;
}

const Progress: React.FC<ProgressProps> = ({ percentage = 0 }) => {
  return (
    <>
      <div className={styles.progress_bar_root}>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          circleRatio={0.5}
          styles={buildStyles({
            rotation: -0.25,
            strokeLinecap: "butt",
            pathColor: "#0761E9",
            textColor: "#272D37",
            trailColor: "#F5F8FE",
          })}
        />
      </div>
    </>
  );
};

export default Progress;