import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  CreateBayDT,
  CreateCurrencyDTO,
  CreatePartnerApiDTo,
  CreateProductInventryApiDTO,
  CreateServiceApiDto,
  CreateStockApiDTo,
  CreateSupplierApiDTo,
  CreateUserDT,
  ProductCategory,
  RoleCreationDT,
  RoleFunction,
  ServiceCategory,
  ServiceInitialStateDT,
  UpdateBayDT,
  UpdateCurrencyDTO,
  UpdatePartnerApiDTo,
  UpdateProductCategoryDT,
  UpdateProductInventryApiDTO,
  UpdateServiceApiDTO,
  UpdateServiceCategoryDT,
  UpdateStockApiDTo,
  UpdateSupplierApiDTo,
  UpdateUserDT,
} from "../types";
import {
  CreateBay,
  CreateCurrency,
  CreateInventryProductRequest,
  CreatePartnerRequest,
  CreateProductCategory,
  CreateRole,
  CreateRoleCreation,
  CreateServiceCategory,
  CreateServiceRequest,
  CreateStockRequest,
  CreateSupplierRequest,
  CreateUser,
  GetAllActiveProductCategory,
  GetAllActiveServiceCategory,
  GetAllBay,
  GetAllCurrency,
  GetAllFunction,
  GetAllPartner,
  GetAllProduct,
  GetAllProductCategory,
  GetAllRole,
  GetAllRoleFunction,
  GetAllRoleType,
  GetAllService,
  GetAllServiceCategory,
  GetAllStock,
  GetAllSupplier,
  GetAllUser,
  OutOfStock,
  UpdateBay,
  UpdateCurrency,
  UpdateInventryProductRequest,
  UpdatePartnerRequest,
  UpdateProductCategory,
  UpdateServiceCategory,
  UpdateServiceRequest,
  UpdateStockRequest,
  UpdateSupplierRequest,
  UpdateUser,
} from "./configuration.request";

export const initialState: ServiceInitialStateDT = {
  success: false,
  error: false,
  loading: false,
  serviceCategoryDetails: null,
  createServiceCateSuccess: false,
  createServiceCateError: false,
  createServiceCateLoading: false,
  createServiceCateDetails: null,
  getAllProductCateSuccess: false,
  getAllProductCateError: false,
  getAllProductCateLoading: false,
  productCategoryDetails: null,
  createProductCateSuccess: false,
  createProductCateError: false,
  createProductCateLoading: false,
  createProductCateDetails: null,
  getAllProductSuccess: false,
  getAllProductError: false,
  getAllProductLoading: false,
  productDetails: null,
  getAllServiceSuccess: false,
  getAllServiceError: false,
  getAllServiceLoading: false,
  serviceDetails: null,
  getAllUserSuccess: false,
  getAllUserError: false,
  getAllUserLoading: false,
  userDetails: null,
  createUser_Loading: false,
  createUser_Error: false,
  createUser_Success: false,
  createUser_Details: null,
  updateUser_Loading: false,
  updateUser_Error: false,
  updateUser_Success: false,
  updateUser_Details: null,
  createInventryProduct_Loading: false,
  createInventryProduct_Error: false,
  createInventryProduct_Success: false,
  inventryProductDetails: null,
  updateInventryProduct_Loading: false,
  updateInventryProduct_Error: false,
  updateInventryProduct_Success: false,
  updateinventryProductDetails: null,
  createService_Loading: false,
  createService_Error: false,
  createService_Success: false,
  createService_Details: null,
  updateService_Loading: false,
  updateService_Error: false,
  updateService_Success: false,
  updateService_Details: null,
  createPartner_Loading: false,
  createPartner_Error: false,
  createPartner_Success: false,
  createPartner_Details: null,
  updatePartner_Loading: false,
  updatePartner_Error: false,
  updatePartner_Success: false,
  updatePartner_Details: null,
  getAllPartnerSuccess: false,
  getAllPartnerError: false,
  getAllPartnerLoading: false,
  PartnerDetails: null,
  updateProductCate_Loading: false,
  updateProductCate_Error: false,
  updateProductCate_Success: false,
  updateProductCate_Details: null,
  updateServiceCate_Loading: false,
  updateServiceCate_Error: false,
  updateServiceCate_Success: false,
  updateServiceCate_Details: null,
  createSupplier_Loading: false,
  createSupplier_Error: false,
  createSupplier_Success: false,
  createSupplier_Details: null,
  updateSupplier_Loading: false,
  updateSupplier_Error: false,
  updateSupplier_Success: false,
  updateSupplier_Details: null,
  getAllSupplierSuccess: false,
  getAllSupplierError: false,
  getAllSupplierLoading: false,
  SupplierDetails: null,
  createStock_Loading: false,
  createStock_Error: false,
  createStock_Success: false,
  createStock_Details: null,
  updateStock_Loading: false,
  updateStock_Error: false,
  updateStock_Success: false,
  updateStock_Details: null,
  getAllStockSuccess: false,
  getAllStockError: false,
  getAllStockLoading: false,
  StockDetails: [],
  outOfStockSuccess: false,
  outOfStockError: false,
  outOfStockLoading: false,
  outOfStockDetails: null,
  getAllRoleTypeSuccess: false,
  getAllRoleTypeError: false,
  getAllRoleTypeLoading: false,
  RoleTypeDetails: [],
  createBay_Loading: false,
  createBay_Error: false,
  createBay_Success: false,
  createBay_Details: null,
  updateBay_Loading: false,
  updateBay_Error: false,
  updateBay_Success: false,
  updateBay_Details: null,
  getAllBaySuccess: false,
  getAllBayError: false,
  getAllBayLoading: false,
  BayDetails: null,
  getAllRoleSuccess: false,
  getAllRoleError: false,
  getAllRoleLoading: false,
  RoleDetails: [],
  getAllFunctionSuccess: false,
  getAllFunctionError: false,
  getAllFunctionLoading: false,
  FunctionDetails: [],
  createRoleSuccess: false,
  createRoleError: false,
  createRoleLoading: false,
  createRoleDetails: null,
  getAllRoleFunctionSuccess: false,
  getAllRoleFunctionError: false,
  getAllRoleFunctionLoading: false,
  getAllRoleFunctionDetails: [],
  createRoleCreationSuccess: false,
  createRoleCreationError: false,
  createRoleCreationLoading: false,
  createRoleCreationDetails: null,
  getAllProductActiveCateSuccess: false,
  getAllProductActiveCateError: false,
  getAllProductActiveCateLoading: false,
  activeProductCategoryDetails: [],
  getAllServiceActiveCateSuccess: false,
  getAllServiceActiveCateError: false,
  getAllServiceActiveCateLoading: false,
  activeServiceCategoryDetails: [],
  createCurrency_Loading: false,
  createCurrency_Error: false,
  createCurrency_Success: false,
  createCurrency_Details: null,
  updateCurrency_Loading: false,
  updateCurrency_Error: false,
  updateCurrency_Success: false,
  updateCurrency_Details: null,
  getAllCurrencySuccess: false,
  getAllCurrencyError: false,
  getAllCurrencyLoading: false,
  CurrencyDetails: null,
};

export const getAllServiceCate = createAsyncThunk(
  "service/getAllServiceCategory",
  async ({ id, queryParams }: { id: number; queryParams?: any }) => {
    try {
      const res = await GetAllServiceCategory(id, queryParams);
      if (res.status === 200) {
        return res.data.serviceCategory;
      }
    } catch (error) {
      console.log(error);
      throw error; // Throw the error to be handled in the rejected action
    }
  }
);

export const createServiceCate = createAsyncThunk(
  "service/createServiceCategory",
  (data: ServiceCategory) => {
    return CreateServiceCategory(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error("Something went wrong");
      });
  }
);
export const updateServiceCate = createAsyncThunk(
  "service/updateServiceCategory",
  (data: UpdateServiceCategoryDT) => {
    return UpdateServiceCategory(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data?.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error("Something went wrong");
      });
  }
);

export const getAllProductCate = createAsyncThunk(
  "product/getAllProductCategory",
  async ({ id, queryParams }: { id: number; queryParams?: any }) => {
    try {
      const res = await GetAllProductCategory(id, queryParams);
      if (res.status === 200) {
        return res.data.productCategory;
      }
    } catch (error) {
      console.log(error);
      throw error; // Throw the error to be handled in the rejected action
    }
  }
);

export const createProductCate = createAsyncThunk(
  "product/createProductCategory",
  (data: ProductCategory) => {
    return CreateProductCategory(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error("Something went wrong");
      });
  }
);
export const updateProductCate = createAsyncThunk(
  "product/updateServiceCategory",
  (data: UpdateProductCategoryDT) => {
    return UpdateProductCategory(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getAllService = createAsyncThunk(
  "service/getAllService",
  ({ id, queryParams }: { id: number; queryParams?: any }) => {
    return GetAllService(id, queryParams)
      .then((res) => {
        if (res.status === 200) {
          return res?.data.service;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);
export const getAllProduct = createAsyncThunk(
  "product/getAllProduct",
  async ({ id, queryParams }: { id: number; queryParams?: any }) => {
    try {
      let res = await GetAllProduct(id, queryParams);
      if (res.status === 200) {
        return res?.data?.product;
      }
    } catch (error) {
      console.log(error);
    }
  }
);
export const getAllUser = createAsyncThunk("user/getAllUser", (id: number) => {
  return GetAllUser(id)
    .then((res) => {
      if (res.status === 200) {
        return res?.data.user;
      }
    })
    .catch((error) => {
      console.log(error);
    });
});
export const createUser = createAsyncThunk(
  "user/createUser",
  (data: CreateUserDT) => {
    return CreateUser(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        // throw new Error("Something went wrong");
        throw new Error(error.response.data.message);
      });
  }
);
export const updateUser = createAsyncThunk(
  "user/updateUser",
  (data: UpdateUserDT) => {
    return UpdateUser(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const createInventryProduct = createAsyncThunk(
  "product/createInventryProduct",
  (data: CreateProductInventryApiDTO) => {
    return CreateInventryProductRequest(data)
      .then((res) => {
        if (res.status === 201) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const updateInventryProduct = createAsyncThunk(
  "product/updateInventryProduct",
  (data: UpdateProductInventryApiDTO) => {
    return UpdateInventryProductRequest(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data?.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const createService = createAsyncThunk(
  "service/createService",
  (data: CreateServiceApiDto) => {
    return CreateServiceRequest(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const updateService = createAsyncThunk(
  "service/updateService",
  (data: UpdateServiceApiDTO) => {
    return UpdateServiceRequest(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data?.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const createPartner = createAsyncThunk(
  "partner/createPartner",
  (data: CreatePartnerApiDTo) => {
    return CreatePartnerRequest(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }
);
export const updatePartner = createAsyncThunk(
  "partner/updatePartner",
  (data: UpdatePartnerApiDTo) => {
    return UpdatePartnerRequest(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data?.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getAllPartner = createAsyncThunk("partner/getAllPartner", () => {
  return GetAllPartner()
    .then((res) => {
      if (res.status === 200) {
        return res?.data.partners;
      }
    })
    .catch((error) => {
      console.log(error);
    });
});

export const createSupplier = createAsyncThunk(
  "supplier/createSupplier",
  (data: CreateSupplierApiDTo) => {
    return CreateSupplierRequest(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  }
);
export const updateSupplier = createAsyncThunk(
  "supplier/updateSupplier",
  (data: UpdateSupplierApiDTo) => {
    return UpdateSupplierRequest(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data?.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getAllSupplier = createAsyncThunk(
  "supplier/getAllSupplier",
  async ({ id, queryParams }: { id: string; queryParams?: any }) => {
    try {
      const res = await GetAllSupplier(id, queryParams);
      if (res.status === 200) {
        return res.data.supplier;
      }
    } catch (error) {
      console.log(error);
      throw error; // Throw the error to be handled in the rejected action
    }
  }
);
export const createStock = createAsyncThunk(
  "stock/createStock",
  (data: CreateStockApiDTo) => {
    return CreateStockRequest(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  }
);
export const updateStock = createAsyncThunk(
  "stock/updateStock",
  (data: UpdateStockApiDTo) => {
    return UpdateStockRequest(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data?.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getAllStock = createAsyncThunk(
  "stock/getAllStock",
  (id: string) => {
    return GetAllStock(id)
      .then((res) => {
        if (res.status === 200) {
          return res?.data.productInventory;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);
export const outOfStock = createAsyncThunk("stock/outOfStock", (id: string) => {
  return OutOfStock(id)
    .then((res) => {
      if (res.status === 200) {
        if (res?.data.message) {
          return res?.data.message;
        }
        return res?.data.error;
      }
    })
    .catch((error) => {
      console.log(error);
    });
});
export const getAllRoleType = createAsyncThunk(
  "roleType/getAllRoleType",
  () => {
    return GetAllRoleType()
      .then((res) => {
        if (res.status === 200) {
          return res?.data.role;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);
export const createRoleCreation = createAsyncThunk(
  "role/createRoleCreation",
  (data: RoleCreationDT) => {
    return CreateRoleCreation(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data.role;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);
export const createBay = createAsyncThunk(
  "bay/createBay",
  (data: CreateBayDT) => {
    return CreateBay(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  }
);
export const updateBay = createAsyncThunk(
  "bay/updateBay",
  (data: UpdateBayDT) => {
    return UpdateBay(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data?.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getAllBay = createAsyncThunk(
  "bay/getAllBay",
  async ({ id, queryParams }: { id: string; queryParams?: any }) => {
    try {
      let res = await GetAllBay(id, queryParams);
      if (res.status === 200) {
        return res?.data.bay;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllRole = createAsyncThunk("role/getAllRole", () => {
  return GetAllRole()
    .then((res) => {
      if (res.status === 200) {
        return res?.data.roles;
      }
    })
    .catch((error) => {
      console.log(error);
    });
});
export const getAllFunction = createAsyncThunk("role/getAllFunction", () => {
  return GetAllFunction()
    .then((res) => {
      if (res.status === 200) {
        return res?.data.function;
      }
    })
    .catch((error) => {
      console.log(error);
    });
});
export const createRole = createAsyncThunk(
  "role/createRole",
  (data: RoleFunction) => {
    return CreateRole(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  }
);
export const getAllRoleFunction = createAsyncThunk(
  "role/getAllRoleFunction",
  () => {
    return GetAllRoleFunction()
      .then((res) => {
        if (res.status === 200) {
          return res?.data.role;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  }
);
export const getAllActiveServiceCate = createAsyncThunk(
  "service/getAllActiveServiceCategory",
  (id: number) => {
    return GetAllActiveServiceCategory(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data.serviceCategory;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);
export const getAllActiveProductCate = createAsyncThunk(
  "product/getAllActiveProductCategory",
  (id: number) => {
    return GetAllActiveProductCategory(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data.productCategory;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);
export const createCurrency = createAsyncThunk(
  "currency/createCurrency",
  (data: CreateCurrencyDTO) => {
    return CreateCurrency(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  }
);
export const updateCurrency = createAsyncThunk(
  "currency/updateCurrency",
  (data: UpdateCurrencyDTO) => {
    return UpdateCurrency(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data?.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getAllCurrency = createAsyncThunk(
  "currency/getAllCurrency",
  (id: string) => {
    return GetAllCurrency(id)
      .then((res) => {
        if (res.status === 200) {
          return res?.data.currency;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  }
);

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    reset: () => initialState,
    setServiceCategoryDetails: (state, action: PayloadAction<any>) => {
      return { ...state, serviceCategoryDetails: action.payload };
    },
    setProductCategoryDetails: (state, action: PayloadAction<any>) => {
      return { ...state, productCategoryDetails: action.payload };
    },

    setServiceDetails: (state, action: PayloadAction<any>) => {
      return { ...state, serviceDetails: action.payload };
    },
    setProductDetails: (state, action: PayloadAction<any>) => {
      return { ...state, productDetails: action.payload };
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      return { ...state, userDetails: action.payload };
    },
    //product
    setInventryProductDetails: (state, action: PayloadAction<any>) => {
      return { ...state, inventryProductDetails: action.payload };
    },
    setUpdateInventryProductDetaile: (state, action: PayloadAction<any>) => {
      return { ...state, updateinventryProductDetails: action.payload };
    },
    createServiceDetaile: (state, action: PayloadAction<any>) => {
      return { ...state, createService_Details: action.payload };
    },
    setUpdateServiceDetails: (state, action: PayloadAction<any>) => {
      return { ...state, updateService_Details: action.payload };
    },
    createUserDetaile: (state, action: PayloadAction<any>) => {
      return { ...state, createUser_Details: action.payload };
    },
    setUpdateUserDetails: (state, action: PayloadAction<any>) => {
      return { ...state, updateUser_Details: action.payload };
    },
    setUpdateServiceCateDetails: (state, action: PayloadAction<any>) => {
      return { ...state, updateServiceCate_Details: action.payload };
    },
    setUpdateProductCateDetails: (state, action: PayloadAction<any>) => {
      return { ...state, updateProductCate_Details: action.payload };
    },
    createPartnerDetails: (state, action: PayloadAction<any>) => {
      return { ...state, createPartner_Details: action.payload };
    },
    setUpdatePartnerDetails: (state, action: PayloadAction<any>) => {
      return { ...state, updatePartner_Details: action.payload };
    },
    setPartnerDetails: (state, action: PayloadAction<any>) => {
      return { ...state, PartnerDetails: action.payload };
    },
    createSupplerDetails: (state, action: PayloadAction<any>) => {
      return { ...state, createSupplier_Details: action.payload };
    },
    setUpdateSupplerDetails: (state, action: PayloadAction<any>) => {
      return { ...state, updateSupplier_Details: action.payload };
    },
    setSupplerDetails: (state, action: PayloadAction<any>) => {
      return { ...state, SupplierDetails: action.payload };
    },
    createStockDetails: (state, action: PayloadAction<any>) => {
      return { ...state, createStock_Details: action.payload };
    },
    setUpdateStockDetails: (state, action: PayloadAction<any>) => {
      return { ...state, updateStock_Details: action.payload };
    },
    setStockDetails: (state, action: PayloadAction<any>) => {
      return { ...state, StockDetails: action.payload };
    },
    setRoleTypeDetails: (state, action: PayloadAction<any>) => {
      return { ...state, RoleTypeDetails: action.payload };
    },
    createBayDetails: (state, action: PayloadAction<any>) => {
      return { ...state, createBay_Details: action.payload };
    },
    setUpdateBayDetails: (state, action: PayloadAction<any>) => {
      return { ...state, updateBay_Details: action.payload };
    },
    setBayDetails: (state, action: PayloadAction<any>) => {
      return { ...state, BayDetails: action.payload };
    },
    setRoleDetails: (state, action: PayloadAction<any>) => {
      return { ...state, RoleDetails: action.payload };
    },
    setFunctionDetails: (state, action: PayloadAction<any>) => {
      return { ...state, FunctionDetails: action.payload };
    },
    createRoleDetails: (state, action: PayloadAction<any>) => {
      return { ...state, createRoleDetails: action.payload };
    },
    setRoleFunctionDetails: (state, action: PayloadAction<any>) => {
      return { ...state, getAllRoleFunctionDetails: action.payload };
    },
    createRoleCreationDetails: (state, action: PayloadAction<any>) => {
      return { ...state, createRoleCreationDetails: action.payload };
    },
    setActiveServiceCategoryDetails: (state, action: PayloadAction<any>) => {
      return { ...state, activeServiceCategoryDetails: action.payload };
    },
    setActiveProductCategoryDetails: (state, action: PayloadAction<any>) => {
      return { ...state, activeProductCategoryDetails: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllServiceCate.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getAllServiceCate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.serviceCategoryDetails = action?.payload;
      }
    );
    builder.addCase(getAllServiceCate.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(createServiceCate.pending, (state) => {
      state.createServiceCateLoading = true;
      state.createServiceCateSuccess = false;
      state.createServiceCateError = false;
    });
    builder.addCase(
      createServiceCate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createServiceCateLoading = false;
        state.createServiceCateSuccess = true;
        state.createServiceCateError = false;
        state.createServiceCateDetails = action?.payload;
      }
    );
    builder.addCase(createServiceCate.rejected, (state, action: AnyAction) => {
      state.createServiceCateLoading = false;
      state.createServiceCateSuccess = false;
      state.createServiceCateError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllProductCate.pending, (state) => {
      state.getAllProductCateLoading = true;
      state.getAllProductCateSuccess = false;
      state.getAllProductCateError = false;
    });
    builder.addCase(
      getAllProductCate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllProductCateLoading = false;
        state.getAllProductCateSuccess = true;
        state.getAllProductCateError = false;
        state.productCategoryDetails = action?.payload;
      }
    );
    builder.addCase(getAllProductCate.rejected, (state, action: AnyAction) => {
      state.getAllProductCateLoading = false;
      state.getAllProductCateSuccess = false;
      state.getAllProductCateError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createProductCate.pending, (state) => {
      state.createProductCateLoading = true;
      state.createProductCateSuccess = false;
      state.createProductCateError = false;
    });
    builder.addCase(
      createProductCate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createProductCateLoading = false;
        state.createProductCateSuccess = true;
        state.createProductCateError = false;
        state.createProductCateDetails = action?.payload;
      }
    );
    builder.addCase(createProductCate.rejected, (state, action: AnyAction) => {
      state.createProductCateLoading = false;
      state.createProductCateSuccess = false;
      state.createProductCateError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createInventryProduct.pending, (state) => {
      state.createInventryProduct_Loading = true;
      state.createInventryProduct_Error = false;
      state.createInventryProduct_Success = false;
    });
    builder.addCase(
      createInventryProduct.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createInventryProduct_Loading = false;
        state.createInventryProduct_Error = false;
        state.createInventryProduct_Success = true;
        state.inventryProductDetails = action?.payload;
      }
    );
    builder.addCase(
      createInventryProduct.rejected,
      (state, action: AnyAction) => {
        state.createInventryProduct_Loading = false;
        state.createInventryProduct_Error = true;
        state.createInventryProduct_Success = false;
        state.createInventryProduct_Error =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(updateInventryProduct.pending, (state) => {
      state.updateInventryProduct_Loading = true;
      state.updateInventryProduct_Error = false;
      state.updateInventryProduct_Success = false;
    });

    builder.addCase(
      updateInventryProduct.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateInventryProduct_Loading = false;
        state.updateInventryProduct_Error = false;
        state.updateInventryProduct_Success = true;
        state.updateinventryProductDetails = action?.payload;
      }
    );
    builder.addCase(
      updateInventryProduct.rejected,
      (state, action: AnyAction) => {
        state.updateInventryProduct_Loading = false;
        state.updateInventryProduct_Error = true;
        state.updateInventryProduct_Success = false;
        state.updateInventryProduct_Error =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(createService.pending, (state) => {
      state.createService_Loading = true;
      state.createService_Error = false;
      state.createService_Success = false;
    });
    builder.addCase(
      createService.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createService_Loading = false;
        state.createService_Error = false;
        state.createService_Success = true;
        state.createService_Details = action?.payload;
      }
    );
    builder.addCase(createService.rejected, (state, action: AnyAction) => {
      state.createService_Loading = false;
      state.createService_Error = true;
      state.createService_Success = false;
      state.createService_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateService.pending, (state) => {
      state.updateService_Loading = true;
      state.updateService_Error = false;
      state.updateService_Success = false;
    });
    builder.addCase(
      updateService.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateService_Loading = false;
        state.updateService_Error = false;
        state.updateService_Success = true;
        state.updateService_Details = action?.payload;
      }
    );
    builder.addCase(updateService.rejected, (state, action: AnyAction) => {
      state.updateService_Loading = false;
      state.updateService_Error = true;
      state.updateService_Success = false;
      state.updateService_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllService.pending, (state) => {
      state.getAllServiceLoading = true;
      state.getAllServiceSuccess = false;
      state.getAllServiceError = false;
    });
    builder.addCase(
      getAllService.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllServiceLoading = false;
        state.getAllServiceSuccess = true;
        state.getAllServiceError = false;
        state.serviceDetails = action?.payload;
      }
    );
    builder.addCase(getAllService.rejected, (state, action: AnyAction) => {
      state.getAllServiceLoading = false;
      state.getAllServiceSuccess = false;
      state.getAllServiceError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllProduct.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getAllProduct.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.productDetails = action?.payload;
      }
    );
    builder.addCase(getAllProduct.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllUser.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getAllUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.userDetails = action?.payload;
      }
    );
    builder.addCase(getAllUser.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(createUser.pending, (state) => {
      state.createUser_Loading = true;
      state.createUser_Error = false;
      state.createUser_Success = false;
    });
    builder.addCase(
      createUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createUser_Loading = false;
        state.createUser_Error = false;
        state.createUser_Success = true;
        state.createUser_Details = action?.payload;
      }
    );
    builder.addCase(createUser.rejected, (state, action: AnyAction) => {
      state.createUser_Loading = false;
      state.createUser_Error = true;
      state.createUser_Success = false;
      state.createUser_Error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateUser.pending, (state) => {
      state.updateUser_Loading = true;
      state.updateUser_Error = false;
      state.updateUser_Success = false;
    });
    builder.addCase(
      updateUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateUser_Loading = false;
        state.updateUser_Error = false;
        state.updateUser_Success = true;
        state.updateUser_Details = action?.payload;
      }
    );
    builder.addCase(updateUser.rejected, (state, action: AnyAction) => {
      state.updateUser_Loading = false;
      state.updateUser_Error = true;
      state.updateUser_Success = false;
      state.updateUser_Error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateProductCate.pending, (state) => {
      state.updateProductCate_Loading = true;
      state.updateProductCate_Error = false;
      state.updateProductCate_Success = false;
    });
    builder.addCase(
      updateProductCate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateProductCate_Loading = false;
        state.updateProductCate_Error = false;
        state.updateProductCate_Success = true;
        state.updateProductCate_Details = action?.payload;
      }
    );
    builder.addCase(updateProductCate.rejected, (state, action: AnyAction) => {
      state.updateProductCate_Loading = false;
      state.updateProductCate_Error = true;
      state.updateProductCate_Success = false;
      state.updateProductCate_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateServiceCate.pending, (state) => {
      state.updateServiceCate_Loading = true;
      state.updateServiceCate_Error = false;
      state.updateServiceCate_Success = false;
    });
    builder.addCase(
      updateServiceCate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateServiceCate_Loading = false;
        state.updateServiceCate_Error = false;
        state.updateServiceCate_Success = true;
        state.updateServiceCate_Details = action?.payload;
      }
    );
    builder.addCase(updateServiceCate.rejected, (state, action: AnyAction) => {
      state.updateServiceCate_Loading = false;
      state.updateServiceCate_Error = true;
      state.updateServiceCate_Success = false;
      state.updateServiceCate_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createPartner.pending, (state) => {
      state.createPartner_Loading = true;
      state.createPartner_Error = false;
      state.createPartner_Success = false;
    });
    builder.addCase(
      createPartner.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createPartner_Loading = false;
        state.createPartner_Error = false;
        state.createPartner_Success = true;
        state.createPartner_Details = action?.payload;
      }
    );
    builder.addCase(createPartner.rejected, (state, action: AnyAction) => {
      state.createPartner_Loading = false;
      state.createPartner_Error = true;
      state.createPartner_Success = false;
      state.createPartner_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(updatePartner.pending, (state) => {
      state.updatePartner_Loading = true;
      state.updatePartner_Error = false;
      state.updatePartner_Success = false;
    });
    builder.addCase(
      updatePartner.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updatePartner_Loading = false;
        state.updatePartner_Error = false;
        state.updatePartner_Success = true;
        state.updatePartner_Details = action?.payload;
      }
    );
    builder.addCase(updatePartner.rejected, (state, action: AnyAction) => {
      state.updatePartner_Loading = false;
      state.updatePartner_Error = true;
      state.updatePartner_Success = false;
      state.updatePartner_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllPartner.pending, (state) => {
      state.getAllPartnerLoading = true;
      state.getAllPartnerSuccess = false;
      state.getAllPartnerError = false;
    });
    builder.addCase(
      getAllPartner.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllPartnerLoading = false;
        state.getAllPartnerSuccess = true;
        state.getAllPartnerError = false;
        state.PartnerDetails = action?.payload;
      }
    );
    builder.addCase(getAllPartner.rejected, (state, action: AnyAction) => {
      state.getAllPartnerLoading = false;
      state.getAllPartnerSuccess = false;
      state.getAllPartnerError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createSupplier.pending, (state) => {
      state.createSupplier_Loading = true;
      state.createSupplier_Error = false;
      state.createSupplier_Success = false;
    });
    builder.addCase(
      createSupplier.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createSupplier_Loading = false;
        state.createSupplier_Error = false;
        state.createSupplier_Success = true;
        state.createSupplier_Details = action?.payload;
      }
    );
    builder.addCase(createSupplier.rejected, (state, action: AnyAction) => {
      state.createSupplier_Loading = false;
      state.createSupplier_Error = true;
      state.createSupplier_Success = false;
      state.createSupplier_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateSupplier.pending, (state) => {
      state.updateSupplier_Loading = true;
      state.updateSupplier_Error = false;
      state.updateSupplier_Success = false;
    });
    builder.addCase(
      updateSupplier.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateSupplier_Loading = false;
        state.updateSupplier_Error = false;
        state.updateSupplier_Success = true;
        state.updateSupplier_Details = action?.payload;
      }
    );
    builder.addCase(updateSupplier.rejected, (state, action: AnyAction) => {
      state.updateSupplier_Loading = false;
      state.updateSupplier_Error = true;
      state.updateSupplier_Success = false;
      state.updateSupplier_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllSupplier.pending, (state) => {
      state.getAllSupplierLoading = true;
      state.getAllSupplierSuccess = false;
      state.getAllSupplierError = false;
    });
    builder.addCase(
      getAllSupplier.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllSupplierLoading = false;
        state.getAllSupplierSuccess = true;
        state.getAllSupplierError = false;
        state.SupplierDetails = action?.payload;
      }
    );
    builder.addCase(getAllSupplier.rejected, (state, action: AnyAction) => {
      state.getAllSupplierLoading = false;
      state.getAllSupplierSuccess = false;
      state.getAllSupplierError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createStock.pending, (state) => {
      state.createStock_Loading = true;
      state.createStock_Error = false;
      state.createStock_Success = false;
    });
    builder.addCase(
      createStock.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createStock_Loading = false;
        state.createStock_Error = false;
        state.createStock_Success = true;
        state.createStock_Details = action?.payload;
      }
    );
    builder.addCase(createStock.rejected, (state, action: AnyAction) => {
      state.createStock_Loading = false;
      state.createStock_Error = true;
      state.createStock_Success = false;
      state.createStock_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateStock.pending, (state) => {
      state.updateStock_Loading = true;
      state.updateStock_Error = false;
      state.updateStock_Success = false;
    });
    builder.addCase(
      updateStock.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateStock_Loading = false;
        state.updateStock_Error = false;
        state.updateStock_Success = true;
        state.updateStock_Details = action?.payload;
      }
    );
    builder.addCase(updateStock.rejected, (state, action: AnyAction) => {
      state.updateStock_Loading = false;
      state.updateStock_Error = true;
      state.updateStock_Success = false;
      state.updateStock_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllStock.pending, (state) => {
      state.getAllStockLoading = true;
      state.getAllStockSuccess = false;
      state.getAllStockError = false;
    });
    builder.addCase(
      getAllStock.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllStockLoading = false;
        state.getAllStockSuccess = true;
        state.getAllStockError = false;
        state.StockDetails = action?.payload;
      }
    );
    builder.addCase(getAllStock.rejected, (state, action: AnyAction) => {
      state.getAllStockLoading = false;
      state.getAllStockSuccess = false;
      state.getAllStockError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(outOfStock.pending, (state) => {
      state.outOfStockLoading = true;
      state.outOfStockSuccess = false;
      state.outOfStockError = false;
    });
    builder.addCase(
      outOfStock.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.outOfStockLoading = false;
        state.outOfStockSuccess = true;
        state.outOfStockError = false;
        state.outOfStockDetails = action?.payload;
      }
    );
    builder.addCase(outOfStock.rejected, (state, action: AnyAction) => {
      state.outOfStockLoading = false;
      state.outOfStockSuccess = false;
      state.outOfStockError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllRoleType.pending, (state) => {
      state.getAllRoleTypeLoading = true;
      state.getAllRoleTypeSuccess = false;
      state.getAllRoleTypeError = false;
    });
    builder.addCase(
      getAllRoleType.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllRoleTypeLoading = false;
        state.getAllRoleTypeSuccess = true;
        state.getAllRoleTypeError = false;
        state.RoleTypeDetails = action?.payload;
      }
    );
    builder.addCase(getAllRoleType.rejected, (state, action: AnyAction) => {
      state.getAllRoleTypeLoading = false;
      state.getAllRoleTypeSuccess = false;
      state.getAllRoleTypeError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createBay.pending, (state) => {
      state.createBay_Loading = true;
      state.createBay_Error = false;
      state.createBay_Success = false;
    });
    builder.addCase(
      createBay.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createBay_Loading = false;
        state.createBay_Error = false;
        state.createBay_Success = true;
        state.createBay_Details = action?.payload;
      }
    );
    builder.addCase(createBay.rejected, (state, action: AnyAction) => {
      state.createBay_Loading = false;
      state.createBay_Error = true;
      state.createBay_Success = false;
      state.createBay_Error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateBay.pending, (state) => {
      state.updateBay_Loading = true;
      state.updateBay_Error = false;
      state.updateBay_Success = false;
    });
    builder.addCase(
      updateBay.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateBay_Loading = false;
        state.updateBay_Error = false;
        state.updateBay_Success = true;
        state.updateBay_Details = action?.payload;
      }
    );
    builder.addCase(updateBay.rejected, (state, action: AnyAction) => {
      state.updateBay_Loading = false;
      state.updateBay_Error = true;
      state.updateBay_Success = false;
      state.updateBay_Error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllBay.pending, (state) => {
      state.getAllBayLoading = true;
      state.getAllBaySuccess = false;
      state.getAllBayError = false;
    });
    builder.addCase(
      getAllBay.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllBayLoading = false;
        state.getAllBaySuccess = true;
        state.getAllBayError = false;
        state.BayDetails = action?.payload;
      }
    );
    builder.addCase(getAllBay.rejected, (state, action: AnyAction) => {
      state.getAllBayLoading = false;
      state.getAllBaySuccess = false;
      state.getAllBayError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllRole.pending, (state) => {
      state.getAllRoleLoading = true;
      state.getAllRoleSuccess = false;
      state.getAllRoleError = false;
    });
    builder.addCase(
      getAllRole.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllRoleLoading = false;
        state.getAllRoleSuccess = true;
        state.getAllRoleError = false;
        state.RoleDetails = action?.payload;
      }
    );
    builder.addCase(getAllRole.rejected, (state, action: AnyAction) => {
      state.getAllRoleLoading = false;
      state.getAllRoleSuccess = false;
      state.getAllRoleError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllFunction.pending, (state) => {
      state.getAllFunctionLoading = true;
      state.getAllFunctionSuccess = false;
      state.getAllFunctionError = false;
    });
    builder.addCase(
      getAllFunction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllFunctionLoading = false;
        state.getAllFunctionSuccess = true;
        state.getAllFunctionError = false;
        state.FunctionDetails = action?.payload;
      }
    );
    builder.addCase(getAllFunction.rejected, (state, action: AnyAction) => {
      state.getAllFunctionLoading = false;
      state.getAllFunctionSuccess = false;
      state.getAllFunctionError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createRole.pending, (state) => {
      state.createRoleLoading = true;
      state.createRoleError = false;
      state.createRoleSuccess = false;
    });
    builder.addCase(
      createRole.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createRoleLoading = false;
        state.createRoleError = false;
        state.createRoleSuccess = true;
        state.createRoleDetails = action?.payload;
      }
    );
    builder.addCase(createRole.rejected, (state, action: AnyAction) => {
      state.createRoleLoading = false;
      state.createRoleError = true;
      state.createRoleSuccess = false;
      state.createRoleError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllRoleFunction.pending, (state) => {
      state.getAllRoleFunctionLoading = true;
      state.getAllRoleFunctionSuccess = false;
      state.getAllRoleFunctionError = false;
    });
    builder.addCase(
      getAllRoleFunction.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllRoleFunctionLoading = false;
        state.getAllRoleFunctionSuccess = true;
        state.getAllRoleFunctionError = false;
        state.getAllRoleFunctionDetails = action?.payload;
      }
    );
    builder.addCase(getAllRoleFunction.rejected, (state, action: AnyAction) => {
      state.getAllRoleFunctionLoading = false;
      state.getAllRoleFunctionSuccess = false;
      state.getAllRoleFunctionError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(createRoleCreation.pending, (state) => {
      state.createRoleCreationLoading = true;
      state.createRoleCreationError = false;
      state.createRoleCreationSuccess = false;
    });
    builder.addCase(
      createRoleCreation.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createRoleCreationLoading = false;
        state.createRoleCreationError = false;
        state.createRoleCreationSuccess = true;
        state.createRoleCreationDetails = action?.payload;
      }
    );
    builder.addCase(createRoleCreation.rejected, (state, action: AnyAction) => {
      state.createRoleCreationLoading = false;
      state.createRoleCreationError = true;
      state.createRoleCreationSuccess = false;
      state.createRoleCreationError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllActiveServiceCate.pending, (state) => {
      state.getAllServiceActiveCateLoading = true;
      state.getAllServiceActiveCateSuccess = false;
      state.getAllServiceActiveCateError = false;
    });
    builder.addCase(
      getAllActiveServiceCate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllServiceActiveCateLoading = false;
        state.getAllServiceActiveCateSuccess = true;
        state.getAllServiceActiveCateError = false;
        state.activeServiceCategoryDetails = action?.payload;
      }
    );
    builder.addCase(
      getAllActiveServiceCate.rejected,
      (state, action: AnyAction) => {
        state.getAllServiceActiveCateLoading = false;
        state.getAllServiceActiveCateSuccess = false;
        state.getAllServiceActiveCateError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getAllActiveProductCate.pending, (state) => {
      state.getAllProductActiveCateLoading = true;
      state.getAllProductActiveCateSuccess = false;
      state.getAllProductActiveCateError = false;
    });
    builder.addCase(
      getAllActiveProductCate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllProductActiveCateLoading = false;
        state.getAllProductActiveCateSuccess = true;
        state.getAllProductActiveCateError = false;
        state.activeProductCategoryDetails = action?.payload;
      }
    );
    builder.addCase(
      getAllActiveProductCate.rejected,
      (state, action: AnyAction) => {
        state.getAllProductActiveCateLoading = false;
        state.getAllProductActiveCateSuccess = false;
        state.getAllProductActiveCateError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(createCurrency.pending, (state) => {
      state.createCurrency_Loading = true;
      state.createCurrency_Error = false;
      state.createCurrency_Success = false;
    });
    builder.addCase(
      createCurrency.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createCurrency_Loading = false;
        state.createCurrency_Error = false;
        state.createCurrency_Success = true;
        state.createCurrency_Details = action?.payload;
      }
    );
    builder.addCase(createCurrency.rejected, (state, action: AnyAction) => {
      state.createCurrency_Loading = false;
      state.createCurrency_Error = true;
      state.createCurrency_Success = false;
      state.createCurrency_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateCurrency.pending, (state) => {
      state.updateCurrency_Loading = true;
      state.updateCurrency_Error = false;
      state.updateCurrency_Success = false;
    });
    builder.addCase(
      updateCurrency.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateCurrency_Loading = false;
        state.updateCurrency_Error = false;
        state.updateCurrency_Success = true;
        state.updateCurrency_Details = action?.payload;
      }
    );
    builder.addCase(updateCurrency.rejected, (state, action: AnyAction) => {
      state.updateCurrency_Loading = false;
      state.updateCurrency_Error = true;
      state.updateCurrency_Success = false;
      state.updateCurrency_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllCurrency.pending, (state) => {
      state.getAllCurrencyLoading = true;
      state.getAllCurrencySuccess = false;
      state.getAllCurrencyError = false;
    });
    builder.addCase(
      getAllCurrency.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllCurrencyLoading = false;
        state.getAllCurrencySuccess = true;
        state.getAllCurrencyError = false;
        state.CurrencyDetails = action?.payload;
      }
    );
    builder.addCase(getAllCurrency.rejected, (state, action: AnyAction) => {
      state.getAllCurrencyLoading = false;
      state.getAllCurrencySuccess = false;
      state.getAllCurrencyError =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default configurationSlice.reducer;

export const {
  reset,
  setServiceCategoryDetails,
  setProductCategoryDetails,
  setInventryProductDetails,
  setProductDetails,
  setServiceDetails,
  setUserDetails,
  setUpdateInventryProductDetaile,
  createServiceDetaile,
  setUpdateServiceDetails,
  createUserDetaile,
  setUpdateUserDetails,
  setUpdateProductCateDetails,
  setUpdateServiceCateDetails,
  createPartnerDetails,
  setPartnerDetails,
  setUpdatePartnerDetails,
  createSupplerDetails,
  setSupplerDetails,
  setUpdateSupplerDetails,
  createStockDetails,
  setStockDetails,
  setUpdateStockDetails,
  setRoleTypeDetails,
  createBayDetails,
  setBayDetails,
  setUpdateBayDetails,
  setRoleDetails,
  createRoleDetails,
  setFunctionDetails,
  createRoleCreationDetails,
  setRoleFunctionDetails,
} = configurationSlice.actions;
