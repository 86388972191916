import { connect } from "react-redux";
import {
  createStock,
  getAllActiveProductCate,
  getAllSupplier,
  reset,
  updateStock,
} from "../../../../../../pages/app/Configuration/services/configurationSlice";
import {
  CreateStockApiDTo,
  ProductCategoryRes,
  UpdateStockApiDTo,
} from "../../../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../../../redux/store";
import StockInventoryForm from "../components/StockInventoryForm";

interface StateProps {
  // getAllProductCateSuccess: boolean;
  // getAllProductCateError: string | boolean | undefined;
  // getAllProductCateLoading: boolean;
  // productCategoryDetails: any;
  getAllProductActiveCateSuccess: boolean;
  getAllProductActiveCateError: boolean;
  getAllProductActiveCateLoading: boolean;
  activeProductCategoryDetails: ProductCategoryRes[];
  getAllSupplierSuccess: boolean;
  getAllSupplierError: boolean;
  getAllSupplierLoading: boolean;
  SupplierDetails: null | [];
  createStock_Loading: boolean;
  createStock_Error: boolean;
  createStock_Success: boolean;
  createStock_Details: null;
  updateStock_Loading: boolean;
  updateStock_Error: boolean;
  updateStock_Success: boolean;
  updateStock_Details: null;
}
interface DispatchProps {
  GetAllActiveProductCate(id: number): void;
  GetAllSupplier(id: string): void;
  resetAll(): void;
  CreateStock(data: CreateStockApiDTo): void;
  UpdateStock(data: UpdateStockApiDTo): void;
}
const mapStateToProps = (state: RootState): StateProps => {
  return {
    // getAllProductCateSuccess: state.configuration.getAllProductCateSuccess,
    // getAllProductCateError: state.configuration.getAllProductCateError,
    // getAllProductCateLoading: state.configuration.getAllProductCateLoading,
    // productCategoryDetails: state.configuration.productCategoryDetails,
    getAllProductActiveCateSuccess:
      state.configuration.getAllProductActiveCateSuccess,
    getAllProductActiveCateError:
      state.configuration.getAllProductActiveCateError,
    getAllProductActiveCateLoading:
      state.configuration.getAllProductActiveCateLoading,
    activeProductCategoryDetails:
      state.configuration.activeProductCategoryDetails,
    getAllSupplierSuccess: state.configuration.getAllSupplierSuccess,
    getAllSupplierError: state.configuration.getAllSupplierError,
    getAllSupplierLoading: state.configuration.getAllSupplierLoading,
    SupplierDetails: state.configuration.SupplierDetails,
    createStock_Loading: state.configuration.createStock_Loading,
    createStock_Error: state.configuration.createStock_Error,
    createStock_Success: state.configuration.createStock_Success,
    createStock_Details: state.configuration.createStock_Details,
    updateStock_Loading: state.configuration.updateStock_Loading,
    updateStock_Error: state.configuration.updateStock_Error,
    updateStock_Success: state.configuration.updateStock_Success,
    updateStock_Details: state.configuration.updateStock_Details,
  };
};
const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllActiveProductCate: (id) => {
      dispatch(getAllActiveProductCate(id));
    },
    GetAllSupplier: (id) => {
      dispatch(getAllSupplier({ id }));
    },
    resetAll: () => {
      dispatch(reset());
    },
    CreateStock: (data) => {
      dispatch(createStock(data));
    },
    UpdateStock: (data) => {
      dispatch(updateStock(data));
    },
  };
};
export interface StockInventoryFormProps extends StateProps, DispatchProps {}
export const StockInventoryFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(StockInventoryForm);
