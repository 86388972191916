import React from "react";
import { Route, Routes } from "react-router-dom";
import { BayFormComponent } from "../components/appSpecific/Bays/container/bayContainer";
import { CurrencyFormComponent } from "../components/appSpecific/Currency/container/currencyContainer";
import { PartnerFormComponent } from "../components/appSpecific/Partner/container/partnerContainer";
import { ProductCategoryComponent } from "../components/appSpecific/ProductCategory/container/productCategoryContainer";
import ProductInventory from "../components/appSpecific/ProductInventory/components/ProductInventory";
import { ProductInventoryFormComponent } from "../components/appSpecific/ProductInventory/components/ProductInventoryForm/container/productInventoryContainer";
import { RoleFormComponent } from "../components/appSpecific/Role/container/roleContainer";
import { RoleCreationFormComponent } from "../components/appSpecific/RoleCreation/container/roleCreationContainer";
import { ServiceCategoryComponent } from "../components/appSpecific/ServiceCategory/container/serviceCategoryContainer";
import ServiceInventory from "../components/appSpecific/ServiceInventory/components/ServiceInventory";
import { ServiceInventoryFormComponent } from "../components/appSpecific/ServiceInventory/container/serviceInventoryContainer";
import { StockInventoryFormComponent } from "../components/appSpecific/StockInventory/components/StockInventoryForm/container/stockInventoryFormContainer";
import SubscriptionPlanForm from "../components/appSpecific/SubscriptionPlan/components/SubscriptionPlanForm/SubscriptionPlanForm";
import { SupplerFormComponent } from "../components/appSpecific/Suppliers/container/supplierContainer";
import { UserFormComponent } from "../components/appSpecific/User/container/userContainer";
import NotFoundComponent from "../components/common/NotFoundComponent/NotFoundComponent";
import { AllMechanicsComponent } from "../pages/app/AllMechanics/container/allMechanicContainer";
import Analytics from "../pages/app/Analytics/components/Analytics";
import { ArchiveJobCardComponent } from "../pages/app/ArchiveJobCard/container/archiveJobCardContainer";
import {
  Bay,
  ConfigurationComponent,
  Currency,
  Partner,
  ProductCategory,
  Products,
  Role,
  RoleCreation,
  Service,
  ServiceCategory,
  Stock,
  SubscriptionPlan,
  Supplier,
  User,
} from "../pages/app/Configuration/container/configuartionContainer";
import { DashboardComponent } from "../pages/app/DashbordNew/container/DashboardContainer";
import { EditProfileComponent } from "../pages/app/EditProfile/container/editProfileContainer";
import { ExpensesLogFormComponent } from "../pages/app/ExpensesLog/components/ExpensesLogForm/container/ExpensesLogFormContainer";
import { ExpensesLogComponent } from "../pages/app/ExpensesLog/container/expensesLogContainer";
import { HistoryComponent } from "../pages/app/History/container/historyContainer";
import { InvoiceComponent } from "../pages/app/Invoice/container/invoiceContainer";
import { HomeComponent } from "../pages/app/Manage/Home/container/homeContainer";
import { RegistrationComponent } from "../pages/app/Manage/VeichleRegistration/container/registrationContainer";
import { MechanicPendingComponent } from "../pages/app/Mechanic/components/MechanicPendingtask/container/mechanicPendingTaskContainer";
import { MechanicComponent } from "../pages/app/Mechanic/container/Mechanic.container";
import NewOwnerDashbord from "../pages/app/NewOwnerDashbord/NewOwnerDashbord";
import Partners from "../pages/app/Partners/components/Partners";
import { PaymentPageComponent } from "../pages/app/Payment/container/paymentContainer";
import { GenerateReportComponent } from "../pages/app/Reports/components/GenerateReport/container/garageReportContainer";
import { JobDurationReportComponent } from "../pages/app/Reports/components/JobDurationReport/container/jobDurationReportContainer";
import { MechanicReportComponent } from "../pages/app/Reports/components/MechanicsPerformanceReport/container/mechanicsPerformanceReportContainer";
import { RevenueReportComponent } from "../pages/app/Reports/components/RevenueReport/container/revenueReportContainer";
import { SparePartsReportComponent } from "../pages/app/Reports/components/SparePartsReport/container/sparePartsReportContainer";
import { TaskReportComponent } from "../pages/app/Reports/components/TaskReport/container/taskReportContainer";
import { ReportsComponent } from "../pages/app/Reports/containers/reportsContainer";
import { SparePartRequestComponent } from "../pages/app/SparePartRequest/container/spareContainer";
import { SupervisorDashboardComponent } from "../pages/app/SupervisorDashboard/container/dashboardContainer";
import {
  getAuthDataFromLocalStorage,
  getUserDataFromLocalStorage,
} from "../utils/helper.utils";

export default function AppRouter() {
  const [user, setUser] = React.useState<any>();
  React.useEffect(() => {
    const userData: string | null = getUserDataFromLocalStorage();
    if (userData) {
      const userDataParse: any = userData;
      setUser(userDataParse.user_type);
      // userDataParse ? setUserData(userDataParse) : setUserDataNull();
    }
  }, []);
  const authData = getAuthDataFromLocalStorage(); // Replace this with the appropriate function to retrieve authData from local storage
  const { category, rolename } = authData ?? {};
  const jobCardCategory = category?.find(
    (category: any) => category?.name === "Dashboard"
  );
  const superVisorCategory = category?.find(
    (category: any) => category?.name === "Job-Card"
  );

  const functions = jobCardCategory?.functions;
  const functionsSuperVisorCategory = superVisorCategory?.functions;

  const isRoleAllowed = ["Job-Card"].some((str) =>
    functionsSuperVisorCategory?.includes(str)
  );
  return (
    <Routes>
      {user === "Owner" && (
        <>
          <Route path="/" element={<NewOwnerDashbord />} />
        </>
      )}
      {["Dashboard"].some((str) => functions?.includes(str)) ? (
        <>
          <Route path="/" element={<DashboardComponent />} />
          {/* <Route path="/" element={<NewOwnerDashbord />} /> */}
        </>
      ) : null}
      {["Create"].some((str) => functionsSuperVisorCategory?.includes(str)) ? (
        <>
          {" "}
          <Route path="/" element={<SupervisorDashboardComponent />} />
          <Route path="/job-card" element={<HomeComponent />} />
        </>
      ) : null}
      <>
        {/* <Route path="/" element={<OwnerDashboardComponent />} /> */}
        {/* <Route path="/" element={<DashboardNew />} /> */}
        <Route path="/partners-management" element={<Partners />} />

        <Route path="/configuration" element={<ConfigurationComponent />} />
        <Route path="*" element={<NotFoundComponent />} />
      </>
      {/* )} */}
      {/* {user === "Partner" && ( */}
      <>
        {/* <Route path="/" element={<PartnerDashboardComponent />} /> */}
        {/* <Route path="/" element={<DashboardNew />} /> */}

        <Route path="/configuration" element={<ConfigurationComponent />} />
        <Route path="/reports" element={<ReportsComponent />} />
        <Route path="/generate-report" element={<GenerateReportComponent />} />
        <Route path="/role" element={<Role />} />
        <Route path="/role-form/add" element={<RoleFormComponent />} />
        <Route path="/role-form/edit" element={<RoleFormComponent />} />
        <Route path="/subscription-plan" element={<SubscriptionPlan />} />
        <Route
          path="/subscription-plan-form/add"
          element={<SubscriptionPlanForm />}
        />
        <Route
          path="/subscription-plan-form/edit"
          element={<SubscriptionPlanForm />}
        />
        <Route path="*" element={<NotFoundComponent />} />
      </>
      {/* )}
      {user === "Supervisor" && ( */}
      <>
        {/* <Route path="/" element={<HomeComponent />} /> */}
        <Route
          path="/spare-part-request"
          element={<SparePartRequestComponent />}
        />
        <Route path="/configuration" element={<ConfigurationComponent />} />
        <Route path="/payment" element={<PaymentPageComponent />} />
        <Route path="/reports" element={<ReportsComponent />} />
        <Route path="/garage-report" element={<GenerateReportComponent />} />
        <Route
          path="/job-duration-report"
          element={<JobDurationReportComponent />}
        />
        <Route
          path="/mechanics-performance-report"
          element={<MechanicReportComponent />}
        />
        <Route path="/revenue-report" element={<RevenueReportComponent />} />
        <Route
          path="/spare-part-report"
          element={<SparePartsReportComponent />}
        />
        <Route path="/task-report" element={<TaskReportComponent />} />
        <Route path="/bays" element={<Bay />} />
        <Route path="/bay-form/edit" element={<BayFormComponent />} />
        <Route path="/bay-form/add" element={<BayFormComponent />} />
        <Route path="*" element={<NotFoundComponent />} />
      </>
      {/* )} */}
      <Route path="/" element={<MechanicComponent />} />
      <Route path="/mechanic" element={<MechanicComponent />} />
      <Route path="/request-spare" element={<MechanicPendingComponent />} />
      {/* <Route path="/" element={<HomeComponent />} /> */}

      <Route path="/analytics" element={<Analytics />} />
      {/* <Route path="/mechanic" element={<MechanicComponent />} /> */}
      <Route path="/vehicle-registration" element={<RegistrationComponent />} />
      <Route path="/all-mechanics" element={<AllMechanicsComponent />} />
      <Route path="/my-teams" element={<AllMechanicsComponent />} />
      {/* <Route path="/configuration" element={<ConfigurationComponent />} /> */}
      <Route path="/products" element={<Products />} />
      <Route path="/product-category" element={<ProductCategory />} />
      <Route path="/services" element={<Service />} />
      <Route path="/service-category" element={<ServiceCategory />} />
      <Route path="/users" element={<User />} />
      <Route path="/product-management" element={<ProductInventory />} />
      <Route path="/services-management" element={<ServiceInventory />} />
      <Route path="/invoice" element={<InvoiceComponent />} />
      <Route path="/partner" element={<Partner />} />
      <Route path="/partner-form/edit" element={<PartnerFormComponent />} />
      <Route path="/partner-form/add" element={<PartnerFormComponent />} />
      <Route path="/suppliers" element={<Supplier />} />
      <Route path="/supplier-form/edit" element={<SupplerFormComponent />} />
      <Route path="/supplier-form/add" element={<SupplerFormComponent />} />

      <Route path="/stock-inventory" element={<Stock />} />
      <Route
        path="/stock-inventory-form/edit"
        element={<StockInventoryFormComponent />}
      />
      <Route
        path="/stock-inventory-form/add"
        element={<StockInventoryFormComponent />}
      />
      <Route path="/role-creation" element={<RoleCreation />} />
      <Route
        path="/role-creation-form/edit"
        element={<RoleCreationFormComponent />}
      />
      <Route
        path="/role-creation-form/add"
        element={<RoleCreationFormComponent />}
      />
      <Route
        path="/product-management-form/add"
        element={<ProductInventoryFormComponent />}
      />
      <Route
        path="/product-management-form/edit"
        element={<ProductInventoryFormComponent />}
      />
      <Route
        path="/services-management-form/add"
        element={<ServiceInventoryFormComponent />}
      />
      <Route
        path="/services-management-form/edit"
        element={<ServiceInventoryFormComponent />}
      />
      <Route
        path="/product-category-form/add"
        element={<ProductCategoryComponent />}
      />
      <Route
        path="/product-category-form/edit"
        element={<ProductCategoryComponent />}
      />
      <Route
        path="/services-category-form/add"
        element={<ServiceCategoryComponent />}
      />
      <Route
        path="/services-category-form/edit"
        element={<ServiceCategoryComponent />}
      />
      <Route path="/user-form/add" element={<UserFormComponent />} />
      <Route path="/user-form/edit" element={<UserFormComponent />} />

      {/* <Route path="/company-form/edit" element={<CompanyForm />} /> */}
      {/* <Route path="/dashbordmain" element={<DashboardMain />} /> */}
      {/* <Route path="/servicedashbord" element={<Servicedashbord />} /> */}
      <Route path="/dashbordnew" element={<DashboardComponent />} />
      <Route path="/history" element={<HistoryComponent />} />
      <Route path="/edit-profile" element={<EditProfileComponent />} />
      <Route path="/expenses-log" element={<ExpensesLogComponent />} />
      <Route path="/expenses-form/add" element={<ExpensesLogFormComponent />} />
      <Route
        path="/expenses-form/edit"
        element={<ExpensesLogFormComponent />}
      />
      <Route path="/currency" element={<Currency />} />
      <Route path="/currency-form/add" element={<CurrencyFormComponent />} />
      <Route path="/currency-form/edit" element={<CurrencyFormComponent />} />
      <Route path="/archive-jobcard" element={<ArchiveJobCardComponent />} />
    </Routes>
  );
}
