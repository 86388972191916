import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { GetFeedback } from "./customerTracking.request";

export const initialState: any = {
  getFeedback_Loading: false,
  getFeedback_Error: false,
  getFeedback_Success: false,
  getFeedback_Details: null,
};

export const getFeedback = createAsyncThunk(
  "/user/getFeedback",
  (data: string) => {
    return GetFeedback(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data.customerFeedback;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);

export const TrackingSlice = createSlice({
  name: "Tracking",
  initialState,
  reducers: {
    reset: () => initialState,
    setGetFeedback: (state, action: PayloadAction<any>) => {
      return { ...state, getFeedback_Details: action.payload };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getFeedback.pending, (state) => {
      state.getFeedback_Loading = true;
      state.getFeedback_Success = false;
      state.getFeedback_Error = false;
    });

    builder.addCase(
      getFeedback.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getFeedback_Loading = false;
        state.getFeedback_Success = true;
        state.getFeedback_Error = false;
        state.getFeedback_Details = action?.payload;
      }
    );

    builder.addCase(getFeedback.rejected, (state, action: AnyAction) => {
      state.getFeedback_Loading = false;
      state.getFeedback_Error = false;
      state.getFeedback_Success = true;
      state.getFeedback_Error =
        action?.error?.message || "Something went wrong";
    });

    // postFeedback
  },
});

export default TrackingSlice.reducer;
export const { reset, setGetFeedback } = TrackingSlice.actions;
