import { CircularProgress, Modal, TextField, Typography } from "@mui/material";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { Lock1 } from "iconsax-react";
import moment from "moment";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LogovetricyStudio from "../../../assets/images/VerticiStudio.svg";
import GatePass from "../../../components/appSpecific/GatePass/GatePass";
import ButtonLog from "../../../components/common/Button/Button";
import CustomLoader from "../../../components/common/Loader/Loader";
import styles from "./Feedback.module.css";
import FeedbackComponent from "./FeedbackComponent";
import { FeedbackProps } from "./container/FeedbackContainer";
interface EmojiRatingProps {
  rating: string;
}
export default function Feedback(props: FeedbackProps) {
  const {
    FeedbackPost,
    GetFeedback,
    feedbackPost_Details,
    feedbackPost_Error,
    feedbackPost_Loading,
    feedbackPost_Success,
    getFeedback_Details,
    getFeedback_Error,
    getFeedback_Loading,
    getFeedback_Success,
    GetFeedbackWithGarageName,
    getFeedbackG_Details,
    getFeedbackG_Loading,
  } = props;

  const [openPdf, setOpenPdf] = React.useState(false);
  const [activeForm, setActiveForm] = useState(1);

  // Function to handle switching to the next form
  const handleNextForm = () => {
    setActiveForm(activeForm + 1);
  };
  const handleOpenPdf = () => {
    setOpenPdf(true);
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
  };
  const { state } = useLocation();
  const navigation = useNavigate();
  const { id } = useParams<{ id: string }>();
  React.useEffect(() => {
    GetFeedback(String(id));
    GetFeedbackWithGarageName(String(id));
  }, [GetFeedback, GetFeedbackWithGarageName, id]);

  React.useEffect(() => {
    if (getFeedback_Success) {
    }
  }, [getFeedback_Success]);

  const [customerDetails, setCustomerDetails] =
    React.useState<EmojiRatingProps>({
      rating: "",
    });
  const [platformDetails, setPlatformDetails] =
    React.useState<EmojiRatingProps>({
      rating: "",
    });

  interface FeedbackProps {
    remarks: string;
  }
  const [Feedbackcontent, setfeedbackcontent] = React.useState<FeedbackProps>({
    remarks: "",
  });
  const [platformFeedbackcontent, setplatformFeedbackcontent] =
    React.useState<FeedbackProps>({
      remarks: "",
    });
  const handlesubmit = React.useCallback(() => {
    FeedbackPost({
      jobCard_id: String(id),
      rating: customerDetails.rating,
      remarks: Feedbackcontent.remarks,
      customer_id: getFeedback_Details?.customer_id,
      partner_id: getFeedback_Details?.partner_id,
      platform_rating: platformDetails.rating,
      platform_remarks: platformFeedbackcontent.remarks,
    });
  }, [
    FeedbackPost,
    Feedbackcontent.remarks,
    customerDetails.rating,
    getFeedback_Details?.customer_id,
    getFeedback_Details?.partner_id,
    id,
    platformDetails.rating,
    platformFeedbackcontent.remarks,
  ]);
  return (
    <>
      {getFeedback_Loading || feedbackPost_Loading ? <CustomLoader /> : null}

      <div className="container">
        <div className={styles.FeedbackMain}>
          <div className={styles.logo}>
            <img
              src={`${
                process.env.REACT_APP_API_ENDPOINT
              }/${getFeedback_Details?.image.replace("uploads/", "")}`}
              alt=""
              width="50"
            />
            <Typography>{getFeedbackG_Details?.garage_name}</Typography>
            <Typography>{getFeedbackG_Details?.address}</Typography>
            <Typography>{getFeedbackG_Details?.contact_number}</Typography>
          </div>
          <div className={styles.nameHeading}>
            <h4>{getFeedback_Details?.customer_name} </h4>
            <h5>{getFeedback_Details?.vehicle_name}</h5>
          </div>
          <div className={styles.top_vehicle_numMain}>
            <div className={styles.top_vehicle_num}>
              {getFeedback_Details?.vehicle_number}
            </div>
          </div>
          <div className={styles.nameHeading}>
            <p>
              Service Date :{" "}
              {moment(getFeedback_Details?.vehicle_date).format("DD/MM/YYYY")}
            </p>
            <p>Service ID :{getFeedback_Details?.jobcard_id}</p>
          </div>
          {/* {!feedbackPost_Success ? ( */}
          {activeForm === 1 && !feedbackPost_Success && (
            <>
              <FeedbackComponent
                setCustomerDetails={setCustomerDetails}
                customerDetails={customerDetails}
                heading="Rate your experience"
              />
              <div className={styles.comments}>
                <h3>Comments</h3>
                <TextField
                  sx={{ width: "100%", borderRadius: "10px" }}
                  id="outlined-multiline-static"
                  // label="Multiline"
                  multiline
                  rows={4}
                  onChange={(e) => {
                    setfeedbackcontent((prevState) => ({
                      ...prevState,
                      remarks: e.target.value,
                    }));
                  }}
                  value={Feedbackcontent.remarks}
                />
                <p>
                  <Lock1 size="18" color="#B1B1B1" />
                  Only visible to Managers
                </p>
              </div>
              <ButtonLog
                text="Next"
                onClick={handleNextForm}
                variant="contained"
              />
            </>
          )}

          {activeForm === 2 && !feedbackPost_Success && (
            <>
              <FeedbackComponent
                setCustomerDetails={setPlatformDetails}
                customerDetails={platformDetails}
                heading="Rate our platform experience"
              />
              <div className={styles.comments}>
                <h3>Comments</h3>
                <TextField
                  sx={{ width: "100%", borderRadius: "10px" }}
                  id="outlined-multiline-static"
                  // label="Multiline"
                  multiline
                  rows={4}
                  onChange={(e) => {
                    setplatformFeedbackcontent((prevState) => ({
                      ...prevState,
                      remarks: e.target.value,
                    }));
                  }}
                  value={platformFeedbackcontent.remarks}
                />
                <p>
                  <Lock1 size="18" color="#B1B1B1" />
                  Only visible to Managers
                </p>
              </div>
              <ButtonLog
                text={
                  feedbackPost_Loading ? (
                    <CircularProgress size={25} color={"inherit"} />
                  ) : (
                    "Submit"
                  )
                }
                onClick={handlesubmit}
                variant="contained"
                type="submit"
              />
            </>
          )}
          {/* </> */}
          {/* ) : ( */}
          {feedbackPost_Success && (
            <>
              <>
                <div>
                  {/* <Button
                    className={styles.downloadLink}
                    onClick={handleOpenPdf}
                  >
                    Click here to download GatePass
                  </Button> */}
                  <PDFDownloadLink
                    document={
                      <GatePass getFeedback_Details={getFeedbackG_Details} />
                    }
                    fileName={`${getFeedbackG_Details?.vehicle_number}_${getFeedbackG_Details?.vehicle_name}_GatePass.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading
                        ? "Click here to download GatePass..."
                        : "Click here to download GatePass"
                    }
                  </PDFDownloadLink>
                </div>
              </>
              <Typography>Feedback Submitted</Typography>
            </>
          )}
        </div>
        <div className={styles.fooerLogo}>
          <img src={LogovetricyStudio} alt="" />
        </div>
      </div>
      <Modal
        open={openPdf}
        onClose={handleClosePdf}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#FFF",
            outline: 0,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            padding: 16,
          }}
        >
          <PDFViewer width={800} height={600}>
            <GatePass getFeedback_Details={getFeedbackG_Details} />
          </PDFViewer>
        </div>
      </Modal>
    </>
  );
}
