import {
  ArrowLeft,
  ColorsSquare,
  Speedometer,
  UserOctagon,
} from "iconsax-react";

import { IconButton } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ListItem from "../../../../components/appSpecific/HistoryListItems/ListItems";
import ServiceCard from "../../../../components/appSpecific/HistorySelectedService/SelectedServices";
import SparePartCard from "../../../../components/appSpecific/HistorySelectedSpare/SelectedSpare";
import EmojiRating from "../../../../components/appSpecific/Rating/Rating";
import CustomLoader from "../../../../components/common/Loader/Loader";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";
import { HistoryProps } from "../container/historyContainer";
import { HistoryRes } from "../services/types";
import styles from "./History.module.css";

export default function History(props: HistoryProps) {
  const {
    GetAllVehicleHistory,
    getAllVehicleHistory_Error,
    getAllVehicleHistory_Loading,
    getAllVehicleHistory_Success,
    getAllVehicleHistory_details,
  } = props;
  const userDataParse = getUserDataFromLocalStorage();

  const navigate = useNavigate();
  const { state } = useLocation();
  const [filteredData, setfilteredData] = useState<HistoryRes[] | undefined>(
    getAllVehicleHistory_details
  );
  const [isActive, setIsActive] = useState("");
  useEffect(() => {
    GetAllVehicleHistory({
      vehicle_number: state.vehicle_number,
      partner_id: userDataParse.partner_id,
    });
  }, [GetAllVehicleHistory, state.vehicle_number, userDataParse.partner_id]);
  useEffect(() => {
    if (getAllVehicleHistory_Success) {
      setfilteredData(
        getAllVehicleHistory_details && getAllVehicleHistory_details
      );
      setIsActive(
        getAllVehicleHistory_details && getAllVehicleHistory_details[0]?.id
      );
    }
  }, [getAllVehicleHistory_Success, getAllVehicleHistory_details]);

  const handleSelected = useCallback(
    (id: string) => {
      const filteredData = getAllVehicleHistory_details.filter(
        (item) => item.id === id
      );
      // console.log(filteredData);
      setfilteredData(filteredData);
      setIsActive(id);
    },
    [getAllVehicleHistory_details]
  );

  return (
    <>
      {getAllVehicleHistory_Loading && <CustomLoader />}
      <div className={styles.main_root}>
        <div className={styles.history_navbar}>
          <IconButton
            className={styles.back_btn_root}
            onClick={() => navigate(-1)}
          >
            <ArrowLeft size="28" color="#292D32" />
          </IconButton>
          <div className={styles.subRoot}>
            <div className={styles.ve_num}>
              <p className={styles.ve_num_txt}>
                {filteredData ? filteredData[0]?.vehicle_number : null}
              </p>
            </div>
            <div className={styles.car_name_root}>
              <p className={styles.car_name}>
                {" "}
                {filteredData ? filteredData[0]?.model_number : null}
              </p>
              <div className={styles.car_name_description}>
                <div className={styles.icon_colorsq}>
                  <ColorsSquare size="25" color="#273A44" variant="Bold" />
                </div>
                <p className={styles.icon_name_color}>
                  {" "}
                  {filteredData ? filteredData[0]?.color : null}
                </p>
                <div className={styles.icon_squre_color}></div>
              </div>
            </div>
            <div className={styles.name_root}>
              <div>
                <UserOctagon size="42" color="#0761e9" variant="Bold" />{" "}
              </div>
              <div>
                <p className={styles.name_user}>
                  {" "}
                  {filteredData ? filteredData[0]?.customer_name : null}
                </p>
                <p className={styles.number_user}>
                  (M) : {filteredData ? filteredData[0]?.phone_number : null}
                </p>
              </div>
            </div>
            <div className={styles.email_root}>
              <p className={styles.email_txt}>
                Email : {filteredData ? filteredData[0]?.email : null}
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className={styles.visited_time}>
                <div className={styles.title_root}>
                  <div className={styles.title}>
                    <p className={styles.title_txt}>
                      Visited {getAllVehicleHistory_details.length} Times
                    </p>
                  </div>
                </div>
                {getAllVehicleHistory_details.map((item, index) => (
                  <>
                    <ListItem
                      key={index}
                      date={item.created_at}
                      jobId={item.id}
                      handleSelected={handleSelected}
                      isActive={isActive}
                    />
                  </>
                ))}
              </div>
            </div>
            <div className="col-lg-9 ps-lg-0">
              <div className={styles.visited_time}>
                <div className={styles.hader_info}>
                  <div className={styles.hader_info_sub}>
                    <div>
                      <p className={styles.hader_info_sub_job_id}>
                        Job ID :{" "}
                        <span className={styles.list_items_jobId_num}>
                          {filteredData
                            ? filteredData[0]?.id.slice(0, 8)
                            : null}
                        </span>
                      </p>
                    </div>

                    <div>
                      <p className={styles.top_vehicle_km}>
                        <Speedometer
                          size="20"
                          color="#6a6a6a"
                          variant="Bold"
                          className={styles.iconSpecing}
                        />
                        {filteredData
                          ? filteredData[0]?.kilometer_driven
                          : null}{" "}
                        KM
                      </p>
                    </div>
                  </div>
                  <div className={styles.hader_info_sub}>
                    <div>
                      <p className={styles.hader_info_sub_job_created}>
                        Job Created :
                        <span className={styles.list_items_jobId_num}>
                          {filteredData
                            ? moment(filteredData[0]?.created_at).format(
                                "MMMM Do YYYY"
                              )
                            : null}
                        </span>
                      </p>
                    </div>

                    <div>
                      <p className={styles.hader_info_sub_job_del}>
                        Delivered :{" "}
                        <span className={styles.list_items_jobId_num}>
                          {filteredData
                            ? moment(filteredData[0]?.modified_at).format(
                                "MMMM Do YYYY"
                              )
                            : null}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.services_root}>
                  <div className={styles.title_service}>
                    <div>
                      <p className={styles.service_txt}>Services</p>
                    </div>

                    {/* <div>
                      <p className={styles.view_more_txt}>View More</p>
                    </div> */}
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      {filteredData &&
                        filteredData[0]?.services.map((item, index) => (
                          <ServiceCard
                            key={index}
                            serviceName={item.service_name}
                            serviceCost={item.service_price}
                            etdTime={item.is_estimated}
                            image={item.service_image}
                          />
                        ))}
                    </div>
                  </div>
                </div>
                <div className={styles.spareparts_root}>
                  <div className={styles.title_service}>
                    <div>
                      <p className={styles.service_txt}>Spare Parts</p>
                    </div>

                    {/* <div>
                      <p className={styles.view_more_txt}>View More</p>
                    </div> */}
                  </div>

                  <div className="container">
                    <div className="row">
                      {filteredData &&
                        filteredData[0]?.spare.map((item, index) => (
                          <SparePartCard
                            key={index}
                            sparePartName={item?.product_name}
                            quantity={item.quantity}
                            image={item.product_image}
                            price={item.product_historyprice}
                          />
                        ))}
                    </div>
                  </div>
                </div>
                <div className={styles.rating_root}>
                  <div className={styles.title_service}>
                    <div>
                      <p className={styles.service_txt}>Rating</p>
                    </div>
                  </div>
                  <div className={styles.emo_root}>
                    {filteredData && filteredData[0]?.feedback.length !== 0 ? (
                      <>
                        <EmojiRating
                          rating={
                            filteredData && filteredData[0]?.feedback[0].rating
                          }
                        />
                      </>
                    ) : (
                      <p className="px-3">No Rating Found...</p>
                    )}
                  </div>
                </div>
                <div className={styles.comments}>
                  <div className={styles.title_service}>
                    <div>
                      <p className={styles.service_txt}>Comments</p>
                    </div>
                  </div>
                  <div className={styles.comment_txt_root}>
                    {filteredData && filteredData[0]?.feedback.length !== 0 ? (
                      <>
                        <p className={styles.comment_txt}>
                          {filteredData && filteredData[0]?.feedback[0].remarks}
                        </p>
                      </>
                    ) : (
                      <p className="px-3">No Comments Found...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
