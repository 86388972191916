import { connect } from "react-redux";
import { StockInventoryDT } from "../../../../components/appSpecific/StockInventory/services/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import BayPage from "../components/Bay/Bay";
import Configuration from "../components/Configuration";
import CurrencyPage from "../components/Currency/Currency";
import PartnerPage from "../components/Partner/PartnerPage";
import ProductCategoryPage from "../components/ProductCategory/ProductCategory";
import ProductsPage from "../components/Products/Products";
import RolePage from "../components/Role/RolePage";
import RoleCreationPage from "../components/RoleCreation/RoleCreation";
import ServiceCategoryPage from "../components/ServiceCategory/ServiceCategory";
import ServicesPage from "../components/Services/Services";
import StockPage from "../components/Stock/Stock";
import SubscriptionPlanPage from "../components/SubscriptionPlan/SubscriptionPlanPage";
import SupplierPage from "../components/Suppliers/Supplier";
import UsersPage from "../components/Users/User";
import {
  getAllBay,
  getAllCurrency,
  getAllPartner,
  getAllProduct,
  getAllProductCate,
  getAllRole,
  getAllRoleFunction,
  getAllService,
  getAllServiceCate,
  getAllStock,
  getAllSupplier,
  getAllUser,
  reset,
} from "../services/configurationSlice";
import { CurrencyRes, RoleFunctionRes, RoleRes } from "../types";
import { outOfStock } from "./../services/configurationSlice";

interface StateProps {
  success: boolean;
  error: string | boolean | undefined;
  loading: boolean;
  serviceCategoryDetails: null | [];
  getAllProductCateSuccess: boolean;
  getAllProductCateError: string | boolean | undefined;
  getAllProductCateLoading: boolean;
  productCategoryDetails: null | [];
  getAllProductSuccess: boolean;
  getAllProductError: boolean;
  getAllProductLoading: boolean;
  productDetails: null;
  getAllServiceSuccess: boolean;
  getAllServiceError: boolean;
  getAllServiceLoading: boolean;
  serviceDetails: null;
  getAllUserSuccess: boolean;
  getAllUserError: boolean;
  getAllUserLoading: boolean;
  userDetails: null;
  getAllPartnerSuccess: boolean;
  getAllPartnerError: boolean;
  getAllPartnerLoading: boolean;
  PartnerDetails: null;
  getAllSupplierSuccess: boolean;
  getAllSupplierError: boolean;
  getAllSupplierLoading: boolean;
  SupplierDetails: null;
  getAllStockSuccess: boolean;
  getAllStockError: boolean;
  getAllStockLoading: boolean;
  StockDetails: StockInventoryDT[];
  outOfStockSuccess: boolean;
  outOfStockError: boolean;
  outOfStockLoading: boolean;
  outOfStockDetails: null;
  getAllBaySuccess: boolean;
  getAllBayError: boolean;
  getAllBayLoading: boolean;
  BayDetails: null | [];
  getAllRoleFunctionSuccess: boolean;
  getAllRoleFunctionError: boolean;
  getAllRoleFunctionLoading: boolean;
  getAllRoleFunctionDetails: RoleFunctionRes[];
  getAllRoleSuccess: boolean;
  getAllRoleError: boolean;
  getAllRoleLoading: boolean;
  RoleDetails: RoleRes[];
  getAllCurrencySuccess: boolean;
  getAllCurrencyError: boolean;
  getAllCurrencyLoading: boolean;
  CurrencyDetails: CurrencyRes[] | null;
}

interface DispatchProps {
  GetAllServiceCate(id: number): void;
  resetAllServiceCategory(): void;
  GetAllProductCate(id: number): void;
  GetAllProduct(id: number): void;
  GetAllService(id: number): void;
  GetAllUser(id: number): void;
  GetAllPartner(): void;
  GetAllSupplier(id: string): void;
  GetAllStock(id: string): void;
  GetAllBay(id: string): void;
  GetAllRoleFunction(): void;
  GetAllRole(): void;
  GetAllCurrency(id: string): void;
  OutOfStock(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.configuration.success,
    error: state.configuration.error,
    loading: state.configuration.loading,
    serviceCategoryDetails: state.configuration.serviceCategoryDetails,
    getAllProductCateSuccess: state.configuration.getAllProductCateSuccess,
    getAllProductCateError: state.configuration.getAllProductCateError,
    getAllProductCateLoading: state.configuration.getAllProductCateLoading,
    productCategoryDetails: state.configuration.productCategoryDetails,
    getAllProductSuccess: state.configuration.getAllProductSuccess,
    getAllProductError: state.configuration.getAllProductError,
    getAllProductLoading: state.configuration.getAllProductLoading,
    productDetails: state.configuration.productDetails,
    getAllServiceSuccess: state.configuration.getAllServiceSuccess,
    getAllServiceError: state.configuration.getAllServiceError,
    getAllServiceLoading: state.configuration.getAllServiceLoading,
    serviceDetails: state.configuration.serviceDetails,
    getAllUserSuccess: state.configuration.getAllUserSuccess,
    getAllUserError: state.configuration.getAllUserError,
    getAllUserLoading: state.configuration.getAllUserLoading,
    userDetails: state.configuration.userDetails,
    getAllPartnerSuccess: state.configuration.getAllPartnerSuccess,
    getAllPartnerError: state.configuration.getAllPartnerError,
    getAllPartnerLoading: state.configuration.getAllPartnerLoading,
    PartnerDetails: state.configuration.PartnerDetails,
    getAllSupplierSuccess: state.configuration.getAllSupplierSuccess,
    getAllSupplierError: state.configuration.getAllSupplierError,
    getAllSupplierLoading: state.configuration.getAllSupplierLoading,
    SupplierDetails: state.configuration.SupplierDetails,
    getAllStockSuccess: state.configuration.getAllStockSuccess,
    getAllStockError: state.configuration.getAllStockError,
    getAllStockLoading: state.configuration.getAllStockLoading,
    StockDetails: state.configuration.StockDetails,
    outOfStockSuccess: state.configuration.outOfStockSuccess,
    outOfStockError: state.configuration.outOfStockError,
    outOfStockLoading: state.configuration.outOfStockLoading,
    outOfStockDetails: state.configuration.outOfStockDetails,
    getAllBaySuccess: state.configuration.getAllBaySuccess,
    getAllBayError: state.configuration.getAllBayError,
    getAllBayLoading: state.configuration.getAllBayLoading,
    BayDetails: state.configuration.BayDetails,
    getAllRoleFunctionSuccess: state.configuration.getAllRoleFunctionSuccess,
    getAllRoleFunctionError: state.configuration.getAllRoleFunctionError,
    getAllRoleFunctionLoading: state.configuration.getAllRoleFunctionLoading,
    getAllRoleFunctionDetails: state.configuration.getAllRoleFunctionDetails,
    getAllRoleSuccess: state.configuration.getAllRoleSuccess,
    getAllRoleError: state.configuration.getAllRoleError,
    getAllRoleLoading: state.configuration.getAllRoleLoading,
    RoleDetails: state.configuration.RoleDetails,
    getAllCurrencySuccess: state.configuration.getAllCurrencySuccess,
    getAllCurrencyError: state.configuration.getAllCurrencyError,
    getAllCurrencyLoading: state.configuration.getAllCurrencyLoading,
    CurrencyDetails: state.configuration.CurrencyDetails,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllServiceCate: (id) => {
      dispatch(getAllServiceCate({ id }));
    },
    resetAllServiceCategory: () => {
      dispatch(reset());
    },
    GetAllProductCate: (id) => {
      dispatch(getAllProductCate({ id }));
    },
    GetAllProduct: (id) => {
      dispatch(getAllProduct({ id }));
    },
    GetAllService: (id) => {
      dispatch(getAllService({ id }));
    },
    GetAllUser: (id) => {
      dispatch(getAllUser(id));
    },
    GetAllPartner: () => {
      dispatch(getAllPartner());
    },
    GetAllSupplier: (id) => {
      dispatch(getAllSupplier({ id }));
    },
    GetAllStock: (id) => {
      dispatch(getAllStock(id));
    },
    OutOfStock(id) {
      dispatch(outOfStock(id));
    },
    GetAllBay: (id) => {
      dispatch(getAllBay({ id }));
    },
    GetAllRoleFunction() {
      dispatch(getAllRoleFunction());
    },
    GetAllRole() {
      dispatch(getAllRole());
    },
    GetAllCurrency(id) {
      dispatch(getAllCurrency(id));
    },
  };
};

export interface ConfigurationProps extends StateProps, DispatchProps {}
export const ConfigurationComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Configuration);
export const Service = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesPage);
export const ServiceCategory = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCategoryPage);
export const Products = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsPage);
export const ProductCategory = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCategoryPage);
export const User = connect(mapStateToProps, mapDispatchToProps)(UsersPage);
export const Partner = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerPage);
export const Supplier = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierPage);
export const Bay = connect(mapStateToProps, mapDispatchToProps)(BayPage);
export const Stock = connect(mapStateToProps, mapDispatchToProps)(StockPage);
export const Role = connect(mapStateToProps, mapDispatchToProps)(RolePage);
export const RoleCreation = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleCreationPage);
export const SubscriptionPlan = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPlanPage);
export const Currency = connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrencyPage);
