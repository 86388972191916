import { createSlice } from "@reduxjs/toolkit";

import { ServiceDashBoardINIT } from "./types";

export const initialState: ServiceDashBoardINIT = {};

export const serviceDashboardSlice = createSlice({
  name: "serviceDashboard",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {},
});

export default serviceDashboardSlice.reducer;
export const { reset } = serviceDashboardSlice.actions;
