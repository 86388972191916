import { axios } from "../../../../api/setup.intersepter";
import {
  CreateBayDT,
  CreateCurrencyDTO,
  CreatePartnerApiDTo,
  CreateProductInventryApiDTO,
  CreateServiceApiDto,
  CreateStockApiDTo,
  CreateSupplierApiDTo,
  CreateUserDT,
  ProductCategory,
  RoleCreationDT,
  RoleFunction,
  ServiceCategory,
  UpdateBayDT,
  UpdateCurrencyDTO,
  UpdatePartnerApiDTo,
  UpdateProductCategoryDT,
  UpdateProductInventryApiDTO,
  UpdateServiceApiDTO,
  UpdateServiceCategoryDT,
  UpdateStockApiDTo,
  UpdateSupplierApiDTo,
  UpdateUserDT,
} from "../types";

export const GetAllServiceCategory = (id: number, queryParams: any) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return axios({
    method: "GET",
    url: `/service-category/service/${id}?${queryString}`,
  });
};
export const CreateServiceCategory = (data: ServiceCategory) => {
  return axios({
    method: "POST",
    url: `/service-category`,
    data: { serviceCategory: data },
  });
};
export const UpdateServiceCategory = (data: UpdateServiceCategoryDT) => {
  return axios({
    method: "PUT",
    url: `/service-category/${data.service_category_id}`,
    data: {
      serviceCategory: {
        name: data.name,
        description: data.description,
        slug: data.slug,
        partner_id: data.partner_id,
        services: data.services,
        IsActive: data.IsActive,
        updated_by: data.updated_by,
      },
    },
  });
};

export const GetAllProductCategory = async (id: number, queryParams: any) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return axios({
    method: "GET",
    url: `/product-category/product/${id}?${queryString}`,
  });
};

export const CreateProductCategory = (data: ProductCategory) => {
  return axios({
    method: "POST",
    url: `/product-category`,
    data: { productCategory: data },
  });
};

export const UpdateProductCategory = (data: UpdateProductCategoryDT) => {
  return axios({
    method: "PUT",
    url: `/product-category/${data.product_category_id}`,
    data: {
      productCategory: {
        name: data.name,
        description: data.description,
        partner_id: data.partner_id,
        slug: data.slug,
        products: data.products,
        updated_by: data.updated_by,
        IsActive: data.IsActive,
      },
    },
  });
};
export const GetAllUser = (id: number) => {
  return axios({
    method: "GET",
    url: `/user/${id}`,
  });
};
export const CreateUser = (data: CreateUserDT) => {
  return axios({
    method: "POST",
    url: `/user`,
    data: { user: data },
  });
};
export const UpdateUser = (data: UpdateUserDT) => {
  return axios({
    method: "PUT",
    url: `/user/${data.user_id}`,
    data: {
      user: {
        name: data.name,
        email: data.email,
        user_type: data.user_type,
        password: data.password,
        specialities: data.specialities,
        partner_id: data.partner_id,
        slug: data.slug,
        is_active: data.is_active,
        updated_by: data.updated_by,
      },
    },
  });
};

export const GetAllService = (id: number, queryParams: any) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return axios({
    method: "GET",
    url: `/service/${id}?${queryString}`,
  });
};

export const GetAllProduct = (id: number, queryParams: any) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return axios({
    method: "GET",
    url: `/product/${id}?${queryString}`,
  });
};

export const CreateInventryProductRequest = (
  body: CreateProductInventryApiDTO
) => {
  return axios({
    method: "POST",
    url: `/product`,
    data: { product: body },
  });
};

export const UpdateInventryProductRequest = (
  body: UpdateProductInventryApiDTO
) => {
  return axios({
    method: "PUT",
    url: `/product/${body.product_id}`,
    data: {
      product: {
        product_code: body.product_code,
        name: body.name,
        description: body.description,
        uom: body.uom,
        min_quantity: body.min_quantity,
        max_quantity: body.max_quantity,
        product_category_id: body.product_category_id,
        image_path: body.image_path,
        slug: body.slug,
        partner_id: body.partner_id,
        IsActive: body.IsActive,
        updated_by: body.updated_by,
      },
    },
  });
};

export const CreateServiceRequest = (body: CreateServiceApiDto) => {
  return axios({
    method: "POST",
    url: `/service`,
    data: { service: body },
  });
};

export const UpdateServiceRequest = (body: UpdateServiceApiDTO) => {
  return axios({
    method: "PUT",
    url: `/service/${body.service_id}`,
    data: {
      service: {
        name: body.name,
        description: body.description,
        price: body.price,
        durationInMinute: body.durationInMinute,
        image_path: body.image_path,
        service_category_id: body.service_category_id,
        partner_id: body.partner_id,
        slug: body.slug,
        is_active: body.is_active,
        updated_by: body.updated_by,
      },
    },
  });
};

export const CreatePartnerRequest = (body: CreatePartnerApiDTo) => {
  return axios({
    method: "POST",
    url: `/partners`,
    data: { partner: body },
  });
};

export const UpdatePartnerRequest = (body: UpdatePartnerApiDTo) => {
  return axios({
    method: "PUT",
    url: `/partners/${body.partner_id}`,
    data: {
      partner: {
        name: body.name,
        contact_person: body.contact_person,
        email: body.email,
        garage_name: body.garage_name,
        // password: body.password,
        currency: body.currency,
        country: body.country,
        tax_number: body.tax_number,
        phone_number: body.phone_number,
        user_type: body.user_type,
        website: body.website,
        tax_percentage: body.tax_percentage,
        address: body.address,
        color_code: body.color_code,
        logo: body.logo,
        slug: body.slug,
        IsActive: body.IsActive,
        updated_by: body.updated_by,
      },
    },
  });
};
export const GetAllPartner = () => {
  return axios({
    method: "GET",
    url: `/partners`,
  });
};
export const CreateSupplierRequest = (body: CreateSupplierApiDTo) => {
  return axios({
    method: "POST",
    url: `/supplier`,
    data: { supplier: body },
  });
};
export const UpdateSupplierRequest = (body: UpdateSupplierApiDTo) => {
  return axios({
    method: "PUT",
    url: `/supplier/${body.supplier_id}`,
    data: {
      supplier: {
        name: body.name,
        address: body.address,
        registration_number: body.registration_number,
        phone_number: body.phone_number,
        slug: body.slug,
        updated_by: body.updated_by,
        IsActive: body.IsActive,
      },
    },
  });
};
export const GetAllSupplier = (id: string, queryParams?: any) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return axios({
    method: "GET",
    url: `/supplier/${id}?${queryString}`,
  });
};

export const CreateStockRequest = (body: CreateStockApiDTo) => {
  return axios({
    method: "POST",
    url: `/product-inventory`,
    data: { productInventory: body },
  });
};
export const UpdateStockRequest = (body: UpdateStockApiDTo) => {
  return axios({
    method: "PUT",
    url: `/product-inventory/${body.stock_id}`,
    data: {
      productInventory: {
        product_category: body.product_category,
        product: body.product,
        quantity: body.quantity,
        unit_price: body.unit_price,
        receipt_number: body.receipt_number,
        receipt_date: body.receipt_date,
        po_reference_number: body.po_reference_number,
        po_date: body.po_date,
        supplier: body.supplier,
        partner_id: body.partner_id,
        stock_id: body.stock_id,
        slug: body.slug,
        IsActive: body.IsActive,
        updated_by: body.updated_by,
        base_price: body.base_price,
      },
    },
  });
};
export const GetAllStock = (id: string) => {
  return axios({
    method: "GET",
    url: `/product-inventory/${id}`,
  });
};
export const OutOfStock = (id: string) => {
  return axios({
    method: "PUT",
    url: `/product-inventory/stockOut/${id}`,
  });
};
export const CreateBay = (data: CreateBayDT) => {
  return axios({
    method: "POST",
    url: `/bay`,
    data: { bay: data },
  });
};
export const UpdateBay = (data: UpdateBayDT) => {
  return axios({
    method: "PUT",
    url: `/bay/${data.bay_id}`,
    data: {
      bay: {
        name: data.name,
        description: data.description,
        partner_id: data.partner_id,
        slug: data.slug,
        updated_by: data.updated_by,
        IsActive: data.is_active,
      },
    },
  });
};
export const GetAllBay = (id: string, queryParams: any) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return axios({
    method: "GET",
    url: `/bay/${id}?${queryString}`,
  });
};
export const GetAllRole = () => {
  return axios({
    method: "GET",
    url: `/role/GetAllRole`,
  });
};
export const GetAllFunction = () => {
  return axios({
    method: "GET",
    url: `/function`,
  });
};
export const CreateRole = (data: RoleFunction) => {
  return axios({
    method: "POST",
    url: `/role-function`,
    data: {
      roleFunction: {
        roleid: data.roleid,
        functionid: data.functionid,
        created_by: data.created_by,
      },
    },
  });
};
export const GetAllRoleFunction = () => {
  return axios({
    method: "GET",
    url: `/role-function`,
  });
};
export const GetAllRoleType = () => {
  return axios({
    method: "GET",
    url: `/role`,
  });
};
export const CreateRoleCreation = (data: RoleCreationDT) => {
  return axios({
    method: "POST",
    url: `/role`,
    data: {
      role: {
        name: data.name,
        description: data.description,
        slug: data.slug,
        created_by: data.created_by,
      },
    },
  });
};
export const GetAllActiveServiceCategory = (id: number) => {
  return axios({
    method: "GET",
    url: `/service-category/active/${id}`,
  });
};
export const GetAllActiveProductCategory = (id: number) => {
  return axios({
    method: "GET",
    url: `/product-category/active/${id}`,
  });
};
export const GetAllCurrency = (id: string) => {
  return axios({
    method: "GET",
    url: `/currency`,
  });
};
export const CreateCurrency = (body: CreateCurrencyDTO) => {
  return axios({
    method: "POST",
    url: `/currency`,
    data: {
      currency: {
        name: body.name,
        exchange_rate: body.exchange_rate,
        code: body.code,
        symbol: body.symbol,
        created_by: body.created_by,
      },
    },
  });
};
export const UpdateCurrency = (body: UpdateCurrencyDTO) => {
  return axios({
    method: "PUT",
    url: `/currency/${body.currency_id}`,
    data: {
      currency: {
        name: body.name,
        exchange_rate: body.exchange_rate,
        code: body.code,
        symbol: body.symbol,
        updated_by: body.updated_by,
      },
    },
  });
};
