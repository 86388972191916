import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { getAllBay } from "../../Configuration/services/configurationSlice";
import { resetSpare } from "../../Manage/VeichleRegistration/components/Spares/services/sparesSlice";
import Mechanic from "../components/Mechanic";
import {
  changeStatusOfServiceAction,
  getAllMechJobsAction,
  reset,
  resetMechanicChangeStatus,
  returnSparePart,
  selectBayByMech,
} from "../service/MechanicSlice";
import {
  ChangeStatusOfServiceProps,
  GetMechJobDT,
  JobCardCreateSpares,
  MechanicJobState,
  SelectBayByMechProps,
} from "../service/type";

interface StateProps extends MechanicJobState {
  getAllBaySuccess: boolean;
  getAllBayError: boolean;
  getAllBayLoading: boolean;
  BayDetails: null | [];
  selectBayByMech_Loading: boolean;
  selectBayByMech_Error: boolean;
  selectBayByMech_Success: boolean;
  requestSparePart_Loading: boolean;
  requestSparePart_Error: boolean;
  requestSparePart_Success: boolean;
  requestSparePart_details: null;
  returnSparePart_Loading: boolean;
  returnSparePart_Error: boolean;
  returnSparePart_Success: boolean;
  returnSparePart_details: null;
}

interface DispatchProps {
  GetAllJobsByMechanicID(body: GetMechJobDT): void;
  ChangeStatusOfService(data: ChangeStatusOfServiceProps): void;
  ResetJobsByMechanicID(): void;
  ResetMechanicChangeStatus(): void;
  GetAllBay(id: string): void;
  SelectBayByMech(data: SelectBayByMechProps): void;
  ResetSpare(): void;
  ReturnSparePart(data: JobCardCreateSpares): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    mechanicJobs: state.mechanicJobs.mechanicJobs,
    getAllMechJobs_Error: state.mechanicJobs.getAllMechJobs_Error,
    getAllMechJobs_Loading: state.mechanicJobs.getAllMechJobs_Loading,
    getAllMechJobs_Success: state.mechanicJobs.getAllMechJobs_Success,
    changeStatusOfService_Error: state.mechanicJobs.changeStatusOfService_Error,
    changeStatusOfService_Loading:
      state.mechanicJobs.changeStatusOfService_Loading,
    changeStatusOfService_Success:
      state.mechanicJobs.changeStatusOfService_Success,
    getAllBaySuccess: state.configuration.getAllBaySuccess,
    getAllBayError: state.configuration.getAllBayError,
    getAllBayLoading: state.configuration.getAllBayLoading,
    BayDetails: state.configuration.BayDetails,
    selectBayByMech_Error: state.mechanicJobs.selectBayByMech_Error,
    selectBayByMech_Loading: state.mechanicJobs.selectBayByMech_Loading,
    selectBayByMech_Success: state.mechanicJobs.selectBayByMech_Success,
    requestSparePart_Success: state.mechanicJobs.requestSparePart_Success,
    requestSparePart_Error: state.mechanicJobs.requestSparePart_Error,
    requestSparePart_Loading: state.mechanicJobs.requestSparePart_Loading,
    requestSparePart_details: state.mechanicJobs.requestSparePart_details,
    returnSparePart_Success: state.mechanicJobs.returnSparePart_Success,
    returnSparePart_Error: state.mechanicJobs.returnSparePart_Error,
    returnSparePart_Loading: state.mechanicJobs.returnSparePart_Loading,
    returnSparePart_details: state.mechanicJobs.returnSparePart_details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllJobsByMechanicID: (id) => {
      dispatch(getAllMechJobsAction(id));
    },
    ChangeStatusOfService: (data) => {
      dispatch(changeStatusOfServiceAction(data));
    },
    ResetJobsByMechanicID: () => {
      dispatch(reset());
    },
    ResetMechanicChangeStatus: () => {
      dispatch(resetMechanicChangeStatus());
    },
    GetAllBay: (id) => {
      dispatch(getAllBay({ id }));
    },
    SelectBayByMech(data) {
      dispatch(selectBayByMech(data));
    },
    ResetSpare() {
      dispatch(resetSpare());
    },
    ReturnSparePart(data) {
      dispatch(returnSparePart(data));
    },
  };
};

export interface MechanicComponentProps extends StateProps, DispatchProps {}
export const MechanicComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Mechanic);
