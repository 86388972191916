import defaultImage from "../../../../assets/images/DefaultImage.png";
import styles from "../dashbord.module.css";
import { SparePartRes } from "../service/types";

interface ServicesCardProps {
  data?: SparePartRes;
  time_count?: string | number;
}

const ServicesCard: React.FC<ServicesCardProps> = ({ data, time_count }) => {
  return (
    <div>
      <div className={styles.service_time}>
        <div className={styles.service_time_root_img}>
          <div className={styles.circle_img}>
            <img
              src={
                data?.image
                  ? `${
                      process.env.REACT_APP_API_ENDPOINT
                    }/${data?.image.replace("uploads/", "")}`
                  : defaultImage
              }
              height={20}
              width={20}
              alt="Car Washing"
            />
          </div>
          <div>
            <p className={styles.service_time_wash_txt}>{data?.name}</p>
          </div>
        </div>
        <div className={styles.time_tab}>
          <p className={styles.count_service_time}>{data?.count}</p>
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
