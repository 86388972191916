import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import averageemoji from "../../../assets/images/Average.png";
import exellentemoji from "../../../assets/images/Exellent.png";
import exellentemojiChecked from "../../../assets/images/ExellentChechked.png";
import goodemoji from "../../../assets/images/Good.png";
import goodemojiChecked from "../../../assets/images/GoodChecked.png";
import pooremoji from "../../../assets/images/Poor.png";
import pooremojiChecked from "../../../assets/images/PoorChecked.png";
import wrostemoji from "../../../assets/images/Worst.png";
import wrostemojiChecked from "../../../assets/images/WorstChecked.png";
import averageemojiChecked from "../../../assets/images/averageChecked.png";
import styles from "./Feedback.module.css";

interface EmojiRatingProps {
  setCustomerDetails: (e: any) => void;
  customerDetails: any;
  heading?: string;
}

const FeedbackComponent = (props: EmojiRatingProps) => {
  const { setCustomerDetails, customerDetails, heading } = props;
  // console.log("the ratting--------->", customerDetails);

  const ratingDetails = [
    {
      id: 1,
      value: "1",
      label: "Worst",
      img: wrostemoji,
      checkedImg: wrostemojiChecked,
    },
    {
      id: 2,
      value: "2",
      label: "Poor",
      img: pooremoji,
      checkedImg: pooremojiChecked,
    },
    {
      id: 3,
      value: "3",
      label: "Average",
      img: averageemoji,
      checkedImg: averageemojiChecked,
    },
    {
      id: 4,
      value: "4",
      label: "Good",
      img: goodemoji,
      checkedImg: goodemojiChecked,
    },
    {
      id: 5,
      value: "5",
      label: "Exellent",
      img: exellentemoji,
      checkedImg: exellentemojiChecked,
    },
  ];
  return (
    <div className={styles.feedBackEmogi}>
      <h3>{heading}</h3>
      <div className={styles.feedbackComponentRoot}>
        {ratingDetails.map((ele) => {
          return (
            <RadioGroup
              key={ele.id}
              row
              name="rating"
              className={styles.radioGroup}
              onChange={(e) => {
                setCustomerDetails((prevState: any) => ({
                  ...prevState,
                  rating: e.target.value,
                }));
                // setIsChecked(true);
              }}
              value={customerDetails.rating}
            >
              <FormControlLabel
                value={ele.value}
                control={
                  <Radio
                    color="primary"
                    icon={<img src={ele.img} alt="" />}
                    checkedIcon={
                      <img
                        src={ele.checkedImg}
                        alt=""
                        style={{ border: "red" }}
                      />
                    }
                  />
                }
                label={ele?.label}
                labelPlacement="bottom"
              />
            </RadioGroup>
          );
        })}
      </div>

      {/* <div className={styles.emoimage_root}>
        <img src={wrostemoji} alt="" />
        <img src={pooremoji} alt="" />
        <img src={averageemoji} alt="" />
        <img src={goodemoji} alt="" />
        <img src={exellentemoji} alt="" />
    </div> */}
    </div>
  );
};

export default FeedbackComponent;
