import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface estimateState {
  description: string;
  price: number;
  estimated: string;
}
interface InitialState {
  estimate: estimateState[];
}
const initialState: InitialState = {
  estimate: [],
};
const estimateSlice = createSlice({
  name: "estimate",
  initialState,
  reducers: {
    addEstimate: (state, action: PayloadAction<estimateState[]>) => {
      const newItem = action.payload;
      state.estimate = newItem;
    },
    resetEstimate: (state) => {
      state.estimate = [];
    },
  },
});

export const { addEstimate, resetEstimate } = estimateSlice.actions;

export default estimateSlice.reducer;
