import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Button, Chip } from "@mui/material";
import { Edit } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { UserDT } from "../../../../pages/app/Configuration/types";
import ButtonLog from "../../../common/Button/Button";
import CustomTable from "../../../common/Table/CustomTable";
import styles from "./User.module.css";

export default function User(props: any) {
  const { userDetails } = props;
  const navigation = useNavigate();

  const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "email", label: "Email", minWidth: 100 },
    { id: "roleName", label: "Role", minWidth: 100 },
    { id: "is_active", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleEditClick = (user: UserDT) => {
    navigation("/user-form/edit", {
      state: { user },
    });
  };

  const updatedRows = userDetails?.map((row: any) => ({
    ...row,
    is_active:
      row.is_active === false ? (
        <Chip label="In Active" variant="outlined" color="error" />
      ) : (
        <Chip label="Active" variant="outlined" color="success" />
      ),
    action: (
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Edit size="18" color="#0761e9" />}
        onClick={() => handleEditClick(row)}
        size="small"
      >
        Edit
      </Button>
    ),
  }));

  return (
    <>
      <div className={styles.inventoryNavBar}>
        <div>
          <ButtonLog
            text={"Back"}
            variant={"contained"}
            onClick={() => navigation(-1)}
            className={styles.backBtn}
          />
        </div>

        <div className={styles.filter}>
          <ButtonLog
            text={"Add User"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/user-form/add")}
          />
        </div>
      </div>

      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <CustomTable columns={columns} rows={updatedRows} />
      </div>
    </>
  );
}
