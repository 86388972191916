import { useEffect } from "react";
import CurrencyScreen from "../../../../../components/appSpecific/Currency/components/Currency";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { ConfigurationProps } from "../../container/configuartionContainer";

export default function CurrencyPage(props: ConfigurationProps) {
  const { CurrencyDetails, getAllCurrencyLoading, GetAllCurrency } = props;
  useEffect(() => {
    GetAllCurrency("0");
  }, [GetAllCurrency]);

  return (
    <>
      {getAllCurrencyLoading ? (
        <CustomLoader />
      ) : (
        <CurrencyScreen CurrencyDetails={CurrencyDetails} />
      )}
    </>
  );
}
