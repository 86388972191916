import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Card, Chip, IconButton, Menu, MenuItem } from "@mui/material";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import CustomTable from "../../../components/common/Table/CustomTable";
import { getUserDataFromLocalStorage } from "../../../utils/helper.utils";
import JobCard from "./Components/JobCard";
import styles from "./NewOwnerDashbord.module.css";
import { useDashboardOwner } from "./Services/OwnerDashboard.request";

const columns = [
  { id: "Partner", label: "Partner", minWidth: 100 },
  { id: "BIllingDate", label: "BIllingDate", minWidth: 100 },
  { id: "Amount", label: "Amount", minWidth: 100 },
  { id: "Days", label: "Days(remaining)", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
];

const updatedRows = [
  {
    Partner: "Laxman Jena",
    BIllingDate: "21 / 06 / 2023",
    Amount: 10,
    Days: "10",
    status: "Active",
  },
  {
    Partner: "Ramakanta Sahoo",
    BIllingDate: "01 / 05 / 2023",
    Amount: 10,
    Days: "12",
    status: "InActive",
  },
  {
    Partner: "Pabitra Samal",
    BIllingDate: "02 / 06 / 2023",
    Amount: 10,
    Days: "20",
    status: "Active",
  },
  {
    Partner: "Lara Dey",
    BIllingDate: "02 / 07 / 2023",
    Amount: 10,
    Days: "10",
    status: "InActive",
  },
  {
    Partner: "Sravan Kumar",
    BIllingDate: "16 / 08 / 2023",
    Amount: 10,
    Days: "10",
    status: "Active",
  },
];

const columns1 = [
  { id: "Name", label: "Name", minWidth: 100 },
  { id: "Date", label: "Date", minWidth: 100 },
  { id: "State", label: "State", minWidth: 100 },
  { id: "PhoneNo", label: "Users", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

const updatedRows1 = [
  {
    Name: "Babul Jena",
    Date: "21 / 06 / 2023",
    State: "Pending",
    PhoneNo: "7700876560",
  },
  {
    Name: "Shayam Sahoo",
    Date: "01 / 05 / 2023",
    State: "Pending",
    PhoneNo: "9977876560",
  },
  {
    Name: "Loren Samal",
    Date: "02 / 06 / 2023",
    State: "Pending",
    PhoneNo: "8877876560",
  },
  {
    Name: "Mousmi Dey",
    Date: "02 / 07 / 2023",
    State: "Pending",
    PhoneNo: "7873876560",
  },
  {
    Name: "Laxman Kumar",
    Date: "16 / 08 / 2023",
    State: "Pending",
    PhoneNo: "8877876500",
  },
];
const ITEM_HEIGHT = 48;
const optionsss = ["In Progress", "Completed"];

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    // title: {
    //   display: true,
    //   text: "Chart.js Bar Chart",
    // },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => Math.random()),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => Math.random()),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
export const datas = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};
export const optionss = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const datass = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => Math.random()),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => Math.random()),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
export default function DashboardNew() {
  const userData = getUserDataFromLocalStorage();
  const [filter, setFilter] = React.useState("weekly");

  const [percentage, setPercentage] = React.useState(0);
  const { data } = useDashboardOwner();

  // getDatetimeRangeByFilterMode()
  // Define separate datasets for different time intervals
  const weeklyData = {
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    datasets: [
      {
        label: "Weekly Dataset 1",
        data: [10, 20, 15, 30, 25, 35, 40],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      // ... Other datasets for the weekly interval
    ],
  };

  const monthlyData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Monthly Dataset 1",
        data: [100, 150, 120, 200],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      // ... Other datasets for the monthly interval
    ],
  };

  const yearlyData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Yearly Dataset 1",
        data: [500, 700, 600, 800, 500, 400, 200],
        backgroundColor: "rgba(255, 206, 86, 0.5)",
      },
      // ... Other datasets for the yearly interval
    ],
  };

  const weeklyData1 = {
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    datasets: [
      {
        label: "Weekly Dataset 1",
        data: [10, 20, 15, 30, 25, 35, 40],
        backgroundColor: "rgba(249, 15, 11, 0.5)",
      },
      // ... Other datasets for the weekly interval
    ],
  };

  const monthlyData1 = {
    labels: ["Kalinga", "Ramsey", "Q-Motor", "Z-motor"],
    datasets: [
      {
        label: "Monthly Dataset 1",
        data: [100, 150, 120, 200],
        backgroundColor: "rgba(255, 120, 0, 0.5)",
      },
      // ... Other datasets for the monthly interval
    ],
  };

  const yearlyData1 = {
    labels: ["Kalinga", "Ramsey", "Q-Motor", "Z-motor"],
    datasets: [
      {
        label: "Yearly Dataset 1",
        data: [500, 700, 600, 800],
        backgroundColor: "rgba(0, 180, 233, 0.5)",
      },
      // ... Other datasets for the yearly interval
    ],
  };

  let selectedData1 = {
    labels,
    datasets: [
      {
        label: "Dataset 2",
        data: labels.map(() => Math.random()),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  // Based on the selected filter, choose the appropriate data
  let selectedData = yearlyData;

  if (filter === "weekly") {
    selectedData = weeklyData;
  } else if (filter === "monthly") {
    selectedData = monthlyData;
  } else if (filter === "yearly") {
    selectedData = yearlyData;
  }
  const [alignment, setAlignment] = React.useState("yearly");

  React.useEffect(() => {
    // setTimeout(() => {
    // if (percentage < 4.5) {
    setPercentage(Math.floor(Math.random() * 100) + 1);
    // }
    // }, 60);
  }, [percentage, alignment]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setFilter(newAlignment);
    setAlignment(newAlignment);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const updatedRowsData = updatedRows1?.map((row: any) => ({
    ...row,

    action: (
      <>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "15ch",
            },
          }}
        >
          {optionsss.map((option) => (
            <MenuItem
              key={option}
              selected={option === "In Progress"}
              onClick={handleClose}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </>
    ),
  }));
  const updatedRowsData1 = updatedRows?.map((row: any) => ({
    ...row,

    status:
      row.status === "InActive" ? (
        <Chip label="In Active" variant="outlined" color="error" />
      ) : (
        <Chip label="Active" variant="outlined" color="success" />
      ),
  }));
  return (
    <Box className={styles.main_root}>
      <div className="container-fluid">
        <Box className={styles.job_headbar}>
          <div>
            <p className={styles.txt_job}> Greetings, {userData.name}!</p>
          </div>
          {/* <div>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton className={styles.weekly_Btn} value="weekly">
                Weekly
              </ToggleButton>
              <ToggleButton className={styles.weekly_Btn} value="monthly">
                Monthly
              </ToggleButton>
              <ToggleButton className={styles.weekly_Btn} value="yearly">
                Yearly
              </ToggleButton>
            </ToggleButtonGroup>
          </div> */}
        </Box>
        <div className="row">
          <div className="col-md-12">
            <Box className={styles.root_jobs}>
              <JobCard data={data} />
            </Box>
          </div>
        </div>
        <div style={{ marginTop: "-190px" }}>
          <div className="row d-flex">
            <div className="col-md-5">
              <Card variant="outlined" className={styles.root_Revenue}>
                <div className={styles.job_headbar}>
                  <div>
                    <p className={styles.txt_job}> Platform Revenue</p>
                  </div>
                </div>
                <div>
                  <div>
                    {/* <p className={styles.count_revenue}> S$ 5852.00</p> */}
                  </div>
                  {/* <Chart /> */}
                  {/* <Bar options={options} data={selectedData} /> */}
                  <Line options={options} data={yearlyData} />
                </div>
              </Card>
            </div>
            <div className="col-md-4">
              <Card variant="outlined" className={styles.root_Revenue}>
                <div className={styles.job_headbar}>
                  <div>
                    <p className={styles.txt_job}> Partners Revenue</p>
                  </div>
                </div>
                <div>
                  <div>
                    {/* <p className={styles.count_revenue}> S$ 5852.00</p> */}
                  </div>
                  {/* <Chart /> */}
                  {/* <Bar options={options} data={yearlyData} /> */}
                  <Line options={options} data={yearlyData1} />
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <Box className={styles.TotalVehicleHead}>
              <div className={styles.job_headbar}>
                <div>
                  <p className={styles.txt_job}>Enquiry Tracking</p>
                </div>
              </div>

              <div className="container-fluid" style={{ paddingTop: "20px" }}>
                <CustomTable columns={columns1} rows={updatedRowsData} />
              </div>
            </Box>
          </div>
          <div className="col-md-6">
            <Box className={styles.TotalVehicleHead}>
              <div className={styles.job_headbar}>
                <div>
                  <p className={styles.txt_job}>Partners plan tracking</p>
                </div>
              </div>

              <div className="container-fluid" style={{ paddingTop: "20px" }}>
                <CustomTable columns={columns} rows={updatedRowsData1} />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Box>
  );
}
