import { More } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import Defaultiamge from "../../../assets/images/DefaultImage.png";
import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";
import { getUserDataFromLocalStorage } from "../../../utils/helper.utils";

interface ComplaintServiceCardProps {
  item: any;
  handleAddService: (item: {
    image_path?: string;
    name: string;
    price: number;
    durationInMinute: number;
    assignedto: string;
  }) => void;
  firstMechanicID: any;
}

export function ComplaintServiceCard(props: ComplaintServiceCardProps) {
  const { item, handleAddService, firstMechanicID } = props;
  const [isActive, setIsActive] = useState(false);
  const userData = getUserDataFromLocalStorage();

  const updatedItem = { ...item, assignedto: firstMechanicID };
  const handleClick = () => {
    handleAddService(updatedItem);
    setIsActive((prevIsActive) => !prevIsActive);
  };

  return (
    <div className="col" onClick={handleClick}>
      <Tooltip
        arrow
        title={item.description}
        sx={{
          float: "right",
        }}
      >
        <More color="action" />
      </Tooltip>
      <div
        className={
          isActive
            ? `${styles.services_wrapper} ${styles.active}`
            : styles.services_wrapper
        }
      >
        <div className="d-flex justify-content-center">
          <div className={styles.img_wrapper}>
            <img
              src={
                item.image_path
                  ? `${
                      process.env.REACT_APP_API_ENDPOINT
                    }/${item.image_path?.replace("uploads/", "")}`
                  : Defaultiamge
              }
              alt="pic"
              className={styles.img_wrapper_img}
            />
          </div>
        </div>
        <p className={styles.services}>{item.name}</p>
        <div className=" ">
          <p className={styles.pro_time_txt}>
            Service Cost: {userData.currency_data.symbol}
            {item.price}
          </p>
        </div>
        <div className=" ">
          <p className={styles.name_txt}>ETD : {item.durationInMinute} min</p>
        </div>
      </div>
    </div>
  );
}
