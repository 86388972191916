import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { AllMechanics } from "../components/AllMechanics";
import {
  createFeedbackMechanics,
  getAllMechanics,
  getAllSupervisor,
  reset,
} from "../services/allMechanicSlice";
import {
  Mechanic,
  MechanicFeedBackDT,
  MechanicsState,
  SupervisorRes,
} from "../services/types";

interface StateProps extends MechanicsState {
  getAllMechanics_Loading: boolean;
  getAllMechanics_Error: boolean;
  getAllMechanics_Success: boolean;
  getAllMechanics_details: Mechanic[];
  createFeedbackMechanics_Loading: boolean;
  createFeedbackMechanics_Error: boolean;
  createFeedbackMechanics_Success: boolean;
  createFeedbackMechanics_details: null;
  getAllSupervisor_Loading: boolean;
  getAllSupervisor_Error: boolean;
  getAllSupervisor_Success: boolean;
  getAllSupervisor_details: SupervisorRes[];
}

interface DispatchProps {
  GetAllMechanics(id: string): void;
  GetAllSupervisor(id: string): void;
  ResetAll(): void;
  CreateFeedbackMechanics(body: MechanicFeedBackDT): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getAllMechanics_Success: state.allMechanics.getAllMechanics_Success,
    getAllMechanics_Error: state.allMechanics.getAllMechanics_Error,
    getAllMechanics_Loading: state.allMechanics.getAllMechanics_Loading,
    getAllMechanics_details: state.allMechanics.getAllMechanics_details,
    createFeedbackMechanics_Success:
      state.allMechanics.createFeedbackMechanics_Success,
    createFeedbackMechanics_Error:
      state.allMechanics.createFeedbackMechanics_Error,
    createFeedbackMechanics_Loading:
      state.allMechanics.createFeedbackMechanics_Loading,
    createFeedbackMechanics_details:
      state.allMechanics.createFeedbackMechanics_details,
    getAllSupervisor_Success: state.allMechanics.getAllSupervisor_Success,
    getAllSupervisor_Error: state.allMechanics.getAllSupervisor_Error,
    getAllSupervisor_Loading: state.allMechanics.getAllSupervisor_Loading,
    getAllSupervisor_details: state.allMechanics.getAllSupervisor_details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllMechanics(id) {
      dispatch(getAllMechanics(id));
    },
    ResetAll() {
      dispatch(reset());
    },
    CreateFeedbackMechanics(body) {
      dispatch(createFeedbackMechanics(body));
    },
    GetAllSupervisor(id) {
      dispatch(getAllSupervisor(id));
    },
  };
};

export interface AllMechanicsProps extends StateProps, DispatchProps {}
export const AllMechanicsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AllMechanics);
