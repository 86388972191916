import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { SupervisorDashboard } from "../components/Dashboard";
import {
  getMechanics,
  getPercentageStatus,
  getVehicleTracking,
} from "../services/dashboardSlice";
import { DashboardRes, MechanicRes, VehicleTrack } from "../services/types";

interface StateProps {
  getPercentageStatus_Loading: boolean;
  getPercentageStatus_Error: boolean;
  getPercentageStatus_Success: boolean;
  getPercentageStatus_Details: DashboardRes;
  getVehicleTracking_Loading: boolean;
  getVehicleTracking_Error: boolean;
  getVehicleTracking_Success: boolean;
  getVehicleTracking_Details: VehicleTrack[] | [];
  getMechanics_Loading: boolean;
  getMechanics_Error: boolean;
  getMechanics_Success: boolean;
  getMechanics_Details: MechanicRes[] | [];
}

interface DispatchProps {
  GetPercentageStatus(id: string): void;
  GetVehicleTracking(id: string): void;
  GetMechanics(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getPercentageStatus_Success:
      state.supervisorDashboard.getPercentageStatus_Success,
    getPercentageStatus_Error:
      state.supervisorDashboard.getPercentageStatus_Error,
    getPercentageStatus_Loading:
      state.supervisorDashboard.getPercentageStatus_Loading,
    getPercentageStatus_Details:
      state.supervisorDashboard.getPercentageStatus_Details,
    getVehicleTracking_Success:
      state.supervisorDashboard.getVehicleTracking_Success,
    getVehicleTracking_Error:
      state.supervisorDashboard.getVehicleTracking_Error,
    getVehicleTracking_Loading:
      state.supervisorDashboard.getVehicleTracking_Loading,
    getVehicleTracking_Details:
      state.supervisorDashboard.getVehicleTracking_Details,
    getMechanics_Success: state.supervisorDashboard.getMechanics_Success,
    getMechanics_Error: state.supervisorDashboard.getMechanics_Error,
    getMechanics_Loading: state.supervisorDashboard.getMechanics_Loading,
    getMechanics_Details: state.supervisorDashboard.getMechanics_Details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetPercentageStatus: (id) => {
      dispatch(getPercentageStatus(id));
    },
    GetVehicleTracking(id) {
      dispatch(getVehicleTracking(id));
    },
    GetMechanics(id) {
      dispatch(getMechanics(id));
    },
  };
};

export interface SupervisorDashboardProps extends StateProps, DispatchProps {}
export const SupervisorDashboardComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupervisorDashboard);
