import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import SparePartRequest from "../components/SparepartRequest";
import {
  getAllSpareRequest,
  reset,
  updateSparePartReq,
} from "../service/spareRequestSlice";
import {
  RequestSpareRes,
  RequestSpareState,
  UpdateReqSparePartDT,
} from "../service/types";

interface StateProps extends RequestSpareState {
  getAllSpareRequest_Loading: boolean;
  getAllSpareRequest_Error: boolean;
  getAllSpareRequest_Success: boolean;
  getAllSpareRequest_details: RequestSpareRes[];
  updateSparePartReq_Loading: boolean;
  updateSparePartReq_Error: boolean;
  updateSparePartReq_Success: boolean;
  updateSparePartReq_details: null;
}

interface DispatchProps {
  GetAllSpareRequest(id: string): void;
  UpdateSparePartReq(body: UpdateReqSparePartDT): void;
  ResetAll(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getAllSpareRequest_Success: state.requestSpare.getAllSpareRequest_Success,
    getAllSpareRequest_Error: state.requestSpare.getAllSpareRequest_Error,
    getAllSpareRequest_Loading: state.requestSpare.getAllSpareRequest_Loading,
    getAllSpareRequest_details: state.requestSpare.getAllSpareRequest_details,
    updateSparePartReq_Success: state.requestSpare.updateSparePartReq_Success,
    updateSparePartReq_Error: state.requestSpare.updateSparePartReq_Error,
    updateSparePartReq_Loading: state.requestSpare.updateSparePartReq_Loading,
    updateSparePartReq_details: state.requestSpare.updateSparePartReq_details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllSpareRequest(id) {
      dispatch(getAllSpareRequest(id));
    },
    UpdateSparePartReq(body) {
      dispatch(updateSparePartReq(body));
    },
    ResetAll() {
      reset();
    },
  };
};

export interface SparePartRequestProps extends StateProps, DispatchProps {}
export const SparePartRequestComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SparePartRequest);
