import { Search } from "@mui/icons-material";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Button, Chip, OutlinedInput } from "@mui/material";
import { Edit } from "iconsax-react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllBay } from "../../../../pages/app/Configuration/services/configurationSlice";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";
import ButtonLog from "../../../common/Button/Button";
import CustomTable from "../../../common/Table/CustomTable";
import styles from "./Bays.module.css";

export default function Bays(props: any) {
  const BayDetails =
    useSelector((state: RootState) => state.configuration.BayDetails) ?? [];

  const dispatch: TypedDispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const userData = getUserDataFromLocalStorage();
  const onSubmit = async (data: any) => {
    await dispatch(
      getAllBay({
        id: userData.partner_id,
        queryParams: {
          name: data?.name,
        },
      })
    );
  };
  const navigation = useNavigate();
  const columns = [
    { id: "name", label: "Name", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 100 },
    { id: "is_active", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleEditClick = (bay: any) => {
    navigation("/bay-form/edit", {
      state: { bay },
    });
  };
  const updatedRows = BayDetails?.map((row: any) => ({
    ...row,
    is_active:
      row.is_active === false ? (
        <Chip label="In Active" variant="outlined" color="error" />
      ) : (
        <Chip label="Active" variant="outlined" color="success" />
      ),
    action: (
      <Button
        variant="outlined"
        startIcon={<Edit size="18" color="#0761e9" />}
        color="primary"
        onClick={() => handleEditClick(row)}
      >
        Edit
      </Button>
    ),
  }));
  return (
    <>
      <div className={styles.inventoryNavBar}>
        <ButtonLog
          text={"Back"}
          variant={"contained"}
          onClick={() => navigation(-1)}
          className={styles.backBtn}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: "flex" }}>
            <OutlinedInput
              sx={{
                borderRadius: "0px !important",
                height: "40px !important",
              }}
              {...register("name")}
            />
            <ButtonLog
              text="Search"
              variant="contained"
              type="submit"
              startIcon={<Search />}
            />
          </div>
        </form>
        <div className={styles.filter}>
          <ButtonLog
            text={"Add Bay"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/bay-form/add")}
          />
        </div>
      </div>

      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <CustomTable columns={columns} rows={updatedRows} />
      </div>
    </>
  );
}
