import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  SnackbarOrigin,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SelectChangeEvent } from "@mui/material/Select";
// import { Car } from "iconsax-react";
import { Camera, Upload } from "@mui/icons-material";
import { MuiColorInput } from "mui-color-input";
import * as React from "react";
import carblack from "../../../../../../assets/images/4wheeler_black.svg";
import carwhite from "../../../../../../assets/images/4wheeler_white.svg";
import suvblack from "../../../../../../assets/images/SUV_black.svg";
import suvwhite from "../../../../../../assets/images/SUV_white.svg";
import add_circle from "../../../../../../assets/images/add-circle.png";
import blackBike from "../../../../../../assets/images/bike_black.svg";
import whiteBike from "../../../../../../assets/images/bike_white.svg";
import close_circle from "../../../../../../assets/images/close-circle.png";
import hatchbackblack from "../../../../../../assets/images/hatchBack_black.svg";
import hatchbackwhite from "../../../../../../assets/images/hatchBack_white.svg";
import minitruckblack from "../../../../../../assets/images/miniTruck_black.svg";
import minitruckwhite from "../../../../../../assets/images/miniTruck_white.svg";
import sedanblack from "../../../../../../assets/images/sadan_black.svg";
import sedanwhite from "../../../../../../assets/images/sadan_white.svg";
import blackScooter from "../../../../../../assets/images/scooter-black.svg";
import whiteScooter from "../../../../../../assets/images/scooter-white.svg";
import CustomLoader from "../../../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../../../components/common/SnackBar/SnackBar";
import { getUserDataFromLocalStorage } from "../../../../../../utils/helper.utils";
import { SearchVehicleDT } from "../../service/types";
import { JobCardDT } from "../../type";
import styles from "../Registration.module.css";
import { VehicleCustomerProps as iProps } from "./container/VehicleCustomerDetailsContainer";

export interface imageData {
  index: number;
  imgUrl: string;
}

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}

interface VehicleCustomerProps extends iProps {
  onCustomerData: (data: JobCardDT) => void;
  SearchJobCard: (data: any) => void;
  searchJobCardDetails: SearchVehicleDT | null;
}

export function VehicleCustomerDetails(props: VehicleCustomerProps) {
  const {
    onCustomerData,
    createJobCard,
    createJobCardDetails,
    createJobCardError,
    createJobCardLoading,
    createJobCardSuccess,
    error,
    getVehicleData,
    loading,
    resetVehicleStates,
    SearchJobCard,
    searchJobCardDetails,
    searchJobCardError,
    searchJobCardLoading,
    searchJobCardSuccess,
  } = props;
  React.useEffect(() => {
    resetVehicleStates();
  }, [resetVehicleStates]);

  const [vechile, setVechile] = React.useState("");
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleChange = (event: SelectChangeEvent) => {
    setVechile(event.target.value);
  };

  const [presetColors, setPresetColors] = React.useState([
    "#FF3737",
    "#232222",
    "#FFFFFF",
    "#23289B",
    "#999999",
  ]);
  const [otherColors, setOtherColors] = React.useState(false);
  const [vehicleCategory, setVehicleCategory] = React.useState("");

  React.useEffect(() => {
    setCustomerDetails({
      engine_number: searchJobCardDetails
        ? searchJobCardDetails.VehicleDetails.engine_number
        : "",
      chasis_number: searchJobCardDetails
        ? searchJobCardDetails.VehicleDetails.chasis_number
        : "",
      vehicle_number: searchJobCardDetails
        ? searchJobCardDetails.VehicleDetails.vehicle_number.toUpperCase()
        : "",
      color: searchJobCardDetails
        ? searchJobCardDetails.VehicleDetails.color
        : "",
      phone_number: searchJobCardDetails
        ? searchJobCardDetails.CustomerDetails.phone_number
        : "",
      name: searchJobCardDetails
        ? searchJobCardDetails.CustomerDetails.name
        : "",
      email: searchJobCardDetails
        ? searchJobCardDetails.CustomerDetails.email
        : "",
      address: searchJobCardDetails
        ? searchJobCardDetails.CustomerDetails.address
        : "",
      model_number: searchJobCardDetails
        ? searchJobCardDetails.VehicleDetails.model_number
        : "",
      slug: searchJobCardDetails
        ? searchJobCardDetails.CustomerDetails.slug
        : "jobcard",
      kilometer_driven: searchJobCardDetails ? "" : "",
      vehicle_category: searchJobCardDetails
        ? searchJobCardDetails.VehicleDetails.vehicle_category
        : "2wheeler",
      vehicle_type: searchJobCardDetails
        ? searchJobCardDetails.VehicleDetails.vehicle_type
        : "bike",
      // image_paths: searchJobCardDetails
      //   ? searchJobCardDetails.VehicleDetails.image_paths
      //   : [],
      created_by: JSON.parse(localStorage.getItem("USER_DATA") ?? `{"id": 0}`)
        .id,
      partner_id: JSON.parse(
        localStorage.getItem("USER_DATA") ?? `{"partner_id": 0}`
      ).partner_id,
    });
    searchJobCardDetails &&
      searchJobCardDetails?.VehicleDetails.color &&
      setPresetColors([searchJobCardDetails.VehicleDetails.color]);
  }, [searchJobCardDetails]);
  let imageIndex = 0;
  const [image, setImage] = React.useState<any>([]);
  // let userDataParse: { id: string } = { id: "" };
  // const userData: any = localStorage.getItem("USER_DATA");
  const userDataParse = getUserDataFromLocalStorage();
  const [base64URL, setBase64URL] = React.useState<any>([]);
  const [customerDetails, setCustomerDetails] = React.useState<JobCardDT>({
    vehicle_number: "",
    color: "",
    model_number: "",
    engine_number: "",
    chasis_number: "",
    partner_id: userDataParse?.partner_id,
    kilometer_driven: "",
    phone_number: "",
    name: "",
    email: "",
    address: "",
    slug: "jhgdas",
    vehicle_category: "2wheeler",
    vehicle_type: "bike",
    image_paths: [],
    created_by: userDataParse?.id,
  });

  const [isChecked, setIsChecked] = React.useState(false);
  React.useEffect(() => {
    // Send data to the parent whenever customerDetails changes
    onCustomerData(customerDetails);
  }, [customerDetails, onCustomerData]);

  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL: any = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleImageUpload = React.useCallback(
    async (e: any) => {
      let selectedFile = e.target.files[0];
      if (base64URL.length <= 7 && e.target.files[0].size < 2097152) {
        setImage([
          ...image,
          {
            index: imageIndex++,
            imgUrl: URL.createObjectURL(selectedFile),
          },
        ]);
      } else {
        setSnackState((snackState: SnackStateProp) => ({
          ...snackState,
          message: String(
            "Image should be less than 3MB and not more than 8 images"
          ),
          snackOpen: true,
        }));
      }
      getBase64(selectedFile)
        .then((base64: any) => {
          selectedFile["base64"] = base64;
          // setFile(selectedFile);
          if (image.length <= 7 && e.target.files[0].size < 2097152) {
            setBase64URL([...base64URL, base64]);
            setCustomerDetails((prevState) => ({
              ...prevState,
              image_paths: [...base64URL, base64],
            }));
            setSnackState((snackState: SnackStateProp) => ({
              ...snackState,
              message: String("Vehicle Image added"),
              snackOpen: true,
              snackSeverity: "success",
            }));
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [base64URL, image, imageIndex]
  );

  React.useEffect(() => {
    if (createJobCardError) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createJobCardError),
        snackOpen: true,
        snackSeverity: "error",
      }));
    }
    // else if (createJobCardSuccess) {
    //   setSnackState((snackState: SnackStateProp) => ({
    //     ...snackState,
    //     message: String(createJobCardDetails),
    //     snackOpen: true,
    //     snackSeverity: "success",
    //   }));
    // }
  }, [createJobCardDetails, createJobCardError, createJobCardSuccess]);
  React.useEffect(() => {
    if (searchJobCardError && !searchJobCardDetails) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(searchJobCardError ? searchJobCardError : "Not found"),
        snackOpen: true,
        snackSeverity: "error",
      }));
    } else if (searchJobCardSuccess && searchJobCardDetails) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String("Vehicle Found !"),
        snackOpen: true,
        snackSeverity: "success",
      }));
    }
  }, [searchJobCardDetails, searchJobCardError, searchJobCardSuccess]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const handleDeleteImage = (url: string) => {
    setBase64URL(base64URL.filter((img: any) => url !== img));
    setCustomerDetails((prevState) => ({
      ...prevState,
      image_paths: base64URL.filter((img: any) => url !== img),
    }));
    setSnackState((snackState: SnackStateProp) => ({
      ...snackState,
      message: String("Vehicle Image removed"),
      snackOpen: true,
      snackSeverity: "success",
    }));
  };
  const handleVehicleSearch = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        SearchJobCard({
          vehicle_number: customerDetails.vehicle_number,
          partner_id: userDataParse?.partner_id,
        });
      }
    },
    [SearchJobCard, customerDetails.vehicle_number, userDataParse?.partner_id]
  );
  return (
    <>
      {createJobCardLoading || searchJobCardLoading ? <CustomLoader /> : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className="container">
        <p className={styles.header}>Vehicle & Customer details</p>
      </div>
      <hr className={styles.hr} />

      <div
        className="container FirstPhase"
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-xs-12 mb-2">
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <span className={styles.label}>
                  Vehicle Category
                  <span style={{ color: "red" }}>*</span>
                </span>
                <div className="container">
                  <RadioGroup
                    row
                    name="vehicle_category"
                    className={styles.radioGroup}
                    onChange={(e) => {
                      setCustomerDetails((prevState) => ({
                        ...prevState,
                        vehicle_category: e.target.value,
                      }));
                      // setIsChecked(true);
                    }}
                    value={customerDetails.vehicle_category}
                  >
                    <FormControlLabel
                      value="2wheeler"
                      control={
                        <Radio
                          color="primary"
                          icon={
                            // <Car
                            //   size="20"
                            //   color="black"
                            //   variant="TwoTone"
                            //   className={styles.radioIcon}
                            // />
                            <img src={blackBike} alt="" />
                          }
                          checkedIcon={
                            // <Car
                            //   size="20"
                            //   color="black"
                            //   variant="TwoTone"
                            //   className={`${styles.radioIcon} ${styles.checkedIcon}`}
                            // />
                            <img
                              src={whiteBike}
                              alt=""
                              style={{ border: "red" }}
                            />
                          }
                        />
                      }
                      label="2 Wheeler"
                      className={
                        customerDetails.vehicle_category === "2wheeler"
                          ? styles.formControlLabel1
                          : styles.formControlLabel
                      }
                    />

                    <FormControlLabel
                      value="4wheeler"
                      control={
                        <Radio
                          color="primary"
                          icon={
                            // <Car
                            //   size="20"
                            //   color="black"
                            //   variant="TwoTone"
                            //   className={styles.radioIcon}
                            // />
                            <img src={carblack} alt="" />
                          }
                          checkedIcon={
                            // <Car
                            //   size="20"
                            //   color="black"
                            //   variant="TwoTone"
                            //   className={`${styles.radioIcon} ${styles.checkedIcon}`}
                            // />
                            <img src={carwhite} alt="" />
                          }
                        />
                      }
                      label="4 Wheeler"
                      className={
                        customerDetails.vehicle_category === "4wheeler"
                          ? styles.formControlLabel1
                          : styles.formControlLabel
                      }
                    />
                  </RadioGroup>
                </div>
              </FormControl>
            </div>
            {customerDetails.vehicle_category === "4wheeler" && (
              <div className="col-lg-6 col-sm-6 col-xs-12 mb-2">
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <span className={styles.label}>
                    Vehicle Type
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="container">
                    <div className="row">
                      <RadioGroup
                        row
                        name="vehicle_type"
                        className={styles.radioGroup}
                        onChange={(e) => {
                          setCustomerDetails((prevState) => ({
                            ...prevState,
                            vehicle_type: e.target.value,
                          }));
                        }}
                        value={customerDetails.vehicle_type}
                      >
                        <FormControlLabel
                          value="hatchback"
                          control={
                            <Radio
                              color="primary"
                              icon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={styles.radioIcon}
                                // />
                                <img src={hatchbackblack} alt="" />
                              }
                              checkedIcon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={`${styles.radioIcon} ${styles.checkedIcon}`}
                                // />

                                <img src={hatchbackwhite} alt="" />
                              }
                            />
                          }
                          label="Hatchback"
                          className={
                            customerDetails.vehicle_type === "hatchback"
                              ? styles.formControlLabel1
                              : styles.formControlLabel
                          }
                        />
                        <FormControlLabel
                          value="sedan"
                          control={
                            <Radio
                              color="primary"
                              icon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={styles.radioIcon}
                                // />
                                <img src={sedanblack} alt="" />
                              }
                              checkedIcon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={`${styles.radioIcon} ${styles.checkedIcon}`}
                                // />
                                <img src={sedanwhite} alt="" />
                              }
                            />
                          }
                          label="Sedan"
                          className={
                            customerDetails.vehicle_type === "sedan"
                              ? styles.formControlLabel1
                              : styles.formControlLabel
                          }
                        />
                        <FormControlLabel
                          value="suv"
                          control={
                            <Radio
                              color="primary"
                              icon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={styles.radioIcon}
                                // />
                                <img src={suvblack} alt="" width="24px" />
                              }
                              checkedIcon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={`${styles.radioIcon} ${styles.checkedIcon}`}
                                // />
                                <img src={suvwhite} alt="" width="24px" />
                              }
                            />
                          }
                          label="SUV"
                          className={
                            customerDetails.vehicle_type === "suv"
                              ? styles.formControlLabel1
                              : styles.formControlLabel
                          }
                        />
                        <FormControlLabel
                          value="miniTruck"
                          control={
                            <Radio
                              color="primary"
                              icon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={styles.radioIcon}
                                // />
                                <img src={minitruckblack} alt="" />
                              }
                              checkedIcon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={`${styles.radioIcon} ${styles.checkedIcon}`}
                                // />
                                <img src={minitruckwhite} alt="" />
                              }
                            />
                          }
                          label="Mini Truck"
                          className={
                            customerDetails.vehicle_type === "miniTruck"
                              ? styles.formControlLabel1
                              : styles.formControlLabel
                          }
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </FormControl>
              </div>
            )}
            {customerDetails.vehicle_category === "2wheeler" && (
              <div className="col-lg-6 col-sm-6 col-xs-12 mb-2">
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <span className={styles.label}>
                    Vehicle Type
                    <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="container">
                    <div className="row">
                      <RadioGroup
                        row
                        name="vehicle_type"
                        className={styles.radioGroup}
                        onChange={(e) => {
                          setCustomerDetails((prevState) => ({
                            ...prevState,
                            vehicle_type: e.target.value,
                          }));
                        }}
                        value={customerDetails.vehicle_type}
                      >
                        <FormControlLabel
                          value="scooter"
                          control={
                            <Radio
                              color="primary"
                              icon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={styles.radioIcon}
                                // />
                                <img src={blackScooter} alt="" width="24px" />
                              }
                              checkedIcon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={`${styles.radioIcon} ${styles.checkedIcon}`}
                                // />

                                <img src={whiteScooter} alt="" width="24px" />
                              }
                            />
                          }
                          label="Scooter"
                          className={
                            customerDetails.vehicle_type === "scooter"
                              ? styles.formControlLabel1
                              : styles.formControlLabel
                          }
                        />
                        <FormControlLabel
                          value="bike"
                          control={
                            <Radio
                              color="primary"
                              icon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={styles.radioIcon}
                                // />
                                <img src={blackBike} alt="" />
                              }
                              checkedIcon={
                                // <Car
                                //   size="20"
                                //   color="black"
                                //   variant="TwoTone"
                                //   className={`${styles.radioIcon} ${styles.checkedIcon}`}
                                // />
                                <img src={whiteBike} alt="" />
                              }
                            />
                          }
                          label="Bike"
                          className={
                            customerDetails.vehicle_type === "bike"
                              ? styles.formControlLabel1
                              : styles.formControlLabel
                          }
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </FormControl>
              </div>
            )}

            <div className="col-lg-6 col-sm-6 col-xs-12 mb-2">
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <span className={styles.label}>
                  Vehicle Registration Number{" "}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  className={styles.InputRegistrationDesign}
                  onChange={(e) => {
                    setCustomerDetails((prevState) => ({
                      ...prevState,
                      vehicle_number: e.target.value.trim().toUpperCase(),
                    }));
                  }}
                  onKeyDown={handleVehicleSearch}
                  value={customerDetails?.vehicle_number}
                  placeholder="Enter Vehicle Number"
                />

                <FormHelperText></FormHelperText>
              </FormControl>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12 mb-2">
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <span className={styles.label}>
                  Vehicle Model
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  placeholder="Enter Vehicle Model"
                  className={styles.InputRegistrationDesign}
                  onChange={(e) => {
                    setCustomerDetails((prevState) => ({
                      ...prevState,
                      model_number: e.target.value,
                    }));
                  }}
                  value={customerDetails?.model_number}
                />
              </FormControl>
            </div>

            <div className="col-lg-6 col-sm-6 col-xs-12 mb-2">
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <span className={styles.label}>
                  Engine Number
                  <span style={{ color: "red" }}>*</span>
                </span>
                <OutlinedInput
                  placeholder="Enter Engine Number"
                  className={styles.InputRegistrationDesign}
                  onChange={(e) => {
                    setCustomerDetails((prevState) => ({
                      ...prevState,
                      engine_number: e.target.value.trim().toUpperCase(),
                    }));
                  }}
                  value={customerDetails?.engine_number}
                />
              </FormControl>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12 mb-2">
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <span className={styles.label}>
                  Chassis Number
                  <span style={{ color: "red" }}>*</span>
                </span>

                <OutlinedInput
                  placeholder="Enter Chasis Number"
                  className={styles.InputRegistrationDesign}
                  onChange={(e) => {
                    setCustomerDetails((prevState) => ({
                      ...prevState,
                      chasis_number: e.target.value.trim().toUpperCase(),
                    }));
                  }}
                  value={customerDetails?.chasis_number}
                />
              </FormControl>
            </div>

            <div className="col-lg-6 col-sm-6 col-xs-12">
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  {searchJobCardDetails
                    ? `Last Odometer ${searchJobCardDetails.jocarddetaisl.kilometer_driven} (KM Driven)`
                    : " Odometer (Km Driven)"}

                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  type="number"
                  placeholder="Enter Kilometer Of Driven"
                  className={styles.InputRegistrationDesign}
                  value={customerDetails?.kilometer_driven}
                  onChange={(e) => {
                    setCustomerDetails((prevState) => ({
                      ...prevState,
                      kilometer_driven: parseInt(e.target.value),
                    }));
                  }}
                  inputProps={{ min: 0 }}
                />
              </FormControl>
            </div>

            {/* <div className="col-lg-6 col-sm-6 col-xs-12">
              <div className="row"> */}
            <div className="col-lg-6 mb-3">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Vehicle color
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>

                <MuiColorInput
                  value={customerDetails?.color}
                  onChange={(color: any) => {
                    setCustomerDetails((prevState) => ({
                      ...prevState,
                      color: color,
                    }));
                  }}
                  className={styles.colorInput}
                  size="small"
                  format="hex"
                />
              </FormControl>
            </div>
            {/* </div>
            </div> */}
            <div className="container">
              <div className="row">
                {/* <div className={styles.image_main_content}> */}
                <label className={styles.label} htmlFor="colorPicker">
                  Vehicle Image
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div>
                  <div
                    style={{
                      // width: "792px",
                      height: "125px",
                      display: "flex",
                      // justifyContent: "space-evenly",
                      alignItems: "center",
                      background: "#FFFFFF",
                    }}
                  >
                    <div className={styles.img_container}>
                      <Button
                        className={styles.uploadLogo_btn}
                        component="label"
                        color="secondary"
                        // onClick={handleImageUpload}
                      >
                        <img
                          src={add_circle}
                          alt=""
                          width="24"
                          className="my-2"
                        />
                        <p className={styles.add_img_txt}>Add Image</p>
                        <input
                          hidden
                          accept="image/*"
                          name="cover"
                          type="file"
                          onChange={handleImageUpload}
                        />
                      </Button>
                    </div>
                    {base64URL.map(
                      (ele: any, index: React.Key | null | undefined) => (
                        <div className={styles.img_container} key={index}>
                          <img
                            src={close_circle}
                            alt=""
                            width="70"
                            className={styles.close_circle_btn}
                            onClick={() => handleDeleteImage(ele)}
                          />

                          <img
                            src={ele}
                            alt=""
                            width="100"
                            className={styles.vehicle_image}
                          />
                        </div>
                      )
                    )}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
      <br />

      <div className="container bottomPart">
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-xs-12 mb-2">
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <span className={styles.label}>
                  Customer Mobile Number
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  onChange={(e) => {
                    setCustomerDetails((prevState) => ({
                      ...prevState,
                      phone_number: e.target.value,
                    }));
                  }}
                  className={styles.InputRegistrationDesign}
                  placeholder="Enter mobile number"
                  type="number"
                  value={customerDetails?.phone_number}
                  // startAdornment={
                  //   <InputAdornment position="start">+91</InputAdornment>
                  // }
                />
              </FormControl>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12 mb-2">
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <span className={styles.label}>
                  Customer Name
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  placeholder="Enter Customer Name"
                  value={customerDetails?.name}
                  className={styles.InputRegistrationDesign}
                  onChange={(e) => {
                    setCustomerDetails((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <span className={styles.label}>
                  Email ID
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  placeholder="Enter Email Id"
                  value={customerDetails?.email}
                  className={styles.InputRegistrationDesign}
                  onChange={(e) => {
                    setCustomerDetails((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <span className={styles.label}>Address</span>
                <OutlinedInput
                  placeholder="Enter Address"
                  className={styles.InputRegistrationDesign}
                  multiline
                  onChange={(e) => {
                    setCustomerDetails((prevState) => ({
                      ...prevState,
                      address: e.target.value,
                    }));
                  }}
                  value={customerDetails?.address}
                  // rows={2}
                  maxRows={4}
                />
              </FormControl>
            </div>
          </div>
        </Box>
      </div>
      <Modal open={modalIsOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            height: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          {" "}
          <IconButton
            onClick={closeModal}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "#34495E",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Upload Image
              </Typography>
              <Button
                variant="contained"
                component="label"
                color="secondary"
                className="mx-3"
                endIcon={<Upload />}
              >
                Upload from File
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </Button>
              <Button
                variant="contained"
                color="primary"
                endIcon={<Camera />}
                onClick={() => {
                  // Implement camera access logic here
                }}
              >
                Open Camera
              </Button>
            </CardContent>
          </Card>
          {/* Add options for camera access and image upload here */}
        </Box>
      </Modal>
    </>
  );
}
