import { axios } from "../../../../../api/index.apis";
import {
  DeleteJobCardByIdDTO,
  DeleteProductById,
  DeleteServiceById,
  GetCheckinoutDT,
  JobCardCreateService,
  JobCardCreateSpares,
  NotificationDT,
  UserCheckInDT,
  UserCheckOutDT,
  updateJobcardStatusDTO,
} from "../type";

// export const getJobs = () => {
//   return axios.get("all-jobs");
// };

// export const getSpecificJob = (id: any) => {
//   return axios.get("get-job/" + `${id}`);
// };

// export const getComplaints = (id: any) => {
//   return axios.get("view-complaints?job_id=" + `${id}`);
// };

// export const getSpares = (id: any) => {
//   return axios.get("view-spares?job_id=" + `${id}`);
// };
export const GetAllJobCard = (id: number) => {
  return axios({
    method: "GET",
    url: `/jobcard/${id}`,
  });
};
export const GetJobCardDetails = (id: string) => {
  return axios({
    method: "GET",
    url: `/jobcard/jobcardDetails/${id}`,
  });
};
export const UpdateJobcardStatus = (data: updateJobcardStatusDTO) => {
  return axios({
    method: "POST",
    url: `/jobcard/updateJobcardStatus`,
    data: data,
  });
};
export const DeleteJobCardById = (data: DeleteJobCardByIdDTO) => {
  return axios({
    method: "DELETE",
    url: `/jobcard/${data.id}`,
    // data: { p_updatedby: data.p_updatedby },
  });
};
export const DeleteSparePartByID = (data: DeleteProductById) => {
  return axios({
    method: "DELETE",
    url: `jobcard-spare-parts/${data.id}`,
    // data: {
    //   deletedBy: data.deletedBy,
    // },
  });
};
export const DeleteJobcardServiceByID = (data: DeleteServiceById) => {
  return axios({
    method: "DELETE",
    url: `jobcard-service/${data.id}`,
    // data: {
    //   serviceId: data.serviceId,
    //   deletedBy: data.deletedBy,
    // },
  });
};

export const MechanicReAssignTask = (data: any) => {
  return axios({
    method: "POST",
    url: `jobcard-tasks/reassign/${data.id}`,
    data: {
      jobcardTask: [
        {
          assigned_to: data.assigned_to,
          createdBy: data.updated_by,
        },
      ],
    },
  });
};

export const CreateJobCardSpareParts = (data: JobCardCreateSpares) => {
  return axios({
    method: "POST",
    url: `jobcard-spare-parts`,
    data: data,
  });
};

export const CreateJobCardServices = (data: JobCardCreateService) => {
  return axios({
    method: "POST",
    url: `jobcard-service/`,
    data: data,
  });
};
export const GetEstimate = (id: string) => {
  return axios({
    method: "GET",
    url: `/jobcard/estimation/${id}`,
  });
};

export const UpdateJobCardSpareParts = (data: JobCardCreateSpares) => {
  return axios({
    method: "POST",
    url: `jobcard-spare-parts/remove-inventory`,
    data: data,
  });
};
export const UserCheckIn = (data: UserCheckInDT) => {
  return axios({
    method: "POST",
    url: `checkinout/checkin`,
    data: data,
  });
};

export const UserCheckOut = (data: UserCheckOutDT) => {
  return axios({
    method: "POST",
    url: `checkinout/checkout/${data.id}`,
    data: {
      checkoutTime: data.checkoutTime,
      checkoutDate: data.checkoutDate,
      partner_id: data.partner_id,
    },
  });
};
export const UserCheckInOutByID = (data: GetCheckinoutDT) => {
  return axios({
    method: "GET",
    url: `checkinout/${data.id}/${data.date}`,
  });
};
export const GetNotification = (id: string) => {
  return axios({
    method: "GET",
    url: `notifications/${id}`,
  });
};
export const CreateNotification = (data: NotificationDT) => {
  return axios({
    method: "POST",
    url: `notifications/`,
    data: data,
  });
};
