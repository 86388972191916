import {
  FormControlLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SnackbarOrigin,
  Switch,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";

import ButtonLog from "../../../../../../components/common/Button/Button";
import CustomLoader from "../../../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../../../components/common/SnackBar/SnackBar";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../../utils/helper.utils";
import styles from "../../ExpensesLog.module.css";
import { ExpensesLogFormProps } from "../container/ExpensesLogFormContainer";
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function ExpensesLogForm(props: ExpensesLogFormProps) {
  const {
    CreateExpensesLog,
    createExpensesLog_details,
    createExpensesLog_error,
    createExpensesLog_loading,
    createExpensesLog_success,
    ResetAll,
  } = props;
  const navigate = useNavigate();

  const [formType, setFormType] = React.useState<string | undefined>(
    "expenses-form/add"
  );
  let param = useParams();
  const { state } = useLocation();

  // let userDataParse: { id: string; company_id: number } = {
  //   id: "",
  //   company_id: 0,
  // };

  // const userData: any = localStorage.getItem("USER_DATA");
  const userDataParse = getUserDataFromLocalStorage();

  const [expenses, setExpenses] = React.useState({
    title: state ? state?.expenses.title : "",
    description: state ? state?.expenses.description : "",
    amount: state ? state?.expenses.amount : "",
    category: state ? state?.expenses.category : "",
  });

  const [checked, setChecked] = React.useState(
    state ? state?.expenses.isExpenses : true
  );

  const handleChange = (event: any) => {
    const updatedValue = event.target.checked;
    setChecked(updatedValue);
  };
  useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "error",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: expenses.title,
      errorMsz: "Title is required and must be of maximum 30 characters",
      max: 30,
      min: 1,
    },

    {
      checkwith: "min",
      value: expenses.description,
      errorMsz: "Description cannot be empty",
      min: 1,
    },
    {
      checkwith: "min",
      value: expenses.amount,
      errorMsz: "Amount cannot be empty",
      min: 1,
    },
    {
      checkwith: "emptyCheck",
      value: expenses.category,
      errorMsz: "Category cannot be empty",
      min: 1,
    },
  ];
  const handleSave = () => {
    validate(ValidateData).status
      ? CreateExpensesLog({
          ...expenses,
          partner_id: userDataParse.partner_id,
          isExpenses: checked ? true : false,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };

  const handleEdit = () => {
    // validate(ValidateData).status
    //   ? UpdateBay({
    //       ...bay,
    //       is_active: checked ? true : false,
    //       bay_id: state.bay.id,
    //       updated_by: userDataParse.id,
    //     })
    //   : setSnackState({
    //       ...snackState,
    //       snackOpen: true,
    //       message: validate(ValidateData).message,
    //       snackSeverity: "error",
    //     });
  };
  const [userType, setUserType] = React.useState("");
  // const handleChange = (event: SelectChangeEvent) => {
  //   setUserType(event.target.value as string);
  // };

  React.useEffect(() => {
    if (createExpensesLog_success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createExpensesLog_details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate("/expenses-log");
      }, 200);
      ResetAll();
    } else if (createExpensesLog_error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          createExpensesLog_details
            ? createExpensesLog_details
            : createExpensesLog_error
        ),
        snackOpen: true,
        snackSeverity: createExpensesLog_details ? "success" : "error",
      }));
      ResetAll();
    }
  }, [
    ResetAll,
    createExpensesLog_details,
    createExpensesLog_error,
    createExpensesLog_success,
    navigate,
  ]);

  React.useEffect(() => {
    if (createExpensesLog_success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createExpensesLog_details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate("/expenses-log");
      }, 200);
      ResetAll();
    } else if (createExpensesLog_error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          createExpensesLog_details
            ? createExpensesLog_details
            : "Something went wrong"
        ),
        snackOpen: true,
        snackSeverity: createExpensesLog_details ? "success" : "error",
      }));
      ResetAll();
    }
  }, [
    ResetAll,
    createExpensesLog_details,
    createExpensesLog_error,
    createExpensesLog_success,
    navigate,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {createExpensesLog_loading ? <CustomLoader /> : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>
            {formType === "expenses-form/add"
              ? "Add Expenses"
              : "Edit Expenses"}
          </p>
        </div>
        <div className="container mt-5" style={{ backgroundColor: "#F5F5F5;" }}>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Title
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Title"
                  type="text"
                  onChange={(e) => {
                    setExpenses((prevState) => ({
                      ...prevState,
                      title: e.target.value,
                    }));
                  }}
                  value={expenses?.title}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Description
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Description"
                  type="text"
                  onChange={(e) => {
                    setExpenses((prevState) => ({
                      ...prevState,
                      description: e.target.value,
                    }));
                  }}
                  value={expenses?.description}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Amount
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Amount"
                  type="number"
                  onChange={(e) => {
                    setExpenses((prevState) => ({
                      ...prevState,
                      amount: e.target.value,
                    }));
                  }}
                  value={expenses?.amount}
                />
              </FormControl>
              {/* {formType === "expenses-form/edit" && ( */}
              <>
                <FormControl
                  className={styles.formControl}
                  size="small"
                  sx={{
                    width: "100%",
                  }}
                >
                  <span className={styles.label}>
                    Is Expenses
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </span>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked
                        checked={checked}
                        onChange={handleChange}
                      />
                    }
                    label={checked ? "Expenses" : "Revenue"}
                    // onChange={}
                  />
                </FormControl>
              </>
              {/* )} */}
            </div>
            <div className="col-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Category
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <Select
                  labelId="demo-select-label"
                  id="demo-select"
                  className={styles.outline_inputbox}
                  value={expenses.category}
                  onChange={(e) => {
                    setExpenses((prevState) => ({
                      ...prevState,
                      category: e.target.value,
                    }));
                  }}
                  // renderValue={(selected) => (selected as string[]).join(", ")}
                >
                  <MenuItem disabled>Select Category</MenuItem>

                  <MenuItem value={"stock"}>Stock</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              variant={"contained"}
              className={styles.saveBtn}
              onClick={
                formType === "expenses-form/edit" ? handleEdit : handleSave
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
