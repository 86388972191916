import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Box, Button, Chip, OutlinedInput } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProductData } from "../../../../pages/app/Configuration/types";
import ButtonLog from "../../../common/Button/Button";
import CustomTable from "../../../common/Table/CustomTable";
import styles from "./ProductCategory.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getAllProductCate } from "../../../../pages/app/Configuration/services/configurationSlice";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";
import { Search } from "@mui/icons-material";
import { RootState, TypedDispatch } from "../../../../redux/store";

interface ProductCategoryProps {
  productCategoryDetails: any;
}

export default function ProductCategory(props: ProductCategoryProps) {
  const productCategoryDetails = useSelector(
    (state: RootState) => state.configuration.productCategoryDetails
  ) ?? [];

  const userData = getUserDataFromLocalStorage();
  const dispatch: TypedDispatch = useDispatch()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigation = useNavigate();

  const columns = [
    { id: "name", label: "Category Name", minWidth: 170 },
    { id: "description", label: "Details", minWidth: 100 },
    { id: "is_active", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const onSubmit = async (data: any) => {
    await dispatch(getAllProductCate({
      id: userData.partner_id,
        queryParams: {
          name: data?.name
        }
    }))
  };

  const handleEditClick = (product: ProductData) => {
    navigation("/product-category-form/edit", {
      state: { product },
    });
  };


  const updatedRows = productCategoryDetails?.map((row: ProductData) => ({
    ...row,
    is_active:
      row.is_active === false ? (
        <Chip label="In Active" variant="outlined" color="error" />
      ) : (
        <Chip label="Active" variant="outlined" color="success" />
      ),
    action: (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleEditClick(row)}
      >
        Edit
      </Button>
    ),
  }));

  return (
    <>
      <div className={styles.inventoryNavBar}>
        <ButtonLog
          text="Back"
          variant="contained"
          onClick={() => navigation(-1)}
          className={styles.backBtn}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex" }}>
            <OutlinedInput sx={{
              borderRadius: "0px !important",
              height: "40px !important"
            }} {...register('name')} />
            <ButtonLog
              text="Search"
              variant="contained"
              type="submit"
              startIcon={<Search />}
            />
          </Box>
        </form>
        <div className={styles.filter}>
          <ButtonLog
            text="Add Product Category"
            variant="contained"
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/product-category-form/add")}
          />
        </div>
      </div>
      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <CustomTable columns={columns} rows={updatedRows} />
      </div>
    </>
  );
}
