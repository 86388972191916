import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@mui/material";
// import TextField from "@mui/material/TextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OutlinedInput from "@mui/material/OutlinedInput";

import React, { useCallback } from "react";
import {
  ErrorMessageBasedOnType,
  RequiredEmptyFieldText,
} from "./FormTextInput.utils";
import { ErrorType, InputType, inputProps } from "./types";
export default function FormTextInput(props: inputProps & any) {
  const {
    label,
    placeholder,
    icon,
    type,
    value,
    onChange,
    error = false,
    showErrorMessage = true,
    validate = false,
    setValidStatus,
    className,
    errorType = "client",
    ...rest
  } = props;
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [OnceFocusedFlag, setOnceFocusedFlag] = React.useState<boolean>(false);
  const [inputValue, setInputVlaue] = React.useState<string>("");
  const [focused, setFocused] = React.useState<boolean>(false);
  const [passwordType, setPasswordType] = React.useState<InputType>(type);
  const [localErrorType, setLocalErrorType] =
    React.useState<ErrorType>("client");
  React.useEffect(() => {
    if (error) {
      setOnceFocusedFlag(true);
    }
  }, [error]);
  React.useEffect(() => {
    if (errorType) {
      setLocalErrorType(errorType);
    }
  }, [errorType]);
  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
    !showPassword ? setPasswordType("text") : setPasswordType("password");
  }, [showPassword]);
  const onChangeHandle = useCallback(
    (e: any) => {
      setOnceFocusedFlag(true);
      setLocalErrorType("client");
      onChange(e);
      setInputVlaue(e.target.value);
      if (validate && e?.target) {
        switch (type) {
          case "email":
            const isVlaidEmail =
              /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,4})+$/.test(
                e.target.value
              );
            setValidStatus(isVlaidEmail);
            break;
          case "phone":
            const isVlaidPhone =
              /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im.test(
                e.target.value
              );
            setValidStatus(isVlaidPhone);
            break;
          default:
            setValidStatus(e.target.value !== "");
        }
      }
    },
    [onChange, setValidStatus, type, validate]
  );
  const handleOnFocus = useCallback(() => {
    setFocused(true);
    inputValue === "" && setValidStatus(false);
  }, [inputValue, setValidStatus]);
  const handleOnBlur = useCallback(() => {
    setFocused(false);
  }, []);
  return (
    <FormControl
      error={!focused && OnceFocusedFlag && error}
      fullWidth
      variant="standard"
      className={`formControl ${className}`}
      sx={{ mb: 2 }}
    >
      {/* <InputLabel>{label}</InputLabel> */}
      <OutlinedInput
        {...rest}
        sx={{ borderRadius: "0px !important" }}
        fullWidth
        type={type !== "password" ? type : passwordType}
        value={value}
        onChange={onChangeHandle}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        endAdornment={
          type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
      {error &&
        showErrorMessage &&
        !focused &&
        OnceFocusedFlag &&
        localErrorType !== "server" && (
          <FormHelperText>
            {inputValue !== ""
              ? ErrorMessageBasedOnType(type)
              : RequiredEmptyFieldText}
          </FormHelperText>
        )}
    </FormControl>
  );
}
