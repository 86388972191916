import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import NotFoundComponent from "../components/common/NotFoundComponent/NotFoundComponent";
// import Feedback from "../pages/app/Feedback/Feedback";
// import FeedbackComponent from
import { CustomerOrderTrackingComponent } from "../pages/app/CustomerOrderTracking/container/CustomerContainer";
import { FeedbackComponent } from "../pages/app/Feedback/container/FeedbackContainer";
import MainLayout from "../pages/app/MainLayout/MainLayout";
import { PlanEnquiryComponent } from "../pages/app/PlanEnquiry/container/planEnquiryConatiner";
import { ForgotPasswordComponent } from "../pages/auth/ForgotPassword/container/forgotPasswordConatiner";
import { LoginComponent } from "../pages/auth/Login/container/loginContainer";
import { setUserDetails } from "../pages/auth/Login/service/loginSlice";
import { getAuthDataFromLocalStorage } from "../utils/helper.utils";
export default function Router() {
  var isTokenIn = localStorage.getItem("TOKEN");
  var isLoggedIn = localStorage.getItem("USER_DATA");
  const authData = getAuthDataFromLocalStorage(); // Replace this with the appropriate function to retrieve authData from local storage
  const { category, rolename } = authData ?? {};
  const [user, setUser] = React.useState<any>();

  const dispatch = useDispatch();
  const setUserData = useCallback(
    (data: any) => {
      setUser(data);
      dispatch(setUserDetails(data));
    },
    [dispatch]
  );

  const setUserDataNull = useCallback(() => {
    setUser(null);
  }, []);

  React.useEffect(() => {
    const userData: string | null = localStorage.getItem("USER_DATA");
    if (userData) {
      const userDataParse: any = JSON.parse(userData);
      setUser(userDataParse.user_type);
      // userDataParse ? setUserData(userDataParse) : setUserDataNull();
    }
  }, [setUserData, setUserDataNull]);
  return (
    <Routes>
      {isLoggedIn && isTokenIn ? (
        <>
          {/* {rolename === "Mechanic" ? (
            <>
              <Route path="/" element={<MechanicComponent />} />
              <Route
                path="/request-spare"
                element={<MechanicPendingComponent />}
              />
            </>
          ) : ( */}
          <Route path="/*" element={<MainLayout />} />
          {/* )} */}
          {/* <Route path="*" element={<NotFoundComponent />} /> */}
        </>
      ) : (
        <>
          <Route path="/" element={<LoginComponent />} />
          <Route
            path="/forget-password"
            element={<ForgotPasswordComponent />}
          />
          <Route path="*" element={<NotFoundComponent />} />
          <Route path="/feedback/:id" element={<FeedbackComponent />} />
          <Route path="/plan-enquiry" element={<PlanEnquiryComponent />} />
          <Route
            path="/service-tracking/:id"
            element={<CustomerOrderTrackingComponent />}
          />
        </>
      )}
    </Routes>
  );
}
