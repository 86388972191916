import { axios } from "../../../../api/setup.intersepter";
import { FORGOTPASSWORDDT, ResetPasswordDT } from "./types";

export const ForgotPasswordRequest = (data: FORGOTPASSWORDDT) => {
  return axios({
    method: "POST",
    url: `/auth/forgot-password`,
    data: {
      email: data.email,
    },
  });
};

export const ResetPasswordRequest = (data: ResetPasswordDT) => {
  return axios({
    method: "POST",
    url: `/auth/reset-password`,
    data: {
      email: data.email,
      code: data.code,
      newPassword: data.newPassword,
    },
  });
};
