import CloseIcon from "@mui/icons-material/Close";
import {
  FormControlLabel,
  IconButton,
  OutlinedInput,
  SnackbarOrigin,
  Switch,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../utils/helper.utils";
import ButtonLog from "../../../../common/Button/Button";
import CustomLoader from "../../../../common/Loader/Loader";
import { SnackBar } from "../../../../common/SnackBar/SnackBar";
import { ProductCategoryProps } from "../../container/productCategoryContainer";
import styles from "../ProductCategory.module.css";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function ProductCategoryForm(props: ProductCategoryProps) {
  const {
    CreateProductCate,
    createProductCateDetails,
    createProductCateError,
    createProductCateLoading,
    createProductCateSuccess,
    UpdateProductCate,
    updateProductCate_Details,
    updateProductCate_Error,
    updateProductCate_Loading,
    updateProductCate_Success,
    ResetProductCate,
  } = props;

  const navigate = useNavigate();
  const { state } = useLocation();
  // let userDataParse: { id: string } = { id: "" };
  // const userData: any = localStorage.getItem("USER_DATA");
  const userDataParse = getUserDataFromLocalStorage();
  const [checked, setChecked] = React.useState(
    state ? state?.product.is_active : true
  );

  const handleChange = (event: any) => {
    const updatedValue = event.target.checked;
    setChecked(updatedValue);
  };

  const [productCategory, setProductCategory] = React.useState({
    name: state ? state?.product.name : "",
    description: state ? state?.product.description : "",
    partner_id: userDataParse.partner_id,
    slug: "p_category",
    products: [],
  });

  const [formType, setFormType] = React.useState<string | undefined>(
    "product-category-form/add"
  );
  let param = useParams();
  React.useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);

  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });

  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: productCategory.name,
      errorMsz: "Category name must be of minimum 1 and maximun 30 characters",
      max: 30,
      min: 1,
    },
    {
      checkwith: "minmax",
      value: productCategory.description,
      errorMsz:
        "Category description must be of minimum 1 and maximun 50 characters allowed",
      max: 50,
      min: 1,
    },
  ];

  const handleSave = () => {
    validate(ValidateData).status
      ? CreateProductCate({ ...productCategory, created_by: userDataParse.id })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };

  const handleEdit = () => {
    validate(ValidateData).status
      ? UpdateProductCate({
          ...productCategory,
          IsActive: checked ? true : false,
          product_category_id: state.product.id,
          updated_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };
  // React.useEffect(() => {
  //   setSnackState((snackState: SnackStateProp) => ({
  //     ...snackState,
  //     message: String(createProductCateDetails),
  //     snackOpen: true,
  //     snackSeverity: "success",
  //   }));
  //   // }
  // }, [createProductCateSuccess]);
  React.useEffect(() => {
    if (createProductCateError) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createProductCateError),
        snackOpen: true,
        snackSeverity: "error",
      }));
      ResetProductCate();
    } else if (createProductCateSuccess) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createProductCateDetails),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 300);
      ResetProductCate();
    }
  }, [
    ResetProductCate,
    createProductCateDetails,
    createProductCateError,
    createProductCateSuccess,
    navigate,
  ]);
  React.useEffect(() => {
    if (updateProductCate_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateProductCate_Error),
        snackOpen: true,
        snackSeverity: "error",
      }));
      ResetProductCate();
    } else if (updateProductCate_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateProductCate_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 300);
      ResetProductCate();
    }
  }, [
    ResetProductCate,
    navigate,
    updateProductCate_Details,
    updateProductCate_Error,
    updateProductCate_Success,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {createProductCateLoading || updateProductCate_Loading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>
            {formType === "product-category-form/add"
              ? "Add Product Category"
              : "Edit Product Category"}
          </p>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Product Category Name
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Product Category Name"
                  onChange={(e) => {
                    setProductCategory((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  value={productCategory?.name}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Product Category Details
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Details"
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={(e) => {
                    setProductCategory((prevState) => ({
                      ...prevState,
                      description: e.target.value,
                    }));
                  }}
                  value={productCategory?.description}
                />
              </FormControl>

              {formType === "product-category-form/edit" && (
                <>
                  <FormControl
                    className={styles.formControl}
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <span className={styles.label}>
                      Status
                      {/* <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span> */}
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="Active"
                      // onChange={}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </div>
          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              onClick={
                formType === "product-category-form/edit"
                  ? handleEdit
                  : handleSave
              }
              variant="contained"
              type="submit"
              className={styles.saveBtn}
            />
          </div>
        </div>
      </div>
    </>
  );
}
