import { Search } from "@mui/icons-material";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { OutlinedInput } from "@mui/material";
import { Eye } from "iconsax-react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../../assets/images/DefaultImage.png";
import edit from "../../../../assets/images/edit.png";
import nodata from "../../../../assets/images/no-data-found.svg";
import { getAllProduct } from "../../../../pages/app/Configuration/services/configurationSlice";
import { Part } from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import {
  getAuthDataFromLocalStorage,
  getUserDataFromLocalStorage,
  isFunctionAllowed,
} from "../../../../utils/helper.utils";
import ButtonLog from "../../../common/Button/Button";
import styles from "./ProductInventory.module.css";

export default function ProductInventory(props: any) {
  const authData = getAuthDataFromLocalStorage();

  const productDetails =
    useSelector((state: RootState) => state.configuration.productDetails) ?? [];

  const dispatch: TypedDispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const userData = getUserDataFromLocalStorage();
  const onSubmit = async (data: any) => {
    await dispatch(
      getAllProduct({
        id: userData.partner_id,
        queryParams: {
          name: data?.name,
        },
      })
    );
  };

  const navigation = useNavigate();
  interface GroupedProductsType {
    [category_name: string]: Part[];
  }
  const [GroupedParts, updateGroupedParts] = useState<GroupedProductsType>({});
  useEffect(() => {
    updateGroupedParts(_.groupBy(productDetails, "product_category_name"));
  }, [productDetails]);

  return (
    <div className={styles.rootbar_main}>
      <div className={styles.inventoryNavBar}>
        <div className={styles.inventorySearch} style={{ display: "flex" }}>
          {/* <TextField
            fullWidth
            placeholder="Search Product"
            InputProps={{
              startAdornment: (
                <IconButton sx={{ padding: "0px", marginRight: "10px" }}>
                  <img src={searchIcon} alt="search-icon" />
                </IconButton>
              ),
              style: {
                height: "40px",
                width: "330px",
                borderRadius: "3px",
              },
            }}
            variant="outlined"
          /> */}

          <ButtonLog
            text={"Back"}
            variant={"contained"}
            onClick={() => navigation(-1)}
            className={styles.backBtn}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: "flex" }}>
            <OutlinedInput
              sx={{
                borderRadius: "0px !important",
                height: "40px !important",
              }}
              {...register("name")}
            />
            <ButtonLog
              text="Search"
              variant="contained"
              type="submit"
              startIcon={<Search />}
            />
          </div>
        </form>
        <div className={styles.filter}>
          {isFunctionAllowed(authData, "Masters", "Product") ? (
            <>
              <ButtonLog
                text={"Stock in"}
                variant={"contained"}
                className={styles.stockBtn}
                onClick={() => navigation("/stock-inventory-form/add")}
              />
            </>
          ) : null}

          <div className={styles.add_cata_btn}>
            <ButtonLog
              text={"Add Product Category"}
              variant={"contained"}
              className={styles.addProducteCateBtn}
              startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
              onClick={() => navigation("/product-category-form/add")}
            />
          </div>

          <ButtonLog
            text={"Add Product"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/product-management-form/add")}
          />
        </div>
      </div>
      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <div className={styles.rootProduct}>
          {productDetails?.length !== 0 ? (
            <>
              {Object.keys(GroupedParts).map((category, index) => (
                <div className="mb-3" key={index}>
                  <p className={styles.cata_title}>{category}</p>
                  <div className="row">
                    {GroupedParts[category].map((product: any, index: any) => (
                      <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div className={styles.spares_wrapper}>
                          <div className="d-flex justify-content-center">
                            <div className={styles.img_wrapper}>
                              <img
                                src={
                                  product.image_path
                                    ? `${
                                        process.env.REACT_APP_API_ENDPOINT
                                      }/${product.image_path.replace(
                                        "uploads/",
                                        ""
                                      )}`
                                    : defaultImage
                                }
                                alt="pic"
                                width={56}
                                height={56}
                                className={styles.img_wrapper_img}
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <p className={styles.spares_name}>{product.name}</p>
                          {/* <div className=" ">
                      <p
                        className={styles.spares_cost}
                      >{`Cost : ${product.Price}`}</p>
                    </div> */}
                          <div className=" ">
                            <p
                              className={styles.spares_stock}
                            >{`Product Code ${product.product_code}`}</p>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <ButtonLog
                                startIcon={
                                  <img
                                    src={edit}
                                    alt="pic"
                                    width={14}
                                    height={14}
                                    className="img-fluid"
                                  />
                                }
                                text={"Edit"}
                                variant={"outlined"}
                                className={styles.editBtn}
                                onClick={() =>
                                  navigation("/product-management-form/edit", {
                                    state: { product },
                                  })
                                }
                              />
                            </div>
                            <div className="col-6">
                              <ButtonLog
                                startIcon={<Eye size="17" />}
                                text={"View"}
                                variant={"outlined"}
                                className={styles.editBtn}
                                onClick={() =>
                                  navigation("/stock-inventory", {
                                    state: { product },
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <img src={nodata} alt="" className="img-fluid " width="500px" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
