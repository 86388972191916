import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../assets/images/logo11.png";
import CustomLoader from "../../../components/common/Loader/Loader";
import styles from "./Invoice.module.css";

import { getUserDataFromLocalStorage } from "../../../utils/helper.utils";
import { InvoiceProps } from "./container/invoiceContainer";
interface Arr {
  inv_name: string;
  inv_price: number;
  inv_minprice?: number;
  inv_maxprice?: number;
}
export default function Invoice(props: InvoiceProps) {
  const {
    // DownloadInvoice,
    // GetCustomerDetails,
    downloadInvoice_Details,
    downloadInvoice_Error,
    downloadInvoice_Loading,
    downloadInvoice_Success,
    getCustomerDetails,
    getCustomerDetails_Error,
    getCustomerDetails_Loading,
    getCustomerDetails_Success,
    resetDownloadInvoice,
    GetJobCradInvoice,
    getJobCradInvoice_Details,
    getJobCradInvoice_Error,
    getJobCradInvoice_Success,
    getJobCradInvoice_loading,
  } = props;

  const { state } = useLocation();
  const navigation = useNavigate();
  const userData = getUserDataFromLocalStorage();
  const [Subtotal, setSubtotal] = React.useState(0);
  const [isShowImg, setIsShowImg] = useState(false);
  const [gst, setGst] = React.useState<number>();
  const [customerDetails, setCustomerDetails] =
    React.useState<any>(getCustomerDetails);

  const componentPDF = useRef<any>();
  // React.useEffect(() => {
  //   DownloadInvoice(state?.id);
  //   // GetCustomerDetails(state?.id);
  // }, [DownloadInvoice, state]);

  React.useEffect(() => {
    setCustomerDetails(getCustomerDetails);
  }, [getCustomerDetails]);
  const handleGeneratePDF = React.useCallback(async () => {
    await setIsShowImg(true);
    await generatePDF();
    await setIsShowImg(false);
  }, []);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Invoice",
    // onafterprint: () => alert("Pdf downloaded"),
  });

  React.useEffect(() => {
    const total = downloadInvoice_Details.reduce((acc: any, item: any) => {
      return acc + item.Quantity * item.Price;
    }, 0);
    setSubtotal(total);
    setGst((total * 7) / 100);
  }, [downloadInvoice_Details]);

  React.useEffect(() => {
    GetJobCradInvoice(state?.id);
  }, [GetJobCradInvoice, state?.id]);
  const [invEntry, setInvEntry] = React.useState<Arr[]>([]);

  React.useEffect(() => {
    let arr: Arr[] = [];
    getJobCradInvoice_Details?.jobcardService?.forEach((ele: any) => {
      arr = [
        ...arr,
        {
          inv_name: ele.service_name,
          inv_price: parseInt(ele.service_price),
        },
      ];
    });

    getJobCradInvoice_Details?.jobcardSpareParts?.forEach((ele: any) => {
      arr = [
        ...arr,
        {
          inv_name: ele.product_name,
          inv_price: ele.spare_part_price,
          inv_minprice: ele.min_quantity,
          inv_maxprice: ele.max_quantity,
        },
      ];
    });
    setInvEntry(arr);
  }, [getJobCradInvoice_Details]);

  return (
    <>
      {getJobCradInvoice_loading ? <CustomLoader /> : null}
      {downloadInvoice_Details ? (
        <div className="container-fluid">
          <div className={styles.root_stack}>
            <div className="row">
              <div
                className="col-12"
                ref={componentPDF}
                style={{ width: "100%" }}
              >
                <div className={styles.invoiceTitle}>
                  {isShowImg && (
                    <div>
                      <img src={Logo} alt="logo" />
                    </div>
                  )}

                  <h1>Tax Invoice</h1>
                </div>
                <div className="row">
                  <div className={styles.invoiceHeader}>
                    <div className={styles.invoice}>
                      {customerDetails && (
                        <address>
                          <strong>Invoice To:</strong>
                          <br />
                          {getJobCradInvoice_Details?.customer?.customer_name}
                          {/* <p> swastik das </p> */}
                          <br />
                          <span className="text-lowercase">
                            {getJobCradInvoice_Details?.customer?.email}
                          </span>
                          <br />
                          {getJobCradInvoice_Details?.customer?.phone_number}
                          <br />
                          {customerDetails.EmailAddress}
                        </address>
                      )}
                    </div>
                    {/* <div className={styles.bill}>
                  <address>
                    <strong>Bill To:</strong>
                    <br />
                    Total Due: $12,110.55
                    <br />
                    Bank name: American Bank
                    <br />
                    Country: United States
                    <br />
                    IBAN: ETD95476213874685
                    <br />
                    SWIFT code: BR91905
                  </address>
                </div> */}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="container-fluid"
                    style={{
                      padding: "30px",
                    }}
                  >
                    {/* <TableContainer
                      component={Paper}
                      className={styles.dataTable}
                      sx={{
                        boxShadow: "none",
                      }}
                    > */}
                    <Table
                      sx={{ minWidth: 550, boxShadow: "none" }}
                      aria-label="simple table"
                    >
                      <TableHead className={styles.tableHead}>
                        <TableRow>
                          <TableCell align="left" width="100px">
                            SL NO.
                          </TableCell>
                          {/* <TableCell align="left">Cd</TableCell> */}
                          <TableCell align="left" width="200px">
                            Items
                          </TableCell>
                          <TableCell align="left" width="100px">
                            Quantity
                          </TableCell>
                          <TableCell align="left" width="100px">
                            Unit price
                          </TableCell>
                          <TableCell align="left" width="100px">
                            Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invEntry.map((ele, index) => {
                          const key = Math.random() * Math.random() + index;
                          return (
                            <TableRow
                              key={key}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {index + 1}
                              </TableCell>
                              {/* <TableCell component="th" scope="row">
                              {row.Cd}
                              </TableCell> */}

                              <TableCell align="left">{ele.inv_name}</TableCell>

                              <TableCell align="left">
                                {ele.inv_maxprice}
                              </TableCell>

                              <TableCell align="left">
                                {ele.inv_price?.toFixed(2)}
                              </TableCell>

                              <TableCell align="left">
                                {ele?.inv_maxprice
                                  ? ele?.inv_maxprice * ele.inv_price
                                  : ele.inv_price?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        <TableRow>
                          <TableCell rowSpan={3} />
                          <TableCell rowSpan={3} />
                          <TableCell colSpan={2}>Subtotal</TableCell>
                          <TableCell align="left">
                            {getJobCradInvoice_Details?.totalJobcardPrice?.toFixed(
                              2
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Tax</TableCell>
                          <TableCell align="left">
                            {userData.tax_percentage}%
                          </TableCell>
                          <TableCell align="left">
                            {getJobCradInvoice_Details?.totalJobcardPrice !==
                              undefined && userData.tax_percentage !== undefined
                              ? (
                                  getJobCradInvoice_Details.totalJobcardPrice +
                                  Math.round(
                                    (getJobCradInvoice_Details.totalJobcardPrice /
                                      100) *
                                      userData.tax_percentage
                                  )
                                ).toFixed(2)
                              : "n/a"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    {isShowImg && (
                      <>
                        <div className={styles.signatures}>
                          <div className={styles.signature}>
                            {/* Signature 1 */}
                            <p>Customer Signature</p>
                          </div>
                          <div className={styles.signature}>
                            {/* Signature 2 */}
                            <p>Authorized Signature</p>
                          </div>
                        </div>
                      </>
                    )}
                    {/* </TableContainer> */}
                    <div className="row">
                      <div className={styles.buttonContainer}>
                        {/* ... (existing buttons) */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={styles.buttonContainer}>
                  <Button
                    variant="outlined"
                    className={styles.footerButton}
                    onClick={() => navigation(-1)}
                  >
                    Back
                  </Button>
                  <Button variant="contained" onClick={handleGeneratePDF}>
                    Print
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h3>No data found</h3>
      )}
    </>
  );
}
