import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, IconButton, SnackbarOrigin } from "@mui/material";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import loginImg from "../../../../assets/images/Asset131.png";
import invincix from "../../../../assets/images/Invincix_TM.png";
import VerticiStudio from "../../../../assets/images/VerticiStudio.svg";
import ButtonLog from "../../../../components/common/Button/Button";
import FormTextInput from "../../../../components/common/FormTextInput/FormTextInput";
import { ErrorType } from "../../../../components/common/FormTextInput/types";
import { SnackBar } from "../../../../components/common/SnackBar/SnackBar";
import { LoginProps } from "../container/loginContainer";
import styles from "./Login.module.css";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function Login(props: LoginProps) {
  const { isLogin, loading, error, userLogin } = props;
  const navigate = useNavigate();
  const [credential, setCredential] = React.useState({
    email: "",
    password: "",
  });
  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [validStatePassword, setValidStatePassword] =
    React.useState<boolean>(true);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong. Please try again.",
    snackSeverity: "error",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateEmail(true);
        setValidStatePassword(true);
      }
      setErrorType("client");
      setCredential({ ...credential, [event.target.id]: event.target.value });
    },
    [credential, errorType]
  );
  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);
  const setValidateStatusPassword = React.useCallback((val: boolean) => {
    setValidStatePassword(val);
  }, []);

  const handleSubmit = React.useCallback(
    async (event: any) => {
      event.preventDefault();
      try {
        !credential.email && setValidStateEmail(false);
        !credential.password && setValidStatePassword(false);
        validStateEmail &&
          validStatePassword &&
          credential.email &&
          credential.password &&
          userLogin({
            email: credential.email,
            password: credential.password,
          });
      } catch (error) {
        console.log(error);
      }
    },
    [
      credential.email,
      credential.password,
      userLogin,
      validStateEmail,
      validStatePassword,
    ]
  );
  React.useEffect(() => {
    if (error && typeof error === "string" && error !== "false") {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: error ? error : "Something went wrong!",
        snackOpen: true,
      }));
      setShowErrMsz(false);
      setErrorType("server");
      // setValidStateEmail(false);
      // setValidStatePassword(false);
    }
  }, [error]);
  React.useEffect(() => {
    if (isLogin) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: "Successfully Login!",
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 500);
    }
  }, [error, isLogin, navigate]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div className="container-fluid">
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div
        className="row"
        // style={{
        //   display: "flex",
        //   flexDirection: "row",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
      >
        <div
          style={{ background: "#F7F7FC" }}
          className="col-lg-7 col-md-6 col-sm-12 d-none d-md-block"
        >
          <div className={styles.LoginImage1}>
            {/* <img src={LogoAutomotive} width="90" alt="" className="img-fluid" /> */}
          </div>
          <div className={styles.LoginImage}>
            <img
              src={loginImg}
              alt=""
              // style={{
              //   position: "absolute",
              //   width: "491px",
              //   height: "468px",
              // }}
              // className="img-fluid"
            />
          </div>
          <div className={styles.PoweredBy}>
            <p>© 2023 INVINCIX Solutions Pvt. Ltd.</p>
          </div>
        </div>
        <div
          style={{ background: "#fff" }}
          className="col-lg-5 col-md-6 col-sm-12"
        >
          <div className={styles.login_formMain}>
            <div className={styles.login_form}>
              <div>
                <p>
                  <img
                    src={VerticiStudio}
                    width="130"
                    alt=""
                    className="img-fluid"
                  />
                  {"  "}
                  {/* <span className={styles.company_name1}>Vertici </span>Auto */}
                </p>
              </div>
              <div>
                <p className={styles.login_header}>LOGIN</p>
                <p className={styles.login_paragraph}>
                  Please sign-in to your account !
                </p>
              </div>
              <form onSubmit={handleSubmit} noValidate>
                <div className="row flex-column justify-content-center text-center">
                  <div className="col-12">
                    <FormTextInput
                      validate={true}
                      setValidStatus={setValidateStatusEmail}
                      // label="Email"
                      // icon={user}
                      placeholder={"Email"}
                      type={"email"}
                      id={"email"}
                      error={!validStateEmail}
                      value={credential.email}
                      onChange={handleChange}
                      showErrorMessage={showErrMsz}
                      errorType={errorType}
                      // className={styles.input}
                    />
                  </div>
                  <div className="col-12">
                    <FormTextInput
                      validate={true}
                      setValidStatus={setValidateStatusPassword}
                      // icon={password}
                      placeholder={"Password"}
                      type={"password"}
                      id={"password"}
                      error={!validStatePassword}
                      value={credential.password}
                      onChange={handleChange}
                      showErrorMessage={showErrMsz}
                      errorType={errorType}
                      // className={styles.input}
                    />
                  </div>
                  <div className="col-12">
                    <div className={styles.linkWrapper}>
                      <Link to="/forget-password" className={styles.link}>
                        Forgot Password?
                      </Link>
                    </div>
                    <div>
                      <ButtonLog
                        text={
                          loading ? (
                            <CircularProgress size={25} color={"inherit"} />
                          ) : (
                            "Login"
                          )
                        }
                        // onClick={handleSubmit}
                        variant="contained"
                        type="submit"
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* <div className={styles.company_wrapper}>
              <div className="">
                <p className={styles.powered_by}>Powered by</p>
                <img
                  src={invincix}
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="mx-2">
                <img
                  src={logo_2}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div> */}
          </div>
          <div className={styles.PoweredByFrom}>
            <p> Designed and developed by</p>
            <div>
              <img src={invincix} width="100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
