import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { GetAllPaymentRequest, UpdatePaymentRequest } from "./payment.request";
import { PaymentRequestState, UpdatePaymentDT } from "./types";

export const initialState: PaymentRequestState = {
  getAllPaymentRequest_Loading: false,
  getAllPaymentRequest_Error: false,
  getAllPaymentRequest_Success: false,
  getAllPaymentRequest_details: [],
  updatePaymentRequest_Loading: false,
  updatePaymentRequest_Error: false,
  updatePaymentRequest_Success: false,
  updatePaymentRequest_details: null,
};
export const getAllPaymentRequest = createAsyncThunk(
  "user/getAllPaymentRequest",
  (id: string) => {
    return GetAllPaymentRequest(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data.payment;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const updatePaymentRequest = createAsyncThunk(
  "user/updatePaymentRequest",
  (body: UpdatePaymentDT) => {
    return UpdatePaymentRequest(body)
      .then((res) => {
        if (res.status === 200) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const PaymentRequest = createSlice({
  name: "PaymentRequest",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPaymentRequest.pending, (state) => {
      state.getAllPaymentRequest_Loading = true;
      state.getAllPaymentRequest_Error = false;
      state.getAllPaymentRequest_Success = false;
    });
    builder.addCase(
      getAllPaymentRequest.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllPaymentRequest_Loading = false;
        state.getAllPaymentRequest_Error = false;
        state.getAllPaymentRequest_Success = true;
        state.getAllPaymentRequest_details = action?.payload;
      }
    );
    builder.addCase(
      getAllPaymentRequest.rejected,
      (state, action: AnyAction) => {
        state.getAllPaymentRequest_Loading = false;
        state.getAllPaymentRequest_Error = false;
        state.getAllPaymentRequest_Success =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(updatePaymentRequest.pending, (state) => {
      state.updatePaymentRequest_Loading = true;
      state.updatePaymentRequest_Error = false;
      state.updatePaymentRequest_Success = false;
    });
    builder.addCase(
      updatePaymentRequest.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updatePaymentRequest_Loading = false;
        state.updatePaymentRequest_Error = false;
        state.updatePaymentRequest_Success = true;
        state.updatePaymentRequest_details = action?.payload;
      }
    );
    builder.addCase(
      updatePaymentRequest.rejected,
      (state, action: AnyAction) => {
        state.updatePaymentRequest_Loading = false;
        state.updatePaymentRequest_Error = false;
        state.updatePaymentRequest_Success =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default PaymentRequest.reducer;
export const { reset } = PaymentRequest.actions;
