import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useNavigate } from "react-router-dom";
import WelcomeImg from "../../../../../assets/images/WelcomeGif.gif";
import ButtonLog from "../../../../../components/common/Button/Button";
import styles from "./Home.module.css";
interface IProps {
  btn?: boolean;
  loading?: boolean;
}
function HomeWelcome(props: IProps) {
  const { btn, loading } = props;
  const navigate = useNavigate();
  return (
    <div className={styles.WelcomeMainHeader}>
      <div>{!btn ? <h2>Welcome you to vertici</h2> : null}</div>
      <div>
        <img src={WelcomeImg} alt="" className={styles.welcomeImg} />
      </div>
      <div>
        {!btn && !loading ? (
          <ButtonLog
            text={"Add vehicle"}
            variant={"contained"}
            className={styles.addVehicleBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigate("/vehicle-registration")}
          />
        ) : null}
      </div>
    </div>
  );
}

export default HomeWelcome;
