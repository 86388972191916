import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

export default function CustomLoader() {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color={"primary"} />
      </Backdrop>
    </div>
  );
}

CustomLoader.propTypes = {
  buttonText: PropTypes.string,
  color: PropTypes.oneOf(["inherit", "primary", "secondary"]),
};

CustomLoader.defaultProps = {
  buttonText: "Show loader",
  color: "primary",
};
