import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { CustomerFeedback } from "../../../pages/app/CustomerOrderTracking/services/types";
import { JobCardDTO } from "../../../pages/app/Manage/Home/type";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    // fontFamily: "Calibri",
    fontSize: 10,
    paddingTop: 25,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.15,
  },
  title: {
    fontSize: 19,
    textAlign: "center",
    marginBottom: 10,
  },
  address: {
    fontSize: 9,
    textAlign: "center",
    marginBottom: 10,
  },
  body: {
    fontSize: 8,
    textAlign: "justify",
  },
  contact: {
    marginLeft: 121.65,
    textAlign: "justify",
    // lineHeight: 10.95,
    fontSize: 9,
    marginBottom: 10,
  },
  section: {
    borderWidth: 1,
    borderColor: "black",
    padding: 5,
    marginBottom: 20,
  },

  fieldLabel: {
    fontWeight: "bold",
    marginBottom: 5,
  },

  fieldValue: {
    marginBottom: 10,
  },

  feedbackFormField: {
    marginBottom: 10,
    borderWidth: 1,
    borderColor: "black",
    padding: 15,
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },

  signatureBlock: {
    // border: "1pt solid black",
    padding: 10,
    marginBottom: 10,
    flexDirection: "column",
    justifyContent: "space-between",
  },
});

interface GatePassProps {
  data?: JobCardDTO | undefined;
  getFeedback_Details: CustomerFeedback | null;
}
export default function GatePass(props: GatePassProps) {
  const { data, getFeedback_Details } = props;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ border: "1 solid black", padding: 20 }}>
          <View>
            <Text style={styles.title}>{getFeedback_Details?.garage_name}</Text>
            <Text style={styles.address}>
              {getFeedback_Details?.address}, Ph:{" "}
              {getFeedback_Details?.contact_number}, Email:{" "}
              {getFeedback_Details?.email}
            </Text>
            <Text
              style={{
                marginBottom: 10,
                fontSize: 14,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Gatepass
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.fieldLabel}>Customer Details:</Text>
            <Text style={styles.fieldValue}>
              Customer Name: {getFeedback_Details?.customer_name}
            </Text>
            <Text style={styles.fieldValue}>
              Vehicle Number: {getFeedback_Details?.vehicle_number}
            </Text>
            <Text style={styles.fieldValue}>
              Vehicle Name: {getFeedback_Details?.vehicle_name}
            </Text>
          </View>

          {/* Feedback Form */}
          <View style={styles.section}>
            <Text style={styles.fieldLabel}>Feedback Form:</Text>
            <View style={styles.feedbackFormField}>
              <Text style={styles.fieldLabel}>Service Rating (1-5): _____</Text>
            </View>
            <Text style={styles.fieldLabel}>Comments:</Text>
            <View style={styles.feedbackFormField}>
              <Text>{/* Multiline text area for comments */}</Text>
            </View>
          </View>
          {/* Rest of the content goes here */}
          <View style={styles.signatureContainer}>
            <View style={styles.signatureBlock}>
              <Text>Customer Signature: ________________________</Text>
              <Text
                style={{
                  marginTop: "10",
                }}
              >
                Date: ________________________
              </Text>
            </View>
            <View style={styles.signatureBlock}>
              <Text>Authorized Signature: ________________________</Text>
              <Text
                style={{
                  marginTop: "10",
                }}
              >
                Date: ________________________
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
