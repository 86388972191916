import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, SnackbarOrigin, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomLoader from "../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../components/common/SnackBar/SnackBar";
import {
  getAuthDataFromLocalStorage,
  getUserDataFromLocalStorage,
} from "../../../../utils/helper.utils";
import {
  ChangeStatusOfServiceProps,
  MechanicPageProps,
  SelectBayByMechProps,
  SparePartRet,
} from "../service/type";
import styles from "./Mechanic.module.css";
import MechanicTabPannel from "./MechanicTabPannel";
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function Mechanic(props: MechanicPageProps) {
  const {
    mechanicJobs,
    GetAllJobsByMechanicID,
    ChangeStatusOfService,
    ResetJobsByMechanicID,
    changeStatusOfService_Loading,
    getAllMechJobs_Loading,
    changeStatusOfService_Success,
    ResetMechanicChangeStatus,
    changeStatusOfService_Error,
    BayDetails,
    GetAllBay,
    getAllBayLoading,
    getAllBayError,
    getAllBaySuccess,
    SelectBayByMech,
    getAllMechJobs_Error,
    getAllMechJobs_Success,
    selectBayByMech_Error,
    selectBayByMech_Loading,
    selectBayByMech_Success,
    requestSparePart_Error,
    requestSparePart_Loading,
    requestSparePart_Success,
    requestSparePart_details,
    ReturnSparePart,
    returnSparePart_Error,
    returnSparePart_Loading,
    returnSparePart_Success,
    returnSparePart_details,
    ResetSpare,
  } = props;
  // let userDataParse: { id: string } = { id: "" };
  //  userData: any = localStorage.getItem("USER_DATA");
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const userDataParse = getUserDataFromLocalStorage();
  const { state } = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    ResetJobsByMechanicID();
    GetAllJobsByMechanicID({
      id: state ? state?.id : userDataParse?.id,
      partner_id: userDataParse?.partner_id,
    });

    GetAllBay(userDataParse?.partner_id);
  }, [
    GetAllBay,
    GetAllJobsByMechanicID,
    ResetJobsByMechanicID,
    state,
    userDataParse?.id,
    userDataParse?.partner_id,
  ]);
  const handleChangeStatusOfService = React.useCallback(
    (data: ChangeStatusOfServiceProps) => {
      ChangeStatusOfService(data);
      // alert(JSON.stringify(data));
    },
    [ChangeStatusOfService]
  );

  const handleReturnPartService = React.useCallback(
    (data: SparePartRet) => {
      const JobCardSpare = {
        id: data.id,
        partner_id: getUserDataFromLocalStorage().partner_id,
        jobCard_id: data?.jobCard_id,
        product_id: data.product_id,
        quantity: data.quantity,
        is_estimated: "0",
        created_by: getUserDataFromLocalStorage().id,
      };
      const datas = {
        jobcardSpareParts: [JobCardSpare],
      };
      ReturnSparePart(datas);

      // alert(JSON.stringify(data));
    },
    [ReturnSparePart]
  );
  const handleSelectBay = React.useCallback(
    (data: SelectBayByMechProps) => {
      SelectBayByMech(data);
      // alert(JSON.stringify(data));
    },
    [SelectBayByMech]
  );
  React.useEffect(() => {
    if (changeStatusOfService_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String("Task Status Updated"),
        snackOpen: true,
        snackSeverity: "success",
      }));
      GetAllJobsByMechanicID({
        id: state ? state?.id : userDataParse?.id,
        partner_id: userDataParse?.partner_id,
      });
    }
    if (changeStatusOfService_Success || changeStatusOfService_Error) {
      ResetMechanicChangeStatus();
    }
  }, [
    GetAllJobsByMechanicID,
    ResetMechanicChangeStatus,
    changeStatusOfService_Error,
    changeStatusOfService_Success,
    state,
    userDataParse?.id,
    userDataParse?.partner_id,
  ]);
  const authData = getAuthDataFromLocalStorage(); // Replace this with the appropriate function to retrieve authData from local storage
  const { category, rolename } = authData ?? {};
  const jobCardCategory = category?.find(
    (category: any) => category.name === "Mechanic"
  );

  const functions = jobCardCategory?.functions;
  React.useEffect(() => {
    if (requestSparePart_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String("Part Requested Successfully"),
        snackOpen: true,
        snackSeverity: "success",
      }));
      // setTimeout(() => {
      //   navigate(-1);
      // }, 200);
      ResetJobsByMechanicID();
      ResetSpare();
    } else if (requestSparePart_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String("Something went wrong !!!"),
        snackOpen: true,
        snackSeverity: requestSparePart_Error ? "error" : "success",
      }));
      ResetJobsByMechanicID();
      ResetSpare();
    }
    if (returnSparePart_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String("Part Returned Successfully"),
        snackOpen: true,
        snackSeverity: "success",
      }));
      // setTimeout(() => {
      //   navigate(-1);
      // }, 200);
      ResetJobsByMechanicID();
      ResetSpare();
      GetAllJobsByMechanicID({
        id: state ? state?.id : userDataParse?.id,
        partner_id: userDataParse?.partner_id,
      });
    } else if (returnSparePart_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String("Something went wrong !!!"),
        snackOpen: true,
        snackSeverity: returnSparePart_Error ? "error" : "success",
      }));
      ResetJobsByMechanicID();
      ResetSpare();
      GetAllJobsByMechanicID({
        id: state ? state?.id : userDataParse?.id,
        partner_id: userDataParse?.partner_id,
      });
    }
    if (selectBayByMech_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String("Bay Selected Successfully"),
        snackOpen: true,
        snackSeverity: "success",
      }));
      // setTimeout(() => {
      //   navigate(-1);
      // }, 200);
      ResetJobsByMechanicID();
      GetAllJobsByMechanicID({
        id: state ? state?.id : userDataParse?.id,
        partner_id: userDataParse?.partner_id,
      });
    } else if (selectBayByMech_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String("Something went wrong !!!"),
        snackOpen: true,
        snackSeverity: selectBayByMech_Error ? "success" : "error",
      }));
      ResetJobsByMechanicID();
      GetAllJobsByMechanicID({
        id: state ? state?.id : userDataParse?.id,
        partner_id: userDataParse?.partner_id,
      });
    }
  }, [
    GetAllJobsByMechanicID,
    ResetJobsByMechanicID,
    ResetSpare,
    navigate,
    requestSparePart_Error,
    requestSparePart_Success,
    returnSparePart_Error,
    returnSparePart_Success,
    selectBayByMech_Error,
    selectBayByMech_Success,
    state,
    userDataParse?.id,
    userDataParse?.partner_id,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortBy, setSortBy] = useState<string>("created_at"); // Set the default sorting field to "created_at"

  const handleSortChange = (field: string) => {
    if (field === sortBy) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortDirection("asc");
    }
  };
  const sortedMechanicJobs = [...mechanicJobs].sort((a, b) => {
    const aValue = a[sortBy];
    const bValue = b[sortBy];

    if (sortDirection === "asc") {
      return aValue.localeCompare(bValue);
    } else {
      return bValue.localeCompare(aValue);
    }
  });

  return (
    <>
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_container}>
        <div className={styles.sort_container}>
          <Tooltip
            title={`Sort ${
              sortDirection === "asc" ? "Descending" : "Ascending"
            }`}
          >
            <IconButton
              onClick={() => handleSortChange("created_at")}
              color="primary" // Always use primary color
              className={styles.sort_button}
            >
              {sortDirection === "asc" ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
          </Tooltip>
        </div>

        <div className="col-12">
          {changeStatusOfService_Loading ||
          selectBayByMech_Loading ||
          getAllMechJobs_Loading ||
          getAllBayLoading ||
          requestSparePart_Loading ||
          returnSparePart_Loading ? (
            <CustomLoader />
          ) : (
            <MechanicTabPannel
              changeStatus={handleChangeStatusOfService}
              data={sortedMechanicJobs?.length ? sortedMechanicJobs : []}
              BayDetails={BayDetails}
              selectBay={handleSelectBay}
              functions={functions}
              returnPart={handleReturnPartService}
            />
          )}
        </div>
      </div>
    </>
  );
}
