import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  IconButton,
  MenuItem,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { Setting2 } from "iconsax-react";
import * as React from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import add_circle from "../../../../../assets/images/add-circle.png";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../utils/helper.utils";
import ButtonLog from "../../../../common/Button/Button";
import CustomLoader from "../../../../common/Loader/Loader";
import { SnackBar } from "../../../../common/SnackBar/SnackBar";
import { SnackStateProp } from "../../../ProductCategory/components/ProductCategoryForm/ProductCategoryForm";
import styles from "../ProductInventory.module.css";
import { productUOMArray } from "./Uom.data";
import { ProductInventoryFormProps } from "./container/productInventoryContainer";
// interface ProductInventoryFormProps extends Iprops{

// }
export default function ProductInventoryForm(props: ProductInventoryFormProps) {
  const {
    createInventryProduct,
    createInventryProduct_Error,
    createInventryProduct_Loading,
    createInventryProduct_Success,
    inventryProductDetails,
    resetAllServiceCategory,
    updateInventryProduct,
    updateInventryProduct_Error,
    updateInventryProduct_Loading,
    updateInventryProduct_Success,
    updateinventryProductDetails,
    activeProductCategoryDetails,
    getAllProductActiveCateError,
    getAllProductActiveCateLoading,
    getAllProductActiveCateSuccess,
    GetAllActiveProductCate,
  } = props;
  const [image, setImage] = React.useState<any>();
  const [base64URL, setBase64URL] = React.useState<any>([]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });

  const [formType, setFormType] = React.useState<string | undefined>(
    "product-management-form/add"
  );
  const userDataParse = getUserDataFromLocalStorage();

  React.useEffect(() => {
    GetAllActiveProductCate(userDataParse?.partner_id);
  }, [GetAllActiveProductCate, userDataParse?.partner_id]);

  const navigate = useNavigate();
  const { state } = useLocation();

  // let userDataParse: { id: string } = { id: "" };

  // const userData: any = localStorage.getItem("USER_DATA");
  // userDataParse = JSON.parse(userData);

  const [product, setProduct] = React.useState({
    product_code: state ? state?.product.product_code : "",
    name: state ? state?.product.name : "",
    description: state ? state?.product.description : "",
    uom: state ? state?.product.uom : "",
    min_quantity: state ? state?.product.min_quantity : 0,
    max_quantity: state ? state?.product.max_quantity : 0,
    product_category_id: state ? state?.product?.product_category_id : "",
    image_path: state ? state?.product.image_path : "",
    slug: state ? state?.product.slug : "p_1",
    partner_id: userDataParse ? userDataParse?.partner_id : "0",
    ProductInventory: [],
  });
  let imageIndex = 0;

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL: any = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const handleImageUpload = React.useCallback(
    async (e: any) => {
      // console.log(e.target.files[0]);
      setImage(e.target.files[0]);
      let selectedFile = e.target.files[0];
      if (image?.length <= 3 && e.target.files[0].size < 2097152) {
        setImage([
          ...image,
          {
            index: imageIndex++,
            imgUrl: URL.createObjectURL(selectedFile),
          },
        ]);
      } else {
      }
      getBase64(selectedFile)
        .then((base64: any) => {
          selectedFile["base64"] = base64;
          // setFile(selectedFile);
          // setBase64URL([...base64URL, base64]);
          setProduct((prevState: typeof product) => ({
            ...prevState,
            image_path: base64,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
      setImage(URL.createObjectURL(e.target.files[0]));
    },
    [image, imageIndex]
  );
  const [checked, setChecked] = React.useState(
    state ? state?.product.is_active : true
  );

  const handleChange = (event: any) => {
    const updatedValue = event.target.checked;
    setChecked(updatedValue);
  };

  React.useEffect(() => {
    if (createInventryProduct_Success) {
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: inventryProductDetails
          ? inventryProductDetails
          : "Created Successfully",
        snackSeverity: "success",
      });
      setTimeout(() => {
        navigate(-1);
      }, 300);
      resetAllServiceCategory();
    } else if (createInventryProduct_Error) {
      resetAllServiceCategory();
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: inventryProductDetails
          ? inventryProductDetails
          : "Something Went Wrong",
        snackSeverity: "error",
      });
    }
  }, [
    createInventryProduct_Success,
    createInventryProduct_Error,
    snackState,
    inventryProductDetails,
    resetAllServiceCategory,
    navigate,
  ]);

  React.useEffect(() => {
    if (updateInventryProduct_Success) {
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: updateinventryProductDetails
          ? updateinventryProductDetails
          : "Created Successfully",
        snackSeverity: "success",
      });
      setTimeout(() => {
        navigate(-1);
      }, 300);
      resetAllServiceCategory();
    } else if (updateInventryProduct_Error) {
      resetAllServiceCategory();
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: updateinventryProductDetails
          ? updateinventryProductDetails
          : "Something Went Wrong",
        snackSeverity: "error",
      });
    }
  }, [
    updateInventryProduct_Success,
    updateInventryProduct_Error,
    snackState,
    updateinventryProductDetails,
    resetAllServiceCategory,
    navigate,
  ]);
  let param = useParams();
  React.useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);

  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: product.name,
      errorMsz: "Product name must be of  maximum 30 characters",
      max: 30,
      min: 1,
    },
    {
      checkwith: "min",
      value: product.product_category_id,
      errorMsz: "Please select product category",
      min: 1,
    },

    {
      checkwith: "min",
      value: product.min_quantity,
      errorMsz: "Please provide the quantity",
      min: 1,
    },
    {
      checkwith: "min",
      value: product.max_quantity,
      errorMsz: "Please provide the quantity",
      min: 1,
    },
  ];
  const handleSave = () => {
    validate(ValidateData).status
      ? createInventryProduct({
          ...product,
          created_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };

  const handleEdit = () => {
    validate(ValidateData).status
      ? updateInventryProduct({
          ...product,
          IsActive: checked ? true : false,
          product_id: state.product.id,
          updated_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {getAllProductActiveCateLoading ||
      createInventryProduct_Loading ||
      updateInventryProduct_Loading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>
            {formType === "product-management-form/add"
              ? "Add Product"
              : "Edit Product"}
          </p>
        </div>
        <div className="container mt-5" style={{ backgroundColor: "#f7f7fc" }}>
          <div className="row align-items-center">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Product Code{" "}
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Product Code"
                  onChange={(e) => {
                    setProduct((prevState: typeof product) => ({
                      ...prevState,
                      product_code: e.target.value,
                    }));
                  }}
                  value={product?.product_code}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Product Name
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Product Name"
                  onChange={(e) => {
                    setProduct((prevState: typeof product) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  value={product?.name}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Product Description
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Product Description"
                  onChange={(e) => {
                    setProduct((prevState: typeof product) => ({
                      ...prevState,
                      description: e.target.value,
                    }));
                  }}
                  value={product?.description}
                />
              </FormControl>
            </div>
            <div className="col-md-5">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Product Category
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  value={product.product_category_id}
                  onChange={(e) => {
                    setProduct((prevState: typeof product) => ({
                      ...prevState,
                      product_category_id: e.target.value,
                    }));
                  }}
                  className={styles.outline_inputbox}
                >
                  {activeProductCategoryDetails &&
                    activeProductCategoryDetails?.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  {/* <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-1">
              <Tooltip arrow title=" Add Product Category">
                <IconButton
                  onClick={() => navigate("/stock-inventory-form/add")}
                >
                  <Setting2 color="#000" />
                </IconButton>
              </Tooltip>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Min quantity{" "}
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Min quantity"
                  onChange={(e) => {
                    setProduct((prevState: typeof product) => ({
                      ...prevState,
                      min_quantity: parseInt(e.target.value),
                    }));
                  }}
                  value={product?.min_quantity}
                  type="number"
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Max quantity
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Max quantity"
                  type="number"
                  onChange={(e) => {
                    setProduct((prevState: typeof product) => ({
                      ...prevState,
                      max_quantity: parseInt(e.target.value),
                    }));
                  }}
                  value={product?.max_quantity}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  UOM
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  value={product.uom}
                  displayEmpty
                  onChange={(e) => {
                    setProduct((prevState: typeof product) => ({
                      ...prevState,
                      uom: e.target.value,
                    }));
                  }}
                  className={styles.outline_inputbox}
                >
                  {productUOMArray &&
                    productUOMArray.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <div>
                <label className={styles.label} htmlFor="colorPicker">
                  Product Image
                </label>
                <div className="imageAdder">
                  <div className={styles.vehiclesimagesAdd}>
                    <div className={styles.img_container}>
                      {!image ? (
                        <Button
                          className={styles.uploadLogo_btn}
                          component="label"
                          color="secondary"
                        >
                          <img
                            src={add_circle}
                            alt=""
                            width="24"
                            className="my-2"
                          />
                          <p className={styles.add_img_txt}>Add Image</p>
                          <input
                            hidden
                            accept="image/*"
                            name="cover"
                            type="file"
                            onChange={handleImageUpload}
                          />
                        </Button>
                      ) : (
                        <img
                          src={image}
                          alt=""
                          width="100"
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              {formType === "product-management-form/edit" && (
                <>
                  <FormControl
                    className={styles.formControl}
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <span className={styles.label}>
                      Status
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="Active"
                      // onChange={}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </div>

          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              variant={"contained"}
              className={styles.saveBtn}
              onClick={
                formType === "product-management-form/edit"
                  ? handleEdit
                  : handleSave
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
