import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";
import React, { useState } from "react";
import down from "../../../../assets/images/downGrowth.png";
import up from "../../../../assets/images/upGrowth.png";
import { freetechnicians, technicians } from "../analytics.data";
import styles from "./Analytics.module.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={styles.tabPanel}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Analytics() {
  const [value, setValue] = React.useState(0);
  const [engagedTec] = useState(technicians);
  const [freeTec] = useState(freetechnicians);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="container-fluid">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.todayStats}>
              <h1>Today</h1>
            </div>
          </div>
        </div>
      </div>
      <Grid container spacing={2} className={styles.analyticsWrapper}>
        <Grid item xs={6} className={styles.statsGrid}>
          <Container>
            <div className={styles.statsContainerTop}>
              <h1>Services</h1>
              <IconButton size="large" aria-label="More" color="inherit">
                <MoreHorizIcon
                  sx={{
                    color: "#000000",
                    opacity: 0.2,
                  }}
                />
              </IconButton>
            </div>
            <div className={styles.cardContainer}>
              <div className={styles.serviceStatPending}>
                <h1>32</h1>
                <p>Pending</p>
              </div>
              <div className={styles.serviceStatProgress}>
                <h1>12</h1>
                <p>In Progress</p>
              </div>
              <div className={styles.serviceStatReady}>
                <h1>23</h1>
                <p>Ready</p>
              </div>
              <div className={styles.serviceStatDelivered}>
                <h1>29</h1>
                <p>Delivered</p>
              </div>
            </div>
          </Container>
        </Grid>
        <Grid item xs={6} className={styles.revenueGrid}>
          <Container className={styles.revenueWrapper}>
            <div className={styles.revContainerTop}>
              <div className={styles.revenueItem}>
                <h1>Est. Revenue</h1>
                <div className={styles.revenueStat}>
                  <h1>$5,230</h1>
                  <img src={up} alt="" />
                  <span className="text-success">12%</span>
                </div>
              </div>
              <div className={styles.revenueItem}>
                <h1>Total Revenue</h1>
                <div className={styles.revenueStat}>
                  <h1>$5,230</h1>
                  <img src={up} alt="" />
                  <span className="text-success">12%</span>
                </div>
              </div>
              <div className={styles.revenueItem}>
                <h1>Total Customer</h1>
                <div className={styles.revenueStat}>
                  <h1>43</h1>
                  <span className="text-danger">12%</span>

                  <img src={down} alt="" />
                </div>
              </div>
              <div className={styles.revenueItem}>
                <IconButton size="large" aria-label="More" color="inherit">
                  <MoreHorizIcon
                    sx={{
                      color: "#000000",
                      opacity: 0.2,
                    }}
                  />
                </IconButton>
              </div>
            </div>
          </Container>
        </Grid>
      </Grid>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={`Engaged Technician ${engagedTec.length}`}
                  {...a11yProps(0)}
                  className={styles.tab_label}
                />
                <Tab
                  label={`Engaged Technician ${freeTec.length}`}
                  {...a11yProps(1)}
                  className={styles.tab_label}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {engagedTec.map((ele) => (
                <div className={styles.task_wrapper}>
                  <div className={styles.service_img_wrapper}>
                    <div className={styles.technician_image}>
                      <img src={ele.img_url} alt="" className="img-fluid" />
                    </div>
                    <div className={styles.technician_details}>
                      <p className={styles.technician_name}>{ele.name}</p>
                      <p className={styles.technician_category_name}>
                        {ele.category_name}
                      </p>
                    </div>
                  </div>

                  <div className={styles.task_details}>
                    <p className={styles.task_no}>{ele.pending}</p>
                    <p className={styles.task_status_pending}>Pending</p>
                  </div>
                  <div className={styles.task_details}>
                    <p className={styles.task_no}>{ele.complete}</p>
                    <p className={styles.task_status_completed}>Completed</p>
                  </div>
                  <div className={styles.task_cost_details}>
                    <p className={styles.task_cost}>{ele.cost}</p>
                    <p className={styles.task_text}>Total Cost of Services</p>
                  </div>
                  <div className={styles.navigate}>
                    <IconButton size="large" aria-label="More" color="inherit">
                      <NavigateNextIcon
                        sx={{
                          color: "#000000",
                          opacity: 0.2,
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              ))}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {freeTec.map((ele) => (
                <div className={styles.task_wrapper}>
                  <div className={styles.service_img_wrapper}>
                    <div className={styles.technician_image}>
                      <img src={ele.img_url} alt="" className="img-fluid" />
                    </div>
                    <div className={styles.technician_details}>
                      <p className={styles.technician_name}>{ele.name}</p>
                      <p className={styles.technician_category_name}>
                        {ele.category_name}
                      </p>
                    </div>
                  </div>

                  <div className={styles.task_details}>
                    <p className={styles.task_no}>{ele.pending}</p>
                    <p className={styles.task_status_pending}>Pending</p>
                  </div>
                  <div className={styles.task_details}>
                    <p className={styles.task_no}>{ele.complete}</p>
                    <p className={styles.task_status_completed}>Completed</p>
                  </div>
                  <div className={styles.task_cost_details}>
                    <p className={styles.task_cost}>{ele.cost}</p>
                    <p className={styles.task_text}>Total Cost of Services</p>
                  </div>
                  <div className={styles.navigate}>
                    <IconButton size="large" aria-label="More" color="inherit">
                      <NavigateNextIcon
                        sx={{
                          color: "#000000",
                          opacity: 0.2,
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              ))}
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
}
