import { connect } from "react-redux";

import {
  createSupplier,
  reset,
  updateSupplier,
} from "../../../../pages/app/Configuration/services/configurationSlice";
import {
  CreateSupplierApiDTo,
  UpdateSupplierApiDTo,
} from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import SupplerForm from "../components/SuppliersForm/SuppliersForm";

interface StateProps {
  createSupplier_Loading: boolean;
  createSupplier_Error: boolean;
  createSupplier_Success: boolean;
  createSupplier_Details: null | {};
  updateSupplier_Loading: boolean;
  updateSupplier_Error: boolean;
  updateSupplier_Success: boolean;
  updateSupplier_Details: null | [];
}
interface DispatchProps {
  resetAll(): void;
  CreateSupplier(data: CreateSupplierApiDTo): void;
  UpdateSupplier(data: UpdateSupplierApiDTo): void;
}
const mapStateToProps = (state: RootState): StateProps => {
  return {
    createSupplier_Loading: state.configuration.createSupplier_Loading,
    createSupplier_Error: state.configuration.createSupplier_Error,
    createSupplier_Success: state.configuration.createSupplier_Success,
    createSupplier_Details: state.configuration.createSupplier_Details,
    updateSupplier_Loading: state.configuration.updateSupplier_Loading,
    updateSupplier_Error: state.configuration.updateSupplier_Error,
    updateSupplier_Success: state.configuration.updateSupplier_Success,
    updateSupplier_Details: state.configuration.updateSupplier_Details,
  };
};
const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    resetAll: () => {
      dispatch(reset());
    },
    CreateSupplier: (data) => {
      dispatch(createSupplier(data));
    },
    UpdateSupplier: (data) => {
      dispatch(updateSupplier(data));
    },
  };
};
export interface SupplerFormProps extends StateProps, DispatchProps {}
export const SupplerFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplerForm);
