import { IconButton } from "@mui/material";
import defaultImage from "../../../assets/images/DefaultImage.png";
import close_circle from "../../../assets/images/close-circle.png";
import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";
import { getUserDataFromLocalStorage } from "../../../utils/helper.utils";

interface ServicesProps {
  item: {
    image_path: string;
    name: string;
    price: number;
    durationInMinute: number;
    id: string;
  };
  handleRemoveService: (id: any) => void;
}
const userData = getUserDataFromLocalStorage();

export function Services(props: ServicesProps) {
  const { item, handleRemoveService } = props;

  return (
    <div className={styles.selected_services}>
      <div className={styles.left_img}>
        {/* <div className={styles.selected_services_card}>
          <img
            src={
              item.image_path
                ? `${
                    process.env.REACT_APP_API_ENDPOINT
                  }/${item.image_path?.replace("uploads/", "")}`
                : defaultImage
            }
            alt=""
            className="img-fluid"
            width="60px"
          />
        </div> */}
        <div className={styles.img_wrapper}>
          <img
            src={
              item.image_path
                ? `${
                    process.env.REACT_APP_API_ENDPOINT
                  }/${item.image_path?.replace("uploads/", "")}`
                : defaultImage
            }
            alt="pic"
            className={styles.img_wrapper_img}
            style={{
              width: "60px",
              height: "60px",
            }}
          />
        </div>
        <div className={styles.selected_services_card}>
          <p className={styles.service_name}>{item.name}</p>
          <p className={styles.service_cost}>
            Service Cost: {userData.currency_data.symbol}
            {item.price}
          </p>
          <p className={styles.etd_time}>ETD : {item.durationInMinute} Min</p>
        </div>
      </div>
      <IconButton
        className=""
        onClick={() => {
          handleRemoveService(item.id);
        }}
      >
        <img src={close_circle} alt="" className="img-fluid" width="24px" />
      </IconButton>
    </div>
  );
}
