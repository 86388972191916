import { Alert, Snackbar, SnackbarOrigin } from "@mui/material";

interface SnackBarProps {
  snackOpen: boolean;
  closeSnack: () => void;
  vertical: SnackbarOrigin["vertical"];
  horizontal: SnackbarOrigin["horizontal"];
  snackSeverity?: "success" | "info" | "warning" | "error";
  action: React.ReactNode;
  message: React.ReactNode;
}

export function SnackBar(props: SnackBarProps) {
  const {
    vertical,
    horizontal,
    snackOpen,
    closeSnack,
    snackSeverity,
    action,
    message,
  } = props;
  return (
    <Snackbar
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      open={snackOpen}
      onClose={closeSnack}
      key={vertical + horizontal}
      autoHideDuration={2000}
    >
      <Alert severity={snackSeverity} action={action}>
        {message}
      </Alert>
    </Snackbar>
  );
}
