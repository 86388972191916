import { Box, Modal } from "@mui/material";
import { Trash } from "iconsax-react";
import React from "react";
import info from "../../../assets/images/info_vartici.gif";
import { AllJobCards } from "../../../pages/app/Manage/Home/type";

import ButtonLog from "../../common/Button/Button";
import styles from "./VehicleList.module.css";
type Props = AllJobCards;
export function VehicleList({
  props,
  setJob,
  index,
  setIsActive,
  isActive,
  handleJobCardDelete,
  functions,
}: {
  props: Props;
  setJob: (id: any) => void;
  index: number;
  setIsActive: (id: any) => void;
  isActive: string;
  handleJobCardDelete?: (id: string) => void;
  functions: string[];
}) {
  const setJObData = (id: any) => {
    return setJob(id);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const [reOpen, setReOpen] = React.useState(false);

  const handleReClose = () => {
    setReOpen(false);
  };

  const handleReOpen = () => {
    setReOpen(true);
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
  };
  return (
    <>
      <div
        className={
          isActive !== props.id
            ? styles.vehicle_wrapper
            : styles.vehicle_wrapper_active
        }
        onClick={() => {
          setJObData(props.id);
          setIsActive(props.id);
        }}
      >
        <div className="row d-flex justify-content-between">
          <div className="col-8">
            <p className={styles.vehicle_name}>{props.vehicle.vehicle_name}</p>
            <p className={styles.vehicle_number}>
              {props.vehicle.vehicle_number}
            </p>
          </div>
          <div className="col-4">
            {["Delete"].some((str) => functions.includes(str)) ? (
              <>
                {props.status === "Pending" ? (
                  <div className={styles.delete_icon}>
                    <Trash
                      size="22"
                      color="#c51818"
                      variant="Outline"
                      className={styles.trashcardOutline}
                      onClick={handleOpen}
                    />
                  </div>
                ) : null}
                {/* {props.status === "Delivered" ? (
                  <div className={styles.delete_icon}>
                    <DirectSend
                      color="#0761E9"
                      variant="Outline"
                      className={styles.trashcardOutline}
                      onClick={handleReOpen}
                    />
                  </div>
                ) : null} */}
              </>
            ) : null}
          </div>
        </div>
        <div className="row d-flex justify-content-between">
          <div className="col-12">
            <p className={styles.vehicle_owner}>
              {props.customer.customer_name}
            </p>
            <p className={styles.vehicle_owner_n}>
              (m) {props.customer.phone_number}
            </p>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className={styles.iconInfo}>
            {/* <InfoCircle size="52" color="#F1948A" /> */}
            <img src={info} height={69} alt="" />
          </Box>
          <Box className={styles.modal_title}>
            <p> Are You Sure ? </p>
            <p> You want to delete this job card </p>
          </Box>
          <Box className={styles.btn_group}>
            <Box className={styles.btn_standard}>
              <ButtonLog
                onClick={handleClose}
                text={"Cancel"}
                variant={"contained"}
                className={styles.cancle_btn}
              />
            </Box>
            <Box>
              <ButtonLog
                text={"Delete"}
                variant={"contained"}
                className={styles.addVehicleBtn}
                onClick={() => {
                  if (
                    handleJobCardDelete &&
                    typeof handleJobCardDelete === "function" &&
                    props.status === "Pending"
                  ) {
                    handleJobCardDelete(props.id);
                  }
                  if (setOpen && typeof setOpen === "function") {
                    setOpen(false);
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={reOpen}
        onClose={handleReClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className={styles.iconInfo}>
            {/* <InfoCircle size="52" color="#F1948A" /> */}
            <img src={info} height={69} alt="" />
          </Box>
          <Box className={styles.modal_title}>
            <p> Are You Sure ? </p>
            <p> You want to reopen this job card </p>
          </Box>
          <Box className={styles.btn_group}>
            <Box className={styles.btn_standard}>
              <ButtonLog
                onClick={handleReClose}
                text={"No"}
                variant={"contained"}
                className={styles.cancle_btn}
              />
            </Box>
            <Box>
              <ButtonLog
                text={"Yes"}
                variant={"contained"}
                className={styles.addVehicleReOpenBtn}
                onClick={() => {}}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
