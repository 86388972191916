import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";
import { Step } from "../../../pages/app/Manage/VeichleRegistration/type";
import ButtonLog from "../../common/Button/Button";

interface BottomSectionProps {
  count: number;
  handleBack: () => void;
  handleNext: () => void;
  activeStep: number;
  disable: boolean;
  loading: boolean;
  steps: Step[];
}

export function BottomSection(props: BottomSectionProps) {
  const { count, handleBack, handleNext, activeStep, disable, loading, steps } =
    props;
  return (
    <div className={styles.bottom_section}>
      <div className={styles.nextPreviousButtons}>
        {count === 1 || count === 3 ? (
          <>
            <div className={styles.est_tech_assignMain}></div>
          </>
        ) : (
          <>
            <div></div>
          </>
        )}

        <div className="d-flex">
          {activeStep !== 1 && activeStep !== 0 && (
            <div>
              <ButtonLog
                text={"Back"}
                variant={"contained"}
                className={styles.backBtn}
                onClick={handleBack}
                disabled={activeStep === 0 || disable}
              />
            </div>
          )}
          <div>
            <ButtonLog
              text={
                loading ? "Creating..." : activeStep === 4 ? "Create" : "Next"
              }
              variant={"contained"}
              className={styles.saveBtn}
              onClick={handleNext}
              disabled={disable}
              // disabled={activeStep === steps?.length - 1}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
