import {
  FormControlLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Switch,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../utils/helper.utils";
import ButtonLog from "../../../../common/Button/Button";
import CustomLoader from "../../../../common/Loader/Loader";
import { SnackBar } from "../../../../common/SnackBar/SnackBar";
import { SnackStateProp } from "../../../ProductCategory/components/ProductCategoryForm/ProductCategoryForm";
import { UserFormProps } from "../../container/userContainer";
import styles from "../User.module.css";
interface RoleType {
  id: string;
  name: string;
  description: string;
  // slug: string;
  // is_active: boolean;
  // created_at: Date;
  // created_by: string;
  // modified_at: Date;
  // modified_by: null;
}

export default function UserForm(props: UserFormProps) {
  const {
    CreateUser,
    UpdateUser,
    createUser_Details,
    createUser_Error,
    createUser_Loading,
    createUser_Success,
    updateUser_Details,
    updateUser_Error,
    updateUser_Loading,
    updateUser_Success,
    resetAllCategory,
    GetAllRoleType,
    RoleTypeDetails,
    getAllRoleTypeError,
    getAllRoleTypeLoading,
    getAllRoleTypeSuccess,
  } = props;
  const navigate = useNavigate();
  const options = ["Supervisor", "Mechanic"];
  useEffect(() => {
    GetAllRoleType();
  }, [GetAllRoleType]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedOptions(event.target.value as string[]);
  };

  const [formType, setFormType] = React.useState<string | undefined>(
    "user-form/add"
  );
  let param = useParams();
  const { state } = useLocation();

  // let userDataParse: { id: string; company_id: number } = {
  //   id: "",
  //   company_id: 0,
  // };

  // const userData: any = localStorage.getItem("USER_DATA");
  const userDataParse = getUserDataFromLocalStorage();
  const [user, setUser] = React.useState({
    email: state ? state?.user.email : "",
    password: state ? state?.user?.password : "",
    user_type: state ? state?.user.user_type : "User",
    name: state ? state?.user.name : "",
    partner_id: userDataParse.partner_id,
    role: state ? state?.user.role : [],
    slug: "user",
  });
  const [checked, setChecked] = React.useState(
    state ? state?.user.is_active : true
  );

  const handleChange = (event: any) => {
    const updatedValue = event.target.checked;
    setChecked(updatedValue);
  };
  useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "error",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "regex",
      value: user.name,
      errorMsz: "Name is required and must be a maximum of 30 characters",
      regex: /^[a-zA-Z\s'-]{1,30}$/,
    },
    {
      checkwith: "regex",
      value: user.email,
      errorMsz: "Please enter valid email address",
      regex: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
    },
    {
      checkwith: "minmax",
      value: user.password,
      errorMsz:
        "Enter valid password with min 8 letter and maximum 16 characters",
      min: 8,
      max: 16,
    },
    {
      checkwith: "min",
      value: user.role,
      errorMsz: "Please select user role",
      min: 1,
    },
  ];
  const handleSave = () => {
    validate(ValidateData).status
      ? CreateUser({
          ...user,
          created_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };

  const handleEdit = () => {
    UpdateUser({
      ...user,
      is_active: checked ? true : false,
      user_id: state.user.id,
      updated_by: userDataParse.id,
    });
  };
  const [userType, setUserType] = React.useState("");
  // const handleChange = (event: SelectChangeEvent) => {
  //   setUserType(event.target.value as string);
  // };
  React.useEffect(() => {
    if (createUser_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createUser_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAllCategory();
    } else if (createUser_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createUser_Details || createUser_Error),
        snackOpen: true,
        snackSeverity: "error",
      }));
      resetAllCategory();
    }
  }, [
    createUser_Details,
    createUser_Error,
    createUser_Success,
    navigate,
    resetAllCategory,
  ]);

  React.useEffect(() => {
    if (updateUser_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateUser_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAllCategory();
    } else if (updateUser_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updateUser_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      resetAllCategory();
    }
  }, [
    navigate,
    resetAllCategory,
    updateUser_Details,
    updateUser_Error,
    updateUser_Success,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {createUser_Loading || updateUser_Loading || getAllRoleTypeLoading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>
            {formType === "user-form/add" ? "Add User" : "Edit User"}
          </p>
        </div>
        <div className="container mt-5" style={{ backgroundColor: "#F5F5F5;" }}>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Full Name
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Full Name"
                  type="text"
                  onChange={(e) => {
                    setUser((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  value={user?.name}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Email
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  placeholder="Enter Email"
                  className={styles.outline_inputbox}
                  type="email"
                  onChange={(e) => {
                    setUser((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                  value={user?.email}
                  disabled={formType === "user-form/edit"}
                />
              </FormControl>
              {formType === "user-form/add" && (
                <FormControl
                  className={styles.formControl}
                  size="small"
                  sx={{
                    width: "100%",
                  }}
                >
                  <span className={styles.label}>
                    Password
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </span>
                  <OutlinedInput
                    placeholder="Enter Password"
                    className={styles.outline_inputbox}
                    onChange={(e) => {
                      setUser((prevState) => ({
                        ...prevState,
                        password: e.target.value,
                      }));
                    }}
                    value={user?.password}
                    type="password"
                  />
                </FormControl>
              )}

              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Role
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={userType}
                  label="Role"
                  onChange={(e) => {
                    setUser((prevState) => ({
                      ...prevState,
                      user_type: e.target.value,
                    }));
                  }}
                  value={user?.user_type}
                  className={styles.outline_inputbox}
                >
                
                  <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                  <MenuItem value={"Mechanic"}>Mechanic</MenuItem>
                </Select> */}
                <Select
                  className={styles.outline_inputbox}
                  // multiple
                  value={user.role}
                  onChange={(e) => {
                    setUser((prevState) => ({
                      ...prevState,
                      role: [e.target.value],
                    }));
                  }}
                  disabled={formType === "user-form/edit"}
                  // renderValue={(selected) => (selected as string[]).join(", ")}
                >
                  {RoleTypeDetails?.map((option: RoleType) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formType === "user-form/edit" && (
                <>
                  <FormControl
                    className={styles.formControl}
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <span className={styles.label}>
                      User Status
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="Active"
                      // onChange={}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </div>

          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              variant={"contained"}
              className={styles.saveBtn}
              onClick={formType === "user-form/edit" ? handleEdit : handleSave}
            />
          </div>
        </div>
      </div>
    </>
  );
}
