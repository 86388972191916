import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { CreateExpenseLog, GetAllExpenseLog } from "./expensesLog.request";
import { ExpensesLogDT, ExpensesLogInit } from "./types";

export const initialState: ExpensesLogInit = {
  createExpensesLog_loading: false,
  createExpensesLog_error: false,
  createExpensesLog_success: false,
  createExpensesLog_details: null,
  getAllExpensesLog_loading: false,
  getAllExpensesLog_error: false,
  getAllExpensesLog_success: false,
  getAllExpensesLog_details: [],
};

export const createExpensesLog = createAsyncThunk(
  "user/createExpensesLog",
  (data: ExpensesLogDT) => {
    return CreateExpenseLog(data)
      .then((res) => {
        if (res.status === 201) {
          return res?.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error("Something went wrong");
      });
  }
);
export const getAllExpensesLog = createAsyncThunk(
  "user/getAllExpensesLog",
  (id: string) => {
    return GetAllExpenseLog(id)
      .then((res) => {
        if (res.status === 200) {
          return res?.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error("Something went wrong");
      });
  }
);
export const expensesLogSlice = createSlice({
  name: "expensesLog",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createExpensesLog.pending, (state) => {
      state.createExpensesLog_loading = true;
      state.createExpensesLog_success = false;
      state.createExpensesLog_error = false;
    });
    builder.addCase(
      createExpensesLog.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createExpensesLog_loading = false;
        state.createExpensesLog_success = true;
        state.createExpensesLog_error = false;
        state.createExpensesLog_details = action?.payload;
      }
    );
    builder.addCase(createExpensesLog.rejected, (state, action: AnyAction) => {
      state.createExpensesLog_loading = false;
      state.createExpensesLog_success = false;
      state.createExpensesLog_error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllExpensesLog.pending, (state) => {
      state.getAllExpensesLog_loading = true;
      state.getAllExpensesLog_success = false;
      state.getAllExpensesLog_error = false;
    });
    builder.addCase(
      getAllExpensesLog.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllExpensesLog_loading = false;
        state.getAllExpensesLog_success = true;
        state.getAllExpensesLog_error = false;
        state.getAllExpensesLog_details = action?.payload;
      }
    );
    builder.addCase(getAllExpensesLog.rejected, (state, action: AnyAction) => {
      state.getAllExpensesLog_loading = false;
      state.getAllExpensesLog_success = false;
      state.getAllExpensesLog_error =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default expensesLogSlice.reducer;

export const { reset } = expensesLogSlice.actions;
