import { Tooltip } from "@mui/material";
import { Call, Message, User } from "iconsax-react";
import { JobCardDTO } from "../../../pages/app/Manage/Home/type";
import styles from "./UserBox.module.css";

export function UserBox({ props }: { props: JobCardDTO | undefined }) {
  return (
    <div className={styles.customer_details_wrapper}>
      <div className={styles.list_item_bar}>
        <p className={styles.list_item_bar_cust}>
          {`Customer Name : ${props?.Name ?? "N/A"}`} <User size="18" />
        </p>
        <Tooltip arrow title="Call">
          <a
            href={`tel:${props?.PhoneNo}`}
            className={styles.list_item_bar_txt1}
          >
            {`Customer Mob : ${props?.PhoneNo ?? "N/A"}`} <Call size="18" />
          </a>
        </Tooltip>
        <Tooltip arrow title="Mail">
          <a
            href={`mailto:${props?.EmailAddress}`}
            className={styles.list_item_bar_txt1}
          >
            {`Customer Email : ${props?.EmailAddress ?? "N/A"}`}{" "}
            <Message size="18" />
          </a>
        </Tooltip>
        {/* <p className={styles.list_item_bar_txt1}>{`Invoice : GEN021523`}</p> */}
      </div>
    </div>
  );
}
