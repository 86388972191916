// import carwash from "../../../assets/images/CarWash.png";
import { JobCardDTO } from "../../../pages/app/Manage/Home/type";
import styles from "./ServiceCard.module.css";

export interface Props {
  // icon: any;
  // name: string;
  // onClick: any;
  // status?: string;\
  data: any;
  getJobCardDetailsData: JobCardDTO;
}

export function ServiceCard(props: Props) {
  const { data, getJobCardDetailsData } = props;
  const completedServices = getJobCardDetailsData?.service.filter(
    (service) => service.task_status === "Completed"
  );
  const completedComplaints = getJobCardDetailsData?.complaints.filter(
    (complaint: any) => complaint.task_status === "Completed"
  );
  const shouldShowStatus =
    data.name === "Services" || data.name === "Customer Complaints";
  return (
    <div
      className="col-lg-3 col-md-6 col-sm-6 col-xs-12 "
      onClick={data.onClick}
    >
      <div className={styles.services_wrapper}>
        <div className="d-flex justify-content-center">
          <div className={styles.img_wrapper}>{data.icon}</div>
        </div>
        <p className={styles.services_name}>{data.name}</p>
        {shouldShowStatus && (
          <p className={styles.services_status}>
            Completed{" "}
            {data.name === "Customer Complaints"
              ? completedComplaints.length
              : completedServices.length}
            /
            {data.name === "Customer Complaints"
              ? getJobCardDetailsData?.complaints.length
              : getJobCardDetailsData.service.length}
          </p>
        )}
      </div>
    </div>
  );
}
