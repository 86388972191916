import { filter } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TechnicianList } from "../../../../../../components/appSpecific/TechnicianList/TechnicianList";
import { RootState } from "../../../../../../redux/store";
import { MechanicDT } from "../../../../Configuration/types";
// import styles from "./TechAssign.module.css";
import { ComplaintsList } from "../../../../../../components/appSpecific/ComplaintsList/ComplaintsList";
import { updateComplaints } from "../ComplantJobCard/service/complaintsSplice";
import {
  AssignService,
  updateService,
} from "../ComplantJobCard/service/servicesSlice";
import styles from "../Registration.module.css";

export function TechAssign(props: any) {
  const { userDetails } = props;
  const items: AssignService[] = useSelector(
    (state: RootState) => state.services.services
  );

  const complaints = useSelector(
    (state: RootState) => state.complaints.complaints
  );

  const [filteredProfiles, setFilteredProfiles] = useState<MechanicDT[]>([]);
  const [ServiceWithTechnicians, setServiceWithTechnicians] =
    useState<AssignService[]>(items);
  React.useEffect(() => {
    setServiceWithTechnicians(items);
  }, [items]);
  const [ComplaintsWithTechnicians, setComplaintsWithTechnicians] =
    useState<any[]>(complaints);
  React.useEffect(() => {
    setComplaintsWithTechnicians(complaints);
  }, [complaints]);
  const [values, setValues] = useState<number[]>([]);
  const [values_complaints, setValues_complaints] = useState<number[]>([]);
  useEffect(() => {
    const filteredData = filter(userDetails, { user_type: "User" });
    setFilteredProfiles(filteredData);
  }, [userDetails]);
  const updatedUserProfiles = filteredProfiles.map((profile) => ({
    ...profile,
    label: `${profile.name}`,
  }));
  React.useEffect(() => {
    if (ServiceWithTechnicians) {
      const valuesTemp = ServiceWithTechnicians.map(
        (ele: any) => ele.assignedto ?? updatedUserProfiles[0]?.id
      );
      setValues(valuesTemp);
    }
  }, []);
  React.useEffect(() => {
    if (ComplaintsWithTechnicians) {
      const valuesTemp = ComplaintsWithTechnicians.map(
        (ele: any) => ele.assignedto ?? updatedUserProfiles[0]?.id
      );
      setValues_complaints(valuesTemp);
    }
  }, []);
  const dispatch = useDispatch();
  const handleChange = useCallback(
    (index: number, id: string) => {
      let tempItem = ServiceWithTechnicians.map((ele, ele_index) =>
        ele_index === index ? { ...ele, assignedto: id } : { ...ele }
      );
      dispatch(updateService(tempItem));
      setServiceWithTechnicians(tempItem);
      setValues((prevValues) => {
        const updatedValues: any = [...prevValues];
        updatedValues[index] = id;
        return updatedValues;
      });
    },
    [ServiceWithTechnicians, dispatch]
  );
  const handleChange_complaints = useCallback(
    (index: number, id: string) => {
      let tempItem = ComplaintsWithTechnicians.map((ele, ele_index) =>
        ele_index === index ? { ...ele, assignedto: id } : { ...ele }
      );
      dispatch(updateComplaints(tempItem));
      setComplaintsWithTechnicians(tempItem);
      setValues_complaints((prevValues) => {
        const updatedValues: any = [...prevValues];
        updatedValues[index] = id;
        return updatedValues;
      });
    },
    [ComplaintsWithTechnicians, dispatch]
  );

  return (
    <div className={styles.root_container}>
      <div className="container-fluid">
        <div className={styles.servicesCards}>
          <p className={styles.servicestxt}>Assign Technician</p>
          <div className="container-fluid">
            <div className="row mb-3">
              {filteredProfiles.length > 0 &&
                items.map((ele, index) => {
                  const key = Math.random() + index;
                  return (
                    <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                      <TechnicianList
                        key={key}
                        assignTech={updatedUserProfiles}
                        item={ele}
                        handleChange={handleChange}
                        index={index}
                        values={values}
                      />
                    </div>
                  );
                })}
              {filteredProfiles.length > 0 &&
                complaints.map((ele: any, index: number) => {
                  const key = Math.random() + index * Math.random();
                  return (
                    <ComplaintsList
                      key={key}
                      assignTech={updatedUserProfiles}
                      item={ele}
                      handleChange={handleChange_complaints}
                      index={index}
                      values={values_complaints}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
