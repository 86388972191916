import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import ArchiveJobCard from "../components/ArchiveJobCard";
import { getAllArchiveJobCard, reset } from "../service/archiveJobCardSlice";
import { Jobcard } from "../service/types";

interface StateProps {
  getAllArchiveJobCard_loading: boolean;
  getAllArchiveJobCard_error: boolean;
  getAllArchiveJobCard_success: boolean;
  getAllArchiveJobCard_details: Jobcard[] | null;
}

interface DispatchProps {
  GetAllArchiveJobCard(id: string): void;
  ResetAll(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getAllArchiveJobCard_success:
      state.archiveJobCard.getAllArchiveJobCard_success,
    getAllArchiveJobCard_error: state.archiveJobCard.getAllArchiveJobCard_error,
    getAllArchiveJobCard_loading:
      state.archiveJobCard.getAllArchiveJobCard_loading,
    getAllArchiveJobCard_details:
      state.archiveJobCard.getAllArchiveJobCard_details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllArchiveJobCard(id) {
      dispatch(getAllArchiveJobCard(id));
    },
    ResetAll() {
      dispatch(reset());
    },
  };
};

export interface ArchiveJobCardProps extends StateProps, DispatchProps {}
export const ArchiveJobCardComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchiveJobCard);
