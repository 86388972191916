import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../redux/store";
import { getUserDataFromLocalStorage } from "../../../../../utils/helper.utils";
import {
  getAllProduct,
  getAllService,
} from "../../../Configuration/services/configurationSlice";
import { ProductRes } from "../../../Mechanic/components/MechanicPendingtask/service/types";
import { resetComplaints } from "../components/ComplantJobCard/service/complaintsSplice";
import { resetService } from "../components/ComplantJobCard/service/servicesSlice";
import { resetEstimate } from "../components/Estimate/service/estimateSlice";
import Registration from "../components/Registration";
import { resetSpare } from "../components/Spares/services/sparesSlice";
import { SearchVehicleDT } from "../service/types";
import {
  createJobCard,
  // createJobCardComplaints,
  createJobCardFinal,
  // createJobCardServices,
  // createJobCardSpareParts,
  getAllActiveProduct,
  getAllActiveService,
  getAllActiveUser,
  reset,
  resetJobcardFinal,
  searchJobCard,
} from "../service/veichleRegistrationSlice";
import { JobCardCreateFinalDataDTO, JobCardDT, JobCardSearchDT } from "../type";

interface StateProps {
  getAllActiveProductSuccess: boolean;
  getAllActiveProductError: boolean;
  getAllActiveProductLoading: boolean;
  activeProductDetails: ProductRes[];
  activeServiceSuccess: boolean;
  activeServiceError: boolean;
  activeServiceLoading: boolean;
  activeServiceDetails: [];
  getAllActiveUser_Loading: boolean;
  getAllActiveUser_Error: boolean;
  getAllActiveUser_Success: boolean;
  getAllActiveUser_Details: null;

  // createJobCardComplaintsSuccess: boolean;
  // createJobCardComplaintsError: boolean;
  // createJobCardComplaintsLoading: boolean;
  // createJobCardComplaintsDetails: null;
  // createJobCardServicesSuccess: boolean;
  // createJobCardServicesError: boolean;
  // createJobCardServicesLoading: boolean;
  // createJobCardServicesDetails: null;
  // createJobCardSparePartsSuccess: boolean;
  // createJobCardSparePartsError: boolean;
  // createJobCardSparePartsLoading: boolean;
  // createJobCardSparePartsDetails: null;

  createJobCardSuccess: boolean;
  createJobCardError: boolean;
  createJobCardLoading: boolean;
  createJobCardDetails: any;
  createJobCardFinalSuccess: boolean;
  createJobCardFinalError: boolean;
  createJobCardFinalLoading: boolean;
  searchJobCardSuccess: boolean;
  searchJobCardError: boolean;
  searchJobCardLoading: boolean;
  searchJobCardDetails: SearchVehicleDT | null;
  services: any;
  complaints: any;
  spares: any;
  estimate: any;
  userID: number;
  jobCardDetailsID: any;
}

interface DispatchProps {
  GetAllProduct(id: number): void;
  GetAllService(id: number): void;
  // GetAllUser(): void;
  ResetVehicleRegistration(): void;
  GetAllActiveUsers(id: number): void;
  GetAllActiveProduct(id: number): void;
  GetAllActiveService(id: number): void;
  CreateJobCard(data: JobCardDT): void;
  SearchJobCard(data: JobCardSearchDT): void;
  // CreateJobCardComplaints(data: CreateComplaintType[]): void;
  // CreateJobCardServices(data: CreateServiceType[]): void;
  // CreateJobCardSpareParts(data: OrderType[]): void;
  CreateJobCardFinal(data: JobCardCreateFinalDataDTO): void;
  ResetJobcardFinal(): void;
  ResetJobcardEstimate(): void;
  ResetJobcardComplaints(): void;
  ResetJobcardServices(): void;
  ResetJobcardSpares(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    activeServiceSuccess: state.vehicleRegistration.activeServiceSuccess,
    activeServiceError: state.vehicleRegistration.activeServiceError,
    activeServiceLoading: state.vehicleRegistration.activeServiceLoading,
    activeServiceDetails: state.vehicleRegistration.activeServiceDetails,
    getAllActiveProductSuccess:
      state.vehicleRegistration.getAllActiveProductSuccess,
    getAllActiveProductError:
      state.vehicleRegistration.getAllActiveProductError,
    getAllActiveProductLoading:
      state.vehicleRegistration.getAllActiveProductLoading,
    activeProductDetails: state.vehicleRegistration.activeProductDetails,
    getAllActiveUser_Loading:
      state.vehicleRegistration.getAllActiveUser_Loading,
    getAllActiveUser_Error: state.vehicleRegistration.getAllActiveUser_Error,
    getAllActiveUser_Success:
      state.vehicleRegistration.getAllActiveUser_Success,
    getAllActiveUser_Details:
      state.vehicleRegistration.getAllActiveUser_Details,
    createJobCardLoading: state.vehicleRegistration.createJobCardLoading,
    createJobCardError: state.vehicleRegistration.createJobCardError,
    createJobCardSuccess: state.vehicleRegistration.createJobCardSuccess,
    createJobCardDetails: state.vehicleRegistration.createJobCardDetails,
    searchJobCardLoading: state.vehicleRegistration.searchJobCardLoading,
    searchJobCardError: state.vehicleRegistration.searchJobCardError,
    searchJobCardSuccess: state.vehicleRegistration.searchJobCardSuccess,
    searchJobCardDetails: state.vehicleRegistration.searchJobCardDetails,
    // createJobCardComplaintsLoading:
    //   state.vehicleRegistration.createJobCardComplaintsLoading,
    // createJobCardComplaintsError:
    //   state.vehicleRegistration.createJobCardComplaintsError,
    // createJobCardComplaintsSuccess:
    //   state.vehicleRegistration.createJobCardComplaintsSuccess,
    // createJobCardComplaintsDetails:
    //   state.vehicleRegistration.createJobCardComplaintsDetails,
    // createJobCardServicesLoading:
    //   state.vehicleRegistration.createJobCardServicesLoading,
    // createJobCardServicesError:
    //   state.vehicleRegistration.createJobCardServicesError,
    // createJobCardServicesSuccess:
    //   state.vehicleRegistration.createJobCardServicesSuccess,
    // createJobCardServicesDetails:
    //   state.vehicleRegistration.createJobCardServicesDetails,
    // createJobCardSparePartsLoading:
    //   state.vehicleRegistration.createJobCardSparePartsLoading,
    // createJobCardSparePartsError:
    //   state.vehicleRegistration.createJobCardSparePartsError,
    // createJobCardSparePartsSuccess:
    //   state.vehicleRegistration.createJobCardSparePartsSuccess,
    // createJobCardSparePartsDetails:
    //   state.vehicleRegistration.createJobCardSparePartsDetails,
    services: state.services.services,
    complaints: state.complaints.complaints,
    spares: state.spares.spares,
    estimate: state.estimate.estimate,
    userID: getUserDataFromLocalStorage().id,
    jobCardDetailsID:
      state.vehicleRegistration.createJobCardDetails &&
      state.vehicleRegistration.createJobCardDetails
        ? state.vehicleRegistration.createJobCardDetails
        : "",
    createJobCardFinalSuccess:
      state.vehicleRegistration.createJobCardFinalSuccess,
    createJobCardFinalError: state.vehicleRegistration.createJobCardFinalError,
    createJobCardFinalLoading:
      state.vehicleRegistration.createJobCardFinalLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllProduct: (id) => {
      dispatch(getAllProduct({ id }));
    },
    GetAllService: (id) => {
      dispatch(getAllService({ id }));
    },
    // GetAllUser: () => {
    //   dispatch(getAllUser());
    // },
    ResetVehicleRegistration: () => {
      dispatch(reset());
    },
    GetAllActiveUsers: (id) => {
      dispatch(getAllActiveUser(id));
    },
    GetAllActiveService: (id) => {
      dispatch(getAllActiveService(id));
    },
    GetAllActiveProduct: (id) => {
      dispatch(getAllActiveProduct(id));
    },
    CreateJobCard: (data) => {
      dispatch(createJobCard(data));
    },
    SearchJobCard: (data) => {
      dispatch(searchJobCard(data));
    },
    // CreateJobCardComplaints: (data) => {
    //   dispatch(createJobCardComplaints(data));
    // },
    // CreateJobCardServices: (data) => {
    //   dispatch(createJobCardServices(data));
    // },
    // CreateJobCardSpareParts: (data) => {
    //   dispatch(createJobCardSpareParts(data));
    // },
    CreateJobCardFinal: (data) => {
      dispatch(createJobCardFinal(data));
    },
    ResetJobcardFinal: () => {
      dispatch(resetJobcardFinal());
    },
    ResetJobcardEstimate: () => {
      dispatch(resetEstimate());
    },
    ResetJobcardComplaints: () => {
      dispatch(resetComplaints());
    },
    ResetJobcardServices: () => {
      dispatch(resetService());
    },
    ResetJobcardSpares: () => {
      dispatch(resetSpare());
    },
  };
};

export interface RegistrationProps extends StateProps, DispatchProps {}
export const RegistrationComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Registration);
