import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Archive from "../../../assets/images/Archive.svg";
import spareIcon from "../../../assets/images/box-add.svg";
import expenses from "../../../assets/images/expenses.svg";
import home from "../../../assets/images/home-sidebar.svg";
import jobcard from "../../../assets/images/jobcard.svg";
import mech from "../../../assets/images/mech.svg";
import team from "../../../assets/images/my-team.svg";
import payment from "../../../assets/images/payment.svg";
import reports from "../../../assets/images/reports.svg";
import hashtag from "../../../assets/images/setting-sidebar.svg";
import AppHeader from "../../../components/common/AppHeader/AppHeader";
import Footer from "../../../components/common/Footer/Footer";
import CustomLoader from "../../../components/common/Loader/Loader";
import { RootState, TypedDispatch } from "../../../redux/store";
import AppRouter from "../../../router/AppRouter";
import {
  getAuthDataFromLocalStorage,
  isFunctionAllowed,
} from "../../../utils/helper.utils";
import {
  getNotification,
  userCheckInOutByID,
} from "../Manage/Home/Service/homeSlice";
import styles from "./MainLayout.module.css";

const drawerWidth = 88;
interface Props {}

export default function MainLayout(props: Props) {
  let param = useParams();
  const navigate = useNavigate();
  const dispatch: TypedDispatch = useDispatch();

  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const [isActive, setIsActive] = useState(0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const userCheckIn_Success: any =
    useSelector((state: RootState) => state.home.userCheckIn_Success) ?? [];
  const userCheckOut_Success: any =
    useSelector((state: RootState) => state.home.userCheckOut_Success) ?? [];
  const userCheckIn_Loading: any =
    useSelector((state: RootState) => state.home.userCheckIn_Loading) ?? [];
  const userCheckOut_Loading: any =
    useSelector((state: RootState) => state.home.userCheckOut_Loading) ?? [];

  React.useEffect(() => {
    dispatch(
      userCheckInOutByID({
        id: JSON.parse(localStorage.getItem("USER_DATA") ?? '{"id":0}').id,
        date: moment().format(),
      })
    );
    dispatch(
      getNotification(
        JSON.parse(localStorage.getItem("USER_DATA") ?? '{"id":0}').id
      )
    );
  }, [dispatch, navigate, userCheckIn_Success, userCheckOut_Success]);
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const [user, setUser] = React.useState<any>();
  React.useEffect(() => {
    const userData: string | null = localStorage.getItem("USER_DATA");
    if (userData) {
      const userDataParse: any = JSON.parse(userData);
      setUser(userDataParse.user_type);
    }
  }, []);
  React.useEffect(() => {
    const value1: any = param["*"];
    if (
      value1 &&
      [
        "products",
        "configuration",
        "product-category",
        "services",
        "service-category",
        "product-management-form",
        "product-category-form",
        "services-management-form",
        "services-category-form",
        "subscription-plan",
        "users",
        "user-form",
        "suppliers",
        "supplier-form",
        "stock-inventory",
        "stock-inventory-form",
        "bays",
        "bay-form",
        "role",
        "partner",
        "currency",
      ].some((str) => value1.includes(str))
    ) {
      setIsActive(3);
    } else if (
      value1 &&
      ["job-card", "vehicle-registration", "invoice", "history"].some((str) =>
        value1.includes(str)
      )
    ) {
      setIsActive(1);
    } else if (
      value1 &&
      [
        "payment",
        "reports",
        "garage-report",
        "revenue-report",
        "task-report",
        "spare-part-report",
        "mechanics-performance-report",
        "job-duration-report",
      ].some((str) => value1.includes(str))
    ) {
      setIsActive(2);
    } else if (
      value1 &&
      ["spare-part-request", "my-teams"].some((str) => value1.includes(str))
    ) {
      setIsActive(4);
    } else if (
      value1 &&
      [
        "dashboard",
        "all-mechanics",
        "mechanic",
        "expenses-log",
        "expenses",
      ].some((str) => value1.includes(str))
    ) {
      setIsActive(5);
    } else if (
      value1 &&
      ["archive-jobcard"].some((str) => value1.includes(str))
    ) {
      setIsActive(6);
    } else {
      setIsActive(0);
    }
  }, [param]);

  const authData = getAuthDataFromLocalStorage();
  const { category, rolename } = authData ?? {};

  const handleDrawerToggle = React.useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);
  const drawer = (
    <AppBar elevation={0}>
      <Toolbar />
      <List
        style={{
          padding: "16px",
          height: "100vh",
          position: "fixed",
          borderRight: "1px solid #e1e1e1",
          background: "#fff",
          paddingTop: `${isMobile ? "10px" : "80px"}`,
        }}
      >
        {isFunctionAllowed(authData, "Job-Card", "Create") ||
        isFunctionAllowed(authData, "Dashboard") ||
        user === "Owner" ? (
          <>
            <Tooltip title="Home" arrow placement="right">
              <ListItem
                button
                onClick={() => {
                  setIsActive(0);
                }}
                disablePadding
                className={styles.navbarmenu}
              >
                <ListItemButton
                  className={
                    isActive !== 0
                      ? styles.navbar_list
                      : styles.navbar_list_active
                  }
                  onClick={() => navigate("/")}
                >
                  <ListItemIcon>
                    <img src={home} className="img-fluid" alt="" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </>
        ) : null}
        {isFunctionAllowed(authData, "Job-Card", "Create") ? (
          <>
            <Tooltip title="Job Card" arrow placement="right">
              <ListItem
                button
                onClick={() => {
                  setIsActive(1);
                }}
                disablePadding
                className={styles.navbarmenu}
              >
                <ListItemButton
                  className={
                    isActive !== 1
                      ? styles.navbar_list
                      : styles.navbar_list_active
                  }
                  onClick={() => navigate("/job-card")}
                >
                  <ListItemIcon>
                    <img src={jobcard} className="img-fluid" alt="" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </>
        ) : null}
        <>
          {isFunctionAllowed(authData, "Payment") ? (
            <>
              <Tooltip title="Payment" arrow placement="right">
                <ListItem
                  button
                  onClick={() => {
                    setIsActive(2);
                  }}
                  disablePadding
                  className={styles.navbarmenu}
                >
                  <ListItemButton
                    className={
                      isActive !== 2
                        ? styles.navbar_list
                        : styles.navbar_list_active
                    }
                    onClick={() => navigate("/payment")}
                  >
                    <ListItemIcon>
                      <img src={payment} className="img-fluid" alt="" />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </>
          ) : null}
          {isFunctionAllowed(authData, "Spare Parts Acceptance") ? (
            <>
              <Tooltip title="Spare Part Request" arrow placement="right">
                <ListItem
                  button
                  onClick={() => {
                    setIsActive(4);
                  }}
                  disablePadding
                  className={styles.navbarmenu}
                >
                  <ListItemButton
                    className={
                      isActive !== 4
                        ? styles.navbar_list
                        : styles.navbar_list_active
                    }
                    onClick={() => navigate("/spare-part-request")}
                  >
                    <ListItemIcon>
                      <img src={spareIcon} className="img-fluid" alt="" />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </>
          ) : null}
        </>

        {isFunctionAllowed(authData, "Masters", "Product") ||
        isFunctionAllowed(authData, "Masters", "User") ||
        isFunctionAllowed(authData, "Masters", "Partner") ||
        user === "Owner" ? (
          <>
            <Tooltip title="Configuration" arrow placement="right">
              <ListItem
                button
                onClick={() => {
                  setIsActive(3);
                }}
                disablePadding
                className={styles.navbarmenu}
              >
                <ListItemButton
                  className={
                    isActive !== 3
                      ? styles.navbar_list
                      : styles.navbar_list_active
                  }
                  onClick={() => navigate("/configuration")}
                >
                  <ListItemIcon>
                    <img src={hashtag} className="img-fluid" alt="" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </>
        ) : null}
        {isFunctionAllowed(authData, "Masters", "Product") ? (
          <>
            <Tooltip title="Teams" arrow placement="right">
              <ListItem
                button
                onClick={() => {
                  setIsActive(5);
                }}
                disablePadding
                className={styles.navbarmenu}
              >
                <ListItemButton
                  className={
                    isActive !== 5
                      ? styles.navbar_list
                      : styles.navbar_list_active
                  }
                  onClick={() => navigate("/all-mechanics")}
                >
                  <ListItemIcon>
                    <img src={mech} className="img-fluid" alt="" width={26} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </>
        ) : null}
        {isFunctionAllowed(authData, "Reports", "Spare Parts") ? (
          <>
            <Tooltip title="Reports" arrow placement="right">
              <ListItem
                button
                onClick={() => {
                  setIsActive(2);
                }}
                disablePadding
                className={styles.navbarmenu}
              >
                <ListItemButton
                  className={
                    isActive !== 2
                      ? styles.navbar_list
                      : styles.navbar_list_active
                  }
                  onClick={() => navigate("/reports")}
                >
                  <ListItemIcon>
                    <img src={reports} className="img-fluid" alt="" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </>
        ) : null}
        {isFunctionAllowed(authData, "Reports", "Spare Parts") ? (
          <>
            <Tooltip title="Teams" arrow placement="right">
              <ListItem
                button
                onClick={() => {
                  setIsActive(4);
                }}
                disablePadding
                className={styles.navbarmenu}
              >
                <ListItemButton
                  className={
                    isActive !== 4
                      ? styles.navbar_list
                      : styles.navbar_list_active
                  }
                  onClick={() => navigate("/my-teams")}
                >
                  <ListItemIcon>
                    <img src={team} className="img-fluid" alt="" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </>
        ) : null}
        {isFunctionAllowed(authData, "Reports", "Spare Parts") ? (
          <>
            <Tooltip title="Expenses/Revenue Log" arrow placement="right">
              <ListItem
                button
                onClick={() => {
                  setIsActive(5);
                }}
                disablePadding
                className={styles.navbarmenu}
              >
                <ListItemButton
                  className={
                    isActive !== 5
                      ? styles.navbar_list
                      : styles.navbar_list_active
                  }
                  onClick={() => navigate("/expenses-log")}
                >
                  <ListItemIcon>
                    <img
                      src={expenses}
                      className="img-fluid"
                      alt=""
                      width={26}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </>
        ) : null}
        {isFunctionAllowed(authData, "Masters", "Product") ? (
          <>
            <Tooltip title="Archive Job Card" arrow placement="right">
              <ListItem
                button
                onClick={() => {
                  setIsActive(6);
                }}
                disablePadding
                className={styles.navbarmenu}
              >
                <ListItemButton
                  className={
                    isActive !== 6
                      ? styles.navbar_list
                      : styles.navbar_list_active
                  }
                  onClick={() => navigate("/archive-jobcard")}
                >
                  <ListItemIcon>
                    <img src={Archive} className="img-fluid" alt="" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </>
        ) : null}
        {/* )} */}
      </List>
    </AppBar>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        style={
          rolename === "Mechanic"
            ? { position: "absolute", display: "none" }
            : {}
        }
        component="nav"
        className="sidebarnav"
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{ sx: { backgroundColor: "#1A3235", position: "unset" } }}
          variant={isMobile ? "temporary" : "permanent"}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            },
          }}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Box className={styles.mainlayout}>
        <AppHeader handleDrawerToggle={handleDrawerToggle} />
        {userCheckIn_Loading || userCheckOut_Loading ? (
          <CustomLoader />
        ) : (
          <AppRouter />
        )}

        <Box className={styles.footer_root}>
          <Footer />
        </Box>
      </Box>
      <Box className={styles.footer_root_main}></Box>
    </Box>
  );
}
