import { connect } from "react-redux";

import {
  createPartner,
  getAllCurrency,
  reset,
  updatePartner,
} from "../../../../pages/app/Configuration/services/configurationSlice";
import {
  CreatePartnerApiDTo,
  CurrencyRes,
  UpdatePartnerApiDTo,
} from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import PartnerForm from "../component/PartnerForm/PartnerForm";

interface StateProps {
  createPartner_Loading: boolean;
  createPartner_Error: boolean;
  createPartner_Success: boolean;
  createPartner_Details: null | {};
  updatePartner_Loading: boolean;
  updatePartner_Error: boolean;
  updatePartner_Success: boolean;
  updatePartner_Details: null | [];
  getAllCurrencySuccess: boolean;
  getAllCurrencyError: boolean;
  getAllCurrencyLoading: boolean;
  CurrencyDetails: CurrencyRes[] | null;
}
interface DispatchProps {
  resetAllCategory(): void;
  CreatePartner(data: CreatePartnerApiDTo): void;
  UpdatePartner(data: UpdatePartnerApiDTo): void;
  GetAllCurrency(id: string): void;
}
const mapStateToProps = (state: RootState): StateProps => {
  return {
    createPartner_Loading: state.configuration.createPartner_Loading,
    createPartner_Error: state.configuration.createPartner_Error,
    createPartner_Success: state.configuration.createPartner_Success,
    createPartner_Details: state.configuration.createPartner_Details,
    updatePartner_Loading: state.configuration.updatePartner_Loading,
    updatePartner_Error: state.configuration.updatePartner_Error,
    updatePartner_Success: state.configuration.updatePartner_Success,
    updatePartner_Details: state.configuration.updatePartner_Details,
    getAllCurrencySuccess: state.configuration.getAllCurrencySuccess,
    getAllCurrencyError: state.configuration.getAllCurrencyError,
    getAllCurrencyLoading: state.configuration.getAllCurrencyLoading,
    CurrencyDetails: state.configuration.CurrencyDetails,
  };
};
const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    resetAllCategory: () => {
      dispatch(reset());
    },
    CreatePartner: (data) => {
      dispatch(createPartner(data));
    },
    UpdatePartner: (data) => {
      dispatch(updatePartner(data));
    },
    GetAllCurrency(id) {
      dispatch(getAllCurrency(id));
    },
  };
};
export interface PartnerFormProps extends StateProps, DispatchProps {}
export const PartnerFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerForm);
