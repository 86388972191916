import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";
import { getUserDataFromLocalStorage } from "../../../utils/helper.utils";
export function EstimateCard(props: any) {
  const { item, index } = props;
  const userData = getUserDataFromLocalStorage();

  return (
    <div className={styles.estimate_services} key={index}>
      <div className={styles.estimate_service_left_img}>
        <div className="">
          <img src={item.icon} alt="" className="img-fluid" width="60px" />
        </div>
        <div className="">
          <p className={styles.estimate_services_name}>{item?.iteNname}</p>
          <p className={styles.estimate_services_qty}>
            {item?.duration
              ? "ETD (In Minutes)- " + item.duration
              : "Quantity - " + item.quantity}
          </p>
        </div>
      </div>
      {item?.cost ? (
        <>
          <div className="">
            <p className={styles.estimate_services_cost}>
              {userData.currency_data.symbol}{" "}
              {item?.cost ? Math.floor(item?.cost) : "N/A"}
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
}
