import Swal from "sweetalert2";

export let a = 0;
export function convertMinutes(minutes: any) {
  let days = Math.floor(minutes / 1440); // 1 day = 1440 minutes
  let hours = Math.floor((minutes % 1440) / 60); // 1 hour = 60 minutes
  let remainingMinutes = minutes % 60;

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""}${
      hours > 0 ? `, ${hours} hour${hours > 1 ? "s" : ""}` : ""
    }${
      remainingMinutes > 0
        ? `, ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`
        : ""
    }`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""}${
      remainingMinutes > 0
        ? `, ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`
        : ""
    }`;
  } else {
    return `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
  }
}

export function getTimeDiff(timestamp1: string, timestamp2: string) {
  const date1: Date = new Date(timestamp1);
  const date2: Date = new Date(timestamp2);

  const diffInMs: number = Math.abs(date2.getTime() - date1.getTime());
  const diffInMinutes: number = Math.floor(diffInMs / (1000 * 60));
  // const diffInHours: number = Math.floor(diffInMs / (1000 * 60 * 60));
  // const diffInDays: number = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  return convertMinutes(diffInMinutes);
}

export function getTime(timestamp: string) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  console.log(`${hours}:${minutes}:${seconds}`); // Output: "14:30:0"
}
export interface validationData {
  value: any;
  min?: number;
  max?: number;
  errorMsz: string;
  checkwith: "regex" | "min" | "emptyCheck" | "minmax" | "max";
  regex?: any;
}
function validateWithRegex(data: string | number, regexStr: string) {
  const regex = new RegExp(regexStr);
  return regex.test(String(data));
}
function validateWithMinLength(
  data: string | Array<any> | number,
  min: number
) {
  return Array.isArray(data)
    ? data.length >= min
      ? true
      : false
    : String(data).length >= min
    ? true
    : false;
}
function validateWithMinMaxLength(
  data: string | Array<any> | number,
  min: number,
  max: number
) {
  return Array.isArray(data)
    ? data.length >= min && data.length <= max
      ? true
      : false
    : String(data).length >= min && String(data).length <= max
    ? true
    : false;
}
function validateWithEmptyCheck(data: string | Array<any> | number) {
  if (Array.isArray(data)) {
    return data.length !== 0 ? true : false;
  } else if (typeof data === "string") {
    return data.length !== 0 ? true : false;
  } else {
    return String(data).length !== 0 ? true : false;
  }
}
export const validate = (data: validationData[]) => {
  let retData = { status: true, message: "" };
  for (let i = 0; i < data.length; i++) {
    const ele = data[i];
    if (ele.value) {
      if (ele.checkwith === "emptyCheck") {
        if (!validateWithEmptyCheck(ele.value)) {
          retData = { status: false, message: ele.errorMsz };
          return retData;
        }
      } else if (ele.checkwith === "min" && ele.min) {
        if (!validateWithMinLength(ele.value, ele.min)) {
          retData = { status: false, message: ele.errorMsz };
          return retData;
        }
      } else if (ele.checkwith === "minmax" && ele.min && ele.max) {
        if (!validateWithMinMaxLength(ele.value, ele.min, ele.max)) {
          retData = { status: false, message: ele.errorMsz };
          return retData;
        }
      } else if (ele.checkwith === "regex" && ele.regex) {
        if (!validateWithRegex(ele.value, ele.regex)) {
          retData = { status: false, message: ele.errorMsz };
          return retData;
        }
      }
    } else {
      retData = { status: false, message: ele.errorMsz };
      return retData;
    }
  }
  return retData;
};
export function getDistinctObjects(array: any[]) {
  const distinctObjects: any = [];

  array?.forEach((obj) => {
    const stringifiedObj = JSON.stringify(obj);

    if (
      !distinctObjects.some(
        (existingObj: any) => JSON.stringify(existingObj) === stringifiedObj
      )
    ) {
      distinctObjects.push(obj);
    }
  });

  return distinctObjects;
}
export const getUserDataFromLocalStorage = () => {
  try {
    const userData = localStorage.getItem("USER_DATA");
    if (userData) {
      return JSON.parse(userData);
    }
    return null;
  } catch (error) {
    console.error("Error parsing user data from localStorage:", error);
    return null;
  }
};
export const getAuthDataFromLocalStorage = () => {
  try {
    const userData = localStorage.getItem("AUTHORIZATION");
    if (userData) {
      return JSON.parse(userData);
    }
    return null;
  } catch (error) {
    console.error("Error parsing user data from localStorage:", error);
    return null;
  }
};
interface UserData {
  token: string;
}

function logout() {
  // Clear user data from localStorage or perform any other necessary cleanup
  localStorage.removeItem("TOKEN");
  localStorage.removeItem("USER_DATA");
  localStorage.removeItem("AUTHORIZATION");

  // Redirect to the login page or any other desired route
  window.location.reload();
  window.location.href = "/";
}

function tokenExpiredError() {
  Swal.fire({
    text: "Your session has expired. Please log in again.",
    icon: "info",
    confirmButtonColor: "#0761E9",
    confirmButtonText: "OK",
  }).then((result) => {
    if (result.isConfirmed) {
      logout();
    }
  });
  // Show a notification or display a message indicating that the session has expired
  // console.error("Your session has expired. Please log in again.");
}

export { logout, tokenExpiredError };
function millisToMinutesAndSeconds(millis: any) {
  var minutes = Math.floor(millis / 60000);
  var seconds: any = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

export { millisToMinutesAndSeconds };
interface Category {
  name: string;
  functions: string[];
}

interface Role {
  category: Category[];
}

function isFunctionAllowed(
  authData: Role,
  categoryName?: string,
  functionName?: string
): boolean {
  const { category } = authData ?? {};

  const matchedCategory = category?.find(
    (category: Category) => category.name === categoryName
  );

  if (!functionName) {
    if (matchedCategory) {
      return true;
    } else {
      return false;
    }
  }

  if (matchedCategory && matchedCategory.functions.includes(functionName)) {
    return true;
  }

  const functions = category?.flatMap(
    (category: Category) => category.functions
  );
  const isFunctionAllowed = functions?.includes(functionName);

  return isFunctionAllowed;
}

export { isFunctionAllowed };
