import { axios } from "../../../../api/setup.intersepter";
import { CreateFeedback } from "./type";

export const GetFeedback = (id: string) => {
  return axios({
    method: "GET",
    url: `/customer-feedback/${id}`,
  });
};
export const FeedbackPost = (data: CreateFeedback) => {
  return axios({
    method: "POST",
    url: `/customer-feedback`,
    data: data,
  });
};
