import React from "react";
import defaultImage from "../../../assets/images/DefaultImage.png";
import styles from "./SelectedServices.module.css";
interface ServiceCardProps {
  serviceName: string;
  serviceCost: string;
  etdTime: string;
  image: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  serviceName,
  serviceCost,
  etdTime,
  image,
}) => {
  return (
    <div className="col-lg-4 mb-3">
      <div className={styles.selected_services}>
        <div className={styles.left_img}>
          {/* <div className="mx-3">
            <img
              src={
                image
                  ? `${process.env.REACT_APP_API_ENDPOINT}/${image.replace(
                      "uploads/",
                      ""
                    )}`
                  : defaultImage
              }
              alt=""
              className="img-fluid"
              width="60px"
            />
          </div> */}
          <div className={styles.img_wrapper}>
            <img
              src={
                image
                  ? `${process.env.REACT_APP_API_ENDPOINT}/${image?.replace(
                      "uploads/",
                      ""
                    )}`
                  : defaultImage
              }
              alt="pic"
              className={styles.img_wrapper_img}
            />
          </div>
          <div className="mx-3">
            <p className={styles.service_name}>{serviceName}</p>
            <p className={styles.service_cost}>Service Cost: {serviceCost}</p>
            <p className={styles.etd_time}>ETD : {etdTime}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
