import { useEffect } from "react";
import Bays from "../../../../../components/appSpecific/Bays/components/Bays";
import { getUserDataFromLocalStorage } from "../../../../../utils/helper.utils";
import { ConfigurationProps } from "../../container/configuartionContainer";

export default function BayPage(props: ConfigurationProps) {
  const {
    BayDetails,
    GetAllBay,
    getAllBayError,
    getAllBayLoading,
    getAllBaySuccess,
  } = props;
  const userData: any = getUserDataFromLocalStorage();

  useEffect(() => {
    GetAllBay(userData?.partner_id);
  }, [GetAllBay, userData?.partner_id]);
  return (
    <>
      <Bays BayDetails={BayDetails} />
    </>
  );
}
