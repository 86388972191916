import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import {
  DownloadInvoice,
  // GetCustomerDetails,
  JobcardInvoice,
} from "./invoice.request";
import { InvoiceIntialStateDTO } from "./types";

export const initialState: InvoiceIntialStateDTO = {
  downloadInvoice_Loading: false,
  downloadInvoice_Error: false,
  downloadInvoice_Success: false,
  downloadInvoice_Details: [],

  getCustomerDetails_Loading: false,
  getCustomerDetails_Error: false,
  getCustomerDetails_Success: false,
  getCustomerDetails: [],

  getJobCradInvoice_loading: false,
  getJobCradInvoice_Error: false,
  getJobCradInvoice_Success: false,
  getJobCradInvoice_Details: null,
};

export const downloadInvoice = createAsyncThunk(
  "downloadInvoice",
  (data: any) => {
    return DownloadInvoice(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
// export const getCustomerDetails = createAsyncThunk(
//   "get/customerDetails",
//   (data: any) => {
//     return GetCustomerDetails(data)
//       .then((res) => {
//         if (res.status === 200) {
//           return res?.data[0];
//         } else {
//           throw new Error("Something went wrong");
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//         throw new Error("Something went wrong");
//       });
//   }
// );

export const getJobcardInvoice = createAsyncThunk(
  "JobcardInvoice",
  (data: any) => {
    return JobcardInvoice(data)
      .then((res) => {
        if (res.status === 200) {
          return res?.data?.jobcard[0];
        } else {
          throw new Error("Somthing went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Somthing went wrong");
      });
  }
);

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    reset: () => initialState,

    setDownloadInvoice: (state, action: PayloadAction<any>) => {
      return { ...state, downloadInvoice_Details: action.payload };
    },
    setCustomerDetails: (state, action: PayloadAction<any>) => {
      return { ...state, getCustomerDetails: action.payload };
    },

    setJobCardInvoice: (state, action: PayloadAction<any>) => {
      return { ...state, getJobCradInvoice_Details: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadInvoice.pending, (state) => {
      state.downloadInvoice_Loading = true;
      state.downloadInvoice_Success = false;
      state.downloadInvoice_Error = false;
    });

    builder.addCase(
      downloadInvoice.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.downloadInvoice_Loading = false;
        state.downloadInvoice_Success = true;
        state.downloadInvoice_Error = false;
        state.downloadInvoice_Details = action?.payload;
      }
    );
    builder.addCase(downloadInvoice.rejected, (state, action: AnyAction) => {
      state.downloadInvoice_Loading = false;
      state.downloadInvoice_Error = false;
      state.downloadInvoice_Success = true;
      state.downloadInvoice_Error =
        action?.error?.message || "Something went wrong";
    });
    // builder.addCase(getCustomerDetails.pending, (state) => {
    //   state.getCustomerDetails_Loading = true;
    //   state.getCustomerDetails_Success = false;
    //   state.getCustomerDetails_Error = false;
    // });

    // builder.addCase(
    //   getCustomerDetails.fulfilled,
    //   (state, action: PayloadAction<any>) => {
    //     state.getCustomerDetails_Loading = false;
    //     state.getCustomerDetails_Success = true;
    //     state.getCustomerDetails_Error = false;
    //     state.getCustomerDetails = action?.payload;
    //   }
    // );
    // builder.addCase(getCustomerDetails.rejected, (state, action: AnyAction) => {
    //   state.getCustomerDetails_Loading = false;
    //   state.getCustomerDetails_Error = false;
    //   state.getCustomerDetails_Success = true;
    //   state.getCustomerDetails_Error =
    //     action?.error?.message || "Something went wrong";
    // });

    // job card status

    builder.addCase(getJobcardInvoice.pending, (state) => {
      state.getJobCradInvoice_loading = true;
      state.getJobCradInvoice_Success = false;
      state.getJobCradInvoice_Error = false;
    });

    builder.addCase(
      getJobcardInvoice.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getJobCradInvoice_loading = false;
        state.getJobCradInvoice_Success = true;
        state.getJobCradInvoice_Error = false;
        state.getJobCradInvoice_Details = action?.payload;
      }
    );

    builder.addCase(getJobcardInvoice.rejected, (state, action: AnyAction) => {
      state.getJobCradInvoice_loading = false;
      state.getJobCradInvoice_Error = false;
      state.getJobCradInvoice_Success = true;
      state.getJobCradInvoice_Error =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default invoiceSlice.reducer;
export const {
  reset,
  setDownloadInvoice,
  setCustomerDetails,
  setJobCardInvoice,
} = invoiceSlice.actions;
