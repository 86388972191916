import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import PaymentPage from "../components/PaymentPage";
import {
  getAllPaymentRequest,
  reset,
  updatePaymentRequest,
} from "../service/paymentSlice";
import {
  PaymentRequestState,
  PaymentRes,
  UpdatePaymentDT,
} from "../service/types";

interface StateProps extends PaymentRequestState {
  getAllPaymentRequest_Loading: boolean;
  getAllPaymentRequest_Error: boolean;
  getAllPaymentRequest_Success: boolean;
  getAllPaymentRequest_details: PaymentRes[];
  updatePaymentRequest_Loading: boolean;
  updatePaymentRequest_Error: boolean;
  updatePaymentRequest_Success: boolean;
  updatePaymentRequest_details: null;
}

interface DispatchProps {
  GetAllPaymentRequest(id: string): void;
  UpdatePaymentRequest(body: UpdatePaymentDT): void;
  ResetAll(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getAllPaymentRequest_Success:
      state.paymentRequest.getAllPaymentRequest_Success,
    getAllPaymentRequest_Error: state.paymentRequest.getAllPaymentRequest_Error,
    getAllPaymentRequest_Loading:
      state.paymentRequest.getAllPaymentRequest_Loading,
    getAllPaymentRequest_details:
      state.paymentRequest.getAllPaymentRequest_details,
    updatePaymentRequest_Success:
      state.paymentRequest.updatePaymentRequest_Success,
    updatePaymentRequest_Error: state.paymentRequest.updatePaymentRequest_Error,
    updatePaymentRequest_Loading:
      state.paymentRequest.updatePaymentRequest_Loading,
    updatePaymentRequest_details:
      state.paymentRequest.updatePaymentRequest_details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllPaymentRequest(id) {
      dispatch(getAllPaymentRequest(id));
    },
    UpdatePaymentRequest(body) {
      dispatch(updatePaymentRequest(body));
    },
    ResetAll() {
      dispatch(reset());
    },
  };
};

export interface PaymentPageProps extends StateProps, DispatchProps {}
export const PaymentPageComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentPage);
