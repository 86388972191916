import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../../redux/store";
import { getTaskReport } from "../../../services/reportsSlice";
import { ReportDT, TaskReportRes } from "../../../services/types";
import TaskReport from "../components/TaskReport";

interface StateProps {
  getTaskReportError: boolean;
  getTaskReportLoading: boolean;
  getTaskReportSuccess: boolean;
  getTaskReportDetails: TaskReportRes[];
}

interface DispatchProps {
  GetTaskReport(body: ReportDT): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getTaskReportDetails: state.reports.getTaskReportDetails,
    getTaskReportError: state.reports.getTaskReportError,
    getTaskReportLoading: state.reports.getTaskReportLoading,
    getTaskReportSuccess: state.reports.getTaskReportSuccess,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetTaskReport(body) {
      dispatch(getTaskReport(body));
    },
  };
};

export interface TaskReportProps extends StateProps, DispatchProps {}
export const TaskReportComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskReport);
