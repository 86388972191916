import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../../redux/store";
import { getSparePartReport } from "../../../services/reportsSlice";
import { ReportDT, SparePartReportRes } from "../../../services/types";
import SparePartsReport from "../components/SparePartsReport";

interface StateProps {
  getSparePartReportError: boolean;
  getSparePartReportLoading: boolean;
  getSparePartReportSuccess: boolean;
  getSparePartReportDetails: SparePartReportRes[];
}

interface DispatchProps {
  GetSparePartReport(body: ReportDT): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getSparePartReportDetails: state.reports.getSparePartReportDetails,
    getSparePartReportError: state.reports.getSparePartReportError,
    getSparePartReportLoading: state.reports.getSparePartReportLoading,
    getSparePartReportSuccess: state.reports.getSparePartReportSuccess,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetSparePartReport(body) {
      dispatch(getSparePartReport(body));
    },
  };
};

export interface SparePartsReportProps extends StateProps, DispatchProps {}
export const SparePartsReportComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SparePartsReport);
