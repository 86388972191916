import { useEffect } from "react";
import RoleCreation from "../../../../../components/appSpecific/RoleCreation/components/RoleCreation";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { getUserDataFromLocalStorage } from "../../../../../utils/helper.utils";
import { ConfigurationProps } from "../../container/configuartionContainer";

function RoleCreationPage(props: ConfigurationProps) {
  const {
    GetAllRole,
    getAllRoleError,
    getAllRoleLoading,
    getAllRoleSuccess,
    RoleDetails,
  } = props;
  const userData: any = getUserDataFromLocalStorage();
  useEffect(() => {
    GetAllRole();
  }, [GetAllRole]);
  return (
    <>
      {getAllRoleLoading ? (
        <CustomLoader />
      ) : (
        <RoleCreation RoleDetails={RoleDetails} />
      )}
    </>
  );
}

export default RoleCreationPage;
