import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, IconButton, SnackbarOrigin } from "@mui/material";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import loginImg from "../../../../assets/images/Asset131.png";
import invincix from "../../../../assets/images/Invincix_TM.png";
import logo from "../../../../assets/images/logo11.png";
import ButtonLog from "../../../../components/common/Button/Button";
import FormTextInput from "../../../../components/common/FormTextInput/FormTextInput";
import { ErrorType } from "../../../../components/common/FormTextInput/types";
import { SnackBar } from "../../../../components/common/SnackBar/SnackBar";
import styles from "../../Login/components/Login.module.css";
import { ForgotPasswordProps } from "../container/forgotPasswordConatiner";

interface Credential {
  email: string;
  otp: string;
  password: string;
  confirmPassword: string;
}
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export default function ForgotPassword(props: ForgotPasswordProps) {
  const {
    ForgotPassword,
    forgotPasswordDetails,
    forgotPasswordError,
    forgotPasswordLoading,
    forgotPasswordSuccess,
    ResetPassword,
    resetPasswordDetails,
    resetPasswordError,
    resetPasswordLoading,
    resetPasswordSuccess,
  } = props;
  const navigate = useNavigate();

  const [credential, setCredential] = React.useState<Credential>({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [validStatePassword, setValidStatePassword] =
    React.useState<boolean>(true);
  const [validStateConfirmPassword, setValidStateConfirmPassword] =
    React.useState<boolean>(true);
  const [validStateOtp, setValidStateOtp] = React.useState<boolean>(true);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  // const [isEmailSend, setIsEmailSend] = React.useState<boolean>(true);
  const [screen, setScreen] = React.useState(1);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateEmail(true);
        setValidStatePassword(true);
        setValidStateConfirmPassword(true);
      }
      setErrorType("client");
      setCredential({ ...credential, [event.target.id]: event.target.value });
    },
    [credential, errorType]
  );
  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);
  const setValidateStatusOtp = React.useCallback((val: boolean) => {
    setValidStateOtp(val);
  }, []);
  const setValidateStatusPassword = React.useCallback((val: boolean) => {
    setValidStatePassword(val);
  }, []);
  const setValidateStatusConfirmPassword = React.useCallback((val: boolean) => {
    setValidStateConfirmPassword(val);
  }, []);
  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      try {
        if (credential.password !== credential.confirmPassword) {
          setValidStatePassword(false);
          setValidStateConfirmPassword(false);
          return;
        }
        validStateOtp && credential.otp && setScreen(3);
        if (credential.password !== "" && credential.confirmPassword !== "") {
          ResetPassword({
            code: credential.otp,
            email: credential.email,
            newPassword: credential.password,
          });
        }
        if (!credential.email) {
          setValidStateEmail(false);
          return;
        }

        if (validStateEmail && screen === 1) {
          ForgotPassword({ email: credential.email });
        }
      } catch (error) {}
    },
    [
      ForgotPassword,
      ResetPassword,
      credential.confirmPassword,
      credential.email,
      credential.otp,
      credential.password,
      screen,
      validStateEmail,
      validStateOtp,
    ]
  );
  React.useEffect(() => {
    if (forgotPasswordError) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(forgotPasswordError),
        snackOpen: true,
        snackSeverity: "error",
      }));
    }
    if (forgotPasswordSuccess) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(forgotPasswordDetails),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setScreen(2);
    }
    if (resetPasswordError) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(resetPasswordError),
        snackOpen: true,
        snackSeverity: "error",
      }));
    }
    if (resetPasswordSuccess) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(resetPasswordDetails),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  }, [
    forgotPasswordDetails,
    forgotPasswordError,
    forgotPasswordSuccess,
    navigate,
    resetPasswordDetails,
    resetPasswordError,
    resetPasswordSuccess,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div className="container-fluid">
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div
        className="row"
        // style={{
        //   display: "flex",
        //   flexDirection: "row",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
      >
        <div className="col-lg-7 col-md-6 col-sm-12 d-sm-none d-md-block">
          <div className={styles.LoginImage}>
            <img
              src={loginImg}
              alt=""
              // style={{
              //   position: "absolute",
              //   width: "491px",
              //   height: "468px",
              // }}
              // className="img-fluid"
            />
          </div>
          <div
            className={styles.PoweredBy}
            style={{
              position: "absolute",
              bottom: "0",
            }}
          >
            <p>© 2023 INVINCIX Solutions Pvt. Ltd.</p>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 col-sm-12">
          <div className={styles.login_formMain}>
            {screen === 1 && (
              <>
                <div className={styles.login_form}>
                  <div>
                    <p>
                      <img
                        src={logo}
                        width="150"
                        alt=""
                        className="img-fluid"
                      />
                      {"  "}
                      {/* <span className={styles.company_name1}>Vertici </span>Auto */}
                    </p>
                  </div>
                  <div>
                    <p className={styles.login_header}>FORGOT PASSWORD</p>
                    <p className={styles.login_paragraph}>
                      Please provide your email-id !
                    </p>
                  </div>
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="row flex-column justify-content-center text-center">
                      <div className="col-12">
                        <FormTextInput
                          validate={true}
                          setValidStatus={setValidateStatusEmail}
                          // label="Email"
                          // icon={user}
                          placeholder={"Email"}
                          type={"email"}
                          id={"email"}
                          error={!validStateEmail}
                          value={credential.email}
                          onChange={handleChange}
                          showErrorMessage={showErrMsz}
                          errorType={errorType}
                          // className={styles.input}
                        />
                      </div>

                      <div className="col-12">
                        <div className={styles.linkWrapper}>
                          <Link to="/" className={styles.link}>
                            Back to login
                          </Link>
                        </div>
                        <div>
                          <ButtonLog
                            text={
                              forgotPasswordLoading ? (
                                <CircularProgress size={25} color={"inherit"} />
                              ) : (
                                "Submit"
                              )
                            }
                            // onClick={handleSubmit}
                            variant="contained"
                            type="submit"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
            {screen === 2 && (
              <>
                <div className={styles.login_form}>
                  <div>
                    <p>
                      <img
                        src={logo}
                        width="150"
                        alt=""
                        className="img-fluid"
                      />
                      {"  "}
                      {/* <span className={styles.company_name1}>Vertici </span>Auto */}
                    </p>
                  </div>
                  <div>
                    <p className={styles.login_header}>VERIFY OTP</p>
                    <p className={styles.login_paragraph}>
                      Please provide your otp !
                    </p>
                  </div>
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="row flex-column justify-content-center text-center">
                      <div className="col-12">
                        <FormTextInput
                          validate={true}
                          setValidStatus={setValidateStatusOtp}
                          // label="Email"
                          // icon={user}
                          placeholder={"Enter Otp"}
                          type={"otp"}
                          id={"otp"}
                          error={!validStateOtp}
                          value={credential.otp}
                          onChange={handleChange}
                          showErrorMessage={showErrMsz}
                          errorType={errorType}
                          // className={styles.input}
                        />
                      </div>

                      <div className="col-12">
                        {/* <div className={styles.linkWrapper}>
                          <span className={styles.otplink}>
                            {otpCounter} sec
                          </span>
                          <span
                            className={`${
                              resendDisabled ? styles.disabledLink : styles.link
                            }`}
                            onClick={handleResendOtp}
                            // disabled={resendDisabled}
                          >
                            Resend OTP
                          </span>
                        </div> */}
                        <div>
                          <ButtonLog
                            text={"Submit"}
                            // onClick={handleSubmit}
                            variant="contained"
                            type="submit"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
            {screen === 3 && (
              <>
                <div className={styles.login_form}>
                  <div>
                    <p>
                      <img
                        src={logo}
                        width="150"
                        alt=""
                        className="img-fluid"
                      />
                      {"  "}
                      {/* <span className={styles.company_name1}>Vertici </span>Auto */}
                    </p>
                  </div>
                  <div>
                    <p className={styles.login_header}>CHANGE PASSWORD</p>
                    <p className={styles.login_paragraph}>
                      Please provide your new password !
                    </p>
                  </div>
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="row flex-column justify-content-center text-center">
                      <div className="col-12">
                        <FormTextInput
                          validate={false}
                          setValidStatus={setValidateStatusPassword}
                          // label="Email"
                          // icon={user}
                          placeholder={"Enter New Password"}
                          type={"password"}
                          id={"password"}
                          error={validStatePassword}
                          value={credential.password}
                          onChange={handleChange}
                          showErrorMessage={showErrMsz}
                          errorType={errorType}
                          // className={styles.input}
                        />
                      </div>
                      <div className="col-12">
                        <FormTextInput
                          validate={true}
                          setValidStatus={setValidateStatusConfirmPassword}
                          // label="Email"
                          // icon={user}
                          placeholder={"Enter Confirm Password"}
                          type={"password"}
                          id={"confirmPassword"}
                          error={!validStateConfirmPassword}
                          value={credential.confirmPassword}
                          onChange={handleChange}
                          showErrorMessage={showErrMsz}
                          errorType={errorType}
                          // className={styles.input}
                        />
                      </div>
                      <div className="col-12">
                        <div className={styles.linkWrapper}>
                          <p
                            onClick={() => setScreen(1)}
                            className={styles.link}
                          >
                            Back
                          </p>
                        </div>
                        <div>
                          <ButtonLog
                            // onClick={handleSubmit}
                            variant="contained"
                            type="submit"
                            text={
                              resetPasswordLoading ? (
                                <CircularProgress size={25} color={"inherit"} />
                              ) : (
                                "Submit"
                              )
                            }
                            disabled={resetPasswordLoading}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
            <div
              className={styles.PoweredByFrom}
              style={{
                position: "absolute",
                bottom: "0",
              }}
            >
              <p> Designed and developed by</p>
              <div>
                <img src={invincix} width="100" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
