import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Box, Button, Modal } from "@mui/material";
import { Edit } from "iconsax-react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../../assets/images/DefaultImage.png";
import info from "../../../../assets/images/info_vartici.gif";
import ButtonLog from "../../../common/Button/Button";
import CustomTable from "../../../common/Table/CustomTable";
import { StockInventoryDT } from "../services/types";
import styles from "./StockInventory.module.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};
interface StockInventoryProps {
  StockDetails: StockInventoryDT[];
  state: any;
  OutOfStock(id: string): void;
}

export default function StockInventory(props: StockInventoryProps) {
  const { StockDetails, state, OutOfStock } = props;
  // const userData: any = getUserDataFromLocalStorage();
  // const { state } = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const navigation = useNavigate();
  const columns = [
    { id: "receipt_date", label: "Receipt Date", minWidth: 170 },
    { id: "receipt_number", label: "Receipt Number", minWidth: 100 },
    { id: "quantity", label: "Quantity", minWidth: 100 },
    { id: "unit_price", label: "Unit Price", minWidth: 100 },
    { id: "po_reference_number", label: "PO Reference Number", minWidth: 100 },
    { id: "po_date", label: "PO Date", minWidth: 100 },
    { id: "supplier_name", label: "Supplier", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [stockId, setStockID] = useState("");
  useEffect(() => {
    const sum = StockDetails?.reduce(
      (total: any, order: any) => total + order.quantity,
      0
    );
    setTotalQuantity(sum);
  }, [StockDetails]);
  const handleEditClick = (stock: StockInventoryDT[]) => {
    navigation("/stock-inventory-form/edit", {
      state: { stock },
    });
  };
  const handleProductEditClick = (product: any) => {
    navigation("/product-management-form/edit", {
      state: { product },
    });
  };
  const handleStockOutModal = useCallback((stock: StockInventoryDT) => {
    handleOpen();
    setStockID(stock.id);
  }, []);
  const handleStockOut = useCallback(() => {
    OutOfStock(stockId);
  }, [OutOfStock, stockId]);
  const updatedRows = StockDetails?.map((row: StockInventoryDT) => ({
    ...row,

    action: (
      <Button
        variant="text"
        color="secondary"
        onClick={() => handleStockOutModal(row)}
        size="small"
      >
        StockOut
      </Button>
    ),
  }));
  return (
    <>
      <div className={styles.InventoryMainContent}>
        <div className={styles.inventoryNavBar}>
          <ButtonLog
            text={"Back"}
            variant={"contained"}
            onClick={() => navigation("/products")}
            className={styles.backBtn}
          />
          <div className={styles.inventorySearch} style={{ display: "flex" }}>
            {/* <TextField
              fullWidth
              placeholder="Search Service"
              InputProps={{
                startAdornment: (
                  <IconButton sx={{ padding: "0px" }}>
                    <SearchOutlinedIcon />
                  </IconButton>
                ),
                style: {
                  height: "40px",
                  width: "330px",
                  borderRadius: "3px",
                },
              }}
              variant="outlined"
            /> */}
            {/* <ButtonLog
              text={"Filter"}
              variant={"contained"}
              startIcon={<img src={dropDown} alt="img-fluid" />}
              className={styles.filterButton}
            /> */}
          </div>
          <div className={styles.filter}>
            <ButtonLog
              text={"Stock In"}
              variant={"contained"}
              className={styles.addProducteCateBtn}
              startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
              // onClick={() => navigation("/stock-inventory-form/add")}
              onClick={() =>
                StockDetails?.length !== 0
                  ? handleEditClick(StockDetails)
                  : navigation("/stock-inventory-form/add")
              }
            />
          </div>
        </div>

        <div className="container-fluid" style={{ paddingTop: "25px" }}>
          <div className={styles.StockINHead}>
            <div className={styles.StockINHeadImg}>
              <img
                src={
                  state?.product.image_path
                    ? `${
                        process.env.REACT_APP_API_ENDPOINT
                      }/${state?.product.image_path.replace("uploads/", "")}`
                    : defaultImage
                }
                alt="image"
              />
              <h4>{state?.product.name}</h4>
            </div>
            <p>Stock Available : {totalQuantity}</p>
            <div className={styles.StockEditBtn}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleProductEditClick(state?.product)}
                // onClick={() => navigation("/stock-inventory-form/edit")}
              >
                <Edit
                  style={{ marginRight: "10px" }}
                  color="#0761E9"
                  size="16"
                />
                Edit
              </Button>
            </div>
          </div>
          <div>
            <CustomTable columns={columns} rows={updatedRows} />
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className={styles.iconInfo}>
            {/* <InfoCircle size="52" color="#F1948A" /> */}
            <img src={info} height={69} alt="" />
          </Box>
          <Box className={styles.modal_title}>
            <p> Are You Sure ? </p>
            <p> You want to stock out this item </p>
          </Box>
          <Box className={styles.btn_group}>
            <Box className={styles.btn_standard}>
              <ButtonLog
                onClick={handleClose}
                text={"No"}
                variant={"contained"}
                className={styles.cancle_btn}
              />
            </Box>
            <Box>
              <ButtonLog
                text={"Yes"}
                variant={"contained"}
                className={styles.addVehicleBtn}
                onClick={handleStockOut}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
