import { connect } from "react-redux";
import {
  createProductCate,
  reset,
  updateProductCate,
} from "../../../../pages/app/Configuration/services/configurationSlice";
import {
  ProductCategory,
  UpdateProductCategoryDT,
} from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import ProductCategoryForm from "../components/ProductCategoryForm/ProductCategoryForm";

interface StateProps {
  createProductCateSuccess: boolean;
  createProductCateError: string | boolean | undefined;
  createProductCateLoading: boolean;
  createProductCateDetails: null;
  updateProductCate_Loading: boolean;
  updateProductCate_Error: string | boolean | undefined;
  updateProductCate_Success: boolean;
  updateProductCate_Details: null;
}

interface DispatchProps {
  ResetProductCate(): void;
  CreateProductCate(data: ProductCategory): void;
  UpdateProductCate(data: UpdateProductCategoryDT): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    createProductCateSuccess: state.configuration.createProductCateSuccess,
    createProductCateError: state.configuration.createProductCateError,
    createProductCateLoading: state.configuration.createProductCateLoading,
    createProductCateDetails: state.configuration.createProductCateDetails,
    updateProductCate_Success: state.configuration.updateProductCate_Success,
    updateProductCate_Error: state.configuration.updateProductCate_Error,
    updateProductCate_Loading: state.configuration.updateProductCate_Loading,
    updateProductCate_Details: state.configuration.updateProductCate_Details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    ResetProductCate: () => {
      dispatch(reset());
    },
    CreateProductCate: (data) => {
      dispatch(createProductCate(data));
    },
    UpdateProductCate: (data) => {
      dispatch(updateProductCate(data));
    },
  };
};

export interface ProductCategoryProps extends StateProps, DispatchProps {}
export const ProductCategoryComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCategoryForm);
