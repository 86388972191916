import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { GetAllArchiveJobCard } from "./archiveJobCard.request";
import { ArchiveJobCardInit } from "./types";

export const initialState: ArchiveJobCardInit = {
  getAllArchiveJobCard_loading: false,
  getAllArchiveJobCard_error: false,
  getAllArchiveJobCard_success: false,
  getAllArchiveJobCard_details: null,
};

export const getAllArchiveJobCard = createAsyncThunk(
  "user/getAllArchiveJobCard",
  (id: string) => {
    return GetAllArchiveJobCard(id)
      .then((res) => {
        if (res.status === 200) {
          return res?.data.jobcard;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error("Something went wrong");
      });
  }
);
export const archiveJobCardSlice = createSlice({
  name: "archiveJobCard",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllArchiveJobCard.pending, (state) => {
      state.getAllArchiveJobCard_loading = true;
      state.getAllArchiveJobCard_success = false;
      state.getAllArchiveJobCard_error = false;
    });
    builder.addCase(
      getAllArchiveJobCard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllArchiveJobCard_loading = false;
        state.getAllArchiveJobCard_success = true;
        state.getAllArchiveJobCard_error = false;
        state.getAllArchiveJobCard_details = action?.payload;
      }
    );
    builder.addCase(
      getAllArchiveJobCard.rejected,
      (state, action: AnyAction) => {
        state.getAllArchiveJobCard_loading = false;
        state.getAllArchiveJobCard_success = false;
        state.getAllArchiveJobCard_error =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default archiveJobCardSlice.reducer;

export const { reset } = archiveJobCardSlice.actions;
