import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import * as React from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import add_cirle from "../../../../../assets/images/add-circle.png";
import ButtonLog from "../../../../common/Button/Button";
import CustomLoader from "../../../../common/Loader/Loader";

import { Setting2 } from "iconsax-react";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../utils/helper.utils";
import { SnackBar } from "../../../../common/SnackBar/SnackBar";
import { SnackStateProp } from "../../../ProductCategory/components/ProductCategoryForm/ProductCategoryForm";
import { ServiceInventoryFormProps } from "../../container/serviceInventoryContainer";
import styles from "../ServiceInventory.module.css";

export default function ServiceInventoryForm(props: ServiceInventoryFormProps) {
  const {
    createService,
    createService_Details,
    createService_Error,
    createService_Loading,
    createService_Success,
    resetAllServiceCategory,
    updateService,
    updateService_Details,
    updateService_Error,
    updateService_Success,
    updateService_Loading,
    // serviceCategoryDetails,
    // GetAllServiceCate,
    // error,
    // loading,
    // success,
    GetAllActiveServiceCate,
    activeServiceCategoryDetails,
    getAllServiceActiveCateError,
    getAllServiceActiveCateLoading,
  } = props;

  const [image, setImage] = React.useState<any>();
  const [base64URL, setBase64URL] = React.useState<any>([]);
  const [Error, setError] = React.useState(false);
  let imageIndex = 0;

  const navigate = useNavigate();
  const { state } = useLocation();
  const userDataParse = getUserDataFromLocalStorage();

  React.useEffect(() => {
    GetAllActiveServiceCate(userDataParse?.partner_id);
  }, [GetAllActiveServiceCate, userDataParse?.partner_id]);
  const [disable, setDisable] = React.useState(false);

  // let userDataParse: { id: string } = { id: "" };
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  // const userData: any = localStorage.getItem("USER_DATA");

  const [service, setService] = React.useState({
    name: state ? state?.service.name : "",
    description: state ? state?.service.description : "",
    price: state ? parseInt(state?.service.price) : "",
    durationInMinute: state ? state?.service.durationInMinute : "",
    service_category_id: state ? state?.service?.service_category_id : "",
    image_path: state ? state?.service?.image_path : "",
    partner_id: userDataParse ? userDataParse?.partner_id : "0",
    slug: "service",
  });
  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL: any = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const handleImageUpload = React.useCallback(
    async (e: any) => {
      // console.log(e.target.files[0]);
      setImage(e.target.files[0]);
      let selectedFile = e.target.files[0];
      if (image?.length <= 3 && e.target.files[0].size < 2097152) {
        setImage([
          ...image,
          {
            index: imageIndex++,
            imgUrl: URL.createObjectURL(selectedFile),
          },
        ]);
      } else {
      }
      getBase64(selectedFile)
        .then((base64: any) => {
          selectedFile["base64"] = base64;
          // setFile(selectedFile);
          // setBase64URL([...base64URL, base64]);
          setService((prevState) => ({
            ...prevState,
            image_path: base64,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
      setImage(URL.createObjectURL(e.target.files[0]));
    },
    [image, imageIndex]
  );
  const [checked, setChecked] = React.useState(
    state ? state?.service.is_active : true
  );

  const handleChange = (event: any) => {
    const updatedValue = event.target.checked;
    setChecked(updatedValue);
  };

  const [formType, setFormType] = React.useState<string | undefined>(
    "product-management-form/add"
  );
  let param = useParams();
  React.useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: service.name,
      errorMsz: "Service name must be of maximum 30 characters",
      max: 30,
      min: 1,
    },
    {
      checkwith: "minmax",
      value: service.description,
      errorMsz: "Service description must be of maximum 300 characters",
      max: 300,
      min: 1,
    },
    {
      checkwith: "min",
      value: service.service_category_id,
      errorMsz: "Please select a service category",
      min: 1,
    },
    {
      checkwith: "min",
      value: service.price,
      errorMsz: "Please add a price",
      min: 1,
    },
    {
      checkwith: "min",
      value: service.durationInMinute,
      errorMsz: "Please provide the duration in minutes",
      min: 1,
    },
  ];
  const handleSave = () => {
    validate(ValidateData).status
      ? createService({
          ...service,
          created_by: userDataParse.id,
          // file: null,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };
  const handleEdit = () => {
    validate(ValidateData).status
      ? updateService({
          ...service,
          is_active: checked ? true : false,
          service_id: state.service.id,
          updated_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };
  React.useEffect(() => {
    if (createService_Success) {
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: createService_Details
          ? createService_Details
          : "Cretaed Successfully",
        snackSeverity: "success",
      });
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAllServiceCategory();
    } else if (createService_Error) {
      resetAllServiceCategory();
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: createService_Details
          ? createService_Details
          : "Something Went Wrong",
        snackSeverity: "error",
      });
    }
  }, [
    createService_Success,
    createService_Error,
    snackState,
    createService_Details,
    resetAllServiceCategory,
    navigate,
  ]);

  React.useEffect(() => {
    if (updateService_Success) {
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: createService_Details
          ? createService_Details
          : "Updated Successfully",
        snackSeverity: "success",
      });
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAllServiceCategory();
    } else if (updateService_Error) {
      resetAllServiceCategory();
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: createService_Details
          ? createService_Details
          : "Something Went Wrong",
        snackSeverity: "error",
      });
    }
  }, [
    createService_Details,
    navigate,
    resetAllServiceCategory,
    snackState,
    updateService_Error,
    updateService_Success,
  ]);
  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {getAllServiceActiveCateLoading ||
      createService_Loading ||
      updateService_Loading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>
            {formType === "services-management-form/add"
              ? "Add Service"
              : "Edit Service Details"}
          </p>
        </div>

        <div className="container mt-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>Service Name</span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Service Name"
                  onChange={(e) => {
                    setService((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  value={service?.name}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>Service Description</span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Service Description"
                  onChange={(e) => {
                    setService((prevState) => ({
                      ...prevState,
                      description: e.target.value,
                    }));
                  }}
                  value={service?.description}
                />
              </FormControl>
            </div>
            <div className="col-md-5">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>Service Category</span>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  // onChange={handleChange}
                  onChange={(e) => {
                    setService((prevState) => ({
                      ...prevState,
                      service_category_id: e.target.value,
                    }));
                  }}
                  value={
                    service?.service_category_id
                      ? service?.service_category_id
                      : "select service category"
                  }
                  className={styles.outline_inputbox}
                >
                  {activeServiceCategoryDetails &&
                    activeServiceCategoryDetails.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-1">
              <Tooltip arrow title=" Add Service Category">
                <IconButton
                  onClick={() => navigate("/services-category-form/add")}
                >
                  <Setting2 color="#000" />
                </IconButton>
              </Tooltip>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>Service Price</span>
                <OutlinedInput
                  placeholder="Price"
                  className={styles.outline_inputbox}
                  onChange={(e) => {
                    setService((prevState) => ({
                      ...prevState,
                      price: parseInt(e.target.value),
                    }));
                  }}
                  value={service?.price}
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">
                      {userDataParse.currency_data.symbol}
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>Service Estimated time</span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Estimated Time"
                  type="number"
                  onChange={(e) => {
                    setService((prevState) => ({
                      ...prevState,
                      durationInMinute: e.target.value,
                    }));
                  }}
                  value={service?.durationInMinute}
                />
              </FormControl>
            </div>
            <div>
              <div>
                <label className={styles.label} htmlFor="colorPicker">
                  Service Image
                </label>
                <div className="imageAdder">
                  <div className={styles.vehiclesimagesAdd}>
                    <div className={styles.img_container}>
                      {!image ? (
                        <Button
                          className={styles.uploadLogo_btn}
                          component="label"
                          color="secondary"
                        >
                          <img
                            src={add_cirle}
                            alt=""
                            width="24"
                            className="my-2"
                          />
                          <p className={styles.add_img_txt}>Add Image</p>
                          <input
                            hidden
                            accept="image/*"
                            name="cover"
                            type="file"
                            onChange={handleImageUpload}
                          />
                        </Button>
                      ) : (
                        <img
                          src={image}
                          alt=""
                          width="100"
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              {formType === "services-management-form/edit" && (
                <div>
                  <FormControl
                    className={styles.formControl}
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <span className={styles.label}>
                      Status
                      {/* <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span> */}
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="Active"
                      // onChange={}
                    />
                  </FormControl>
                </div>
              )}
            </div>
          </div>
          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              variant={"contained"}
              className={styles.saveBtn}
              onClick={
                formType === "services-management-form/edit"
                  ? handleEdit
                  : handleSave
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
