import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../../redux/store";
import { getMechanicReport } from "../../../services/reportsSlice";
import { MechanicPerformanceRes, ReportDT } from "../../../services/types";
import MechanicReport from "../components/MechanicsPerformanceReport";

interface StateProps {
  getMechanicReportError: boolean;
  getMechanicReportLoading: boolean;
  getMechanicReportSuccess: boolean;
  getMechanicReportDetails: MechanicPerformanceRes[];
}

interface DispatchProps {
  GetMechanicReport(body: ReportDT): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getMechanicReportDetails: state.reports.getMechanicReportDetails,
    getMechanicReportError: state.reports.getMechanicReportError,
    getMechanicReportLoading: state.reports.getMechanicReportLoading,
    getMechanicReportSuccess: state.reports.getMechanicReportSuccess,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetMechanicReport(body) {
      dispatch(getMechanicReport(body));
    },
  };
};

export interface MechanicReportProps extends StateProps, DispatchProps {}
export const MechanicReportComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(MechanicReport);
