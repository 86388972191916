import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { GetAllCustReview, GetAllMechReview } from "./dashboard.request";
import { DashBoardPartnerInit } from "./types";

export const initialState: DashBoardPartnerInit = {
  getAllCustReview_loading: false,
  getAllCustReview_error: false,
  getAllCustReview_success: false,
  getAllCustReview_details: [],
  getAllMechReview_loading: false,
  getAllMechReview_error: false,
  getAllMechReview_success: false,
  getAllMechReview_details: [],
};
export const getAllCustReview = createAsyncThunk(
  "user/getAllCustReview",
  (id: string) => {
    return GetAllCustReview(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data.customerFeedback;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getAllMechReview = createAsyncThunk(
  "user/getAllMechReview",
  (id: string) => {
    return GetAllMechReview(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data.mechanicFeedback;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);

export const Dashboard = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCustReview.pending, (state) => {
      state.getAllCustReview_loading = true;
      state.getAllCustReview_success = false;
      state.getAllCustReview_error = false;
    });
    builder.addCase(
      getAllCustReview.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllCustReview_loading = false;
        state.getAllCustReview_success = true;
        state.getAllCustReview_error = false;
        state.getAllCustReview_details = action?.payload;
      }
    );
    builder.addCase(getAllCustReview.rejected, (state, action: AnyAction) => {
      state.getAllCustReview_loading = false;
      state.getAllCustReview_success = false;
      state.getAllCustReview_error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllMechReview.pending, (state) => {
      state.getAllMechReview_loading = true;
      state.getAllMechReview_success = false;
      state.getAllMechReview_error = false;
    });
    builder.addCase(
      getAllMechReview.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllMechReview_loading = false;
        state.getAllMechReview_success = true;
        state.getAllMechReview_error = false;
        state.getAllMechReview_details = action?.payload;
      }
    );
    builder.addCase(getAllMechReview.rejected, (state, action: AnyAction) => {
      state.getAllMechReview_loading = false;
      state.getAllMechReview_success = false;
      state.getAllMechReview_error =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default Dashboard.reducer;
export const { reset } = Dashboard.actions;
