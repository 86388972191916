import useSWR from "swr";
import { axios } from "../../../../api/setup.intersepter";

export const GetAllPaymentRequest = (id: string) => {
  return axios({
    method: "GET",
    url: `/payment`,
  });
};

export const useGetJobcardDashboardInfor = (id: string, params?: any) => {
  let queryString = new URLSearchParams(params);
  const { data, error, isValidating, mutate } = useSWR(
    `/jobcard/dashboard/${id}?${queryString}`,
    async (url: string) => {
      let res = await axios({
        method: "GET",
        url,
      })?.then((res) => res?.data);
      return res;
    },
    {
      revalidateOnFocus: false,
    }
  );

  return {
    data,
    error,
    mutate,
    isValidating,
  };
};
export const useDashboardIRevenue = (id: string, params?: any) => {
  let queryString = new URLSearchParams(params);
  const { data, error, isValidating, mutate } = useSWR(
    `/jobcard/dashboard/revenue/${id}`,
    async (url: string) => {
      let res = await axios({
        method: "GET",
        url,
      })?.then((res) => res?.data);
      return res;
    },
    {
      revalidateOnFocus: false,
    }
  );

  return {
    data,
    error,
    mutate,
    isValidating,
  };
};
export const useDashboardIReview = (id: string, params?: any) => {
  let queryString = new URLSearchParams(params);
  const { data, error, isValidating, mutate } = useSWR(
    `/jobcard/dashboard/review/${id}`,
    async (url: string) => {
      let res = await axios({
        method: "GET",
        url,
      })?.then((res) => res?.data);
      return res;
    },
    {
      revalidateOnFocus: false,
    }
  );

  return {
    data,
    error,
    mutate,
    isValidating,
  };
};
export const GetAllCustReview = (id: string) => {
  return axios({
    method: "GET",
    url: `/customer-feedback/review/${id}`,
  });
};
export const GetAllMechReview = (id: string) => {
  return axios({
    method: "GET",
    url: `/mechanic-feedback/${id}`,
  });
};
