import { axios } from "../../../../api/setup.intersepter";

export const JobcardInvoice = (id: any) => {
  return axios({
    method: "GET",
    url: `/jobcard/invoice/${id}`,
  });
};

export const DownloadInvoice = (id: any) => {
  return axios({
    method: "GET",
    url: `/jobcard/invoiceByJobCard/${id}`,
  });
};

// export const GetCustomerDetails = (id: any) => {
//   return axios({
//     method: "GET",
//     url: `/jobcard/customerInfoByJobCard/${id}`,
//   });
// };
