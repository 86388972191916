import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  Modal,
  Rating,
  SnackbarOrigin,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowCircleRight2, Eye } from "iconsax-react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CustomLoader from "../../../../components/common/Loader/Loader";
import { SnackBar } from "../../../../components/common/SnackBar/SnackBar";
import CustomTable from "../../../../components/common/Table/CustomTable";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";
import { AllMechanicsProps } from "../container/allMechanicContainer";
import { Mechanic, SupervisorRes } from "../services/types";
import styles from "./AllMechanics.module.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  // height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};
interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
export const AllMechanics = (props: AllMechanicsProps) => {
  const {
    GetAllMechanics,
    ResetAll,
    getAllMechanics_Error,
    getAllMechanics_Loading,
    getAllMechanics_Success,
    getAllMechanics_details,
    CreateFeedbackMechanics,
    createFeedbackMechanics_Error,
    createFeedbackMechanics_Loading,
    createFeedbackMechanics_Success,
    createFeedbackMechanics_details,
    getAllSupervisor_Error,
    getAllSupervisor_Loading,
    getAllSupervisor_Success,
    getAllSupervisor_details,
    GetAllSupervisor,
  } = props;

  const userDataParse = getUserDataFromLocalStorage();
  const navigation = useNavigate();
  const [snackState, setSnackState] = useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong. Please try again.",
    snackSeverity: "error",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [selectedMechanic, setSelectedMechanic] = useState<Mechanic | null>(
    null
  );
  const [review, setReview] = useState("");
  // const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState<number | null>(null);
  useEffect(() => {
    ResetAll();
    GetAllMechanics(userDataParse.partner_id);
    GetAllSupervisor(userDataParse.partner_id);
  }, [
    GetAllMechanics,
    ResetAll,
    userDataParse.partner_id,
    createFeedbackMechanics_Success,
    createFeedbackMechanics_Error,
    GetAllSupervisor,
  ]);
  const columns = [
    { id: "sl", label: "Serial No.", minWidth: 100 },
    { id: "name", label: "Name", minWidth: 100 },
    { id: "Pending", label: "Pending Task", minWidth: 100 },
    { id: "Start", label: "Start Task", minWidth: 100 },
    { id: "Pause", label: "Pause Task", minWidth: 100 },
    { id: "Complete", label: "Complete Task", minWidth: 100 },
    { id: "taskCount", label: "Total Task", minWidth: 100 },
    { id: "averageRating", label: "Average Rating", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const updatedRows = getAllMechanics_details?.map(
    (row: Mechanic, index: number) => ({
      ...row,
      sl: index + 1,
      Start: row.Start
        ? Number(row.Start)
        : 0 + row.Start
        ? Number(row.StartAgain)
        : "0",
      Pending: row.Pending ? row.Pending : "0",
      Pause: row.Pause ? row.Pause : "0",
      Complete: row.Complete ? row.Complete : "0",
      averageRating: (
        <>
          <Rating
            value={Number(row.averageRating)}
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
            readOnly
          />
        </>
      ),

      action: userDataParse.user_type !== "Partner" && (
        <>
          <Tooltip arrow title="Task List">
            <IconButton onClick={() => handleView(row)} color="primary">
              <ArrowCircleRight2 size="26" variant="TwoTone" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Profile">
            <IconButton onClick={() => handleProfile(row)} color="secondary">
              <Eye size="26" variant="TwoTone" />
            </IconButton>
          </Tooltip>
        </>
      ),
    })
  );
  const supervisorColumns = [
    { id: "sl", label: "Serial No.", minWidth: 100 },
    { id: "name", label: "Name", minWidth: 100 },
    { id: "email", label: "Email", minWidth: 100 },
  ];

  const supervisorUpdatedRows = getAllSupervisor_details?.map(
    (row: SupervisorRes, index: number) => ({
      ...row,
      sl: index + 1,
    })
  );
  const handleView = useCallback(
    (row: Mechanic) => {
      navigation("/mechanic", {
        state: { id: row.id },
      });
    },
    [navigation]
  );
  const handleProfile = useCallback((row: Mechanic) => {
    setSelectedMechanic(row);
    setProfileModalOpen(true);
  }, []);
  const handleCloseProfileModal = useCallback(() => {
    setSelectedMechanic(null);
    setProfileModalOpen(false);
    setReview("");
    // setFeedback("");
    setRating(null);
  }, []);
  const handleSaveFeedback = useCallback(() => {
    CreateFeedbackMechanics({
      mechanic_id: selectedMechanic ? selectedMechanic?.id : "",
      partner_id: userDataParse.partner_id,
      rating: String(rating),
      review: review,
    });
    handleCloseProfileModal();
  }, [
    CreateFeedbackMechanics,
    handleCloseProfileModal,
    rating,
    review,
    selectedMechanic,
    userDataParse.partner_id,
  ]);
  const handleDeleteSpecialty = (index: number) => {
    if (!selectedMechanic) {
      return;
    }
    const updatedSpecialties = [...selectedMechanic.specialities];
    updatedSpecialties.splice(index, 1);
    const updatedMechanic = {
      ...selectedMechanic,
      specialities: updatedSpecialties,
    };
    setSelectedMechanic(updatedMechanic);
  };
  useEffect(() => {
    if (
      createFeedbackMechanics_Error &&
      typeof createFeedbackMechanics_Error === "string" &&
      createFeedbackMechanics_Error !== "false"
    ) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: createFeedbackMechanics_Error
          ? createFeedbackMechanics_Error
          : "Something went wrong!",
        snackOpen: true,
      }));
    }
    // ResetAll();
  }, [createFeedbackMechanics_Error]);
  useEffect(() => {
    if (createFeedbackMechanics_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: createFeedbackMechanics_details
          ? createFeedbackMechanics_details
          : "Successfully Updated",
        snackOpen: true,
        snackSeverity: "success",
      }));
      // ResetAll();
    }
  }, [createFeedbackMechanics_Success, createFeedbackMechanics_details]);
  const closeSnack = useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      {getAllMechanics_Loading ||
      createFeedbackMechanics_Loading ||
      getAllSupervisor_Loading ? (
        <CustomLoader />
      ) : (
        <div className={styles.main_root}>
          {/* <div className={styles.job_headbar}>
            <div>
              <p className={styles.txt_job}>My Team</p>
            </div>
          </div> */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className={styles.root_jobs}>
                  <div className={styles.job_headbar}>
                    <div>
                      <p className={styles.txt_job}>Mechanic List</p>
                    </div>
                  </div>
                  <CustomTable columns={columns} rows={updatedRows} />
                </div>
              </div>
              {userDataParse.user_type === "Partner" ? (
                <>
                  <div className="col-md-12">
                    <div className={styles.root_jobs}>
                      <div className={styles.job_headbar}>
                        <div>
                          <p className={styles.txt_job}>Supervisor List</p>
                        </div>
                      </div>
                      <CustomTable
                        columns={supervisorColumns}
                        rows={supervisorUpdatedRows}
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}

      {/* Modal to show profile */}
      <Modal
        open={isProfileModalOpen}
        onClose={handleCloseProfileModal}
        aria-labelledby="profile-modal-title"
        aria-describedby="profile-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={handleCloseProfileModal}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "#34495E",
            }}
          >
            <CloseIcon />
          </IconButton>

          {selectedMechanic && (
            <>
              <Typography
                id="profile-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: "center",
                  color: "#34495E",

                  fontSize: 18,
                  fontWeight: 900,
                }}
              >
                Mechanic Profile
              </Typography>
              <div className="row align-items-center justify-content-between">
                <div className="col-3">
                  <Box>
                    <Box className={styles.icon_user}>
                      <Avatar>{selectedMechanic.name[0].toUpperCase()}</Avatar>
                      <Typography
                        sx={{
                          color: "#34495E",
                          // position: "relative",
                          // right: 20,
                          // top: 5,
                          fontSize: 13,
                          fontWeight: 600,
                          textTransform: "capitalize",
                          // mt: 3,
                          mx: 1,
                        }}
                      >
                        {" "}
                        {selectedMechanic.name}
                        <Rating
                          name="hover-feedback"
                          precision={0.5}
                          value={Number(selectedMechanic.averageRating)}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                          readOnly
                          size="small"
                        />
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div className="col-3"></div>
                <div className="col-6">
                  <Box sx={{ mt: 4 }}>
                    {/* <Typography sx={{ fontSize: 12, fontWeight: 600, mt: 4 }}>
                      Specialties:
                    </Typography> */}
                    {selectedMechanic.specialities?.length > 0 ? (
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        <Typography
                          sx={{
                            color: "#34495E",
                            fontSize: 14,
                            fontWeight: 600,
                            // mt: 3,
                          }}
                        >
                          Specialties:{" "}
                        </Typography>
                        {selectedMechanic.specialities.map(
                          (specialty, index) => (
                            <Chip
                              key={index}
                              label={specialty}
                              variant="outlined"
                              // onDelete={() => handleDeleteSpecialty(index)}
                              // deleteIcon={<Delete />}
                              // sx={{ m: 1 }}
                            />
                          )
                        )}
                      </Box>
                    ) : (
                      <Typography>No specialties available.</Typography>
                    )}
                  </Box>
                  <Divider sx={{ mt: 3 }} />
                </div>
              </div>

              <Box className="row">
                <Box sx={{ mt: 3 }} className="col-6">
                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                    Review:
                  </Typography>
                  <TextField
                    label="Review"
                    multiline
                    rows={2}
                    fullWidth
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                  {/* <TextField
                label="Feedback"
                multiline
                rows={4}
                fullWidth
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                sx={{ mt: 2 }}
              /> */}

                  <Box
                    component="fieldset"
                    mb={3}
                    borderColor="transparent"
                    sx={{ mt: 2 }}
                  >
                    <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                      Rating
                    </Typography>
                    <Rating
                      name="hover-feedback"
                      precision={0.5}
                      value={rating || 0}
                      onChange={(event, newValue) => {
                        setRating(newValue);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box sx={{ mt: 3 }} className="col-6">
                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                    Feedbacks:
                  </Typography>
                  {selectedMechanic.feedbackDetails.length > 0 ? (
                    <div
                      style={{
                        maxHeight: "180px",
                        overflowY: "auto",
                      }}
                    >
                      {selectedMechanic.feedbackDetails.map(
                        (feedback, index) => (
                          <Card
                            key={index}
                            sx={{
                              mt: 2,
                              padding: "10px",
                            }}
                            variant="outlined"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: 16, fontWeight: 600 }}
                              >
                                {/* Rating: */}
                                <Rating
                                  name="hover-feedback"
                                  precision={0.5}
                                  value={Number(feedback.rating)}
                                  emptyIcon={
                                    <StarIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                  readOnly
                                />
                              </Typography>
                              <Typography sx={{ fontSize: 14 }}>
                                Review At:{" "}
                                {moment(feedback.created_at).format(
                                  "MMMM Do YYYY"
                                )}
                              </Typography>
                            </Box>
                            <Typography sx={{ mt: 2 }}>
                              {feedback.review}
                            </Typography>
                          </Card>
                        )
                      )}
                    </div>
                  ) : (
                    <Typography>No feedback available.</Typography>
                  )}
                </Box>
                {/* Add other properties from Mechanic as needed */}
              </Box>
              {/* You can add validation, error handling, or other input components as needed */}
              <div className="d-flex justify-content-end">
                <Button
                  onClick={handleSaveFeedback}
                  sx={{ mt: 2 }}
                  disabled={!review.trim() || rating === null}
                >
                  Save Feedback
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};
