import { axios } from "../../../../api/setup.intersepter";
import { MechanicFeedBackDT } from "./types";

export const GetAllMechanics = (id: string) => {
  return axios({
    method: "GET",
    url: `jobcard-tasks/allmechanic/${id}`,
  });
};
export const CreateFeedbackMechanics = (body: MechanicFeedBackDT) => {
  return axios({
    method: "POST",
    url: `mechanic-feedback/`,
    data: body,
  });
};
export const GetAllSupervisor = (id: string) => {
  return axios({
    method: "GET",
    url: `/user/allSupervisor/${id}`,
  });
};
