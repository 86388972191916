import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SnackbarOrigin,
  Switch,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import add_circle from "../../../../../assets/images/add-circle.png";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../utils/helper.utils";
import ButtonLog from "../../../../common/Button/Button";
import CustomLoader from "../../../../common/Loader/Loader";
import { SnackBar } from "../../../../common/SnackBar/SnackBar";
import { PartnerFormProps } from "../../container/partnerContainer";
import { countries } from "../../country.data";
import styles from "../partner.module.css";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}
const PartnerForm = (props: PartnerFormProps) => {
  const {
    CreatePartner,
    UpdatePartner,
    createPartner_Details,
    updatePartner_Details,
    createPartner_Error,
    updatePartner_Error,
    updatePartner_Success,
    createPartner_Success,
    resetAllCategory,
    createPartner_Loading,
    updatePartner_Loading,
    CurrencyDetails,
    getAllCurrencyLoading,
    GetAllCurrency,
  } = props;
  const { state } = useLocation();
  const navigate = useNavigate();
  let param = useParams();
  React.useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);
  React.useEffect(() => {
    GetAllCurrency("0");
  }, [GetAllCurrency]);

  // let userDataParse: { id: string } = { id: state ? state?.partner.Name : "" };
  // const userData: any = localStorage.getItem("USER_DATA");
  const userDataParse = getUserDataFromLocalStorage();
  const [formType, setFormType] = React.useState<string | undefined>(
    "partner-form/add"
  );
  const [image, setImage] = React.useState<any>();
  const [base64URL, setBase64URL] = React.useState<any>([]);
  const [color, setColor] = React.useState("#ffffff");

  const [partner, setPartner] = React.useState({
    name: state ? state?.partner.name : "",
    contact_person: state ? state?.partner.contact_person : "",
    garage_name: state ? state?.partner.garage_name : "",
    email: state ? state?.partner.email : "",
    password: state ? state?.partner.password : "",
    currency: state ? state?.partner.currency : "",
    country: state ? state?.partner.country : "",
    tax_number: state ? state?.partner.tax_number : "",
    phone_number: state ? state?.partner.phone_number : "",
    website: state ? state?.partner.website : "",
    tax_percentage: state ? state?.partner.tax_percentage : "",
    address: state ? state?.partner.address : "",
    color_code: state ? state?.partner.color_code : "#ffffff",
    logo: state ? state?.partner.logo : "",
    slug: state ? state?.partner.slug : "test",
    user_type: state ? state?.partner.user_type : "Partner",
  });

  const [checked, setChecked] = React.useState(
    state ? state?.partner.is_active : true
  );
  // const handleColorChange = (color: any) => {
  //   setColor(color);
  // };
  const handleChange = (event: any) => {
    const updatedValue = event.target.checked;
    setChecked(updatedValue);
  };
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });
  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL: any = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  let imageIndex = 0;

  const handleImageUpload = React.useCallback(
    async (e: any) => {
      setImage(e.target.files[0]);
      let selectedFile = e.target.files[0];
      if (image?.length <= 3 && e.target.files[0].size < 500000) {
        setImage([
          ...image,
          {
            index: imageIndex++,
            imgUrl: URL.createObjectURL(selectedFile),
          },
        ]);
      } else {
      }
      getBase64(selectedFile)
        .then((base64) => {
          selectedFile["base64"] = base64;
          // setFile(selectedFile);
          // setBase64URL([...base64URL, base64]);
          setPartner((prevState: typeof partner) => ({
            ...prevState,
            logo: base64,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
      setImage(URL.createObjectURL(e.target.files[0]));
    },
    [image, imageIndex]
  );
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "minmax",
      value: partner.name,
      errorMsz: "Name is required",
      min: 1,
    },
    {
      checkwith: "minmax",
      value: partner.password,
      errorMsz:
        "Enter valid password with min 8 letter and maximum 16 characters",
      min: 8,
      max: 16,
    },
    // {
    //   checkwith: "regex",
    //   value: partner.website,
    //   errorMsz: "Enter valid website ",
    //   regex:
    //     " /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}.[a-z]{2,4}\b(/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi",
    // },
    {
      checkwith: "regex",
      value: partner.email,
      errorMsz: "Please enter valid email address",
      regex: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
    },
  ];
  const handleSave = () => {
    validate(ValidateData).status
      ? CreatePartner({
          ...partner,
          created_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };

  const handleEdit = () => {
    UpdatePartner({
      ...partner,
      IsActive: checked ? true : false,
      partner_id: state.partner.id,
      // logo: base64URL[0],
      updated_by: userDataParse.id,
    });
  };

  React.useEffect(() => {
    if (createPartner_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(createPartner_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAllCategory();
    } else if (createPartner_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          createPartner_Details ? createPartner_Details : createPartner_Error
        ),
        snackOpen: true,
        snackSeverity: createPartner_Details ? "success" : "error",
      }));
      resetAllCategory();
    }
  }, [
    createPartner_Success,
    updatePartner_Details,
    navigate,
    resetAllCategory,
    createPartner_Error,
    createPartner_Details,
  ]);

  React.useEffect(() => {
    if (updatePartner_Success) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(updatePartner_Details),
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        navigate(-1);
      }, 200);
      resetAllCategory();
    } else if (updatePartner_Error) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          updatePartner_Details ? updatePartner_Details : "Something went wrong"
        ),
        snackOpen: true,
        snackSeverity: updatePartner_Details ? "success" : "error",
      }));
      resetAllCategory();
    }
  }, [
    updatePartner_Details,
    navigate,
    resetAllCategory,

    updatePartner_Success,
    updatePartner_Error,
  ]);

  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {createPartner_Loading ||
      updatePartner_Loading ||
      getAllCurrencyLoading ? (
        <>
          <CustomLoader />
        </>
      ) : (
        <></>
      )}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>
            {/* Add Partner */}
            {formType === "partner-form/add" ? "Add Partner" : "Edit Partner"}
          </p>
        </div>

        <div className="container mt-5 pb-5">
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Partner Name
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>

                <OutlinedInput
                  placeholder="Enter Partner Name"
                  multiline
                  className={styles.outline_inputbox}
                  // rows={2}
                  // maxRows={4}
                  onChange={(e) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  value={partner?.name}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>Garage Name</span>

                <OutlinedInput
                  placeholder="Enter Garage Name"
                  multiline
                  className={styles.outline_inputbox}
                  // rows={2}
                  // maxRows={4}
                  onChange={(e) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      garage_name: e.target.value,
                    }));
                  }}
                  value={partner?.garage_name}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Email{" "}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>

                <OutlinedInput
                  placeholder="Enter Email"
                  multiline
                  className={styles.outline_inputbox}
                  // rows={2}
                  // maxRows={4}
                  onChange={(e) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                  value={partner?.email}
                  disabled={formType === "partner-form/edit"}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Currency
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  onChange={(e) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      currency: e.target.value,
                    }));
                  }}
                  value={partner.currency}
                  className={styles.outline_inputbox}
                >
                  <MenuItem disabled>Select Currency</MenuItem>

                  {CurrencyDetails &&
                    CurrencyDetails.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Country
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  onChange={(e) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      country: e.target.value,
                    }));
                  }}
                  value={partner.country}
                  className={styles.outline_inputbox}
                >
                  <MenuItem disabled>Select Country</MenuItem>
                  {countries &&
                    countries.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Tax No.{" "}
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>

                <OutlinedInput
                  placeholder="Enter Tax No"
                  multiline
                  className={styles.outline_inputbox}
                  // rows={2}
                  // maxRows={4}
                  type="number"
                  onChange={(e) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      tax_number: e.target.value,
                    }));
                  }}
                  value={partner?.tax_number}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Phone No.{" "}
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Phone No"
                  // multiline
                  type="number"
                  // rows={2}
                  // maxRows={4}
                  onChange={(e) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      phone_number: e.target.value,
                    }));
                  }}
                  value={partner?.phone_number}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Website
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Website"
                  onChange={(e) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      website: e.target.value,
                    }));
                  }}
                  value={partner?.website}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Tax Percentage{" "}
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>

                <OutlinedInput
                  placeholder="Enter Tax Percentage"
                  multiline
                  className={styles.outline_inputbox}
                  type="number"
                  // rows={2}
                  // maxRows={4}
                  onChange={(e) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      tax_percentage: e.target.value,
                    }));
                  }}
                  value={partner?.tax_percentage}
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Address
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Address"
                  // multiline

                  // rows={2}
                  // maxRows={4}
                  onChange={(e) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      address: e.target.value,
                    }));
                  }}
                  value={partner?.address}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Color Code
                  {/* <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span> */}
                </span>

                <MuiColorInput
                  value={partner?.color_code}
                  onChange={(color) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      color_code: color,
                    }));
                  }}
                  className={styles.colorInput}
                  size="small"
                  format="hex"
                />
              </FormControl>
            </div>
            {formType === "partner-form/add" ? (
              <div className="col-md-6">
                <FormControl
                  className={styles.formControl}
                  size="small"
                  sx={{
                    width: "100%",
                  }}
                >
                  <span className={styles.label}>
                    Password
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </span>
                  <OutlinedInput
                    className={styles.outline_inputbox}
                    placeholder="Enter Password"
                    // multiline
                    // rows={2}
                    // maxRows={4}
                    onChange={(e) => {
                      setPartner((prevState: typeof partner) => ({
                        ...prevState,
                        password: e.target.value,
                      }));
                    }}
                    value={partner?.password}
                    type="password"
                  />
                </FormControl>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>Contact Person</span>
                <OutlinedInput
                  className={styles.outline_inputbox}
                  placeholder="Enter Contact Person"
                  onChange={(e) => {
                    setPartner((prevState: typeof partner) => ({
                      ...prevState,
                      contact_person: e.target.value,
                    }));
                  }}
                  value={partner?.contact_person}
                />
              </FormControl>
              {/* {formType === "services-category-form/edit" && ( */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div>
                <label className={styles.label} htmlFor="colorPicker">
                  Logo
                </label>
                <div className="imageAdder">
                  <div className={styles.vehiclesimagesAdd}>
                    <div className={styles.img_container}>
                      {!image ? (
                        <Button
                          className={styles.uploadLogo_btn}
                          component="label"
                          color="secondary"
                        >
                          <img
                            src={add_circle}
                            alt=""
                            width="24"
                            className="my-2"
                          />
                          <p className={styles.add_img_txt}>Add Image</p>
                          <input
                            hidden
                            accept="image/*"
                            name="cover"
                            type="file"
                            onChange={handleImageUpload}
                          />
                        </Button>
                      ) : (
                        <img
                          src={image}
                          alt=""
                          width="100"
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {formType === "partner-form/edit" && (
                <>
                  <FormControl
                    className={styles.formControl}
                    size="small"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <span className={styles.label}>
                      Status
                      {/* <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span> */}
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label="Active"
                      // onChange={}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </div>
          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />
            <ButtonLog
              text={"Save"}
              variant="contained"
              type="submit"
              className={styles.saveBtn}
              onClick={
                formType === "partner-form/edit" ? handleEdit : handleSave
              }
            />
          </div>
        </div>
      </div>
      {/* <div className={styles.footer_root}>
              <Footer />
            </div> */}
    </>
  );
};
export default PartnerForm;
