import { connect } from "react-redux";
import {
  createRoleCreation,
  reset,
} from "../../../../pages/app/Configuration/services/configurationSlice";
import { RoleCreationDT } from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import RoleCreationForm from "../components/RoleCreationForm/RoleCreationForm";

interface StateProps {
  createRoleCreationSuccess: boolean;
  createRoleCreationError: boolean;
  createRoleCreationLoading: boolean;
  createRoleCreationDetails: null;
}

interface DispatchProps {
  CreateRoleCreation(data: RoleCreationDT): void;
  resetAll(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    createRoleCreationLoading: state.configuration.createRoleCreationLoading,
    createRoleCreationError: state.configuration.createRoleCreationError,
    createRoleCreationSuccess: state.configuration.createRoleCreationSuccess,
    createRoleCreationDetails: state.configuration.createRoleCreationDetails,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateRoleCreation(data) {
      dispatch(createRoleCreation(data));
    },
    resetAll() {
      dispatch(reset());
    },
  };
};

export interface RoleCreationFormProps extends StateProps, DispatchProps {}

export const RoleCreationFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleCreationForm);
