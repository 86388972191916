import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { JobCardCreateFinalDataDTO, JobCardDT, JobCardSearchDT } from "../type";
import { VeichleRegInitialState } from "./types";
import {
  CreateJobCard,
  // CreateJobCardComplaints,
  CreateJobCardFinal,
  // CreateJobCardServices,
  // CreateJobCardSpareParts,
  GetAllActiveProduct,
  GetAllActiveService,
  GetAllActiveUser,
  SearchJobCard,
} from "./veichleRegistration.request";

export const initialState: VeichleRegInitialState = {
  getAllActiveUser_Loading: false,
  getAllActiveUser_Error: false,
  getAllActiveUser_Success: false,
  getAllActiveUser_Details: null,
  getAllActiveProductSuccess: false,
  getAllActiveProductError: false,
  getAllActiveProductLoading: false,
  activeProductDetails: [],
  activeServiceSuccess: false,
  activeServiceError: false,
  activeServiceLoading: false,
  activeServiceDetails: [],
  createJobCardSuccess: false,
  createJobCardError: false,
  createJobCardLoading: false,
  createJobCardDetails: undefined,
  createJobCardServicesSuccess: false,
  createJobCardServicesError: false,
  createJobCardServicesLoading: false,
  createJobCardServicesDetails: null,
  createJobCardComplaintsSuccess: false,
  createJobCardComplaintsError: false,
  createJobCardComplaintsLoading: false,
  createJobCardComplaintsDetails: null,
  createJobCardSparePartsSuccess: false,
  createJobCardSparePartsError: false,
  createJobCardSparePartsLoading: false,
  createJobCardSparePartsDetails: null,
  createJobCardFinalSuccess: false,
  createJobCardFinalError: false,
  createJobCardFinalLoading: false,
  searchJobCardSuccess: false,
  searchJobCardError: false,
  searchJobCardLoading: false,
  searchJobCardDetails: null,
};

export const getAllActiveUser = createAsyncThunk(
  "getAllActiveUser",
  (id: number) => {
    return GetAllActiveUser(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data.user;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error("Something went wrong");
      });
  }
);
export const getAllActiveService = createAsyncThunk(
  "service/getAllActiveService",
  (id: number) => {
    return GetAllActiveService(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data.service;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);
export const getAllActiveProduct = createAsyncThunk(
  "product/getAllActiveProduct",
  (id: number) => {
    return GetAllActiveProduct(id)
      .then((res) => {
        if (res.status === 200) {
          return res.data.product;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);
export const createJobCard = createAsyncThunk(
  "/jobcard/createJobcard",
  (data: JobCardDT) => {
    return CreateJobCard(data)
      .then((res) => {
        if (res.status === 201) {
          return res.data.jobcard.JobcardId;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  }
);
export const searchJobCard = createAsyncThunk(
  "/jobcard/searchJobcard",
  (data: JobCardSearchDT) => {
    return SearchJobCard(data)
      .then((res) => {
        if (res.status === 200) {
          return res.data.vehicle;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }
);
// export const createJobCardComplaints = createAsyncThunk(
//   "/jobcard/createJobCardComplaints",
//   (data: CreateComplaintType[]) => {
//     return CreateJobCardComplaints(data)
//       .then((res) => {
//         if (res.status === 200) {
//           return res.data;
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }
// );
// export const createJobCardServices = createAsyncThunk(
//   "/jobcard/createJobCardServices",
//   (data: CreateServiceType[]) => {
//     return CreateJobCardServices(data)
//       .then((res) => {
//         if (res.status === 200) {
//           return res.data;
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }
// );
// export const createJobCardSpareParts = createAsyncThunk(
//   "/jobcard/createJobCardSpareParts",
//   (data: OrderType[]) => {
//     return CreateJobCardSpareParts(data)
//       .then((res) => {
//         if (res.status === 200) {
//           return res.data;
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }
// );
export const createJobCardFinal = createAsyncThunk(
  "/jobcard/CreateJobcardFinal",
  (data: JobCardCreateFinalDataDTO) => {
    return CreateJobCardFinal(data)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);
export const vehicleRegistrationSlice = createSlice({
  name: "vehicleRegistration",
  initialState,
  reducers: {
    reset: () => initialState,
    setAllActiveUser: (state, action: PayloadAction<any>) => {
      return { ...state, getAllActiveUser_Details: action.payload };
    },
    setActiveServiceDetails: (state, action: PayloadAction<any>) => {
      return { ...state, activeServiceDetails: action.payload };
    },
    setActiveProductDetails: (state, action: PayloadAction<any>) => {
      return { ...state, activeProductDetails: action.payload };
    },
    setJobCardDetails: (state, action: PayloadAction<any>) => {
      return { ...state, createJobCardDetails: action.payload };
    },
    setJobCardComplaintsDetails: (state, action: PayloadAction<any>) => {
      return { ...state, createJobCardComplaintsDetails: action.payload };
    },
    setJobCardServicesDetails: (state, action: PayloadAction<any>) => {
      return { ...state, createJobCardServicesDetails: action.payload };
    },
    setJobCardSparePartsDetails: (state, action: PayloadAction<any>) => {
      return { ...state, createJobCardSparePartsDetails: action.payload };
    },
    setSearchJobCardDetails: (state, action: PayloadAction<any>) => {
      return { ...state, searchJobCardDetails: action.payload };
    },
    resetJobcardFinal: (state) => {
      return {
        ...state,
        createJobCardFinalSuccess: false,
        createJobCardFinalError: false,
        createJobCardFinalLoading: false,
        searchJobCardSuccess: false,
        searchJobCardError: false,
        searchJobCardLoading: false,
        searchJobCardDetails: null,
        createJobCardDetails: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllActiveUser.pending, (state) => {
      state.getAllActiveUser_Loading = true;
      state.getAllActiveUser_Error = false;
      state.getAllActiveUser_Success = false;
    });
    builder.addCase(
      getAllActiveUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllActiveUser_Loading = false;
        state.getAllActiveUser_Error = false;
        state.getAllActiveUser_Success = true;
        state.getAllActiveUser_Details = action?.payload;
      }
    );
    builder.addCase(getAllActiveUser.rejected, (state, action: AnyAction) => {
      state.getAllActiveUser_Loading = false;
      state.getAllActiveUser_Error = false;
      state.getAllActiveUser_Success = false;
      state.getAllActiveUser_Error =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAllActiveService.pending, (state) => {
      state.activeServiceLoading = true;
      state.activeServiceSuccess = false;
      state.activeServiceError = false;
    });
    builder.addCase(
      getAllActiveService.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.activeServiceLoading = false;
        state.activeServiceSuccess = true;
        state.activeServiceError = false;
        state.activeServiceDetails = action?.payload;
      }
    );
    builder.addCase(
      getAllActiveService.rejected,
      (state, action: AnyAction) => {
        state.activeServiceLoading = false;
        state.activeServiceSuccess = false;
        state.activeServiceError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getAllActiveProduct.pending, (state) => {
      state.getAllActiveProductLoading = true;
      state.getAllActiveProductSuccess = false;
      state.getAllActiveProductError = false;
    });
    builder.addCase(
      getAllActiveProduct.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllActiveProductLoading = false;
        state.getAllActiveProductSuccess = true;
        state.getAllActiveProductError = false;
        state.activeProductDetails = action?.payload;
      }
    );
    builder.addCase(
      getAllActiveProduct.rejected,
      (state, action: AnyAction) => {
        state.getAllActiveProductLoading = false;
        state.getAllActiveProductSuccess = false;
        state.getAllActiveProductError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(createJobCard.pending, (state) => {
      state.createJobCardLoading = true;
      state.createJobCardSuccess = false;
      state.createJobCardError = false;
    });
    builder.addCase(
      createJobCard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createJobCardLoading = false;
        state.createJobCardSuccess = true;
        state.createJobCardError = false;
        state.createJobCardDetails = action?.payload;
      }
    );
    builder.addCase(createJobCard.rejected, (state, action: AnyAction) => {
      state.createJobCardLoading = false;
      state.createJobCardSuccess = false;
      state.createJobCardError = true;
      state.createJobCardError =
        action?.error?.message || "Something went wrong";
    });
    // builder.addCase(createJobCardComplaints.pending, (state) => {
    //   state.createJobCardComplaintsLoading = true;
    //   state.createJobCardComplaintsSuccess = false;
    //   state.createJobCardComplaintsError = false;
    // });
    // builder.addCase(
    //   createJobCardComplaints.fulfilled,
    //   (state, action: PayloadAction<any>) => {
    //     state.createJobCardComplaintsLoading = false;
    //     state.createJobCardComplaintsSuccess = true;
    //     state.createJobCardComplaintsError = false;
    //     state.createJobCardComplaintsDetails = action?.payload;
    //   }
    // );
    // builder.addCase(
    //   createJobCardComplaints.rejected,
    //   (state, action: AnyAction) => {
    //     state.createJobCardComplaintsLoading = false;
    //     state.createJobCardComplaintsSuccess = false;
    //     state.createJobCardComplaintsError =
    //       action?.error?.message || "Something went wrong";
    //   }
    // );
    // builder.addCase(createJobCardServices.pending, (state) => {
    //   state.createJobCardServicesLoading = true;
    //   state.createJobCardServicesSuccess = false;
    //   state.createJobCardServicesError = false;
    // });
    // builder.addCase(
    //   createJobCardServices.fulfilled,
    //   (state, action: PayloadAction<any>) => {
    //     state.createJobCardServicesLoading = false;
    //     state.createJobCardServicesSuccess = true;
    //     state.createJobCardServicesError = false;
    //     state.createJobCardServicesDetails = action?.payload;
    //   }
    // );
    // builder.addCase(
    //   createJobCardServices.rejected,
    //   (state, action: AnyAction) => {
    //     state.createJobCardServicesLoading = false;
    //     state.createJobCardServicesSuccess = false;
    //     state.createJobCardServicesError =
    //       action?.error?.message || "Something went wrong";
    //   }
    // );
    // builder.addCase(createJobCardSpareParts.pending, (state) => {
    //   state.createJobCardSparePartsLoading = true;
    //   state.createJobCardSparePartsSuccess = false;
    //   state.createJobCardSparePartsError = false;
    // });
    // builder.addCase(
    //   createJobCardSpareParts.fulfilled,
    //   (state, action: PayloadAction<any>) => {
    //     state.createJobCardSparePartsLoading = false;
    //     state.createJobCardSparePartsSuccess = true;
    //     state.createJobCardSparePartsError = false;
    //     state.createJobCardSparePartsDetails = action?.payload;
    //   }
    // );
    // builder.addCase(
    //   createJobCardSpareParts.rejected,
    //   (state, action: AnyAction) => {
    //     state.createJobCardSparePartsLoading = false;
    //     state.createJobCardSparePartsSuccess = false;
    //     state.createJobCardSparePartsError =
    //       action?.error?.message || "Something went wrong";
    //   }
    // );
    builder.addCase(searchJobCard.pending, (state) => {
      state.searchJobCardLoading = true;
      state.searchJobCardSuccess = false;
      state.searchJobCardError = false;
    });
    builder.addCase(
      searchJobCard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.searchJobCardLoading = false;
        state.searchJobCardSuccess = true;
        state.searchJobCardError = false;
        state.searchJobCardDetails = action?.payload;
      }
    );
    builder.addCase(searchJobCard.rejected, (state, action: AnyAction) => {
      state.searchJobCardLoading = false;
      state.searchJobCardSuccess = false;
      state.searchJobCardError =
        action?.error?.message || "Something went wrong";
    });

    // Create Job card final
    builder.addCase(createJobCardFinal.pending, (state) => {
      state.createJobCardFinalSuccess = false;
      state.createJobCardFinalError = false;
      state.createJobCardFinalLoading = true;
    });
    builder.addCase(
      createJobCardFinal.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createJobCardFinalSuccess = true;
        state.createJobCardFinalError = false;
        state.createJobCardFinalLoading = false;
      }
    );
    builder.addCase(createJobCardFinal.rejected, (state, action: AnyAction) => {
      state.createJobCardFinalSuccess = false;
      state.createJobCardFinalError =
        action?.error?.message ||
        "Failed to create job card. Please try again.";
      state.createJobCardFinalLoading = false;
    });
  },
});

export default vehicleRegistrationSlice.reducer;
export const {
  reset,
  setAllActiveUser,
  setActiveProductDetails,
  setActiveServiceDetails,
  setJobCardDetails,
  setJobCardComplaintsDetails,
  setJobCardServicesDetails,
  setJobCardSparePartsDetails,
  setSearchJobCardDetails,
  resetJobcardFinal,
} = vehicleRegistrationSlice.actions;
