import * as FileSaver from "file-saver";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import ButtonLog from "../../../../../../components/common/Button/Button";
import CustomLoader from "../../../../../../components/common/Loader/Loader";
import CustomTable from "../../../../../../components/common/Table/CustomTable";
import { getUserDataFromLocalStorage } from "../../../../../../utils/helper.utils";
import { GarageReportRes } from "../../../services/types";
import { GenerateReportProps } from "../container/garageReportContainer";
import styles from "./GarageReport.module.css";

export default function GarageReport(props: GenerateReportProps) {
  const {
    GetGarageReport,
    getGarageReportDetails,
    getGarageReportError,
    getGarageReportLoading,
    getGarageReportSuccess,
  } = props;
  const userData: any = getUserDataFromLocalStorage();
  const { state } = useLocation();
  useEffect(() => {
    GetGarageReport({
      id: userData?.partner_id,
      startDate: state ? state.fromDate : "",
      endDate: state ? state.toDate : "",
    });
  }, [GetGarageReport, state, userData?.partner_id]);
  const navigation = useNavigate();
  const columns = [
    // { id: "Job_Name", label: "Name", minWidth: 170 },
    { id: "sl", label: "Serial No.", minWidth: 100 },
    { id: "supervisor_name", label: "Supervisor", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "created_at", label: "Start Date", minWidth: 100 },
    { id: "modified_at", label: "End Date", minWidth: 100 },
    { id: "revenue", label: "Revenue", minWidth: 100 },
    { id: "payment_status", label: "Payment Status", minWidth: 100 },
  ];

  const updatedRows: any = getGarageReportDetails?.map(
    (row: GarageReportRes, index: number) => ({
      ...row,
      sl: index + 1,
      created_at: moment(row.created_at).format("DD/MM/YYYY"),
      modified_at: moment(row.modified_at).format("DD/MM/YYYY"),
    })
  );

  const exportToCSV = useCallback(
    (fileName: string) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(updatedRows);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    },
    [updatedRows]
  );

  return (
    <>
      {getGarageReportLoading && <CustomLoader />}
      <div className={styles.inventoryNavBar}>
        <ButtonLog
          text={"Back"}
          variant={"contained"}
          onClick={() => navigation("/reports")}
          className={styles.backBtn}
        />
        {/* <div className={styles.filter}>
          <ButtonLog
            text={"Generate"}
            variant={"contained"}
            className={styles.addProducteBtn}
          />
        </div> */}
      </div>
      <div className="row me-1  mt-2">
        <div className="offset-lg-10 offset-md-8 offset-sm-6 col-lg-1 col-md-2 col-sm-3 mt-1">
          <ButtonLog
            text={
              <CSVLink
                style={{ color: "inherit" }}
                headers={columns?.map((item) => ({
                  key: item.id,
                  label: item.label,
                }))}
                data={updatedRows ?? []}
              >
                {" "}
                CSV{" "}
              </CSVLink>
            }
            variant={"contained"}
            className={styles.addProducteBtn}
          />
        </div>
        <div className="col-lg-1 col-md-2 col-sm-3 mt-1">
          <ButtonLog
            text={"XLSX"}
            onClick={() => exportToCSV("garage_report")}
            variant={"contained"}
            className={styles.addProducteBtn}
          />
        </div>
      </div>
      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <CustomTable columns={columns} rows={updatedRows} />
      </div>
    </>
  );
}
