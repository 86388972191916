import { useEffect } from "react";
import Suppliers from "../../../../../components/appSpecific/Suppliers/components/Suppliers";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { getUserDataFromLocalStorage } from "../../../../../utils/helper.utils";
import { ConfigurationProps } from "../../container/configuartionContainer";

export default function SupplierPage(props: ConfigurationProps) {
  const {
    SupplierDetails,
    GetAllSupplier,
    getAllSupplierError,
    getAllSupplierLoading,
    getAllSupplierSuccess,
  } = props;
  const userData: any = getUserDataFromLocalStorage();

  useEffect(() => {
    GetAllSupplier(userData?.partner_id);
  }, [GetAllSupplier, userData?.partner_id]);
  return (
    <>
      {getAllSupplierLoading ? (
        <CustomLoader />
      ) : (
        <Suppliers SupplierDetails={SupplierDetails} />
      )}
    </>
  );
}
