import { connect } from "react-redux";

import {
  createCurrency,
  reset,
  updateCurrency,
} from "../../../../pages/app/Configuration/services/configurationSlice";
import {
  CreateCurrencyDTO,
  UpdateCurrencyDTO,
} from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import CurrencyForm from "../components/CurrencyForm/CurrencyForm";

interface StateProps {
  createCurrency_Loading: boolean;
  createCurrency_Error: boolean;
  createCurrency_Success: boolean;
  createCurrency_Details: null;
  updateCurrency_Loading: boolean;
  updateCurrency_Error: boolean;
  updateCurrency_Success: boolean;
  updateCurrency_Details: null;
}
interface DispatchProps {
  CreateCurrency(data: CreateCurrencyDTO): void;
  UpdateCurrency(data: UpdateCurrencyDTO): void;
  resetAll(): void;
}
const mapStateToProps = (state: RootState): StateProps => {
  return {
    createCurrency_Loading: state.configuration.createCurrency_Loading,
    createCurrency_Error: state.configuration.createCurrency_Error,
    createCurrency_Success: state.configuration.createCurrency_Success,
    createCurrency_Details: state.configuration.createCurrency_Details,
    updateCurrency_Loading: state.configuration.updateCurrency_Loading,
    updateCurrency_Error: state.configuration.updateCurrency_Error,
    updateCurrency_Success: state.configuration.updateCurrency_Success,
    updateCurrency_Details: state.configuration.updateCurrency_Details,
  };
};
const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    resetAll: () => {
      dispatch(reset());
    },
    CreateCurrency: (data) => {
      dispatch(createCurrency(data));
    },
    UpdateCurrency: (data) => {
      dispatch(updateCurrency(data));
    },
  };
};
export interface CurrencyFormProps extends StateProps, DispatchProps {}
export const CurrencyFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrencyForm);
