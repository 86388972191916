import { connect } from "react-redux";
import {
  createServiceCate,
  reset,
  updateServiceCate,
} from "../../../../pages/app/Configuration/services/configurationSlice";
import {
  ServiceCategory,
  UpdateServiceCategoryDT,
} from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import ServiceCategoryForm from "../components/ServiceCategoryForm/ServiceCategoryForm";

interface StateProps {
  createServiceCateSuccess: boolean;
  createServiceCateError: string | boolean | undefined;
  createServiceCateLoading: boolean;
  createServiceCateDetails: null;
  updateServiceCate_Loading: boolean;
  updateServiceCate_Error: string | boolean | undefined;
  updateServiceCate_Success: boolean;
  updateServiceCate_Details: null;
}

interface DispatchProps {
  resetAllcatagory(): void;
  CreateServiceCate(data: ServiceCategory): void;
  UpdateServiceCate(data: UpdateServiceCategoryDT): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    createServiceCateSuccess: state.configuration.createServiceCateSuccess,
    createServiceCateError: state.configuration.createServiceCateError,
    createServiceCateLoading: state.configuration.createServiceCateLoading,
    createServiceCateDetails: state.configuration.createServiceCateDetails,
    updateServiceCate_Success: state.configuration.updateServiceCate_Success,
    updateServiceCate_Error: state.configuration.updateServiceCate_Error,
    updateServiceCate_Loading: state.configuration.updateServiceCate_Loading,
    updateServiceCate_Details: state.configuration.updateServiceCate_Details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    resetAllcatagory: () => {
      dispatch(reset());
    },
    CreateServiceCate: (data) => {
      dispatch(createServiceCate(data));
    },
    UpdateServiceCate: (data) => {
      dispatch(updateServiceCate(data));
    },
  };
};

export interface ServiceCategoryProps extends StateProps, DispatchProps {}
export const ServiceCategoryComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCategoryForm);
