import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../../redux/store";
import { getAllActiveProduct } from "../../../../Manage/VeichleRegistration/service/veichleRegistrationSlice";
import { requestSparePart, reset } from "../../../service/MechanicSlice";
import MechanicPending from "../components/MechanicsPending";
import { ProductRes, SpareReqDT } from "../service/types";

interface StateProps {
  getAllActiveProductSuccess: boolean;
  getAllActiveProductError: boolean;
  getAllActiveProductLoading: boolean;
  activeProductDetails: ProductRes[];
  spares: any;
  requestSparePart_Loading: boolean;
  requestSparePart_Error: boolean;
  requestSparePart_Success: boolean;
  requestSparePart_details: null;
}

interface DispatchProps {
  GetAllActiveProduct(id: number): void;
  RequestSparePart(data: SpareReqDT): void;
  ResetAll(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getAllActiveProductSuccess:
      state.vehicleRegistration.getAllActiveProductSuccess,
    getAllActiveProductError:
      state.vehicleRegistration.getAllActiveProductError,
    getAllActiveProductLoading:
      state.vehicleRegistration.getAllActiveProductLoading,
    activeProductDetails: state.vehicleRegistration.activeProductDetails,
    spares: state.spares.spares,
    requestSparePart_Success: state.mechanicJobs.requestSparePart_Success,
    requestSparePart_Error: state.mechanicJobs.requestSparePart_Error,
    requestSparePart_Loading: state.mechanicJobs.requestSparePart_Loading,
    requestSparePart_details: state.mechanicJobs.requestSparePart_details,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllActiveProduct: (id) => {
      dispatch(getAllActiveProduct(id));
    },
    RequestSparePart(data) {
      dispatch(requestSparePart(data));
    },
    ResetAll() {
      dispatch(reset());
    },
  };
};

export interface MechanicPendingProps extends StateProps, DispatchProps {}
export const MechanicPendingComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(MechanicPending);
