import StarIcon from "@mui/icons-material/Star";
import { Rating } from "@mui/material";
import * as FileSaver from "file-saver";
import { useCallback, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import ButtonLog from "../../../../../../components/common/Button/Button";
import CustomLoader from "../../../../../../components/common/Loader/Loader";
import CustomTable from "../../../../../../components/common/Table/CustomTable";
import { getUserDataFromLocalStorage } from "../../../../../../utils/helper.utils";
import { MechanicPerformanceRes } from "../../../services/types";
import { MechanicReportProps } from "../container/mechanicsPerformanceReportContainer";
import styles from "./MechanicsPerformanceReport.module.css";

export default function MechanicReport(props: MechanicReportProps) {
  const {
    GetMechanicReport,
    getMechanicReportDetails,
    getMechanicReportError,
    getMechanicReportLoading,
    getMechanicReportSuccess,
  } = props;
  const userData: any = getUserDataFromLocalStorage();
  const { state } = useLocation();

  useEffect(() => {
    GetMechanicReport({
      id: userData?.partner_id,
      startDate: state ? state.fromDate : "",
      endDate: state ? state.toDate : "",
    });
  }, [GetMechanicReport, state, userData?.partner_id]);
  const navigation = useNavigate();
  const columns = [
    { id: "name", label: "Mechanic", minWidth: 170 },
    { id: "Complete", label: "Completed Jobs", minWidth: 100 },
    { id: "Pause", label: "In Progress Jobs", minWidth: 100 },
    { id: "Pending", label: "Pending Jobs", minWidth: 100 },
    { id: "taskCount", label: "Total Jobs", minWidth: 100 },
    { id: "Revenue_Earned", label: "Revenue Earned", minWidth: 100 },
    { id: "averageRating", label: "Average Rating", minWidth: 100 },
  ];

  const updatedRows: any = getMechanicReportDetails?.map(
    (row: MechanicPerformanceRes) => ({
      ...row,
      Pending: row.Pending ? row.Pending : "0",
      Pause: row.Pause ? row.Pause : "0",
      Complete: row.Complete ? row.Complete : "0",
      averageRating: (
        <>
          <Rating
            value={Number(row.averageRating)}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
            readOnly
          />
        </>
      ),
    })
  );

  const exportToCSV = useCallback(
    (fileName: string) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(updatedRows);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    },
    [updatedRows]
  );

  return (
    <>
      {getMechanicReportLoading && <CustomLoader />}
      <div className={styles.inventoryNavBar}>
        <ButtonLog
          text={"Back"}
          variant={"contained"}
          onClick={() => navigation("/reports")}
          className={styles.backBtn}
        />
        {/* <div className={styles.filter}>
          <ButtonLog
            text={"Generate"}
            variant={"contained"}
            className={styles.addProducteBtn}
          />
        </div> */}
      </div>
      <div className="row me-1  mt-2">
        <div className="offset-lg-10 offset-md-8 offset-sm-6 col-lg-1 col-md-2 col-sm-3 mt-1">
          <ButtonLog
            text={
              <CSVLink
                style={{ color: "inherit" }}
                headers={columns?.map((item) => ({
                  key: item.id,
                  label: item.label,
                }))}
                data={updatedRows ?? []}
              >
                {" "}
                CSV{" "}
              </CSVLink>
            }
            variant={"contained"}
            className={styles.addProducteBtn}
          />
        </div>
        <div className="col-lg-1 col-md-2 col-sm-3 mt-1">
          <ButtonLog
            text={"XLSX"}
            variant={"contained"}
            onClick={() => exportToCSV("mechanic_report")}
            className={styles.addProducteBtn}
          />
        </div>
      </div>
      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <CustomTable columns={columns} rows={updatedRows} />
      </div>
    </>
  );
}
