import { IconButton } from "@mui/material";
import closeCircle from "../../../assets/images/close-circle.png";
import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";

interface ComplaintProps {
  item: {
    complaint: string;
  };
  index: number;
  handleRemoveComplaint: (item: { complaint: string }) => void;
}

export function Complaint(props: ComplaintProps) {
  const { item, index, handleRemoveComplaint } = props;

  const handleClick = () => {
    handleRemoveComplaint(item);
  };

  return (
    <div className={styles.complaint_wrapper}>
      <p className={styles.complaint_name}>{item.complaint}</p>
      <IconButton onClick={handleClick}>
        <img src={closeCircle} alt="" width={24} height={24} />
      </IconButton>
    </div>
  );
}
