import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { GetVehicleDetails } from "./VehicleCustomerDetails.request";
export const initialState: any = {
  error: false,
  success: false,
  loading: false,
};

export const getVehicleData = createAsyncThunk(
  "vehicleData/get",
  (data: any) => {
    return GetVehicleDetails(data)
      .then((res: any) => {
        if (res?.data?.result) {
          return res.data.result;
        } else {
          throw Promise.reject({ message: "Failed to get Vehicle Data" });
        }
      })
      .catch((error: any) => {
        console.log("err", error);
        throw Promise.reject({ message: "Failed to get Vehicle Data" });
      });
  }
);
export const vehicleCustomerSlice = createSlice({
  name: "vehicleCustomerData",
  initialState,
  reducers: {
    reset: () => initialState,
    setVehicleCustomerData: (state, action: PayloadAction<any>) => {
      return { ...state, vehicleCustomerData: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVehicleData.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getVehicleData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.success = true;
        state.error = false;
      }
    );
    builder.addCase(getVehicleData.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
  },
});

export default vehicleCustomerSlice.reducer;
export const { reset, setVehicleCustomerData } = vehicleCustomerSlice.actions;
