import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { CreateEnquiry } from "./planEnquiry.request";
import { EnquiryDT, EnquiryPlanState } from "./types";

export const initialState: EnquiryPlanState = {
  createEnquiryRequest_Loading: false,
  createEnquiryRequest_Error: false,
  createEnquiryRequest_Success: false,
  createEnquiryRequest_details: null,
};
export const createEnquiryRequest = createAsyncThunk(
  "user/createEnquiryRequest",
  (body: EnquiryDT) => {
    return CreateEnquiry(body)
      .then((res) => {
        if (res.status === 201) {
          return res.data.message;
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        throw new Error("Something went wrong");
      });
  }
);

export const EnquiryPlan = createSlice({
  name: "EnquiryPlan",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createEnquiryRequest.pending, (state) => {
      state.createEnquiryRequest_Loading = true;
      state.createEnquiryRequest_Error = false;
      state.createEnquiryRequest_Success = false;
    });
    builder.addCase(
      createEnquiryRequest.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createEnquiryRequest_Loading = false;
        state.createEnquiryRequest_Error = false;
        state.createEnquiryRequest_Success = true;
        state.createEnquiryRequest_details = action?.payload;
      }
    );
    builder.addCase(
      createEnquiryRequest.rejected,
      (state, action: AnyAction) => {
        state.createEnquiryRequest_Loading = false;
        state.createEnquiryRequest_Error = false;
        state.createEnquiryRequest_Success =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default EnquiryPlan.reducer;
export const { reset } = EnquiryPlan.actions;
