import { createSlice } from "@reduxjs/toolkit";

import { GenerateReportINIT } from "./types";

export const initialState: GenerateReportINIT = {};

export const generateReportSlice = createSlice({
  name: "generateReport",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {},
});

export default generateReportSlice.reducer;
export const { reset } = generateReportSlice.actions;
