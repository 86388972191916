import { useEffect } from "react";
import ProductCategory from "../../../../../components/appSpecific/ProductCategory/components/ProductCategory";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { getUserDataFromLocalStorage } from "../../../../../utils/helper.utils";
import { ConfigurationProps } from "../../container/configuartionContainer";

export default function ProductCategoryPage({
  loading,
  GetAllProductCate,
  productCategoryDetails,
  getAllProductCateLoading,
}: ConfigurationProps) {
  const userData = getUserDataFromLocalStorage();

  useEffect(() => {
    GetAllProductCate(userData?.partner_id);
  }, [GetAllProductCate, userData?.partner_id]);

  return (
    <>
      {loading || (getAllProductCateLoading && <CustomLoader />)}
      <ProductCategory productCategoryDetails={productCategoryDetails} />
    </>
  );
}
