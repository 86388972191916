import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface Complaint {
  complaint: string;
  duration: number;
  id: number;
  assignedto: string;
}
interface ComplaintState {
  complaints: Complaint[];
}
const initialState: ComplaintState = {
  complaints: [],
};
const complaintSlice = createSlice({
  name: "complaints",
  initialState,
  reducers: {
    resetComplaints: (state) => {
      state.complaints = [];
    },
    addComplaints: (state, action: PayloadAction<any>) => {
      const newItem = action.payload;
      state.complaints.push(newItem);
    },
    updateComplaints: (state, action: PayloadAction<any>) => {
      const newItem = action.payload;
      state.complaints = newItem;
    },
    removeComplaints: (state, action: PayloadAction<any>) => {
      const newItem = action.payload;
      state.complaints = state.complaints.filter(
        (item: any) => item.complaint !== newItem.complaint
      );
    },
  },
});

export const {
  addComplaints,
  removeComplaints,
  updateComplaints,
  resetComplaints,
} = complaintSlice.actions;

export default complaintSlice.reducer;
