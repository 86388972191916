import axios from "axios";

export const GetVehicleDetails = (data: any) => {
  var options = {
    method: "POST",
    url: `${process.env.REACT_APP_API_ENDPOINT}/get/vehicle`,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
    data: {
      jsonrpc: "2.0",
      params: {
        vehicle_no: "OD05HY7788",
        // this vehicle number will be dynamically fetched
      },
    },
  };
  const req = axios.request(options);
  return req;
};
