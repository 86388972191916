import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../../redux/store";
import { getGarageReport } from "../../../services/reportsSlice";
import { GarageReportRes, ReportDT } from "../../../services/types";
import GenerateReport from "../components/GarageReport";

interface StateProps {
  getGarageReportError: boolean;
  getGarageReportLoading: boolean;
  getGarageReportSuccess: boolean;
  getGarageReportDetails: GarageReportRes[];
}

interface DispatchProps {
  GetGarageReport(body: ReportDT): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getGarageReportDetails: state.reports.getGarageReportDetails,
    getGarageReportError: state.reports.getGarageReportError,
    getGarageReportLoading: state.reports.getGarageReportLoading,
    getGarageReportSuccess: state.reports.getGarageReportSuccess,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetGarageReport(body) {
      dispatch(getGarageReport(body));
    },
  };
};

export interface GenerateReportProps extends StateProps, DispatchProps {}
export const GenerateReportComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateReport);
