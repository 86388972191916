import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import InProgress from "../../../../assets/images/garage_owner.svg";
import Ready from "../../../../assets/images/supervisor_icon.svg";
import styles from "../NewOwnerDashbord.module.css";

interface OwnerDashboardDT {
  partner: number;
  revenue: number;
  averagePlatformRatingPercentage: number;
}

export default function JobCard({
  inProgress,
  ready,
  delivered,
  pending,
  data,
}: {
  inProgress?: number;
  ready?: number;
  delivered?: number;
  pending?: number;
  data: OwnerDashboardDT;
}) {
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (percentage < 85.5) {
        setPercentage(percentage + 1);
      }
    }, 60);
  }, [percentage]);
  return (
    <>
      <div className={styles.job_counter_root}>
        <div className="row">
          <div className="col-md-12 col-lg-5">
            <Card variant="outlined" className={styles.jobStatusCard_progress}>
              <div className={styles.inprogressImg}>
                <img
                  src={InProgress}
                  height={22}
                  width={22}
                  // className={"me-5"}
                  alt=""
                />
              </div>

              <div className={styles.jobStatusCard_rightContain}>
                <h3 className={styles.txt_count_progress}>
                  {data?.partner ?? 0}
                </h3>
                <p className={styles.jobStatus_txt}>Total Partners</p>
              </div>
            </Card>
          </div>
          <div className="col-md-12 col-lg-4">
            <Card variant="outlined" className={styles.jobStatusCard_ready}>
              <div className={styles.readyImg}>
                <img
                  src={Ready}
                  height={22}
                  width={22}
                  // className={"me-5"}
                  alt=""
                />
              </div>
              <div className={styles.jobStatusCard_rightContain}>
                <h3 className={styles.txt_count_redy}>
                  ₹ {data?.revenue ?? "0"}
                </h3>
                <p className={styles.jobStatus_txt}>Total Revenue</p>
              </div>
            </Card>
          </div>

          <div className="col-md-3">
            <Card variant="outlined" className={styles.root_Revenue_customer}>
              <div className={styles.job_headbar}>
                <div>
                  <p className={styles.txt_job2}>
                    {" "}
                    Customer Satisfaction Index
                  </p>
                </div>
              </div>
              <div>
                <div>
                  {/* <p className={styles.count_revenue}> S$ 5852.00</p> */}
                </div>
                {/* <Chart /> */}
                <div className={styles.progress_bar_root}>
                  <CircularProgressbar
                    value={data?.averagePlatformRatingPercentage}
                    text={`${data?.averagePlatformRatingPercentage?.toFixed(
                      2
                    )}%`}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: "butt",
                      textSize: "17px",
                      pathTransitionDuration: 0.5,
                      pathColor: "#0761E9",
                      textColor: "#272D37",
                      trailColor: "#F5F8FE",
                    })}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
