import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { Button } from "@mui/material";
import { Edit } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { RoleFunctionRes } from "../../../../pages/app/Configuration/types";
import {
  getAuthDataFromLocalStorage,
  getUserDataFromLocalStorage,
  isFunctionAllowed,
} from "../../../../utils/helper.utils";
import ButtonLog from "../../../common/Button/Button";
import CustomTable from "../../../common/Table/CustomTable";
import styles from "./SubscriptionPlan.module.css";

export default function SubscriptionPlan() {
  const authData = getAuthDataFromLocalStorage();
  const user = getUserDataFromLocalStorage();
  const navigation = useNavigate();
  const columns = [
    { id: "rolename", label: "Role Name", minWidth: 100 },
    { id: "functionName", label: "Functions Access", maxWidth: 100 },
    // { id: "is_active", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleEditClick = (subscription_plan: RoleFunctionRes) => {
    navigation("/subscription-plan-form/edit", {
      state: { subscription_plan },
    });
  };

  const updatedRows = []?.map((row) => ({
    action: (
      <>
        (
        <>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Edit size="18" color="#0761e9" />}
            onClick={() => handleEditClick(row)}
          >
            Edit
          </Button>
        </>
        )
      </>
    ),
  }));

  return (
    <>
      {/* <div className={styles.inventoryNavBar}>
        <div className={styles.filter}>
          <ButtonLog
            text={"Add Role"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/role-form/add")}
          />
        </div>
      </div> */}
      <div className={styles.inventoryNavBar}>
        <ButtonLog
          text={"Back"}
          variant={"contained"}
          onClick={() => navigation(-1)}
          className={styles.backBtn}
        />
        <div className={styles.filter}>
          {isFunctionAllowed(authData, "Masters", "Role Function Mapping") ||
          user.user_type === "Owner" ? (
            <>
              <ButtonLog
                text={"Add Subscription Plan"}
                variant={"contained"}
                className={styles.addProducteBtn}
                startIcon={
                  <ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />
                }
                onClick={() => navigation("/subscription-plan-form/add")}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="container-fluid mt-3">
        <CustomTable columns={columns} rows={updatedRows ? updatedRows : []} />
      </div>
    </>
  );
}
