import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../../../redux/store";
import { SearchVehicleDT } from "../../../service/types";
import {
  createJobCard,
  searchJobCard,
} from "../../../service/veichleRegistrationSlice";
import { VehicleCustomerDetails } from "../VehicleCustomerDetails";
import { getVehicleData, reset } from "../services/VehicleCustomerDetailsSlice";

interface StateProps {
  success: boolean;
  error: string | boolean | undefined;
  loading: boolean;
  createJobCardSuccess: boolean;
  createJobCardError: boolean;
  createJobCardLoading: boolean;
  createJobCardDetails: { MESSAGE: number } | undefined;
  searchJobCardSuccess: boolean;
  searchJobCardError: boolean;
  searchJobCardLoading: boolean;
  searchJobCardDetails: SearchVehicleDT | null;
}

interface DispatchProps {
  getVehicleData(data: any): void;
  resetVehicleStates(): void;
  createJobCard(data: any): void;
  searchJobCard(data: any): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.vehicleCustomerData.success,
    error: state.vehicleCustomerData.error,
    loading: state.vehicleCustomerData.loading,
    createJobCardSuccess: state.vehicleRegistration.createJobCardSuccess,
    createJobCardError: state.vehicleRegistration.createJobCardError,
    createJobCardLoading: state.vehicleRegistration.createJobCardLoading,
    createJobCardDetails: state.vehicleRegistration.createJobCardDetails,
    searchJobCardSuccess: state.vehicleRegistration.searchJobCardSuccess,
    searchJobCardError: state.vehicleRegistration.searchJobCardError,
    searchJobCardLoading: state.vehicleRegistration.searchJobCardLoading,
    searchJobCardDetails: state.vehicleRegistration.searchJobCardDetails,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    getVehicleData: (data) => {
      dispatch(getVehicleData(data));
    },
    resetVehicleStates: () => {
      dispatch(reset());
    },
    createJobCard: (data) => {
      dispatch(createJobCard(data));
    },
    searchJobCard: (data) => {
      dispatch(searchJobCard(data));
    },
  };
};

export interface VehicleCustomerProps extends StateProps, DispatchProps {}
export const VehicleCustomerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleCustomerDetails);
