import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  SnackbarOrigin,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import {
  getUserDataFromLocalStorage,
  validate,
  validationData,
} from "../../../../../../utils/helper.utils";
import ButtonLog from "../../../../../common/Button/Button";
import { SnackBar } from "../../../../../common/SnackBar/SnackBar";
import styles from "../../StockInventory.module.css";
import { StockInventoryFormProps } from "../container/stockInventoryFormContainer";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { ProductCategoryRes } from "../../../../../../pages/app/Configuration/types";
import CustomLoader from "../../../../../common/Loader/Loader";
export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}

export default function StockInventoryForm(props: StockInventoryFormProps) {
  const {
    GetAllActiveProductCate,
    activeProductCategoryDetails,
    getAllProductActiveCateError,
    getAllProductActiveCateLoading,
    getAllProductActiveCateSuccess,
    GetAllSupplier,
    SupplierDetails,
    getAllSupplierError,
    getAllSupplierLoading,
    getAllSupplierSuccess,
    CreateStock,
    UpdateStock,
    createStock_Details,
    createStock_Error,
    createStock_Loading,
    createStock_Success,
    resetAll,
    updateStock_Details,
    updateStock_Error,
    updateStock_Loading,
    updateStock_Success,
  } = props;
  const navigate = useNavigate();
  const { state } = useLocation();

  const userDataParse = getUserDataFromLocalStorage();

  React.useEffect(() => {
    GetAllActiveProductCate(userDataParse?.partner_id);
    GetAllSupplier(userDataParse?.partner_id);
  }, [GetAllActiveProductCate, GetAllSupplier, userDataParse?.partner_id]);

  const [checked, setChecked] = React.useState(
    state ? state?.stock?.IsActive : true
  );

  const [selectedProducts, setSelectedProducts] =
    useState<ProductCategoryRes[]>();
  const [productStock, setProductStock] = React.useState({
    product_category: state?.stock ? state?.stock[0]?.product_category : "",
    product: state?.stock ? state?.stock[0]?.product : "",
    quantity: state?.stock ? state?.stock[0]?.quantity : 0,
    unit_price: state?.stock ? state?.stock[0]?.unit_price : 0,
    receipt_number: state?.stock ? state?.stock[0]?.receipt_number : "",
    receipt_date: state?.stock ? state?.stock[0]?.receipt_date : "",
    po_reference_number: state?.stock
      ? state?.stock[0]?.po_reference_number
      : "",
    po_date: state?.stock ? state?.stock[0]?.po_date : "",
    supplier: state?.stock ? state?.stock[0]?.supplier : "",
    partner_id: userDataParse?.partner_id,
    slug: "partner_1",
    base_price: state?.stock ? state?.stock[0]?.base_price : 0,
  });

  const [formType, setFormType] = React.useState<string | undefined>(
    "stock-inventory-form/add"
  );
  let param = useParams();
  React.useEffect(() => {
    const value1 = param["*"];
    setFormType(value1);
  }, [param]);
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "success",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const ValidateData: validationData[] = [
    {
      checkwith: "min",
      value: productStock.product_category,
      errorMsz: "Please select product category",
      // max: 10,
      min: 1,
    },
    {
      checkwith: "min",
      value: productStock.product,
      errorMsz: "Please select product",
      // max: 50,
      min: 1,
    },
    {
      checkwith: "min",
      value: productStock.receipt_number,
      errorMsz: "Please select receipt number",
      // max: 10,
      min: 1,
    },
    {
      checkwith: "min",
      value: productStock.po_reference_number,
      errorMsz: "Please select PO Reference Number",
      // max: 10,
      min: 1,
    },
    {
      checkwith: "min",
      value: productStock.po_date,
      errorMsz: "Please select PO Date",
      // max: 10,
      min: 1,
    },
    {
      checkwith: "min",
      value: productStock.base_price,
      errorMsz: "Please enter base price",
      // max: 10,
      min: 1,
    },
  ];
  const handleSave = () => {
    validate(ValidateData).status
      ? CreateStock({
          ...productStock,
          created_by: userDataParse.id,
        })
      : setSnackState({
          ...snackState,
          snackOpen: true,
          message: validate(ValidateData).message,
          snackSeverity: "error",
        });
  };
  const handleEdit = () => {
    UpdateStock({
      ...productStock,
      IsActive: true,
      stock_id: state.stock ? state?.stock[0]?.id : "0",
      updated_by: userDataParse.id,
    });
  };

  React.useEffect(() => {
    if (createStock_Success) {
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: createStock_Details
          ? createStock_Details
          : "Created Successfully",
        snackSeverity: "success",
      });
      setTimeout(() => {
        navigate("/products");
      }, 300);
      resetAll();
    } else if (createStock_Error) {
      resetAll();
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: String(createStock_Details || createStock_Error),

        snackSeverity: "error",
      });
    }
  }, [
    createStock_Details,
    createStock_Error,
    createStock_Success,
    navigate,
    resetAll,
    snackState,
  ]);

  React.useEffect(() => {
    if (updateStock_Success) {
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: updateStock_Details
          ? updateStock_Details
          : "Created Successfully",
        snackSeverity: "success",
      });
      setTimeout(() => {
        navigate(-1);
      }, 300);
      resetAll();
    } else if (updateStock_Error) {
      resetAll();
      setSnackState({
        ...snackState,
        snackOpen: true,
        message: String(updateStock_Details || updateStock_Error),
        snackSeverity: "error",
      });
    }
  }, [
    navigate,
    resetAll,
    snackState,
    updateStock_Details,
    updateStock_Error,
    updateStock_Success,
  ]);

  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackSeverity}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    const selectedProduct =
      (activeProductCategoryDetails &&
        activeProductCategoryDetails.find(
          (category: any) => category.id === productStock.product_category
        )?.Products) ||
      [];
    setSelectedProducts(selectedProduct);
  }, [activeProductCategoryDetails, productStock.product_category]);

  return (
    <>
      {getAllProductActiveCateLoading ||
      updateStock_Loading ||
      createStock_Loading ||
      getAllSupplierLoading ? (
        <CustomLoader />
      ) : null}
      <SnackBar
        snackOpen={snackOpen}
        closeSnack={closeSnack}
        vertical={vertical}
        horizontal={horizontal}
        snackSeverity={snackSeverity}
        action={action}
        message={message}
      />
      <div className={styles.root_contaer_box}>
        <div className={styles.pagrHeadingContainer}>
          <p className={styles.pageHeadig}>Stock In</p>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Product Category
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <Select
                  className={styles.outline_inputbox}
                  onChange={(e) => {
                    setProductStock((prevState) => ({
                      ...prevState,
                      product_category: e.target.value,
                    }));
                  }}
                  value={productStock?.product_category}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    Select Product Category
                  </MenuItem>
                  {activeProductCategoryDetails &&
                    activeProductCategoryDetails?.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Product
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <Select
                  className={styles.outline_inputbox}
                  onChange={(e) => {
                    setProductStock((prevState) => ({
                      ...prevState,
                      product: e.target.value,
                    }));
                  }}
                  value={productStock?.product}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    Select Product
                  </MenuItem>
                  {selectedProducts &&
                    selectedProducts?.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>quantity</span>
                <OutlinedInput
                  placeholder="0"
                  className={styles.outline_inputbox}
                  onChange={(e) => {
                    setProductStock((prevState) => ({
                      ...prevState,
                      quantity: parseInt(e.target.value),
                    }));
                  }}
                  value={productStock.quantity}
                  type="number"
                />
              </FormControl>
            </div>

            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Base Price
                  <span
                    style={{
                      color: "red",
                    }}
                  ></span>
                </span>
                <OutlinedInput
                  placeholder="Base Price"
                  className={styles.outline_inputbox}
                  onChange={(e) => {
                    setProductStock((prevState) => ({
                      ...prevState,
                      base_price: parseInt(e.target.value),
                    }));
                  }}
                  value={productStock.base_price}
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">
                      {userDataParse.currency_data.symbol}
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Unit Price
                  <span
                    style={{
                      color: "red",
                    }}
                  ></span>
                </span>
                <OutlinedInput
                  placeholder="Price"
                  className={styles.outline_inputbox}
                  startAdornment={
                    <InputAdornment position="start">
                      {userDataParse.currency_data.symbol}
                    </InputAdornment>
                  }
                  onChange={(e) => {
                    setProductStock((prevState) => ({
                      ...prevState,
                      unit_price: parseInt(e.target.value),
                    }));
                  }}
                  value={productStock.unit_price}
                  type="number"
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Receipt Number
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  placeholder="Enter Receipt Number"
                  className={styles.outline_inputbox}
                  onChange={(e) => {
                    setProductStock((prevState) => ({
                      ...prevState,
                      receipt_number: e.target.value,
                    }));
                  }}
                  value={productStock.receipt_number}
                  type="text"
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Receipt Date
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{ background: "white", borderRadius: "0px" }}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                    onChange={(date: any) => {
                      setProductStock((prevState) => ({
                        ...prevState,
                        receipt_date: moment(date).format("MMMM Do YYYY"),
                      }));
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  PO Reference Number
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <OutlinedInput
                  placeholder="Enter PO Reference Number"
                  className={styles.outline_inputbox}
                  onChange={(e) => {
                    setProductStock((prevState) => ({
                      ...prevState,
                      po_reference_number: e.target.value,
                    }));
                  }}
                  value={productStock.po_reference_number}
                  type="text"
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  PO Date
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{ background: "white", borderRadius: "0px" }}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                    onChange={(date: any) => {
                      setProductStock((prevState) => ({
                        ...prevState,
                        po_date: moment(date).format("MMMM Do YYYY"),
                      }));
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl
                className={styles.formControl}
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <span className={styles.label}>
                  Supplier
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </span>
                <Select
                  className={styles.outline_inputbox}
                  onChange={(e) => {
                    setProductStock((prevState) => ({
                      ...prevState,
                      supplier: e.target.value,
                    }));
                  }}
                  value={productStock.supplier}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem
                    value=""
                    disabled
                    className={styles.select_placeholder}
                  >
                    Select Supplier
                    {/* <p className={styles.select_placeholder}>Select Supplier</p> */}
                  </MenuItem>
                  {SupplierDetails &&
                    SupplierDetails?.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className={styles.pageFooterContainer}>
            <ButtonLog
              text={"Back"}
              variant={"contained"}
              className={styles.backBtn}
              onClick={() => navigate(-1)}
            />

            <ButtonLog
              text={"Save"}
              onClick={
                formType === "stock-inventory-form/edit"
                  ? handleEdit
                  : handleSave
              }
              variant="contained"
              type="submit"
              className={styles.saveBtn}
            />
          </div>
        </div>
      </div>
    </>
  );
}
