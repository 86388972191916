import { Search } from "@mui/icons-material";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { OutlinedInput } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../../assets/images/DefaultImage.png";
import edit from "../../../../assets/images/edit.png";
import nodata from "../../../../assets/images/no-data-found.svg";
import { getAllService } from "../../../../pages/app/Configuration/services/configurationSlice";
import { Service } from "../../../../pages/app/Configuration/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { getUserDataFromLocalStorage } from "../../../../utils/helper.utils";
import ButtonLog from "../../../common/Button/Button";
import styles from "./ServiceInventory.module.css";

export default function ServiceInventory(props: any) {
  const serviceDetails =
    useSelector((state: RootState) => state.configuration.serviceDetails) ?? [];

  const dispatch: TypedDispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const userData = getUserDataFromLocalStorage();
  const onSubmit = async (data: any) => {
    await dispatch(
      getAllService({
        id: userData.partner_id,
        queryParams: {
          name: data?.name,
        },
      })
    );
  };

  const navigation = useNavigate();
  interface GroupedServicesType {
    [service_category: string]: Service[];
  }

  const [GroupedParts, updateGroupedParts] = useState<GroupedServicesType>({});
  useEffect(() => {
    updateGroupedParts(_.groupBy(serviceDetails, "service_category_name"));
  }, [serviceDetails]);

  return (
    <div className={styles.InventoryMainContent}>
      <div className={styles.inventoryNavBar}>
        <ButtonLog
          text={"Back"}
          variant={"contained"}
          onClick={() => navigation(-1)}
          className={styles.backBtn}
        />
        <div className={styles.inventorySearch} style={{ display: "flex" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ display: "flex" }}>
              <OutlinedInput
                sx={{
                  borderRadius: "0px !important",
                  height: "40px !important",
                }}
                {...register("name")}
              />
              <ButtonLog
                text="Search"
                variant="contained"
                type="submit"
                startIcon={<Search />}
              />
            </div>
          </form>
        </div>
        <div className={styles.filter}>
          <ButtonLog
            text={"Add Service Category"}
            variant={"contained"}
            className={styles.addProducteCateBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/services-category-form/add")}
          />
          <ButtonLog
            text={"Add Service"}
            variant={"contained"}
            className={styles.addProducteBtn}
            startIcon={<ControlPointOutlinedIcon sx={{ fontSize: "15px" }} />}
            onClick={() => navigation("/services-management-form/add")}
          />
        </div>
      </div>

      <div className="container-fluid" style={{ paddingTop: "25px" }}>
        <div className={styles.rootService}>
          {serviceDetails?.length !== 0 ? (
            <>
              {Object.keys(GroupedParts).map((category, index) => (
                <div className="mb-3" key={index}>
                  <p className={styles.cata_title}>{category}</p>
                  <div className="row">
                    {GroupedParts[category].map((service: any, index: any) => (
                      <div
                        className="col-lg-2 col-md-4 col-sm-6 colxs-12 mb-3"
                        key={index}
                      >
                        <div className={styles.spares_wrapper}>
                          <div className="d-flex justify-content-center">
                            <div className={styles.img_wrapper}>
                              <img
                                src={
                                  service.image_path
                                    ? `${
                                        process.env.REACT_APP_API_ENDPOINT
                                      }/${service.image_path.replace(
                                        "uploads/",
                                        ""
                                      )}`
                                    : defaultImage
                                }
                                style={{ objectFit: "cover" }}
                                alt="pic"
                                width={"auto"}
                                height={"100%"}
                                className={styles.img_wrapper_img}
                              />
                            </div>
                          </div>
                          <p className={styles.spares_name}>{service.name}</p>
                          <div className=" ">
                            <p
                              className={styles.spares_cost}
                            >{`Cost : ${service.price}`}</p>
                          </div>
                          <div className=" ">
                            <p
                              className={styles.spares_stock}
                            >{`Estimated Time ${service.durationInMinute}`}</p>
                          </div>
                          <div
                            className={styles.edit_item_botton_spares}
                            onClick={() =>
                              navigation("/services-management-form/edit", {
                                state: { service },
                              })
                            }
                          >
                            <ButtonLog
                              startIcon={
                                <img
                                  src={edit}
                                  alt="pic"
                                  width={14}
                                  height={14}
                                  className="img-fluid"
                                />
                              }
                              text={"Edit"}
                              variant={"outlined"}
                              className={styles.editBtn}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <img src={nodata} alt="" className="img-fluid " width="500px" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
