import Brightness1Icon from "@mui/icons-material/Brightness1";
import { Box, IconButton, Modal, Tooltip } from "@mui/material";
import { DriverRefresh, Trash } from "iconsax-react";
import React from "react";
import defaultImage from "../../../assets/images/DefaultImage.png";
import info from "../../../assets/images/info_vartici.gif";
import { Service_JobCard } from "../../../pages/app/Manage/Home/type";
import styles from "../../../pages/app/Manage/VeichleRegistration/components/Registration.module.css";
import { getUserDataFromLocalStorage } from "../../../utils/helper.utils";
import ButtonLog from "../../common/Button/Button";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};
export function TechnicianListHome(props: Service_JobCard) {
  const {
    ServiceID,
    ServiceName,
    ServicePrice,
    jobcard_serviceId,
    isActive,
    service_image,
    Status,
    task_status,
    handleServiceDelete,
    handleReassignModal,
  } = props;
  const statusColors: any = {
    Pending: "warning",
    Start: "primary",
    StartAgain: "primary",
    Paused: "info",
    Completed: "success",
  };
  const status: any = {
    Pending: "Pending",
    Start: "In Progress",
    StartAgain: "In Progress",
    Paused: "Paused",
    Completed: "Completed",
  };
  const userData = getUserDataFromLocalStorage();

  const statusColor = statusColors[task_status] || "default";
  const taskStatus = status[task_status] || "Pending";
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <div
        className="col-lg-3 col-md-4 col-sm-4 col-xs-12"
        style={{ position: "relative" }}
        key={ServiceID}
      >
        <Tooltip arrow title={taskStatus}>
          <IconButton
            style={{
              float: "right",
              position: "absolute",
              right: "10px",
            }}
          >
            <Brightness1Icon color={statusColor} />
          </IconButton>
        </Tooltip>
        <div className={styles.spares_wrapper}>
          <div className="d-flex justify-content-center mt-3">
            <div className={styles.img_wrapper}>
              <img
                src={
                  service_image
                    ? `${
                        process.env.REACT_APP_API_ENDPOINT
                      }/${service_image?.replace("uploads/", "")}`
                    : defaultImage
                }
                alt="pic"
                className={styles.img_wrapper_img}
              />
            </div>
          </div>
          <p className={styles.spares_name}>{ServiceName}</p>
          <div className="mt-1">
            <p className={styles.spares_cost}>
              {userData.currency_data.symbol}
              {ServicePrice}/-
            </p>
          </div>
          {Status !== "Delivered" && Status !== "Ready" ? (
            <div className="d-flex flex-row justify-content-center align-content-center align-items-center mt-2">
              <Tooltip arrow title={"Re-assign"}>
                <IconButton
                  className="me-3"
                  onClick={() =>
                    handleReassignModal(jobcard_serviceId, ServiceName)
                  }
                >
                  <DriverRefresh size="22" color="#334455" variant="Outline" />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title={"Delete"}>
                <IconButton
                  className="ms-3"
                  // onClick={() => handleServiceDelete(jobcard_serviceId)}
                  onClick={handleOpen}
                >
                  <Trash size="22" color="#c51818" variant="Outline" />
                </IconButton>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className={styles.iconInfo}>
            {/* <InfoCircle size="52" color="#F1948A" /> */}
            <img src={info} height={69} alt="" />
          </Box>
          <Box className={styles.modal_title}>
            <p> Are You Sure ? </p>
            <p> You want to delete this service</p>
          </Box>
          <Box className={styles.btn_group}>
            <Box className={styles.btn_standard}>
              <ButtonLog
                onClick={handleClose}
                text={"Cancel"}
                variant={"contained"}
                className={styles.cancle_btn}
              />
            </Box>
            <Box>
              <ButtonLog
                text={"Delete"}
                variant={"contained"}
                className={styles.addVehicleBtn}
                onClick={() => {
                  handleServiceDelete(jobcard_serviceId);
                  handleClose();
                }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
