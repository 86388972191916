import React, { useCallback, useEffect, useState } from "react";

import styles from "./dashbord.module.css";
// import { ArrowUp } from "iconsax-react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import Operations from "../../../components/appSpecific/Opertions/Opertions";
import Reviews from "../../../components/appSpecific/Reviews/Reviews";
import CustomLoader from "../../../components/common/Loader/Loader";
import { getUserDataFromLocalStorage } from "../../../utils/helper.utils";
import { Revenue } from "../Revenue/components/Revenue";
import { DashboardProps } from "./container/DashboardContainer";
import { useGetJobcardDashboardInfor } from "./service/dashboard.request";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const getStartOfPreviousNDays = (n: number) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const past = new Date();
  past.setTime(today.getTime() - n * 24 * 60 * 60 * 1000);
  return past;
};

const getStartOfPreviousNMonths = (n: number) => {
  const currentDate = new Date();
  currentDate.setDate(1); // Set to the first day of the current month
  currentDate.setHours(0, 0, 0, 0); // Set to the beginning of the day
  // Calculate the new month and year values
  let newMonth = currentDate.getMonth() - n;
  let newYear = currentDate.getFullYear();
  // Handle the case when the previous 3 months cross into the previous year
  while (newMonth < 0) {
    newMonth += 12;
    newYear -= 1;
  }
  currentDate.setMonth(newMonth);
  currentDate.setFullYear(newYear);
  return currentDate;
};

type FilterMode = "1" | "7" | "30" | "60" | "90";

export default function DashboardNew(props: DashboardProps) {
  const {
    GetAllCustMechReview,
    GetAllCustReview,
    getAllCustReview_details,
    getAllMechReview_details,
    getAllCustReview_loading,
    getAllMechReview_loading,
  } = props;
  const [age, setAge] = React.useState("");
  const userData: any = getUserDataFromLocalStorage();
  useEffect(() => {
    GetAllCustMechReview(userData.partner_id);
    GetAllCustReview(userData.partner_id);
  }, [GetAllCustMechReview, GetAllCustReview, userData.partner_id]);

  const [filterMode, setFilterMode] = useState<FilterMode>("1");
  const [alignment, setAlignment] = React.useState("weekly");
  const [button, setButton] = React.useState("Operation");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };
  const handleChangeButton = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setButton(newAlignment);
  };
  const getDatetimeRangeByFilterMode = useCallback((): {
    startDate: any;
    endDate: any;
  } => {
    switch (filterMode) {
      case "1":
        return {
          startDate: getStartOfPreviousNDays(0),
          endDate: getStartOfPreviousNDays(-1),
        };

      case "7":
        return {
          startDate: getStartOfPreviousNDays(7),
          endDate: getStartOfPreviousNDays(0),
        };

      case "30":
        return {
          startDate: getStartOfPreviousNMonths(1),
          endDate: getStartOfPreviousNMonths(0),
        };

      case "60":
        return {
          startDate: getStartOfPreviousNMonths(2),
          endDate: getStartOfPreviousNMonths(0),
        };

      case "90":
        return {
          startDate: getStartOfPreviousNMonths(3),
          endDate: getStartOfPreviousNMonths(0),
        };
    }
  }, [filterMode]);

  const { data } = useGetJobcardDashboardInfor(
    userData?.partner_id
    // getDatetimeRangeByFilterMode()
  );

  return (
    <div className={styles.main_root}>
      <div className="container-fluid">
        <div className={styles.job_headbar}>
          <div>
            <p className={styles.txt_job}> Greetings, {userData.name}!</p>
          </div>
          <div className="">
            <ToggleButtonGroup
              color="primary"
              value={button}
              exclusive
              onChange={handleChangeButton}
              aria-label="Platform"
            >
              <ToggleButton className={styles.Operation_Btn} value="Operation">
                Operation
              </ToggleButton>
              <ToggleButton className={styles.Operation_Btn} value="Revenue">
                Revenue
              </ToggleButton>
              <ToggleButton className={styles.Operation_Btn} value="Reviews">
                Reviews
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {/* <div>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton className={styles.Operation_Btn} value="weekly">
                Weekly
              </ToggleButton>
              <ToggleButton className={styles.Operation_Btn} value="monthly">
                Monthly
              </ToggleButton>
              <ToggleButton className={styles.Operation_Btn} value="yearly">
                Yearly
              </ToggleButton>
            </ToggleButtonGroup>
          </div> */}
        </div>
        {getAllMechReview_loading ? (
          <CustomLoader />
        ) : (
          <>
            {button === "Operation" && <Operations />}
            {button === "Revenue" && <Revenue />}
            {button === "Reviews" && (
              <Reviews
                getAllMechReview_details={getAllMechReview_details}
                getAllCustReview_details={getAllCustReview_details}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
