import { useEffect } from "react";
import ServiceCategory from "../../../../../components/appSpecific/ServiceCategory/components/ServiceCategory";
import CustomLoader from "../../../../../components/common/Loader/Loader";
import { getUserDataFromLocalStorage } from "../../../../../utils/helper.utils";
import { ConfigurationProps } from "../../container/configuartionContainer";


export default function ServiceCategoryPage(props: ConfigurationProps) {
  const { GetAllServiceCate, loading, serviceCategoryDetails } = props;
  const userData = getUserDataFromLocalStorage();

  useEffect(() => {
    GetAllServiceCate(userData?.partner_id);
  }, [GetAllServiceCate, userData?.partner_id]);
  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <ServiceCategory serviceCategoryDetails={serviceCategoryDetails} />
      )}
    </>
  );
}
