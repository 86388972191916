import { InputType } from "./types";
export const RequiredEmptyFieldText = "*This is a required filed";
export const ErrorMessageBasedOnType = (type: InputType) => {
  const errorMessage = {
    email: "Invalid E-mail address",
    password: "Invalid Password",
    text: "Field cannot be empty",
    phone: "Invalid Phone number",
    textarea: "Field cannot be empty",
    number: "Field cannot be empty",
  }[type];
  return errorMessage;
};
